// Actions
import { enterPage } from "../redux/shared/location/locationActions";

// Components
// Boxes
import DiscountIntroBoxesContainer from "../components/boxes/discountProfilePage/discountIntroBoxesContainer";
import DiscountReportConditionBoxesContainer from "../components/boxes/discountProfilePage/discountReportConditionBoxesContainer";
import DiscountRuleBoxesContainer from "../components/boxes/discountProfilePage/discountRuleBoxesContainer";
import MerchandiseGroupBoxesContainer from "../components/boxes/discountProfilePage/merchandiseGroupBoxesContainer";
import MerchandiseGroupMerchandiseBoxesContainer from "../components/boxes/discountProfilePage/merchandiseGroupMerchandiseBoxesContainer";
import UsageInfoBoxesContainer from "../components/boxes/discountProfilePage/usageInfoBoxesContainer";
// Charts
import DiscountTrendChartContainer from "../components/charts/discountProfilePage/discountTrendChartContainer";
// Forms
import MerchandiseGroupDetailFormContainer from "../components/forms/discountProfilePage/merchandiseGroupDetailFormContainer";
// Headers
import DiscountProfilePageHeader from "../components/headers/discountProfilePage/discountProfilePageHeader";
// Spacing Boxes
import SpacingBox from "../components/boxes/spacing/spacingBox";

// Configs
import stylesConfig from "../configs/stylesConfig";

// Material UI
// Components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// React
import { useEffect } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

function DiscountProfilePage() {
  // Hooks
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const tabItem = useSelector((state) => state.discountProfilePage.tabItem);

  // Life Cycle
  useEffect(() => {
    // Update Redux Store
    dispatch(enterPage("DiscountProfile"));
  }, []);

  return (
    <Container maxWidth={false}>
      {/* Header */}
      <SpacingBox
        // Render
        height={stylesConfig.appBarHeight}
      />
      <DiscountProfilePageHeader />
      {/* Content */}
      <Grid container spacing={4}>
        <Grid item xs={12} md={4} lg={3}>
          <DiscountIntroBoxesContainer />
          <SpacingBox
            // Render
            height={stylesConfig.spacingBoxMarginBottom}
          />
          {tabItem === "BasicInfo" && (
            <>
              <MerchandiseGroupBoxesContainer />
              <SpacingBox
                // Render
                height={stylesConfig.spacingBoxMarginBottom}
              />
            </>
          )}
          {tabItem === "UsageStatus" && (
            <>
              <DiscountReportConditionBoxesContainer />
              <SpacingBox
                // Render
                height={stylesConfig.spacingBoxMarginBottom}
              />
            </>
          )}
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          {tabItem === "BasicInfo" && (
            <Grid container spacing={4}>
              <Grid item xs={12} lg={8}>
                <MerchandiseGroupDetailFormContainer />
                <SpacingBox
                  // Render
                  height={stylesConfig.spacingBoxMarginBottom}
                />
                <MerchandiseGroupMerchandiseBoxesContainer />
                <SpacingBox
                  // Render
                  height={stylesConfig.spacingBoxMarginBottom}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <DiscountRuleBoxesContainer />
                <SpacingBox
                  // Render
                  height={stylesConfig.spacingBoxMarginBottom}
                />
              </Grid>
            </Grid>
          )}
          {tabItem === "UsageStatus" && (
            <Grid container spacing={4}>
              <Grid item xs={12} lg={8}>
                <DiscountTrendChartContainer />
                <SpacingBox
                  // Render
                  height={stylesConfig.spacingBoxMarginBottom}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <UsageInfoBoxesContainer />
                <SpacingBox
                  // Render
                  height={stylesConfig.spacingBoxMarginBottom}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}

export default DiscountProfilePage;
