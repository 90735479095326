// Components
// Charts
import PaymentMethodChart from "./paymentMethodChart";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { getAllPaymentMethodsWithCurrentRevenueFetch } from "../../../fetches/paymentMethodFetches";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function PaymentMethodChartContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [paymentMethods, setPaymentMethods] = useState(null);

  // Handle States
  const itemsArr = paymentMethods;

  // Functions
  // Functions - Queries
  const getAllPaymentMethodsWithCurrentRevenue = async () => {
    const results = await getAllPaymentMethodsWithCurrentRevenueFetch(token);

    // Set States
    setPaymentMethods(results.paymentMethods ? results.paymentMethods : null);
  };

  // Life Cycle
  useEffect(() => {
    getAllPaymentMethodsWithCurrentRevenue();
  }, []);

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("付款方式s")}
          </Typography>
        </div>
      </div>
      {/* Content */}
      {itemsArr ? (
        <PaymentMethodChart itemsArr={itemsArr} />
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有付款方式資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default PaymentMethodChartContainer;
