// Configs
import stylesConfig from "./configs/stylesConfig";

// Material-UI
import { createStyles, makeStyles } from "@mui/styles";

export const useAppStyles = makeStyles((theme) =>
  createStyles({
    loginPageContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      width: "100vw",
    },
    pageContainer: {
      height: "100vh",
      width: "100vw",
      paddingTop: stylesConfig.contentPaddingTop,
      paddingBottom: stylesConfig.contentPaddingBottom,
      overflowY: "scroll",
    },
  })
);
