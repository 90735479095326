// Actions
import {
  onSubCategoryChange,
  onFormSubmitCountIncrease,
} from "../../../redux/pages/categoriesAndBrandsPage/categoriesAndBrandsPageActions";

// Components
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { getSubCategoryDetailFormOptionsFetch } from "../../../fetches/formOptionFetches";
import {
  createSubCategoryFetch,
  editSubCategoryFetch,
  getSubCategoryDetailsByIdFetch,
} from "../../../fetches/subCategoryFetches";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function SubCategoryDetailForm() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const categoryId = useSelector(
    (state) => state.categoriesAndBrandsPage.categoryId
  );
  const subCategoryId = useSelector(
    (state) => state.categoriesAndBrandsPage.subCategoryId
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Dialog
  const [
    subCategoryDetailActionDialogText,
    setSubCategoryDetailActionDialogText,
  ] = useState("");
  const [
    subCategoryDetailActionDialogType,
    setSubCategoryDetailActionDialogType,
  ] = useState(null);
  const [showSubCategoryDetailAction, setShowSubCategoryDetailAction] =
    useState(false);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Option Fields
  const [categoryField, setCategoryField] = useState(null);
  // Options
  const [categoryOptions, setCategoryOptions] = useState([]);
  // Render
  const [isOptionsRetrieved, setIsOptionsRetrieved] = useState(false);
  // Switches
  const [isItemActive, setIsItemActive] = useState(true);
  // Text Fields
  const [code, setCode] = useState("");
  const [nameCh, setNameCh] = useState("");
  const [nameEn, setNameEn] = useState("");

  // Events
  // Events - Dialogs
  const onSubCategoryDetailActionCanceled = () => {
    // Set States
    setShowSubCategoryDetailAction(false);
  };

  const onSubCategoryDetailActionConfirmed = () => {
    switch (subCategoryDetailActionDialogType) {
      case "CreateSubCategory":
        createSubCategory();
        break;
      case "EditSubCategory":
        editSubCategory();
        break;
      default:
        break;
    }

    // Set States
    setShowSubCategoryDetailAction(false);
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "categoryField":
        setCategoryField(value);
        break;
      case "code":
        setCode(value);
        break;
      case "isItemActive":
        setIsItemActive(value);
        break;
      case "nameCh":
        setNameCh(value);
        break;
      case "nameEn":
        setNameEn(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked();
    }
  };

  // Events - Forms
  const onSubmitBtnClicked = () => {
    let isError = false;

    if (!categoryField) {
      addToErrorFields("categoryField", t("請先填寫 類別"));
      isError = true;
    }

    if (!code) {
      addToErrorFields("code", t("請先填寫 代號"));
      isError = true;
    }

    if (!nameCh) {
      addToErrorFields("nameCh", t("請先填寫 中文名稱"));
      isError = true;
    }

    if (!nameEn) {
      addToErrorFields("nameEn", t("請先填寫 英文名稱"));
      isError = true;
    }

    if (isError) {
      return;
    }

    displaySubCategoryDetailActionDialog(
      subCategoryId ? "EditSubCategory" : "CreateSubCategory"
    );
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const clearErrorFields = () => {
    // Set States
    setErrorFields([]);
  };

  const clearForm = () => {
    // Set States
    setCategoryField(
      categoryId ? categoryOptions.find((item) => item.id === categoryId) : null
    );
    setCode("");
    setNameCh("");
    setNameEn("");
    setIsItemActive(true);

    clearErrorFields();
  };

  const displaySubCategoryDetailActionDialog = (
    subCategoryDetailActionType
  ) => {
    // Set States
    setSubCategoryDetailActionDialogType(subCategoryDetailActionType);
    switch (subCategoryDetailActionType) {
      case "CreateSubCategory":
        setSubCategoryDetailActionDialogText(t("確認要新增 子類別 嗎？"));
        break;
      case "EditSubCategory":
        setSubCategoryDetailActionDialogText(t("確認要編輯 子類別資料 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowSubCategoryDetailAction(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const createSubCategory = async () => {
    const results = await createSubCategoryFetch(
      token,
      categoryField ? categoryField.id : null,
      code,
      nameCh,
      nameEn,
      isItemActive
    );

    if (results.success) {
      showFormAlert("success", t("成功提交"));

      // Update Redux Store
      dispatch(onSubCategoryChange(results.subCategoryId));
      dispatch(onFormSubmitCountIncrease());
    } else if (results.isDuplicate) {
      showFormAlert("warning", t("此 子類別 已存在"));
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  const editSubCategory = async () => {
    if (subCategoryId) {
      const results = await editSubCategoryFetch(
        token,
        subCategoryId,
        categoryField ? categoryField.id : null,
        code,
        nameCh,
        nameEn,
        isItemActive
      );

      if (results.success) {
        showFormAlert("success", t("成功提交"));

        // Update Redux Store
        dispatch(onFormSubmitCountIncrease());
      } else if (results.isDuplicate) {
        showFormAlert("warning", t("此 子類別 已存在"));
      } else {
        showFormAlert("error", t("未能提交"));
      }
    }
  };

  // Functions - Queries
  const getSubCategoryDetailFormOptions = async () => {
    const results = await getSubCategoryDetailFormOptionsFetch(token);

    // Set States
    setCategoryOptions(results.categories ? results.categories : []);
  };

  const getSubCategoryDetailsById = async () => {
    const results = await getSubCategoryDetailsByIdFetch(token, subCategoryId);

    if (results.subCategoryDetails) {
      const {
        category_id,
        sub_category_code,
        sub_category_name_ch,
        sub_category_name_en,
        is_active,
      } = results.subCategoryDetails;

      // Set States
      setCategoryField(
        category_id
          ? categoryOptions.find((item) => item.id === category_id)
          : null
      );
      setCode(sub_category_code ? sub_category_code : "");
      setNameCh(sub_category_name_ch ? sub_category_name_ch : null);
      setNameEn(sub_category_name_en ? sub_category_name_en : null);
      setIsItemActive(is_active);
    } else {
      // Set States
      setCategoryField(null);
      setCode("");
      setNameCh("");
      setNameEn("");
      setIsItemActive(true);
    }

    clearErrorFields();
  };

  // Life Cycle
  useEffect(() => {
    getSubCategoryDetailFormOptions();
  }, []);

  useEffect(() => {
    // Set States
    if (!isOptionsRetrieved && categoryOptions[0]) {
      setIsOptionsRetrieved(true);
    }
  }, [categoryOptions]);

  useEffect(() => {
    if (isOptionsRetrieved) {
      if (subCategoryId) {
        getSubCategoryDetailsById();
      } else {
        clearForm();
      }
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [isOptionsRetrieved, subCategoryId]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onSubCategoryDetailActionCanceled}
        onDialogConfirmed={onSubCategoryDetailActionConfirmed}
        // States
        dialogText={subCategoryDetailActionDialogText}
        showDialog={showSubCategoryDetailAction}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("子類別資料")}
          </Typography>
        </div>
      </div>
      {/* Form */}
      <Grid
        className={classes.formContainer}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        container
        rowSpacing={1}
      >
        <Grid className={classes.formAutoCompleteContainer} item xs={6}>
          {categoryOptions && (
            <Autocomplete
              disablePortal
              getOptionLabel={(option) => option[t("category_name_ch")]}
              onChange={(event, value) =>
                onInputFieldChange("categoryField", value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              options={categoryOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={checkIsFieldError("categoryField")}
                  helperText={getErrorFieldMessage("categoryField")}
                  label={t("類別")}
                  variant="standard"
                />
              )}
              value={categoryField}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          <TextField
            error={checkIsFieldError("code")}
            fullWidth
            helperText={getErrorFieldMessage("code")}
            label={t("代號")}
            margin="dense"
            name="code"
            onChange={(event) => onInputFieldChange("code", event.target.value)}
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={code}
            variant="standard"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            error={checkIsFieldError("nameCh")}
            fullWidth
            helperText={getErrorFieldMessage("nameCh")}
            label={t("中文名稱")}
            margin="dense"
            name="nameCh"
            onChange={(event) =>
              onInputFieldChange("nameCh", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            required
            value={nameCh}
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={checkIsFieldError("nameEn")}
            fullWidth
            helperText={getErrorFieldMessage("nameEn")}
            label={t("英文名稱")}
            margin="dense"
            name="nameEn"
            onChange={(event) =>
              onInputFieldChange("nameEn", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={nameEn}
            variant="standard"
          />
        </Grid>
        <Grid item xs={6} className={classes.formSwitchContainer}>
          <Typography align={"left"} variant="body1">
            {t("有效")}
          </Typography>
          <Switch
            checked={isItemActive}
            onChange={() => onInputFieldChange("isItemActive", !isItemActive)}
          />
        </Grid>
        <Grid item xs={12} className={classes.formSubmitBtnContainer}>
          <Button
            onClick={onSubmitBtnClicked}
            variant="contained"
            sx={formSubmitBtnStyles}
          >
            {subCategoryId ? t("修改") : t("新增")}
          </Button>
        </Grid>
        {/* Alert */}
        {shouldShowFormAlert && (
          <Grid item xs={12}>
            <Alert severity={formAlertType}>{formAlertText}</Alert>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default SubCategoryDetailForm;
