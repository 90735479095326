export const getPageTitleText = (titleType) => {
  let titleText;

  switch (titleType) {
    case "AgeGroups":
      titleText = "年齡層s";
      break;
    case "CategoriesAndBrands":
      titleText = "類別 / 品牌s";
      break;
    case "Dashboard":
      titleText = "主頁";
      break;
    case "Discounts":
      titleText = "優惠s";
      break;
    case "DiscountProfile":
      titleText = "優惠概覧";
      break;
    case "Merchandises":
      titleText = "商品s";
      break;
    case "MerchandiseSales":
      titleText = "商品銷量s";
      break;
    case "SalesRecords":
      titleText = "銷售紀錄s";
      break;
    case "SalesRecordProfile":
      titleText = "銷售紀錄概覧";
      break;
    case "SalesReports":
      titleText = "銷售報告s";
      break;
    case "Stocks":
      titleText = "庫存s";
      break;
    default:
      titleText = "";
      break;
  }

  return titleText;
};
