// Components
// Backdrop
import LoadingBackdrop from "../../backdrop/loadingBackdrop";
// Charts
import DiscountTrendChart from "./discountTrendChart";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Consts
import { discountNumberTypes } from "../../../consts/discountNumberTypeConsts";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { getDiscountUsageTrendByDiscountIdFetch } from "../../../fetches/discountUsageTrendFetches";

// Helper Functions
import { getDateVarsForSalesTrends } from "../../../helperFunctions/getDateVarForCharts";
import { getMomentLocale } from "../../../helperFunctions/getMomentLocale";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// Moment
import moment from "moment";
import "moment/min/locales";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function DiscountTrendChartContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const comparisonRecordDate = useSelector(
    (state) => state.discountProfilePage.comparisonRecordDate
  );
  const discountId = useSelector(
    (state) => state.discountProfilePage.discountId
  );
  const discountNumberType = useSelector(
    (state) => state.discountProfilePage.discountNumberType
  );
  const isComparisonShown = useSelector(
    (state) => state.discountProfilePage.isComparisonShown
  );
  const language = useSelector((state) => state.language.language);
  const recordDate = useSelector(
    (state) => state.discountProfilePage.recordDate
  );
  const recordDateType = useSelector(
    (state) => state.discountProfilePage.recordDateType
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [usageNumbers, setUsageNumbers] = useState(null);
  const [xAxisDataGridKey, setXAxisDataGridKey] = useState("");
  // Redner
  const [isLoading, setIsLoading] = useState(false);

  // Handle States
  const comparisonRecordDateVar = new Date(comparisonRecordDate);
  const discountNumberTypeItem = discountNumberTypes.find(
    (item) => item.type === discountNumberType
  );
  const itemsArr = usageNumbers;
  const recordDateVar = new Date(recordDate);

  const comparisonRecordDateDisplay =
    recordDateType === "Date"
      ? moment(comparisonRecordDateVar).format(t("Y 年 M 月"))
      : moment(comparisonRecordDateVar).format(t("Y 年"));
  const discountNumberTypeItemName = discountNumberTypeItem
    ? discountNumberTypeItem[t("nameCh")]
    : "";
  const isMoney = discountNumberTypeItem
    ? discountNumberTypeItem.isMoney
    : false;
  const recordDateDisplay =
    recordDateType === "Date"
      ? moment(recordDateVar).format(t("Y 年 M 月"))
      : moment(recordDateVar).format(t("Y 年"));

  // Functions
  // Functions - Queries
  const getDiscountUsageTrendByDiscountId = async () => {
    const {
      startDateVar,
      endDateVar,
      comparisonStartDateVar,
      comparisonEndDateVar,
    } = getDateVarsForSalesTrends(
      recordDateType,
      isComparisonShown,
      recordDateVar,
      comparisonRecordDateVar
    );

    const results = await getDiscountUsageTrendByDiscountIdFetch(
      token,
      discountId,
      discountNumberType,
      recordDateType,
      startDateVar,
      endDateVar,
      comparisonStartDateVar,
      comparisonEndDateVar
    );

    if (results.months) {
      for (let item of results.months) {
        item.usageNumber = Number(item.usageNumber);
        item.comparisonUsageNumber = item.comparisonUsageNumber
          ? Number(item.comparisonUsageNumber)
          : 0;
        item.dateNumberText = moment(item.date).format(t("D日"));
        item.monthNumberText = moment(item.date).format(t("M月"));
      }
    }

    // Set States
    setUsageNumbers(results.months ? results.months : null);

    setIsLoading(false);
  };

  // Life Cycle
  useEffect(() => {
    // Set States
    setXAxisDataGridKey(
      recordDateType === "Date" ? "dateNumberText" : "monthNumberText"
    );
  }, [recordDateType, language]);

  useEffect(() => {
    if (discountId) {
      // Set States
      setIsLoading(true);

      getDiscountUsageTrendByDiscountId();
    }
  }, [
    discountNumberType,
    discountId,
    recordDate,
    comparisonRecordDate,
    recordDateType,
    isComparisonShown,
    language,
  ]);

  useEffect(() => {
    moment.locale(getMomentLocale(language));
  }, [language]);

  return (
    <div className={classes.contentBox}>
      {/* Backdrop */}
      <LoadingBackdrop
        // States
        showBackdrop={isLoading}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {`${t("趨勢分析")} - ${discountNumberTypeItemName}`}
          </Typography>
        </div>
      </div>
      {/* Content */}
      {itemsArr ? (
        <DiscountTrendChart
          // States
          comparisonRecordDateDisplay={comparisonRecordDateDisplay}
          isComparisonShown={isComparisonShown}
          isMoney={isMoney}
          itemsArr={itemsArr}
          recordDateDisplay={recordDateDisplay}
          xAxisDataGridKey={xAxisDataGridKey}
        />
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有趨勢分析")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default DiscountTrendChartContainer;
