const initialState = {
  appliedDiscountId: null,
  discountId: null,
  formSubmitCount: 0,
  merchandiseSoldId: null,
  merchandiseSoldItemId: null,
  salesRecordId: null,
  tabItem: "BasicInfo",
  typeOfAppliedDiscount: null,
};

export const salesRecordProfilePageReducers = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "ON_SALES_RECORD_PROFILE_PAGE_APPLIED_DISCOUNT_CHANGE":
      const { appliedDiscountId, discountId, typeOfAppliedDiscount } = action;
      return {
        ...state,
        appliedDiscountId,
        discountId,
        typeOfAppliedDiscount,
      };
    case "ON_SALES_RECORD_PROFILE_PAGE_FORM_SUBMIT_COUNT_INCREASE":
      return {
        ...state,
        formSubmitCount: state.formSubmitCount + 1,
      };
    case "ON_SALES_RECORD_PROFILE_PAGE_MERCHANDISE_SOLD_CHANGE":
      const { merchandiseSoldId } = action;
      return {
        ...state,
        merchandiseSoldId,
      };
    case "ON_SALES_RECORD_PROFILE_PAGE_MERCHANDISE_SOLD_ITEM_CHANGE":
      const { merchandiseSoldItemId } = action;
      return {
        ...state,
        merchandiseSoldItemId,
      };
    case "ON_SALES_RECORD_PROFILE_PAGE_SALES_RECORD_CHANGE":
      const { salesRecordId } = action;
      return {
        ...state,
        salesRecordId,
      };
    case "ON_SALES_RECORD_PROFILE_PAGE_TAB_ITEM_CHANGE":
      const { tabItem } = action;
      return {
        ...state,
        tabItem,
      };
    default:
      return state;
  }
};
