// Configs
import apisConfig from "../configs/apisConfig";

export const getAllSalesRecordsByConditionsWithPaginationFetch = async (
  token,
  shopId,
  salespersonsId,
  date,
  pageNum,
  limitNum
) => {
  try {
    const queryRoute =
      "/salesRecord/getAllSalesRecordsByConditionsWithPagination";

    const reqBody = {
      shopId,
      salespersonsId,
      date,
      pageNum,
      limitNum,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getSalesRecordDetailsByIdFetch = async (token, salesRecordId) => {
  try {
    const queryRoute = `/salesRecord/getSalesRecordDetailsById/${salesRecordId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
