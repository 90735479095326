// Actions
import { enterPage } from "../redux/shared/location/locationActions";

// Components
// Boxes
import MerchandiseSaleConditionBoxesContainer from "../components/boxes/merchandiseSalesPage/merchandiseSaleConditionBoxesContainer";
import RankedMerchandiseBoxesContainer from "../components/boxes/merchandiseSalesPage/rankedMerchandiseBoxesContainer";
import RankedVariationBoxesContainer from "../components/boxes/merchandiseSalesPage/rankedVariationBoxesContainer";
// Charts
import MerchandiseSalesTrendChartContainer from "../components/charts/merchandiseSalesPage/merchandiseSalesTrendChartContainer";
import SalesNumberChartContainer from "../components/charts/merchandiseSalesPage/salesNumberChartContainer";
import SalesTrendChartContainer from "../components/charts/merchandiseSalesPage/salesTrendChartContainer";

// Headers
import MerchandiseSalesPageHeader from "../components/headers/merchandiseSalesPage/merchandiseSalesPageHeader";
// Spacing Boxes
import SpacingBox from "../components/boxes/spacing/spacingBox";

// Configs
import stylesConfig from "../configs/stylesConfig";

// Material UI
// Components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// React
import { useEffect } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

function MerchandiseSalesPage() {
  // Hooks
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const tabItem = useSelector((state) => state.merchandiseSalesPage.tabItem);

  // Life Cycle
  useEffect(() => {
    // Update Redux Store
    dispatch(enterPage("MerchandiseSales"));
  }, []);

  return (
    <Container maxWidth={false}>
      {/* Header */}
      <SpacingBox
        // Render
        height={stylesConfig.appBarHeight}
      />
      <MerchandiseSalesPageHeader />
      {/* Content */}
      <Grid container spacing={4}>
        <Grid item xs={12} md={4} lg={3}>
          {["Rankings", "SalesNumbers", "SalesTrends"].includes(tabItem) && (
            <MerchandiseSaleConditionBoxesContainer />
          )}
          <SpacingBox
            // Render
            height={stylesConfig.spacingBoxMarginBottom}
          />
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          {tabItem === "Rankings" && (
            <>
              <Grid container spacing={4}>
                <Grid item xs={12} lg={6}>
                  <RankedMerchandiseBoxesContainer />
                  <SpacingBox
                    // Render
                    height={stylesConfig.spacingBoxMarginBottom}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <RankedVariationBoxesContainer />
                  <SpacingBox
                    // Render
                    height={stylesConfig.spacingBoxMarginBottom}
                  />
                  <MerchandiseSalesTrendChartContainer />
                  <SpacingBox
                    // Render
                    height={stylesConfig.spacingBoxMarginBottom}
                  />
                </Grid>
              </Grid>
            </>
          )}
          {tabItem === "SalesNumbers" && <SalesNumberChartContainer />}
          {tabItem === "SalesTrends" && <SalesTrendChartContainer />}
        </Grid>
      </Grid>
    </Container>
  );
}

export default MerchandiseSalesPage;
