// Actions
import { showDrawer } from "../../redux/shared/drawer/drawerActions";

// Material UI
// Components
import Fab from "@mui/material/Fab";
import Tooltip from "@mui/material/Tooltip";
// Icons
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";

// React-Redux
import { useDispatch } from "react-redux";

// Styles
import { showMenuBtnStyles } from "../../styles/componentStyles/fltActBtnStyles/showMenuBtnStyles";

function ShowMenuBtn() {
  // Hooks
  // Redux
  const dispatch = useDispatch();

  // Events
  // Events - Btns
  const onShowDrawerBtnClicked = () => {
    // Update Redux Store
    dispatch(showDrawer("right"));
  };

  return (
    <Tooltip title={"顯示選單"}>
      <Fab
        color="primary"
        onClick={onShowDrawerBtnClicked}
        sx={showMenuBtnStyles}
      >
        <MenuRoundedIcon />
      </Fab>
    </Tooltip>
  );
}

export default ShowMenuBtn;
