const initialState = {
  drawerAnchor: "left",
  isDrawerOpen: false,
};

export const drawerReducers = (state = initialState, action) => {
  switch (action.type) {
    case "CLOSE_DRAWER":
      return {
        ...state,
        isDrawerOpen: false,
      };
    case "SHOW_DRAWER":
      const { drawerAnchor } = action;
      return {
        ...state,
        drawerAnchor: drawerAnchor,
        isDrawerOpen: true,
      };
    default:
      return state;
  }
};
