// Components
import StyledMenu from "../styledMenu";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import MenuItem from "@mui/material/MenuItem";
// Icons
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import RestoreRoundedIcon from "@mui/icons-material/RestoreRounded";

// React-Redux
import { useSelector } from "react-redux";

function MerchandiseActionMenu(props) {
  // Hooks
  // Languages
  const t = useLanguage();

  // Props
  const {
    // States
    showMerchandiseActionMenu,
    // Set States
    setShowMerchandiseActionMenu,
    // Functions
    displayMerchandiseActionDialog,
  } = props;

  // Redux Store
  const typeOfMerchandises = useSelector(
    (state) => state.merchandisesPage.typeOfMerchandises
  );

  // Events
  const onMenuClosed = () => {
    // Set States
    setShowMerchandiseActionMenu(null);
  };

  const onMenuItemClicked = (key) => {
    onMenuClosed();

    switch (key) {
      case "Delete":
        displayMerchandiseActionDialog("DeleteMerchandise");
        break;
      case "Restore":
        displayMerchandiseActionDialog("RestoreMerchandise");
        break;
      default:
        break;
    }
  };

  return (
    <StyledMenu
      // States
      anchorEl={showMerchandiseActionMenu}
      open={Boolean(showMerchandiseActionMenu)}
      // Events
      onClose={onMenuClosed}
    >
      {typeOfMerchandises === "Current" ? (
        <MenuItem disableRipple onClick={() => onMenuItemClicked("Delete")}>
          <DeleteRoundedIcon />
          {t("刪除")}
        </MenuItem>
      ) : (
        <MenuItem disableRipple onClick={() => onMenuItemClicked("Restore")}>
          <RestoreRoundedIcon />
          {t("還原")}
        </MenuItem>
      )}
    </StyledMenu>
  );
}

export default MerchandiseActionMenu;
