// Actions
import {
  onRecordDateChange,
  onTabItemChange,
} from "../../../redux/pages/salesRecordsPage/salesRecordsPageActions";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import IconButton from "@mui/material/IconButton";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
// Icons
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import RestoreRoundedIcon from "@mui/icons-material/RestoreRounded";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardListPageStyles } from "../../../styles/pageStyles/standardListPageStyles";

// Consts
const today = new Date();

function SalesRecordsPageHeader() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardListPageStyles();

  // Redux Store
  const recordDate = useSelector((state) => state.salesRecordsPage.recordDate);
  const tabItem = useSelector((state) => state.salesRecordsPage.tabItem);

  // Handle States
  const recordDateVar = new Date(recordDate);

  const isRecordDateVarEqualsToday =
    recordDateVar.getFullYear() === today.getFullYear() &&
    recordDateVar.getMonth() === today.getMonth() &&
    recordDateVar.getDate() === today.getDate();

  // Events
  // Events - Fields
  const onDateBackAndForwardBtnClicked = (actionType) => {
    const newDateVar =
      actionType === "back"
        ? new Date(
            recordDateVar.getFullYear(),
            recordDateVar.getMonth(),
            recordDateVar.getDate() - 1
          )
        : new Date(
            recordDateVar.getFullYear(),
            recordDateVar.getMonth(),
            recordDateVar.getDate() + 1
          );

    // Update Redux Store
    dispatch(onRecordDateChange(newDateVar.toString()));
  };

  const onRecordRecordsDateChange = (value) => {
    // Update Redux Store
    dispatch(onRecordDateChange(value.toString()));
  };

  // Events - Tabs
  const onTabClicked = (event, value) => {
    // Update Redux Store
    dispatch(onTabItemChange(value));
  };

  // Events - Title
  const onReturnToTodayBtnClicked = () => {
    // Update Redux Store
    dispatch(onRecordDateChange(today.toString()));
  };

  return (
    <Box className={classes.divisionsContainer}>
      {/* Tabs */}
      <div className={classes.tabsContainer}>
        {tabItem && (
          <Tabs
            scrollButtons={false}
            value={tabItem}
            variant="scrollable"
            onChange={onTabClicked}
          >
            <Tab
              disableRipple
              key={"SalesRecords"}
              label={
                <Typography variant="h6" align={"center"} gutterBottom>
                  {t("銷售紀錄s")}
                </Typography>
              }
              value={"SalesRecords"}
            />
          </Tabs>
        )}
      </div>
      {/* Select Boxes */}
      {tabItem === "SalesRecords" && (
        <div className={classes.tabsContainer}>
          {!isRecordDateVarEqualsToday && (
            <div className={classes.formTwinIconBtnContainerLeft}>
              <Button
                onClick={onReturnToTodayBtnClicked}
                startIcon={<RestoreRoundedIcon />}
                variant="text"
              >
                {t("返回本日")}
              </Button>
            </div>
          )}
          <DatePicker
            format="dd/MM/yyyy"
            label={t("日期 (日/月/年)")}
            onChange={onRecordRecordsDateChange}
            slotProps={{ textField: { variant: "standard" } }}
            value={recordDateVar}
            views={["year", "month", "day"]}
          />
          <div className={classes.formTwinIconBtnContainerRight}>
            <IconButton onClick={() => onDateBackAndForwardBtnClicked("back")}>
              <ArrowBackIosRoundedIcon />
            </IconButton>
            <IconButton
              onClick={() => onDateBackAndForwardBtnClicked("forward")}
            >
              <ArrowForwardIosRoundedIcon />
            </IconButton>
          </div>
        </div>
      )}
    </Box>
  );
}

export default SalesRecordsPageHeader;
