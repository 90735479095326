// Actions
import {
  onConditionBrandChange,
  onConditionBrandItemChange,
  onConditionCategoryChange,
  onConditionCategoryItemChange,
  onConditionSubCategoryChange,
  onConditionSubCategoryItemChange,
  onKeywordChange,
  onRecordDateTypeChange,
  onSalesNumberTypeChange,
} from "../../../redux/pages/merchandiseSalesPage/merchandiseSalesPageActions";

// Consts
import { merchandiseSalesNumberTypes } from "../../../consts/salesNumberTypeConsts";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Dummy Datum
import { brandDummyData } from "../../../dummyDatum/brandDummyData";
import { categoryDummyData } from "../../../dummyDatum/categoryDummyData";
import { subCategoryDummyData } from "../../../dummyDatum/subCategoryDummyData";

// Fetches
import { getAllBrandsFetch } from "../../../fetches/brandFetches";
import { getAllCategoriesFetch } from "../../../fetches/categoryFetches";
import { getAllSubCategoriesByCategoryIdFetch } from "../../../fetches/subCategoryFetches";

// Material UI
// Components
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
// Icons
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function MerchandiseSaleConditionBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const brandId = useSelector(
    (state) => state.merchandiseSalesPage.conditionBrandId
  );
  const brandItem = useSelector(
    (state) => state.merchandiseSalesPage.conditionBrandItem
  );
  const categoryId = useSelector(
    (state) => state.merchandiseSalesPage.conditionCategoryId
  );
  const categoryItem = useSelector(
    (state) => state.merchandiseSalesPage.conditionCategoryItem
  );
  const keyword = useSelector((state) => state.merchandiseSalesPage.keyword);
  const recordDateType = useSelector(
    (state) => state.merchandiseSalesPage.recordDateType
  );
  const salesNumberType = useSelector(
    (state) => state.merchandiseSalesPage.salesNumberType
  );
  const subCategoryId = useSelector(
    (state) => state.merchandiseSalesPage.conditionSubCategoryId
  );
  const subCategoryItem = useSelector(
    (state) => state.merchandiseSalesPage.conditionSubCategoryItem
  );
  const tabItem = useSelector((state) => state.merchandiseSalesPage.tabItem);
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [brands, setBrands] = useState(null);
  const [categories, setCategories] = useState(null);
  const [subCategories, setSubCategories] = useState(null);

  // Handle States
  const salesNumberTypeItem = merchandiseSalesNumberTypes.find(
    (item) => item.type === salesNumberType
  );

  // Events
  // Events - Form
  const onInputFieldChange = (field, value) => {
    switch (field) {
      // Update Redux Store
      case "brand":
        dispatch(onConditionBrandChange(value.id));
        break;
      case "category":
        if (value) {
          dispatch(onConditionCategoryChange(value.id));

          if (value === "dummy") {
            dispatch(onConditionSubCategoryChange("dummy"));
          }
        }
        break;
      case "keyword":
        dispatch(onKeywordChange(value));
        break;
      case "salesNumberType":
        dispatch(onSalesNumberTypeChange(value.type));
        break;
      case "subCategory":
        if (value) {
          dispatch(onConditionSubCategoryChange(value.id));
        }
        break;
      default:
        break;
    }
  };

  // Events - Title
  const onChangeRecordDateTypeBtnClicked = () => {
    // Update Redux Store
    dispatch(
      onRecordDateTypeChange(recordDateType === "Date" ? "Month" : "Date")
    );
  };

  // Functions
  // Functions - Queries
  const getAllBrands = async () => {
    const results = await getAllBrandsFetch(token, "Current");

    // Set States
    setBrands(results.brands ? [brandDummyData, ...results.brands] : null);

    // Update Redux Store
    if (results.brands && !brandId) {
      dispatch(onConditionBrandChange(brandDummyData.id));
    }
  };

  const getAllCategories = async () => {
    const results = await getAllCategoriesFetch(token, "Current");

    // Set States
    setCategories(
      results.categories ? [categoryDummyData, ...results.categories] : null
    );

    // Update Redux Store
    if (results.categories && !categoryId) {
      dispatch(onConditionCategoryChange(categoryDummyData.id));
    }
  };

  const getAllSubCategoriesByCategoryId = async () => {
    const results = await getAllSubCategoriesByCategoryIdFetch(
      token,
      categoryId,
      "Current"
    );

    // Set States
    setSubCategories(
      results.subCategories
        ? [subCategoryDummyData, ...results.subCategories]
        : null
    );

    // Update Redux Store
    if (
      results.subCategories &&
      (!subCategoryId ||
        !results.subCategories.some((item) => item.id === subCategoryId))
    ) {
      dispatch(onConditionSubCategoryChange(subCategoryDummyData.id));
    }
  };

  // Life Cycle
  useEffect(() => {
    getAllBrands();
    getAllCategories();
  }, []);

  useEffect(() => {
    if (categoryId && categoryId !== "dummy") {
      getAllSubCategoriesByCategoryId();
    }
  }, [categoryId]);

  useEffect(() => {
    // Update Redux Store
    if (brands && brandId) {
      dispatch(
        onConditionBrandItemChange(brands.find((item) => item.id === brandId))
      );
    }
  }, [brands, brandId]);

  useEffect(() => {
    // Update Redux Store
    if (categories && categoryId) {
      dispatch(
        onConditionCategoryItemChange(
          categories.find((item) => item.id === categoryId)
        )
      );
    }
  }, [categories, categoryId]);

  useEffect(() => {
    // Update Redux Store
    if (subCategories && subCategoryId) {
      dispatch(
        onConditionSubCategoryItemChange(
          subCategories.find((item) => item.id === subCategoryId)
        )
      );
    }
  }, [subCategories, subCategoryId]);

  return (
    <div className={classes.contentBox}>
      {tabItem === "Rankings" && (
        <>
          {/* Title */}
          <div className={classes.titleContainer}>
            <div className={classes.titleTextContainer}>
              <Typography variant="h6" align={"left"}>
                {t("搜尋商品")}
              </Typography>
            </div>
          </div>
          {/* Text Field */}
          <div className={classes.autoCompleteContainer}>
            <TextField
              autoFocus
              fullWidth
              label={t("關鍵字")}
              margin="dense"
              name="keyword"
              onChange={(event) =>
                onInputFieldChange("keyword", event.target.value)
              }
              required
              value={keyword}
              variant="standard"
            />
          </div>
        </>
      )}
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("銷售數據類別")}
          </Typography>
        </div>
        <Button
          endIcon={<ChevronRightRoundedIcon />}
          onClick={onChangeRecordDateTypeBtnClicked}
          variant="text"
        >
          {recordDateType === "Date"
            ? t("以月為顯示單位")
            : t("以日為顯示單位")}
        </Button>
      </div>
      {/* Select Box */}
      <div className={classes.autoCompleteContainer}>
        <Autocomplete
          disablePortal
          fullWidth
          getOptionLabel={(option) => option[t("nameCh")]}
          onChange={(event, value) =>
            onInputFieldChange("salesNumberType", value)
          }
          options={merchandiseSalesNumberTypes}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("銷售數據類別")}
              variant="outlined"
            />
          )}
          value={salesNumberTypeItem}
        />
      </div>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("類別")}
          </Typography>
        </div>
      </div>
      {/* Select Box */}
      {categories && (
        <div className={classes.autoCompleteContainer}>
          <Autocomplete
            disablePortal
            fullWidth
            getOptionLabel={(option) => option[t("category_name_ch")]}
            onChange={(event, value) => onInputFieldChange("category", value)}
            options={categories}
            renderInput={(params) => (
              <TextField {...params} label={t("類別")} variant="outlined" />
            )}
            value={categoryItem}
          />
        </div>
      )}
      {["Rankings", "SalesTrends"].includes(tabItem) &&
        categoryId &&
        categoryId !== "dummy" && (
          <>
            {/* Select Box */}
            {subCategories && (
              <div className={classes.autoCompleteContainer}>
                <Autocomplete
                  disablePortal
                  fullWidth
                  getOptionLabel={(option) => option.sub_category_name_ch}
                  onChange={(event, value) =>
                    onInputFieldChange("subCategory", value)
                  }
                  options={subCategories}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("子類別")}
                      variant="outlined"
                    />
                  )}
                  value={subCategoryItem}
                />
              </div>
            )}
          </>
        )}
      {tabItem === "Rankings" && (
        <>
          {/* Title */}
          <div className={classes.titleContainer}>
            <div className={classes.titleTextContainer}>
              <Typography variant="h6" align={"left"}>
                {t("品牌")}
              </Typography>
            </div>
          </div>
          {/* Select Box */}
          {brands && (
            <div className={classes.autoCompleteContainer}>
              <Autocomplete
                disablePortal
                fullWidth
                getOptionLabel={(option) =>
                  option.id !== "dummy"
                    ? option.brand_name
                    : option[t("brand_name_ch")]
                }
                onChange={(event, value) => onInputFieldChange("brand", value)}
                options={brands}
                renderInput={(params) => (
                  <TextField {...params} label={t("品牌")} variant="outlined" />
                )}
                value={brandItem}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default MerchandiseSaleConditionBoxesContainer;
