// Actions
import {
  onTabItemChange,
  onTypeOfCategoriesAndBrandsChange,
  onTypeOfSubCategoriesChange,
} from "../../../redux/pages/categoriesAndBrandsPage/categoriesAndBrandsPageActions";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import {
  selectBoxContainerStyles,
  useStandardListPageStyles,
} from "../../../styles/pageStyles/standardListPageStyles";

function CategoriesAndBrandsPageHeader() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardListPageStyles();

  // Redux Store
  const tabItem = useSelector((state) => state.categoriesAndBrandsPage.tabItem);
  const typeOfCategoriesAndBrands = useSelector(
    (state) => state.categoriesAndBrandsPage.typeOfCategoriesAndBrands
  );
  const typeOfContent = useSelector(
    (state) => state.categoriesAndBrandsPage.typeOfContent
  );
  const typeOfSubCategories = useSelector(
    (state) => state.categoriesAndBrandsPage.typeOfSubCategories
  );

  // Events
  // Events - Select Box
  const onTypeOfCategoriesAndBrandsSelectBoxValueChanged = (value) => {
    // Update Redux Store
    dispatch(onTypeOfCategoriesAndBrandsChange(value));
  };

  const onTypeOfSubCategoriesSelectBoxValueChanged = (value) => {
    // Update Redux Store
    dispatch(onTypeOfSubCategoriesChange(value));
  };

  // Events - Tabs
  const onTabClicked = (event, value) => {
    // Update Redux Store
    dispatch(onTabItemChange(value));
  };

  return (
    <Box className={classes.divisionsContainer}>
      {/* Tabs */}
      <div className={classes.tabsContainer}>
        {tabItem && (
          <Tabs
            scrollButtons={false}
            value={tabItem}
            variant="scrollable"
            onChange={onTabClicked}
          >
            <Tab
              disableRipple
              key={"Categories"}
              label={
                <Typography variant="h6" align={"center"} gutterBottom>
                  {t("類別s")}
                </Typography>
              }
              value={"Categories"}
            />
            <Tab
              disableRipple
              key={"Brands"}
              label={
                <Typography variant="h6" align={"center"} gutterBottom>
                  {t("品牌s")}
                </Typography>
              }
              value={"Brands"}
            />
          </Tabs>
        )}
      </div>
      {/* Select Boxes */}
      <div>
        <FormControl variant="standard" sx={selectBoxContainerStyles}>
          <Select
            value={typeOfCategoriesAndBrands}
            onChange={(event) =>
              onTypeOfCategoriesAndBrandsSelectBoxValueChanged(
                event.target.value
              )
            }
          >
            <MenuItem value={"Current"}>
              {tabItem === "Categories" ? t("顯示有效類別") : t("顯示有效品牌")}
            </MenuItem>
            <MenuItem value={"Deleted"}>
              {tabItem === "Categories"
                ? t("顯示已刪除類別")
                : t("顯示已刪除品牌")}
            </MenuItem>
          </Select>
        </FormControl>
        {typeOfContent === "SubCategoryList" && (
          <FormControl variant="standard" sx={selectBoxContainerStyles}>
            <Select
              value={typeOfSubCategories}
              onChange={(event) =>
                onTypeOfSubCategoriesSelectBoxValueChanged(event.target.value)
              }
            >
              <MenuItem value={"Current"}>{t("顯示有效子類別")}</MenuItem>
              <MenuItem value={"Deleted"}>{t("顯示已刪除子類別")}</MenuItem>
            </Select>
          </FormControl>
        )}
      </div>
    </Box>
  );
}

export default CategoriesAndBrandsPageHeader;
