// Actions
import {
  onFormSubmitCountIncrease,
  onMerchandiseChange,
} from "../../../redux/pages/merchandisesPage/merchandisesPageActions";

// Components
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Forms
import MerchandiseDetailFormContent from "./merchandiseDetailFormContent";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { getMerchandiseDetailFormOptionsFetch } from "../../../fetches/formOptionFetches";
import {
  createMerchandiseFetch,
  editMerchandiseFetch,
  getMerchandiseDetailsByIdFetch,
} from "../../../fetches/merchandiseFetches";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

function MerchandiseDetailFormContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const merchandiseId = useSelector(
    (state) => state.merchandisesPage.merchandiseId
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Dialog
  const [
    merchandiseDetailActionDialogText,
    setMerchandiseDetailActionDialogText,
  ] = useState("");
  const [
    merchandiseDetailActionDialogType,
    setMerchandiseDetailActionDialogType,
  ] = useState(null);
  const [
    showMerchandiseDetailActionDialog,
    setShowMerchandiseDetailActionDialog,
  ] = useState(false);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // File Fields
  const [photo, setPhoto] = useState(null);
  // Option Fields
  const [ageGroupField, setAgeGroupField] = useState(null);
  const [brandField, setBrandField] = useState(null);
  const [categoryField, setCategoryField] = useState(null);
  const [genderTypeField, setGenderTypeField] = useState(null);
  const [subCategoryField, setSubCategoryField] = useState(null);
  // Options
  const [ageGroupOptions, setAgeGroupOptions] = useState([]);
  const [brandOptions, setBrandOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [genderTypeOptions, setGenderTypeOptions] = useState([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  // Render
  const [isOptionsRetrieved, setIsOptionsRetrieved] = useState(false);
  // Switches
  const [isItemActive, setIsItemActive] = useState(true);
  // Text Fields
  const [basePrice, setBasePrice] = useState("");
  const [code, setCode] = useState("");
  const [nameCh, setNameCh] = useState("");
  const [nameEn, setNameEn] = useState("");

  // Events
  // Events - Dialogs
  const onMerchandiseDetailActionDialogCanceled = () => {
    // Set States
    setShowMerchandiseDetailActionDialog(false);
  };

  const onMerchandiseDetailActionDialogConfirmed = () => {
    switch (merchandiseDetailActionDialogType) {
      case "CreateMerchandise":
        createMerchandise();
        break;
      case "EditMerchandise":
        editMerchandise();
        break;
      default:
        break;
    }

    // Set States
    setShowMerchandiseDetailActionDialog(false);
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "ageGroupField":
        setAgeGroupField(value);
        break;
      case "basePrice":
        setBasePrice(value);
        break;
      case "brandField":
        setBrandField(value);
        break;
      case "categoryField":
        setCategoryField(value);
        setSubCategoryField(
          value
            ? subCategoryOptions.find((item) => item.category_id === value.id)
            : null
        );

        removeErrorField("subCategoryField");

        break;
      case "code":
        setCode(value);
        break;
      case "genderTypeField":
        setGenderTypeField(value);
        break;
      case "isItemActive":
        setIsItemActive(value);
        break;
      case "nameCh":
        setNameCh(value);
        break;
      case "nameEn":
        setNameEn(value);
        break;
      case "photo":
        setPhoto(value);
        break;
      case "subCategoryField":
        setSubCategoryField(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked();
    }
  };

  // Events - Forms
  const onSubmitBtnClicked = () => {
    let isError = false;

    if (!brandField) {
      addToErrorFields("brandField", t("請先填寫 品牌"));
      isError = true;
    }

    if (!categoryField) {
      addToErrorFields("categoryField", t("請先填寫 類別"));
      isError = true;
    }

    if (!subCategoryField) {
      addToErrorFields("subCategoryField", t("請先填寫 子類別"));
      isError = true;
    }

    if (!genderTypeField) {
      addToErrorFields("genderTypeField", t("請先填寫 適用性別"));
      isError = true;
    }

    if (!ageGroupField) {
      addToErrorFields("ageGroupField", t("請先填寫 年齡層"));
      isError = true;
    }

    if (!code) {
      addToErrorFields("code", t("請先填寫 代號"));
      isError = true;
    }

    if (!nameCh) {
      addToErrorFields("nameCh", t("請先填寫 中文名稱"));
      isError = true;
    }

    if (!nameEn) {
      addToErrorFields("nameEn", t("請先填寫 英文名稱"));
      isError = true;
    }

    if (!basePrice) {
      addToErrorFields("basePrice", t("請先填寫 基礎價格"));
      isError = true;
    } else {
      if (isNaN(basePrice)) {
        addToErrorFields("basePrice", t("基礎價格 必須為數字"));
        isError = true;
      }
    }

    if (isError) {
      return;
    }

    displayMerchandiseDetailActionDialog(
      merchandiseId ? "EditMerchandise" : "CreateMerchandise"
    );
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const clearErrorFields = () => {
    // Set States
    setErrorFields([]);
  };

  const clearForm = () => {
    // Set States
    setCategoryField(null);
    setSubCategoryField(null);
    setBrandField(null);
    setAgeGroupField(null);
    setGenderTypeField(null);
    setCode("");
    setNameCh("");
    setNameEn("");
    setBasePrice("");
    setPhoto(null);
    setIsItemActive(true);

    clearErrorFields();
  };

  const displayMerchandiseDetailActionDialog = (
    merchandiseDetailActionType
  ) => {
    // Set States
    setMerchandiseDetailActionDialogType(merchandiseDetailActionType);

    switch (merchandiseDetailActionType) {
      case "CreateMerchandise":
        setMerchandiseDetailActionDialogText(t("確認要新增 商品 嗎？"));
        break;
      case "EditMerchandise":
        setMerchandiseDetailActionDialogText(t("確認要編輯 商品資料 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowMerchandiseDetailActionDialog(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const createMerchandise = async () => {
    const results = await createMerchandiseFetch(
      token,
      subCategoryField ? subCategoryField.id : null,
      brandField ? brandField.id : null,
      ageGroupField ? ageGroupField.id : null,
      genderTypeField ? genderTypeField.id : null,
      code,
      nameCh,
      nameEn,
      basePrice ? basePrice : null,
      photo ? photo : null,
      isItemActive
    );

    if (results.success) {
      showFormAlert("success", t("成功提交"));

      // Update Redux Store
      dispatch(onMerchandiseChange(results.merchandiseId));
      dispatch(onFormSubmitCountIncrease());
    } else if (results.isDuplicate) {
      showFormAlert("warning", t("此 商品 已存在"));
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  const editMerchandise = async () => {
    if (merchandiseId) {
      const results = await editMerchandiseFetch(
        token,
        merchandiseId,
        subCategoryField ? subCategoryField.id : null,
        brandField ? brandField.id : null,
        ageGroupField ? ageGroupField.id : null,
        genderTypeField ? genderTypeField.id : null,
        code,
        nameCh,
        nameEn,
        basePrice ? basePrice : null,
        isItemActive
      );

      if (results.success) {
        showFormAlert("success", t("成功提交"));

        // Update Redux Store
        dispatch(onFormSubmitCountIncrease());
      } else if (results.isDuplicate) {
        showFormAlert("warning", t("此 商品 已存在"));
      } else {
        showFormAlert("error", t("未能提交"));
      }
    }
  };

  // Functions - Queries
  const getMerchandiseDetailFormOptions = async () => {
    const results = await getMerchandiseDetailFormOptionsFetch(token);

    // Set States
    setAgeGroupOptions(results.ageGroups ? results.ageGroups : []);
    setBrandOptions(results.brands ? results.brands : []);
    setCategoryOptions(results.categories ? results.categories : []);
    setGenderTypeOptions(results.genderTypes ? results.genderTypes : []);
    setSubCategoryOptions(results.subCategories ? results.subCategories : []);
  };

  const getMerchandiseDetailsById = async () => {
    const results = await getMerchandiseDetailsByIdFetch(token, merchandiseId);

    if (results.merchandiseDetails) {
      const {
        merchandise_code,
        merchandise_name_ch,
        merchandise_name_en,
        base_price,
        is_active,
        sub_category_id,
        category_id,
        brand_id,
        age_group_id,
        gender_type_id,
      } = results.merchandiseDetails;

      // Set States
      setCategoryField(
        category_id
          ? categoryOptions.find((item) => item.id === category_id)
          : null
      );
      setSubCategoryField(
        sub_category_id
          ? subCategoryOptions.find((item) => item.id === sub_category_id)
          : null
      );
      setBrandField(
        brand_id ? brandOptions.find((item) => item.id === brand_id) : null
      );
      setAgeGroupField(
        age_group_id
          ? ageGroupOptions.find((item) => item.id === age_group_id)
          : null
      );
      setGenderTypeField(
        gender_type_id
          ? genderTypeOptions.find((item) => item.id === gender_type_id)
          : null
      );
      setCode(merchandise_code ? merchandise_code : "");
      setNameCh(merchandise_name_ch ? merchandise_name_ch : null);
      setNameEn(merchandise_name_en ? merchandise_name_en : null);
      setBasePrice(base_price ? base_price : "");
      setIsItemActive(is_active);
    } else {
      // Set States
      setCategoryField(null);
      setSubCategoryField(null);
      setBrandField(null);
      setAgeGroupField(null);
      setGenderTypeField(null);
      setCode("");
      setNameCh("");
      setNameEn("");
      setBasePrice("");
      setPhoto(null);
      setIsItemActive(true);
    }

    clearErrorFields();
  };

  // Life Cycle
  useEffect(() => {
    getMerchandiseDetailFormOptions();
  }, []);

  useEffect(() => {
    // Set States
    if (!isOptionsRetrieved && ageGroupOptions[0]) {
      setIsOptionsRetrieved(true);
    }
  }, [ageGroupOptions]);

  useEffect(() => {
    if (isOptionsRetrieved) {
      if (merchandiseId) {
        getMerchandiseDetailsById();
      } else {
        clearForm();
      }
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [isOptionsRetrieved, merchandiseId]);

  return (
    <>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onMerchandiseDetailActionDialogCanceled}
        onDialogConfirmed={onMerchandiseDetailActionDialogConfirmed}
        // States
        dialogText={merchandiseDetailActionDialogText}
        showDialog={showMerchandiseDetailActionDialog}
      />
      <MerchandiseDetailFormContent
        // States
        ageGroupField={ageGroupField}
        ageGroupOptions={ageGroupOptions}
        basePrice={basePrice}
        brandField={brandField}
        brandOptions={brandOptions}
        categoryField={categoryField}
        categoryOptions={categoryOptions}
        code={code}
        formAlertText={formAlertText}
        formAlertType={formAlertType}
        genderTypeField={genderTypeField}
        genderTypeOptions={genderTypeOptions}
        isItemActive={isItemActive}
        merchandiseId={merchandiseId}
        nameCh={nameCh}
        nameEn={nameEn}
        photo={photo}
        shouldShowFormAlert={shouldShowFormAlert}
        subCategoryField={subCategoryField}
        subCategoryOptions={subCategoryOptions}
        // Events
        onInputFieldChange={onInputFieldChange}
        onInputFieldKeyPressed={onInputFieldKeyPressed}
        onSubmitBtnClicked={onSubmitBtnClicked}
        // Functions
        checkIsFieldError={checkIsFieldError}
        getErrorFieldMessage={getErrorFieldMessage}
      />
    </>
  );
}

export default MerchandiseDetailFormContainer;
