// Actions
import { onMerchandiseSoldChange } from "../../../redux/pages/salesRecordProfilePage/salesRecordProfilePageActions";

// Components
// Boxes
import MerchandiseSoldBox from "./merchandiseSoldBox";

// Configs
import paginationConfig from "../../../configs/paginationConfig";
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { getAllMerchandiseSoldsBySalesRecordIdWithPaginationFetch } from "../../../fetches/merchandiseSoldFetches";

// Helper Functions
import { separateComma } from "../../../helperFunctions/separateComma";

// Material UI
// Components
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

// Consts
const limitNum = paginationConfig.paginationLimitNum;

function MerchandiseSoldBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const appliedDiscountId = useSelector(
    (state) => state.salesRecordProfilePage.appliedDiscountId
  );
  const formSubmitCount = useSelector(
    (state) => state.salesRecordProfilePage.formSubmitCount
  );
  const merchandiseSoldId = useSelector(
    (state) => state.salesRecordProfilePage.merchandiseSoldId
  );
  const salesRecordId = useSelector(
    (state) => state.salesRecordProfilePage.salesRecordId
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [merchandiseSolds, setMerchandiseSolds] = useState(null);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Pagination
  const [pageCount, setPageCount] = useState(null);
  const [pageNum, setPageNum] = useState(1);

  // Handle States
  const currentItemId = merchandiseSoldId;
  const itemsArr = merchandiseSolds;

  // Events
  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Update Redux Store
    dispatch(onMerchandiseSoldChange(itemId));
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Pagination
  const onPageChange = (value) => {
    // Set States
    setPageNum(value);
  };

  // Functions
  // Functions - Queries
  const getAllMerchandiseSoldsBySalesRecordIdWithPagination = async () => {
    const results =
      await getAllMerchandiseSoldsBySalesRecordIdWithPaginationFetch(
        token,
        salesRecordId,
        appliedDiscountId,
        pageNum,
        limitNum
      );

    if (results.merchandiseSolds) {
      for (let item of results.merchandiseSolds) {
        item.product_price_display = item.product_price
          ? `$ ${separateComma(Number(item.product_price).toFixed(1))}`
          : null;

        item.total_price =
          item.product_price && item.quantity
            ? Number(item.product_price) * Number(item.quantity)
            : null;

        item.total_price_display = item.total_price
          ? `$ ${separateComma(Number(item.total_price).toFixed(1))}`
          : null;

        item.merchandise_total_display = item.merchandise_total
          ? `$ ${separateComma(Number(item.merchandise_total).toFixed(1))}`
          : null;
      }
    }

    // Set States
    setPageCount(results.pageCount ? results.pageCount : 0);
    setMerchandiseSolds(
      results.merchandiseSolds ? results.merchandiseSolds : null
    );

    if (results.pageCount && !results.merchandiseSolds) {
      setPageNum(results.pageCount);
    }

    // Update Redux Store
    if (results.merchandiseSolds) {
      if (
        !(
          merchandiseSoldId &&
          results.merchandiseSolds.some((item) => item.id === merchandiseSoldId)
        )
      ) {
        dispatch(onMerchandiseSoldChange(results.merchandiseSolds[0].id));
      }
    } else {
      dispatch(onMerchandiseSoldChange(null));
    }
  };

  // Life Cycle
  useEffect(() => {
    if (salesRecordId) {
      getAllMerchandiseSoldsBySalesRecordIdWithPagination();
    }
  }, [salesRecordId, appliedDiscountId, pageNum, formSubmitCount]);

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("賣出商品s")}
          </Typography>
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        <>
          {itemsArr.map((item) => (
            <MerchandiseSoldBox
              key={item.id}
              // States
              currentItemId={currentItemId}
              hoveredItemId={hoveredItemId}
              item={item}
              // Events
              onItemClicked={onItemClicked}
              onMouseEnterItem={onMouseEnterItem}
              onMouseLeaveItem={onMouseLeaveItem}
            />
          ))}
          <Stack className={classes.paginationStack} spacing={2}>
            <Pagination
              color={"primary"}
              count={pageCount}
              onChange={(event, page) => onPageChange(page)}
              page={pageNum}
            />
          </Stack>
        </>
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有賣出商品資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default MerchandiseSoldBoxesContainer;
