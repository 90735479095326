export function closeDrawer() {
  return {
    type: "CLOSE_DRAWER",
  };
}

export function showDrawer(drawerAnchor) {
  return {
    type: "SHOW_DRAWER",
    drawerAnchor,
  };
}
