// Actions
import {
  onFormSubmitCountIncrease,
  onTabItemChange,
} from "../../../redux/pages/stocksPage/stocksPageActions";

// Components
// Backdrop
import LoadingBackdrop from "../../backdrop/loadingBackdrop";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { forceMigrateStocksFetch } from "../../../fetches/stockFetches";

// Material UI
// Components
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
// Icons
import UpdateRoundedIcon from "@mui/icons-material/UpdateRounded";

// React
import { useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardListPageStyles } from "../../../styles/pageStyles/standardListPageStyles";

function StocksPageHeader() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardListPageStyles();

  // Redux Store
  const tabItem = useSelector((state) => state.stocksPage.tabItem);
  const token = useSelector((state) => state.staff.token);

  // States
  // Redner
  const [isLoading, setIsLoading] = useState(false);

  // Events
  // Events - Tabs
  const onTabClicked = (event, value) => {
    // Update Redux Store
    dispatch(onTabItemChange(value));
  };

  // Events - Title
  const onForceUpdateBtnClicked = () => {
    // Set States
    setIsLoading(true);

    forceMigrateStocks();
  };

  // Functions
  // Functions - Mutations
  const forceMigrateStocks = async () => {
    const results = await forceMigrateStocksFetch(token);

    if (results.success) {
      dispatch(onFormSubmitCountIncrease());
    }

    // Set States
    setIsLoading(false);
  };

  return (
    <Box className={classes.divisionsContainer}>
      {/* Backdrop */}
      <LoadingBackdrop
        // States
        showBackdrop={isLoading}
      />
      {/* Tabs */}
      <div className={classes.tabsContainer}>
        {tabItem && (
          <Tabs
            scrollButtons={false}
            value={tabItem}
            variant="scrollable"
            onChange={onTabClicked}
          >
            <Tab
              disableRipple
              key={"Stocks"}
              label={
                <Typography variant="h6" align={"center"} gutterBottom>
                  {t("庫存s")}
                </Typography>
              }
              value={"Stocks"}
            />
          </Tabs>
        )}
      </div>
      {/* Select Boxes */}
      {tabItem === "Stocks" && (
        <div className={classes.tabsContainer}>
          <div className={classes.formTwinIconBtnContainerLeft}>
            <Button
              onClick={onForceUpdateBtnClicked}
              startIcon={<UpdateRoundedIcon />}
              variant="text"
            >
              {t("強制更新")}
            </Button>
          </div>
        </div>
      )}
    </Box>
  );
}

export default StocksPageHeader;
