// Configs
import apisConfig from '../configs/apisConfig';

export const getAllBrandsFetch = async (token, typeOfBrands) => {
  try {
    const queryRoute = '/shopBrand/getAllBrands';

    const reqBody = {typeOfBrands};

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
