// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Helper Functions
import { getFileURL } from "../../../helperFunctions/file";

// Material UI
// Components
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

// Styles
import {
  avatorWithSrcSmallStyles,
  avatorWithTextSmallStyles,
  useStandardItemBoxStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxStyles";

function MerchandiseSoldBox(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxStyles();

  // Props
  const {
    // States
    currentItemId,
    hoveredItemId,
    item,
    // Events
    onItemClicked,
    onMouseEnterItem,
    onMouseLeaveItem,
  } = props;

  // Handle States
  const isDiscounted =
    Number(item.total_price) !== Number(item.merchandise_total);
  const isHovered = hoveredItemId && hoveredItemId === item.id;
  const isSelected = currentItemId && currentItemId === item.id;
  const photoURL = item.file_path ? getFileURL(item.file_path) : null;
  const productText = `${item.brand_name} ${item[t("merchandise_name_ch")]}`;

  return (
    <div
      className={
        isSelected
          ? classes.selectedItemContainer
          : isHovered
          ? classes.hoveredItemContainer
          : classes.itemContainer
      }
      onMouseEnter={() => onMouseEnterItem(item.id)}
      onMouseLeave={onMouseLeaveItem}
    >
      <div
        className={classes.itemContentContainer}
        onClick={() => onItemClicked(item.id)}
      >
        <div className={classes.itemContentSubContainerAvator}>
          {photoURL ? (
            <Avatar
              alt={t("圖片")}
              src={photoURL}
              sx={avatorWithSrcSmallStyles}
            />
          ) : (
            <Avatar sx={avatorWithTextSmallStyles}>
              {item.merchandise_code}
            </Avatar>
          )}
        </div>
        <div className={classes.itemContentSubContainerLarge}>
          <Typography
            align={"left"}
            color={
              isSelected
                ? stylesConfig.whiteTextColor
                : isHovered
                ? stylesConfig.mainTextColor
                : null
            }
            variant="body1"
          >
            {productText}
          </Typography>
          <Typography
            align={"left"}
            color={
              isSelected
                ? stylesConfig.whiteTextColor
                : stylesConfig.greyTextColor
            }
            variant="body2"
          >
            {item.attributeDisplayText}
          </Typography>
        </div>
        <div className={classes.itemContentSubContainer}>
          <Typography
            align={"left"}
            color={
              isSelected
                ? stylesConfig.whiteTextColor
                : isHovered
                ? stylesConfig.mainTextColor
                : null
            }
            variant="body1"
          >
            {`${t("商品數量")} - ${item.quantity}`}
          </Typography>
          <Typography
            align={"left"}
            color={
              isSelected
                ? stylesConfig.whiteTextColor
                : stylesConfig.greyTextColor
            }
            variant="body2"
          >
            {`(${t("單價")} - ${item.product_price_display})`}
          </Typography>
        </div>
        <div className={classes.itemContentSubContainerRight}>
          <Typography
            align={"left"}
            color={
              isSelected
                ? stylesConfig.whiteTextColor
                : isHovered
                ? stylesConfig.mainTextColor
                : null
            }
            sx={{ textDecoration: isDiscounted ? "line-through" : null }}
            variant="body1"
          >
            {`${t("總價")} - ${item.total_price_display}`}
          </Typography>
          {isDiscounted && (
            <Typography
              align={"left"}
              color={
                isSelected
                  ? stylesConfig.whiteTextColor
                  : stylesConfig.redTextColor
              }
              variant="body2"
            >
              {`${t("折實價")} - ${item.merchandise_total_display}`}
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
}

export default MerchandiseSoldBox;
