// Components
// Boxes
import DiscountRuleBox from "./discountRuleBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Menus
import DiscountRuleActionMenu from "../../menus/discountProfilePage/discountRuleActionMenu";
// Modals
import ModalContainer from "../../modals/modalContainer";
import DiscountRuleActionModal from "../../modals/discountProfilePage/discountRuleActionModal";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  deleteDiscountRuleFetch,
  getAllDiscountRulesByDiscountIdFetch,
  getDiscountRulesDetailByDiscountIdFetch,
} from "../../../fetches/discountRuleFetches";

// Material UI
// Components
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import AddRoundedIcon from "@mui/icons-material/AddRounded";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function DiscountRuleBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const discountId = useSelector(
    (state) => state.discountProfilePage.discountId
  );
  const formSubmitCount = useSelector(
    (state) => state.discountProfilePage.formSubmitCount
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [discountConditionTypeNameCh, setDiscountConditionTypeNameCh] =
    useState(null);
  const [discountConditionTypeNameEn, setDiscountConditionTypeNameEn] =
    useState(null);
  const [discountRules, setDiscountRules] = useState(null);
  const [discountRuleTypeNameCh, setDiscountRuleTypeNameCh] = useState(null);
  const [discountRuleTypeNameEn, setDiscountRuleTypeNameEn] = useState(null);
  const [isRuleAddable, setIsRuleAddable] = useState(false);
  // Dialog
  const [discountRuleActionDialogText, setDiscountRuleActionDialogText] =
    useState("");
  const [discountRuleActionDialogType, setDiscountRuleActionDialogType] =
    useState(null);
  const [showDiscountRuleActionDialog, setShowDiscountRuleActionDialog] =
    useState(false);
  // Hover
  const [currentItemId, setCurrentItemId] = useState(null);
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Menu
  const [showDiscountRuleActionMenu, setShowDiscountRuleActionMenu] =
    useState(null);
  // Modals
  const [showDiscountRuleActionModal, setShowDiscountRuleActionModal] =
    useState(false);

  // Handle States
  const itemsArr = discountRules;

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Set States
    setCurrentItemId(itemId);
    displayDiscountRuleActionModal();
  };

  const onItemRightClicked = (itemId, currentTarget) => {
    // Set States
    setCurrentItemId(itemId);
    setShowDiscountRuleActionMenu(currentTarget);
  };

  const onItemShowMenuBtnClicked = (itemId, currentTarget) => {
    // Set States
    setCurrentItemId(itemId);
    setShowDiscountRuleActionMenu(currentTarget);
  };

  // Events - Dialogs
  const onDiscountRuleActionDialogCanceled = () => {
    // Set States
    setShowDiscountRuleActionDialog(false);
  };

  const onDiscountRuleActionDialogConfirmed = () => {
    switch (discountRuleActionDialogType) {
      case "DeleteDiscountRule":
        deleteDiscountRule();
        break;
      default:
        break;
    }

    // Set States
    setShowDiscountRuleActionDialog(false);
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Modal
  const onDiscountRuleActionModalClosed = () => {
    getAllDiscountRules();
    // Set States
    setShowDiscountRuleActionModal(false);
  };

  // Events - Title
  const onAddItemBtnClicked = () => {
    // Set States
    setCurrentItemId(null);
    displayDiscountRuleActionModal();
  };

  // Functions
  // Functions - Normal
  const clearData = () => {
    // Set States
    setDiscountRules(null);
  };

  const displayDiscountRuleActionDialog = (discountRuleActionType) => {
    // Set States
    setDiscountRuleActionDialogType(discountRuleActionType);

    switch (discountRuleActionType) {
      case "DeleteDiscountRule":
        setDiscountRuleActionDialogText(t("確認要刪除 優惠規則 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowDiscountRuleActionDialog(true);
  };

  const displayDiscountRuleActionModal = () => {
    setShowDiscountRuleActionModal(true);
  };

  const getAllDiscountRules = () => {
    getAllDiscountRulesByDiscountId();
    getDiscountRulesDetailByDiscountId();
  };

  // Functions - Mutations
  const deleteDiscountRule = async () => {
    const results = await deleteDiscountRuleFetch(token, currentItemId);

    if (results.success) {
      getAllDiscountRules();
    }
  };

  // Functions - Queries
  const getAllDiscountRulesByDiscountId = async () => {
    const results = await getAllDiscountRulesByDiscountIdFetch(
      token,
      discountId
    );

    // Set States
    setDiscountRules(results.discountRules ? results.discountRules : null);
  };

  const getDiscountRulesDetailByDiscountId = async () => {
    const results = await getDiscountRulesDetailByDiscountIdFetch(
      token,
      discountId
    );

    // Set States
    setDiscountConditionTypeNameCh(
      results.discountConditionTypeNameCh
        ? results.discountConditionTypeNameCh
        : null
    );
    setDiscountConditionTypeNameEn(
      results.discountConditionTypeNameEn
        ? results.discountConditionTypeNameEn
        : null
    );
    setDiscountRuleTypeNameCh(
      results.discountRuleTypeNameCh ? results.discountRuleTypeNameCh : null
    );
    setDiscountRuleTypeNameEn(
      results.discountRuleTypeNameEn ? results.discountRuleTypeNameEn : null
    );
    setIsRuleAddable(results.isRuleAddable ? results.isRuleAddable : false);
  };

  // Life Cycle
  useEffect(() => {
    if (discountId) {
      getAllDiscountRules();
    } else {
      clearData();
    }
  }, [discountId, formSubmitCount]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onDiscountRuleActionDialogCanceled}
        onDialogConfirmed={onDiscountRuleActionDialogConfirmed}
        // States
        dialogText={discountRuleActionDialogText}
        showDialog={showDiscountRuleActionDialog}
      />
      {/* Menu */}
      <DiscountRuleActionMenu
        // States
        showDiscountRuleActionMenu={showDiscountRuleActionMenu}
        // Set States
        setShowDiscountRuleActionMenu={setShowDiscountRuleActionMenu}
        // Functions
        displayDiscountRuleActionDialog={displayDiscountRuleActionDialog}
        displayDiscountRuleActionModal={displayDiscountRuleActionModal}
      />
      {/* Modal */}
      <ModalContainer
        // Events
        onModalClosed={onDiscountRuleActionModalClosed}
        // States
        showModal={showDiscountRuleActionModal}
      >
        <DiscountRuleActionModal
          // Events
          onModalClosed={onDiscountRuleActionModalClosed}
          // States
          discountConditionTypeNameCh={discountConditionTypeNameCh}
          discountConditionTypeNameEn={discountConditionTypeNameEn}
          discountRuleId={currentItemId}
          discountRuleTypeNameCh={discountRuleTypeNameCh}
          discountRuleTypeNameEn={discountRuleTypeNameEn}
        />
      </ModalContainer>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("優惠規則s")}
          </Typography>
          {isRuleAddable && (
            <Tooltip placement="right" title={t("新增優惠規則")}>
              <IconButton color={"primary"} onClick={onAddItemBtnClicked}>
                <AddRoundedIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        itemsArr.map((item) => (
          <DiscountRuleBox
            key={item.id}
            // States
            hoveredItemId={hoveredItemId}
            item={item}
            // Events
            onItemClicked={onItemClicked}
            onItemRightClicked={onItemRightClicked}
            onItemShowMenuBtnClicked={onItemShowMenuBtnClicked}
            onMouseEnterItem={onMouseEnterItem}
            onMouseLeaveItem={onMouseLeaveItem}
          />
        ))
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有優惠規則資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default DiscountRuleBoxesContainer;
