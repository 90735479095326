// Actions
import {
  onConditionBrandChange,
  onConditionBrandItemChange,
  onConditionCategoryChange,
  onConditionCategoryItemChange,
  onConditionShopChange,
  onConditionShopItemChange,
  onConditionSubCategoryChange,
  onConditionSubCategoryItemChange,
  onConditionWarehouseChange,
  onConditionWarehouseItemChange,
  onConditionWarehouseTypeChange,
  onKeywordChange,
} from "../../../redux/pages/stocksPage/stocksPageActions";

// Consts
import { warehouseTypes } from "../../../consts/warehouseTypeConsts";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Dummy Datum
import { brandDummyData } from "../../../dummyDatum/brandDummyData";
import { categoryDummyData } from "../../../dummyDatum/categoryDummyData";
import { subCategoryDummyData } from "../../../dummyDatum/subCategoryDummyData";

// Fetches
import { getAllBrandsFetch } from "../../../fetches/brandFetches";
import { getAllCategoriesFetch } from "../../../fetches/categoryFetches";
import { getAllShopsFetch } from "../../../fetches/shopFetches";
import { getAllSubCategoriesByCategoryIdFetch } from "../../../fetches/subCategoryFetches";
import { getAllWarehousesFetch } from "../../../fetches/warehouseFetches";

// Material UI
// Components
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function StockConditionBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const brandId = useSelector((state) => state.stocksPage.conditionBrandId);
  const brandItem = useSelector((state) => state.stocksPage.conditionBrandItem);
  const categoryId = useSelector(
    (state) => state.stocksPage.conditionCategoryId
  );
  const categoryItem = useSelector(
    (state) => state.stocksPage.conditionCategoryItem
  );
  const keyword = useSelector((state) => state.stocksPage.keyword);
  const shopId = useSelector((state) => state.stocksPage.conditionShopId);
  const shopItem = useSelector((state) => state.stocksPage.conditionShopItem);
  const subCategoryId = useSelector(
    (state) => state.stocksPage.conditionSubCategoryId
  );
  const subCategoryItem = useSelector(
    (state) => state.stocksPage.conditionSubCategoryItem
  );
  const token = useSelector((state) => state.staff.token);
  const warehouseId = useSelector(
    (state) => state.stocksPage.conditionWarehouseId
  );
  const warehouseItem = useSelector(
    (state) => state.stocksPage.conditionWarehouseItem
  );
  const warehouseType = useSelector(
    (state) => state.stocksPage.conditionWarehouseType
  );

  // States
  // Data
  const [brands, setBrands] = useState(null);
  const [categories, setCategories] = useState(null);
  const [shops, setShops] = useState(null);
  const [subCategories, setSubCategories] = useState(null);
  const [warehouses, setWarehouses] = useState(null);

  // Handle States
  const warehouseTypeItem = warehouseTypes.find(
    (item) => item.type === warehouseType
  );

  // Events
  // Events - Form
  const onInputFieldChange = (field, value) => {
    switch (field) {
      // Update Redux Store
      case "brand":
        dispatch(onConditionBrandChange(value.id));
        break;
      case "category":
        if (value) {
          dispatch(onConditionCategoryChange(value.id));

          if (value === "dummy") {
            dispatch(onConditionSubCategoryChange("dummy"));
          }
        }
        break;
      case "keyword":
        dispatch(onKeywordChange(value));
        break;
      case "shop":
        if (value) {
          dispatch(onConditionShopChange(value.id));
        } else {
          dispatch(onConditionWarehouseTypeChange("All"));
        }
        break;
      case "subCategory":
        if (value) {
          dispatch(onConditionSubCategoryChange(value.id));
        }
        break;
      case "warehouse":
        if (value) {
          dispatch(onConditionWarehouseChange(value.id));
        } else {
          dispatch(onConditionWarehouseTypeChange("All"));
        }
        break;
      case "warehouseType":
        dispatch(onConditionWarehouseTypeChange(value.type));
        break;
      default:
        break;
    }
  };

  // Functions
  // Functions - Queries
  const getAllBrands = async () => {
    const results = await getAllBrandsFetch(token, "Current");

    // Set States
    setBrands(results.brands ? [brandDummyData, ...results.brands] : null);

    // Update Redux Store
    if (results.brands && !brandId) {
      dispatch(onConditionBrandChange(brandDummyData.id));
    }
  };

  const getAllCategories = async () => {
    const results = await getAllCategoriesFetch(token, "Current");

    // Set States
    setCategories(
      results.categories ? [categoryDummyData, ...results.categories] : null
    );

    // Update Redux Store
    if (results.categories && !categoryId) {
      dispatch(onConditionCategoryChange(categoryDummyData.id));
    }
  };

  const getAllShops = async () => {
    const results = await getAllShopsFetch(token, "Current");

    // Set States
    setShops(results.shops ? results.shops : null);

    // Update Redux Store
    if (results.shops && !shopId) {
      dispatch(onConditionShopChange(results.shops[0].id));
    }
  };

  const getAllSubCategoriesByCategoryId = async () => {
    const results = await getAllSubCategoriesByCategoryIdFetch(
      token,
      categoryId,
      "Current"
    );

    // Set States
    setSubCategories(
      results.subCategories
        ? [subCategoryDummyData, ...results.subCategories]
        : null
    );

    // Update Redux Store
    if (
      results.subCategories &&
      (!subCategoryId ||
        !results.subCategories.some((item) => item.id === subCategoryId))
    ) {
      dispatch(onConditionSubCategoryChange(subCategoryDummyData.id));
    }
  };

  const getAllWarehouses = async () => {
    const results = await getAllWarehousesFetch(token, "Current");

    // Set States
    setWarehouses(results.warehouses ? results.warehouses : null);

    // Update Redux Store
    if (results.warehouses && !warehouseId) {
      dispatch(onConditionWarehouseChange(results.warehouses[0].id));
    }
  };

  // Life Cycle
  useEffect(() => {
    getAllBrands();
    getAllCategories();
    getAllShops();
    getAllWarehouses();
  }, []);

  useEffect(() => {
    if (categoryId && categoryId !== "dummy") {
      getAllSubCategoriesByCategoryId();
    }
  }, [categoryId]);

  useEffect(() => {
    // Update Redux Store
    if (brands && brandId) {
      dispatch(
        onConditionBrandItemChange(brands.find((item) => item.id === brandId))
      );
    }
  }, [brands, brandId]);

  useEffect(() => {
    // Update Redux Store
    if (categories && categoryId) {
      dispatch(
        onConditionCategoryItemChange(
          categories.find((item) => item.id === categoryId)
        )
      );
    }
  }, [categories, categoryId]);

  useEffect(() => {
    // Update Redux Store
    if (shops && shopId) {
      dispatch(
        onConditionShopItemChange(shops.find((item) => item.id === shopId))
      );
    }
  }, [shops, shopId]);

  useEffect(() => {
    // Update Redux Store
    if (subCategories && subCategoryId) {
      dispatch(
        onConditionSubCategoryItemChange(
          subCategories.find((item) => item.id === subCategoryId)
        )
      );
    }
  }, [subCategories, subCategoryId]);

  useEffect(() => {
    // Update Redux Store
    if (warehouses && warehouseId) {
      dispatch(
        onConditionWarehouseItemChange(
          warehouses.find((item) => item.id === warehouseId)
        )
      );
    }
  }, [warehouses, warehouseId]);

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("搜尋商品")}
          </Typography>
        </div>
      </div>
      {/* Text Field */}
      <div className={classes.autoCompleteContainer}>
        <TextField
          autoFocus
          fullWidth
          label={t("關鍵字")}
          margin="dense"
          name="keyword"
          onChange={(event) =>
            onInputFieldChange("keyword", event.target.value)
          }
          required
          value={keyword}
          variant="standard"
        />
      </div>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("庫存數據類別")}
          </Typography>
        </div>
      </div>
      {/* Select Box */}
      <div className={classes.autoCompleteContainer}>
        <Autocomplete
          disablePortal
          fullWidth
          getOptionLabel={(option) => option[t("nameCh")]}
          onChange={(event, value) =>
            onInputFieldChange("warehouseType", value)
          }
          options={warehouseTypes}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("庫存數據類別")}
              variant="outlined"
            />
          )}
          value={warehouseTypeItem}
        />
      </div>
      {warehouseType === "Shop" && shops && (
        <div className={classes.autoCompleteContainer}>
          <Autocomplete
            disablePortal
            fullWidth
            getOptionLabel={(option) => option.shop_code}
            onChange={(event, value) => onInputFieldChange("shop", value)}
            options={shops}
            renderInput={(params) => (
              <TextField {...params} label={t("店鋪")} variant="outlined" />
            )}
            value={shopItem}
          />
        </div>
      )}
      {warehouseType === "Warehouse" && warehouses && (
        <div className={classes.autoCompleteContainer}>
          <Autocomplete
            disablePortal
            fullWidth
            getOptionLabel={(option) => option.warehouse_name_en_short}
            onChange={(event, value) => onInputFieldChange("warehouse", value)}
            options={warehouses}
            renderInput={(params) => (
              <TextField {...params} label={t("倉庫")} variant="outlined" />
            )}
            value={warehouseItem}
          />
        </div>
      )}
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("類別")}
          </Typography>
        </div>
      </div>
      {/* Select Box */}
      {categories && (
        <div className={classes.autoCompleteContainer}>
          <Autocomplete
            disablePortal
            fullWidth
            getOptionLabel={(option) => option[t("category_name_ch")]}
            onChange={(event, value) => onInputFieldChange("category", value)}
            options={categories}
            renderInput={(params) => (
              <TextField {...params} label={t("類別")} variant="outlined" />
            )}
            value={categoryItem}
          />
        </div>
      )}
      {categoryId && categoryId !== "dummy" && (
        <>
          {/* Select Box */}
          {subCategories && (
            <div className={classes.autoCompleteContainer}>
              <Autocomplete
                disablePortal
                fullWidth
                getOptionLabel={(option) => option[t("sub_category_name_ch")]}
                onChange={(event, value) =>
                  onInputFieldChange("subCategory", value)
                }
                options={subCategories}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("子類別")}
                    variant="outlined"
                  />
                )}
                value={subCategoryItem}
              />
            </div>
          )}
        </>
      )}
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("品牌")}
          </Typography>
        </div>
      </div>
      {/* Select Box */}
      {brands && (
        <div className={classes.autoCompleteContainer}>
          <Autocomplete
            disablePortal
            fullWidth
            getOptionLabel={(option) =>
              option.id !== "dummy"
                ? option.brand_name
                : option[t("brand_name_ch")]
            }
            onChange={(event, value) => onInputFieldChange("brand", value)}
            options={brands}
            renderInput={(params) => (
              <TextField {...params} label={t("品牌")} variant="outlined" />
            )}
            value={brandItem}
          />
        </div>
      )}
    </div>
  );
}

export default StockConditionBoxesContainer;
