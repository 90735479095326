export function onComparisonRecordDateChange(comparisonRecordDate) {
  return {
    type: "ON_DISCOUNT_PROFILE_PAGE_COMPARISON_RECORD_DATE_CHANGE",
    comparisonRecordDate,
  };
}

export function onDiscountChange(discountId) {
  return {
    type: "ON_DISCOUNT_PROFILE_PAGE_DISCOUNT_CHANGE",
    discountId,
  };
}

export function onDiscountNumberTypeChange(discountNumberType) {
  return {
    type: "ON_DISCOUNT_PROFILE_PAGE_DISCOUNT_NUMBER_TYPE_CHANGE",
    discountNumberType,
  };
}

export function onFormSubmitCountIncrease() {
  return {
    type: "ON_DISCOUNT_PROFILE_PAGE_FORM_SUBMIT_COUNT_INCREASE",
  };
}

export function onIsComparisonShownChange(isComparisonShown) {
  return {
    type: "ON_DISCOUNT_PROFILE_PAGE_IS_COMPARISON_CHANGE",
    isComparisonShown,
  };
}

export function onIsMercGrpAvailableChange(isMercGrpAvailable) {
  return {
    type: "ON_DISCOUNT_PROFILE_PAGE_IS_MERC_GRP_AVAILABLE_CHANGE",
    isMercGrpAvailable,
  };
}

export function onMercGrpTypeChange(mercGrpTypeId) {
  return {
    type: "ON_DISCOUNT_PROFILE_PAGE_MERC_GRP_TYPE_CHANGE",
    mercGrpTypeId,
  };
}

export function onMercGrpTypeItemChange(mercGrpTypeItem) {
  return {
    type: "ON_DISCOUNT_PROFILE_PAGE_MERC_GRP_TYPE_ITEM_CHANGE",
    mercGrpTypeItem,
  };
}

export function onMerchandiseGroupChange(merchandiseGroupId) {
  return {
    type: "ON_DISCOUNT_PROFILE_PAGE_MERCHANDISE_GROUP_CHANGE",
    merchandiseGroupId,
  };
}

export function onMerchandiseGroupItemChange(merchandiseGroupItem) {
  return {
    type: "ON_DISCOUNT_PROFILE_PAGE_MERCHANDISE_GROUP_ITEM_CHANGE",
    merchandiseGroupItem,
  };
}

export function onRecordDateChange(recordDate) {
  return {
    type: "ON_DISCOUNT_PROFILE_PAGE_RECORD_DATE_CHANGE",
    recordDate,
  };
}

export function onRecordDateTypeChange(recordDateType) {
  return {
    type: "ON_DISCOUNT_PROFILE_PAGE_RECORD_DATE_TYPE_CHANGE",
    recordDateType,
  };
}

export function onTabItemChange(tabItem) {
  return {
    type: "ON_DISCOUNT_PROFILE_PAGE_TAB_ITEM_CHANGE",
    tabItem,
  };
}
