export function onBrandChange(brandId) {
  return {
    type: "ON_CATEGORIES_AND_BRANDS_PAGE_BRAND_CHANGE",
    brandId,
  };
}

export function onCategoryChange(categoryId) {
  return {
    type: "ON_CATEGORIES_AND_BRANDS_PAGE_CATEGORY_CHANGE",
    categoryId,
  };
}

export function onFormSubmitCountIncrease() {
  return {
    type: "ON_CATEGORIES_AND_BRANDS_PAGE_FORM_SUBMIT_COUNT_INCREASE",
  };
}

export function onSubCategoryChange(subCategoryId) {
  return {
    type: "ON_CATEGORIES_AND_BRANDS_PAGE_SUB_CATEGORY_CHANGE",
    subCategoryId,
  };
}

export function onTabItemChange(tabItem) {
  return {
    type: "ON_CATEGORIES_AND_BRANDS_PAGE_TAB_ITEM_CHANGE",
    tabItem,
  };
}

export function onTypeOfCategoriesAndBrandsChange(typeOfCategoriesAndBrands) {
  return {
    type: "ON_CATEGORIES_AND_BRANDS_PAGE_TYPE_OF_CATEGORIES_AND_BRANDS_CHANGE",
    typeOfCategoriesAndBrands,
  };
}

export function onTypeOfContentChange(typeOfContent) {
  return {
    type: "ON_CATEGORIES_AND_BRANDS_PAGE_TYPE_OF_CONTENT_CHANGE",
    typeOfContent,
  };
}

export function onTypeOfSubCategoriesChange(typeOfSubCategories) {
  return {
    type: "ON_CATEGORIES_AND_BRANDS_PAGE_TYPE_OF_SUB_CATEGORIES_CHANGE",
    typeOfSubCategories,
  };
}
