// Configs
import apisConfig from "../configs/apisConfig";

export const getAllAppliedDiscountItemsByMerchandiseSoldItemIdFetch = async (
  token,
  merchandiseSoldItemId
) => {
  try {
    const queryRoute = `/appliedDiscountItem/getAllAppliedDiscountItemsByMerchandiseSoldItemId/${merchandiseSoldItemId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllAppliedDiscountItemsBySalesRecordIdFetch = async (
  token,
  salesRecordId
) => {
  try {
    const queryRoute = `/appliedDiscountItem/getAllAppliedDiscountItemsBySalesRecordId/${salesRecordId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
