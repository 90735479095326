// Consts
import { languageOptions } from "../consts/languageConsts";

export const getMomentLocale = (currentLanguage) => {
  const currentLanguageItem = languageOptions.find(
    (item) => item.value === currentLanguage
  );

  return currentLanguageItem ? currentLanguageItem.locale : null;
};
