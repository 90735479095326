export const merchandiseSalesNumberTypes = [
  { type: "Revenue", nameCh: "營業額", nameEn: "Revenue", isMoney: true },
  {
    type: "SalesVolume",
    nameCh: "銷量",
    nameEn: "Sales Volume",
    isMoney: false,
  },
];

export const salesNumberTypes = [
  { type: "Revenue", nameCh: "營業額", nameEn: "Revenue", isMoney: true },
  {
    type: "NumOfSalesRecords",
    nameCh: "開單數量",
    nameEn: "Number of Sales",
    isMoney: false,
  },
  {
    type: "NumOfMerchandiseSolds",
    nameCh: "商品銷量",
    nameEn: "Revenue",
    isMoney: false,
  },
  {
    type: "AverageRevenueOfSalesRecords",
    nameCh: "每單平均金額",
    nameEn: "Average Amount per Sales",
    isMoney: true,
  },
  {
    type: "AverageNumOfMerchandiseOfSalesRecords",
    nameCh: "每單平均商品數量",
    nameEn: "Average Num of Merchandises per Sales",
    isMoney: false,
  },
];
