// Configs
import apisConfig from "../configs/apisConfig";

export const createAgeGroupFetch = async (
  token,
  ageGroupCode,
  ageGroupNameCh,
  ageGroupNameEn,
  startAge,
  endAge,
  isActive
) => {
  try {
    const queryRoute = "/ageGroup/createAgeGroup";

    const reqBody = {
      ageGroupCode,
      ageGroupNameCh,
      ageGroupNameEn,
      startAge,
      endAge,
      isActive,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const deleteOrRestoreAgeGroupFetch = async (
  token,
  ageGroupId,
  typeOfAgeGroups
) => {
  try {
    const queryRoute = `/ageGroup/deleteOrRestoreAgeGroup/${ageGroupId}`;

    const reqBody = { typeOfAgeGroups };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editAgeGroupFetch = async (
  token,
  ageGroupId,
  ageGroupCode,
  ageGroupNameCh,
  ageGroupNameEn,
  startAge,
  endAge,
  isActive
) => {
  try {
    const queryRoute = `/ageGroup/editAgeGroup/${ageGroupId}`;

    const reqBody = {
      ageGroupCode,
      ageGroupNameCh,
      ageGroupNameEn,
      startAge,
      endAge,
      isActive,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllAgeGroupsFetch = async (token, typeOfAgeGroups) => {
  try {
    const queryRoute = "/ageGroup/getAllAgeGroups";

    const reqBody = { typeOfAgeGroups };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAgeGroupDetailsByIdFetch = async (token, ageGroupId) => {
  try {
    const queryRoute = `/ageGroup/getAgeGroupDetailsById/${ageGroupId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
