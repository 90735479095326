const initialState = {
  previousPage: null,
  currentPage: null,
};

export const locationReducers = (state = initialState, action) => {
  switch (action.type) {
    case "ENTER_PAGE":
      const { pageName } = action;

      if (pageName === state.currentPage) {
        return state;
      } else {
        return {
          currentPage: pageName,
          previousPage: state.currentPage,
        };
      }
    default:
      return state;
  }
};
