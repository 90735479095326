// Actions
import { onFormSubmitCountIncrease } from "../../../redux/pages/merchandisesPage/merchandisesPageActions";

// Components
// Boxes
import MerchandiseAttributeBox from "../../boxes/merchandisesPage/merchandiseAttributeActionModal/merchandiseAttributeBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { getMerchandiseAttributeFormOptionsFetch } from "../../../fetches/formOptionFetches";
import {
  createOrEditMerchandiseToPropertiesAndAttributesFetch,
  removeMerchandiseToPropertiesAndAttributesFetch,
} from "../../../fetches/merchandiseToPropertyFetches";
import { getAllPropertiesAndAttributesByMerchandiseIdFetch } from "../../../fetches/propertyFetches";

// Helper Functions
import {
  createInitialPropertyItem,
  createPropertyItem,
} from "../../../helperFunctions/createPropertyItem";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function MerchandiseAttributeActionModal(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // Events
    onModalClosed,
  } = props;

  // Redux Store
  const merchandiseId = useSelector(
    (state) => state.merchandisesPage.merchandiseId
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Dialog
  const [
    merchandiseAttributeActionDialogText,
    setMerchandiseAttributeActionDialogText,
  ] = useState("");
  const [
    merchandiseAttributeActionDialogType,
    setMerchandiseAttributeActionDialogType,
  ] = useState(null);
  const [
    showMerchandiseAttributeActionDialog,
    setShowMerchandiseAttributeActionDialog,
  ] = useState(false);
  // Dynamic Fields
  const [properties, setProperties] = useState([createInitialPropertyItem()]);
  // Error Fields
  const [dynamicErrorFields, setDynamicErrorFields] = useState([]);
  // Options
  const [attributeOptions, setAttributeOptions] = useState([]);
  const [propertyOptions, setPropertyOptions] = useState([]);
  // Render
  const [isOptionsRetrieved, setIsOptionsRetrieved] = useState(false);

  // Events
  // Events - Dialogs
  const onMerchandiseAttributeActionDialogCanceled = () => {
    // Set States
    setShowMerchandiseAttributeActionDialog(false);
  };

  const onMerchandiseAttributeActionDialogConfirmed = () => {
    switch (merchandiseAttributeActionDialogType) {
      case "CreateOrEditMerchandiseAttributes":
        createOrEditMerchandiseToPropertiesAndAttributes();
        break;
      case "RemoveMerchandiseAttributes":
        removeMerchandiseToPropertiesAndAttributes();
        break;
      default:
        break;
    }

    // Set States
    setShowMerchandiseAttributeActionDialog(false);
  };

  // Events - Fields
  const onDynamicInputFieldChange = (field, id, subField, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "property":
        setProperties((currentState) => {
          let tempState = currentState;

          for (let item of tempState) {
            if (item.id === id) {
              item[subField] = value;

              if (subField === "propertyField" && value) {
                const tempAttributes = attributeOptions.filter(
                  (attributeItem) => attributeItem.property_id === value.id
                );

                item["attributeField"] = tempAttributes;
              }
            }
          }

          return tempState;
        });

        removeAllSpecificDynamicError(field, t("特徵 不可重覆"));
        removeDynamicErrorField(field, id, "attributeField");
        break;
      default:
        break;
    }

    removeDynamicErrorField(field, id, subField);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked();
    }
  };

  // Events - Forms
  const onAddDynamicFieldBtnClicked = (field) => {
    // Set States
    switch (field) {
      case "property":
        setProperties((currentState) => [
          ...currentState,
          createPropertyItem(currentState),
        ]);

        break;
      default:
        break;
    }
  };

  const onSubmitBtnClicked = () => {
    // Return If Null
    if (!properties[0]) {
      displayMerchandiseAttributeActionDialog("RemoveMerchandiseAttributes");
      return;
    }

    let isError = false;

    for (let item of properties) {
      // Property Field
      if (!item.propertyField) {
        addToDynamicErrorFields(
          "property",
          item.id,
          "propertyField",
          t("請先填寫 特徵")
        );
        isError = true;
      } else {
        // Check Duplicates
        const itemPropertyFeildId = item.propertyField.id;

        const numOfItemsWithSamePropertyField = properties.filter(
          (propertyItem) =>
            propertyItem.propertyField &&
            propertyItem.propertyField.id === itemPropertyFeildId
        ).length;

        if (
          numOfItemsWithSamePropertyField &&
          numOfItemsWithSamePropertyField > 1
        ) {
          addToDynamicErrorFields(
            "property",
            item.id,
            "propertyField",
            t("特徵 不可重覆")
          );
          isError = true;
        }
      }

      // Attribute Field
      if (!item.attributeField[0]) {
        addToDynamicErrorFields(
          "property",
          item.id,
          "attributeField",
          t("請先填寫 選項")
        );
        isError = true;
      }
    }

    if (isError) {
      return;
    }

    displayMerchandiseAttributeActionDialog(
      "CreateOrEditMerchandiseAttributes"
    );
  };

  const onDeleteDynamicFieldBtnClicked = (field, id) => {
    // Set States
    switch (field) {
      case "property":
        setProperties((currentState) =>
          currentState.filter((item) => item.id !== id)
        );
        break;
      default:
        break;
    }

    removeAllDynamicErrorField(field, id);
  };

  // Functions
  // Functions - Normal
  const addToDynamicErrorFields = (field, id, subField, message) => {
    if (
      dynamicErrorFields.some(
        (item) =>
          item.field === field && item.id === id && item.subField === subField
      )
    ) {
      return;
    }

    // Set States
    setDynamicErrorFields((currentState) => [
      ...currentState,
      { field, id, subField, message },
    ]);
  };

  const checkIsDynamicFieldError = (field, id, subField) => {
    return dynamicErrorFields.some(
      (item) =>
        item.field === field && item.id === id && item.subField === subField
    );
  };

  const clearDynamicErrorFields = () => {
    // Set States
    setDynamicErrorFields([]);
  };

  const displayMerchandiseAttributeActionDialog = (
    merchandiseAttributeActionType
  ) => {
    // Set States
    setMerchandiseAttributeActionDialogType(merchandiseAttributeActionType);

    switch (merchandiseAttributeActionType) {
      case "CreateOrEditMerchandiseAttributes":
        setMerchandiseAttributeActionDialogText(t("確認要編輯 選項 嗎？"));
        break;
      case "RemoveMerchandiseAttributes":
        setMerchandiseAttributeActionDialogText(t("確認要刪除 選項 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowMerchandiseAttributeActionDialog(true);
  };

  const getDynamicErrorFieldMessage = (field, id, subField) => {
    const targetField = dynamicErrorFields.find(
      (item) =>
        item.field === field && item.id === id && item.subField === subField
    );

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeAllDynamicErrorField = (field, id) => {
    // Set States
    setDynamicErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field || item.id !== id)
    );
  };

  const removeAllSpecificDynamicError = (field, message) => {
    // Set States
    setDynamicErrorFields((currentState) =>
      currentState.filter(
        (item) => item.field !== field || item.message !== message
      )
    );
  };

  const removeDynamicErrorField = (field, id, subField) => {
    // Set States
    setDynamicErrorFields((currentState) =>
      currentState.filter(
        (item) =>
          item.field !== field || item.id !== id || item.subField !== subField
      )
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const createOrEditMerchandiseToPropertiesAndAttributes = async () => {
    let propertyIdsArr = [];
    let attributeIdsArr = [];

    // Properties
    for (let propertyItem of properties) {
      const { propertyField, attributeField } = propertyItem;

      const propertyId = propertyField.id;

      propertyIdsArr.push(propertyId);

      // Attributes
      for (let attributeItem of attributeField) {
        const attributeId = attributeItem.id;

        attributeIdsArr.push(attributeId);
      }
    }

    const results = await createOrEditMerchandiseToPropertiesAndAttributesFetch(
      token,
      merchandiseId,
      propertyIdsArr,
      attributeIdsArr
    );

    if (results.success) {
      // Update Redux Store
      dispatch(onFormSubmitCountIncrease());

      onModalClosed();
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  const removeMerchandiseToPropertiesAndAttributes = async () => {
    const results = await removeMerchandiseToPropertiesAndAttributesFetch(
      token,
      merchandiseId
    );

    if (results.success) {
      // Update Redux Store
      dispatch(onFormSubmitCountIncrease());

      onModalClosed();
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  // Functions - Queries
  const getAllPropertiesAndAttributesByMerchandiseId = async () => {
    const results = await getAllPropertiesAndAttributesByMerchandiseIdFetch(
      token,
      merchandiseId
    );

    if (results.properties) {
      let tempProperties = [];

      // Merchandise Properties
      for (let i = 0; i < results.properties.length; i++) {
        const merchandisePropertyItem = results.properties[i];
        const propertyId = merchandisePropertyItem.id;

        const tempPropertyItem = propertyOptions.find(
          (item) => item.id === propertyId
        );

        // Merchandise Attributes
        let tempAttributes = [];

        if (merchandisePropertyItem.attributes) {
          const merchandiseAttributeIdsArr =
            merchandisePropertyItem.attributes.map((item) => item.id);

          tempAttributes = attributeOptions.filter((item) =>
            merchandiseAttributeIdsArr.includes(item.id)
          );
        }

        tempProperties.push({
          id: i + 1,
          attributeField: tempAttributes,
          propertyField: tempPropertyItem,
        });
      }

      // Set States
      if (tempProperties[0]) {
        setProperties(tempProperties);
      }
    }

    clearDynamicErrorFields();
  };

  const getMerchandiseAttributeFormOptions = async () => {
    const results = await getMerchandiseAttributeFormOptionsFetch(token);

    // Set States
    setAttributeOptions(results.attributes ? results.attributes : []);
    setPropertyOptions(results.properties ? results.properties : []);
  };

  // Life Cycle
  useEffect(() => {
    getMerchandiseAttributeFormOptions();
  }, []);

  useEffect(() => {
    // Set States
    if (!isOptionsRetrieved && attributeOptions[0]) {
      setIsOptionsRetrieved(true);
    }
  }, [attributeOptions]);

  useEffect(() => {
    if (isOptionsRetrieved) {
      if (merchandiseId) {
        getAllPropertiesAndAttributesByMerchandiseId();
      }
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [isOptionsRetrieved, merchandiseId]);

  return (
    <div className={classes.modalContainerLarge}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onMerchandiseAttributeActionDialogCanceled}
        onDialogConfirmed={onMerchandiseAttributeActionDialogConfirmed}
        // States
        dialogText={merchandiseAttributeActionDialogText}
        showDialog={showMerchandiseAttributeActionDialog}
      />

      {properties &&
        properties.map((item, index) => (
          <MerchandiseAttributeBox
            key={item.id}
            // Events
            onDeleteDynamicFieldBtnClicked={onDeleteDynamicFieldBtnClicked}
            onDynamicInputFieldChange={onDynamicInputFieldChange}
            onInputFieldKeyPressed={onInputFieldKeyPressed}
            // Functions
            checkIsDynamicFieldError={checkIsDynamicFieldError}
            getDynamicErrorFieldMessage={getDynamicErrorFieldMessage}
            // States
            attributeOptions={attributeOptions}
            index={index}
            item={item}
            propertyOptions={propertyOptions}
          />
        ))}
      <div>
        <Grid
          className={classes.formContainer}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          container
          rowSpacing={1}
        >
          <Grid item xs={12} className={classes.formSubmitBtnContainer}>
            <Button
              onClick={() => onAddDynamicFieldBtnClicked("property")}
              variant="outlined"
              sx={formSubmitBtnStyles}
            >
              {t("新增特徵")}
            </Button>
          </Grid>
          <Grid item xs={12} className={classes.formSubmitBtnContainer}>
            <Button
              onClick={onSubmitBtnClicked}
              variant="contained"
              sx={formSubmitBtnStyles}
            >
              {t("確定")}
            </Button>
          </Grid>
          {/* Alert */}
          {shouldShowFormAlert && (
            <Grid item xs={12}>
              <Alert severity={formAlertType}>{formAlertText}</Alert>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
}

export default MerchandiseAttributeActionModal;
