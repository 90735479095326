// Actions
import { onFormSubmitCountIncrease } from "../../../redux/pages/discountProfilePage/discountProfilePageActions";

// Components
// Boxes
import MerchandiseGroupMerchandiseBox from "./merchandiseGroupMerchandiseBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Modals
import ModalContainer from "../../modals/modalContainer";
import SelectMerchandiseModal from "../../modals/search/selectMerchandiseModal";

// Configs
import paginationConfig from "../../../configs/paginationConfig";
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { getAllMerchandisesByMerchandiseGroupIdWithPaginationFetch } from "../../../fetches/merchandiseFetches";
import {
  createMercGrpToMercFetch,
  deleteMercGrpToMercFetch,
} from "../../../fetches/mercGrpToMercFetches";

// Material UI
// Components
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import {
  containerSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

// Consts
const limitNum = paginationConfig.paginationLimitNum;

function MerchandiseGroupMerchandiseBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const formSubmitCount = useSelector(
    (state) => state.discountProfilePage.formSubmitCount
  );
  const isMercGrpAvailable = useSelector(
    (state) => state.discountProfilePage.isMercGrpAvailable
  );
  const merchandiseGroupId = useSelector(
    (state) => state.discountProfilePage.merchandiseGroupId
  );
  const merchandiseGroupItem = useSelector(
    (state) => state.discountProfilePage.merchandiseGroupItem
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [checkedItemIdsArr, setCheckedItemIdsArr] = useState([]);
  const [checkedItemsArr, setCheckedItemsArr] = useState([]);
  const [merchandises, setMerchandises] = useState(null);
  // Dialog
  const [merchandiseActionDialogText, setMerchandiseActionDialogText] =
    useState("");
  const [merchandiseActionDialogType, setMerchandiseActionDialogType] =
    useState("");
  const [showMerchandiseActionDialog, setShowMerchandiseActionDialog] =
    useState(false);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Modals
  const [selectedModalItemIdsArr, setSelectedModalItemIdsArr] = useState(null);
  const [showSelectMerchandiseModal, setShowSelectMerchandiseModal] =
    useState(false);
  // Pagination
  const [pageCount, setPageCount] = useState(null);
  const [pageNum, setPageNum] = useState(1);
  // Render
  const [shouldShowCheckedItemsOnly, setShouldShowCheckedItemsOnly] =
    useState(false);

  // Handle States
  const itemsArr = !shouldShowCheckedItemsOnly ? merchandises : checkedItemsArr;
  const shouldShowMerchandises =
    isMercGrpAvailable &&
    merchandiseGroupItem &&
    merchandiseGroupItem.merc_grp_cat_type_id === 4;

  // Events
  // Events - Box
  const onItemChecked = (item) => {
    const itemId = item.id;

    // Set States
    if (!checkedItemIdsArr.includes(itemId)) {
      setCheckedItemIdsArr((currentState) => [...currentState, itemId]);
      setCheckedItemsArr((currentState) => [...currentState, item]);
    } else {
      setCheckedItemIdsArr((currentState) =>
        currentState.filter((id) => id !== itemId)
      );
      setCheckedItemsArr((currentState) =>
        currentState.filter((item) => item.id !== itemId)
      );
    }
  };

  // Events - Buttons
  const onSubmitBtnClicked = () => {
    displayMerchandiseActionDialog("DeleteMerchandise");
  };

  // Events - Dialogs
  const onMerchandiseActionDialogCanceled = () => {
    // Set States
    setShowMerchandiseActionDialog(false);
  };

  const onMerchandiseActionDialogConfirmed = () => {
    switch (merchandiseActionDialogType) {
      case "AddMerchandise":
        createMercGrpToMerc(selectedModalItemIdsArr);
        break;
      case "DeleteMerchandise":
        deleteMercGrpToMerc();
        break;
      default:
        break;
    }

    // Set States
    setShowMerchandiseActionDialog(false);
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Modal
  const onSelectMerchandiseModalClosed = () => {
    // Set States
    setShowSelectMerchandiseModal(false);
  };

  const onSelectMerchandiseModalSubmitBtnClicked = (itemIdsArr) => {
    // Set States
    setSelectedModalItemIdsArr(itemIdsArr);
    displayMerchandiseActionDialog("AddMerchandise");
  };

  // Events - Pagination
  const onPageChange = (value) => {
    // Set States
    setPageNum(value);
  };

  // Events - Title
  const onAddMerchandiseBtnClicked = () => {
    displaySelectMerchandiseModal();
  };

  const onShowCheckedItemsOnlyBtnClicked = () => {
    // Set States
    setShouldShowCheckedItemsOnly(!shouldShowCheckedItemsOnly);
  };

  // Functions
  // Functions - Normal
  const clearData = () => {
    // Set States
    setMerchandises(null);
  };

  const displaySelectMerchandiseModal = () => {
    setShowSelectMerchandiseModal(true);
  };

  const displayMerchandiseActionDialog = (merchandiseActionType) => {
    // Set States
    setMerchandiseActionDialogType(merchandiseActionType);

    switch (merchandiseActionType) {
      case "AddMerchandise":
        setMerchandiseActionDialogText(t("確認要從新增 商品 到 群組 嗎？"));
        break;
      case "DeleteMerchandise":
        setMerchandiseActionDialogText(t("確認要從 群組 刪除 商品 嗎？"));
        break;
      default:
        break;
    }

    setShowMerchandiseActionDialog(true);
  };

  const resetList = () => {
    // Set States
    setCheckedItemIdsArr([]);
    setCheckedItemsArr([]);
  };

  const showAllItems = () => {
    // Set States
    setShouldShowCheckedItemsOnly(false);
  };

  // Functions - Mutations
  const createMercGrpToMerc = async (merchandiseIdsArr) => {
    const results = await createMercGrpToMercFetch(
      token,
      merchandiseGroupId,
      merchandiseIdsArr
    );

    if (results.success) {
      // Set States
      getAllMerchandisesByMerchandiseGroupId();

      onSelectMerchandiseModalClosed();

      // Update Redux Store
      dispatch(onFormSubmitCountIncrease());
    }
  };

  const deleteMercGrpToMerc = async () => {
    const results = await deleteMercGrpToMercFetch(
      token,
      merchandiseGroupId,
      checkedItemIdsArr
    );

    if (results.success) {
      resetList();
      getAllMerchandisesByMerchandiseGroupId();

      // Update Redux Store
      dispatch(onFormSubmitCountIncrease());
    }
  };

  // Functions - Queries
  const getAllMerchandisesByMerchandiseGroupId = async () => {
    const results =
      await getAllMerchandisesByMerchandiseGroupIdWithPaginationFetch(
        token,
        merchandiseGroupId,
        pageNum,
        limitNum
      );

    // Set States
    setPageCount(results.pageCount ? results.pageCount : 0);
    setMerchandises(results.merchandises ? results.merchandises : null);

    if (results.pageCount && !results.merchandises) {
      setPageNum(results.pageCount);
    }
  };

  // Life Cycle
  useEffect(() => {
    resetList();
  }, [formSubmitCount, merchandiseGroupId]);

  useEffect(() => {
    if (merchandiseGroupId) {
      getAllMerchandisesByMerchandiseGroupId();
    } else {
      clearData();
    }
  }, [formSubmitCount, merchandiseGroupId, pageNum]);

  useEffect(() => {
    if (!checkedItemIdsArr[0]) {
      showAllItems();
    }
  }, [checkedItemIdsArr]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onMerchandiseActionDialogCanceled}
        onDialogConfirmed={onMerchandiseActionDialogConfirmed}
        // States
        dialogText={merchandiseActionDialogText}
        showDialog={showMerchandiseActionDialog}
      />
      {/* Modal */}
      <ModalContainer
        // Events
        onModalClosed={onSelectMerchandiseModalClosed}
        // States
        showModal={showSelectMerchandiseModal}
      >
        <SelectMerchandiseModal
          // Events
          onModalSubmitBtnClicked={onSelectMerchandiseModalSubmitBtnClicked}
          // States
          paramsArr={["MerchandiseGroup"]}
        />
      </ModalContainer>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("指定商品s")}
          </Typography>
          {shouldShowMerchandises && (
            <Tooltip placement="right" title={t("新增商品a")}>
              <IconButton
                color={"primary"}
                onClick={onAddMerchandiseBtnClicked}
              >
                <AddRoundedIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
        {checkedItemIdsArr[0] && (
          <Button
            endIcon={<KeyboardArrowDownRoundedIcon />}
            onClick={onShowCheckedItemsOnlyBtnClicked}
            variant="text"
          >
            {`${t("顯示 ")}${
              shouldShowCheckedItemsOnly ? t("所有") : t("已選擇")
            }${t(" 商品s")}`}
          </Button>
        )}
      </div>
      {/* List */}
      {itemsArr ? (
        <>
          {itemsArr.map((item) => (
            <MerchandiseGroupMerchandiseBox
              key={item.id}
              // States
              checkedItemIdsArr={checkedItemIdsArr}
              hoveredItemId={hoveredItemId}
              item={item}
              // Events
              onItemChecked={onItemChecked}
              onMouseEnterItem={onMouseEnterItem}
              onMouseLeaveItem={onMouseLeaveItem}
            />
          ))}
          {checkedItemIdsArr[0] && (
            <div className={classes.containerSubmitBtnBox}>
              <Button
                onClick={onSubmitBtnClicked}
                variant="contained"
                sx={containerSubmitBtnStyles}
              >
                {t("移除")}
              </Button>
            </div>
          )}
          {!shouldShowCheckedItemsOnly && (
            <Stack className={classes.paginationStack} spacing={2}>
              <Pagination
                color={"primary"}
                count={pageCount}
                onChange={(event, page) => onPageChange(page)}
                page={pageNum}
              />
            </Stack>
          )}
        </>
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {shouldShowMerchandises ? t("未有商品資料") : t("不適用")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default MerchandiseGroupMerchandiseBoxesContainer;
