// Actions
import {
  onBrandChange,
  onCategoryChange,
  onTypeOfContentChange,
} from "../../../redux/pages/categoriesAndBrandsPage/categoriesAndBrandsPageActions";

// Components
// Boxes
import CategoryAndBrandBox from "./categoryAndBrandBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Menus
import CategoryAndBrandActionMenu from "../../menus/categoriesAndBrandsPage/categoryAndBrandActionMenu";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  deleteOrRestoreBrandFetch,
  getAllBrandsFetch,
} from "../../../fetches/brandFetches";
import {
  deleteOrRestoreCategoryFetch,
  getAllCategoriesFetch,
} from "../../../fetches/categoryFetches";

// Material UI
// Components
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function CategoryAndBrandBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const brandId = useSelector((state) => state.categoriesAndBrandsPage.brandId);
  const categoryId = useSelector(
    (state) => state.categoriesAndBrandsPage.categoryId
  );
  const formSubmitCount = useSelector(
    (state) => state.categoriesAndBrandsPage.formSubmitCount
  );
  const tabItem = useSelector((state) => state.categoriesAndBrandsPage.tabItem);
  const token = useSelector((state) => state.staff.token);
  const typeOfCategoriesAndBrands = useSelector(
    (state) => state.categoriesAndBrandsPage.typeOfCategoriesAndBrands
  );
  const typeOfContent = useSelector(
    (state) => state.categoriesAndBrandsPage.typeOfContent
  );

  // States
  // Data
  const [brands, setBrands] = useState(null);
  const [categories, setCategories] = useState(null);
  // Dialog
  const [
    categoryAndBrandActionDialogText,
    setCategoryAndBrandActionDialogText,
  ] = useState("");
  const [
    categoryAndBrandActionDialogType,
    setCategoryAndBrandActionDialogType,
  ] = useState(null);
  const [
    showCategoryAndBrandActionDialog,
    setShowCategoryAndBrandActionDialog,
  ] = useState(false);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Menu
  const [showCategoryAndBrandActionMenu, setShowCategoryAndBrandActionMenu] =
    useState(null);

  // Handle States
  const currentItemId = tabItem === "Categories" ? categoryId : brandId;
  const itemsArr = tabItem === "Categories" ? categories : brands;

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Update Redux Store
    if (tabItem === "Categories") {
      dispatch(onCategoryChange(itemId));
    } else {
      dispatch(onBrandChange(itemId));
    }
  };

  const onItemRightClicked = (itemId, currentTarget) => {
    onItemClicked(itemId);
    // Set States
    setShowCategoryAndBrandActionMenu(currentTarget);
  };

  const onItemShowMenuBtnClicked = (itemId, currentTarget) => {
    onItemClicked(itemId);
    // Set States
    setShowCategoryAndBrandActionMenu(currentTarget);
  };

  // Events - Dialogs
  const onCategoryAndBrandActionDialogCanceled = () => {
    // Set States
    setShowCategoryAndBrandActionDialog(false);
  };

  const onCategoryAndBrandActionDialogConfirmed = () => {
    switch (categoryAndBrandActionDialogType) {
      case "DeleteBrand":
        deleteOrRestoreBrand();
        break;
      case "DeleteCategory":
        deleteOrRestoreCategory();
        break;
      case "RestoreBrand":
        deleteOrRestoreBrand();
        break;
      case "RestoreCategory":
        deleteOrRestoreCategory();
        break;
      default:
        break;
    }

    // Set States
    setShowCategoryAndBrandActionDialog(false);
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Title
  const onAddItemBtnClicked = () => {
    // Update Redux Store
    if (tabItem === "Categories") {
      dispatch(onCategoryChange(null));
    } else {
      dispatch(onBrandChange(null));
    }

    // Update Redux Store
    dispatch(onTypeOfContentChange("CategoryAndBrandDetail"));
  };

  const onChangeTypeOfContentBtnClicked = () => {
    // Update Redux Store
    dispatch(
      onTypeOfContentChange(
        typeOfContent === "SubCategoryList"
          ? "CategoryAndBrandDetail"
          : "SubCategoryList"
      )
    );
  };

  // Functions
  // Functions - Normal
  const displayCategoryAndBrandActionDialog = (categoryAndBrandActionType) => {
    // Set States
    setCategoryAndBrandActionDialogType(categoryAndBrandActionType);

    switch (categoryAndBrandActionType) {
      case "DeleteCategory":
        setCategoryAndBrandActionDialogText(t("確認要刪除 類別 嗎？"));
        break;
      case "DeleteBrand":
        setCategoryAndBrandActionDialogText(t("確認要刪除 品牌 嗎？"));
        break;
      case "RestoreCategory":
        setCategoryAndBrandActionDialogText(t("確認要還原 類別 嗎？"));
        break;
      case "RestoreBrand":
        setCategoryAndBrandActionDialogText(t("確認要還原 品牌 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowCategoryAndBrandActionDialog(true);
  };

  // Functions - Mutations
  const deleteOrRestoreBrand = async () => {
    const results = await deleteOrRestoreBrandFetch(
      token,
      currentItemId,
      typeOfCategoriesAndBrands
    );

    if (results.success) {
      getAllBrands(true);
    }
  };

  const deleteOrRestoreCategory = async () => {
    const results = await deleteOrRestoreCategoryFetch(
      token,
      currentItemId,
      typeOfCategoriesAndBrands
    );

    if (results.success) {
      getAllCategories(true);
    }
  };

  // Functions - Queries
  const getAllBrands = async (shoulddUpdateCurrentBrandId) => {
    const results = await getAllBrandsFetch(token, typeOfCategoriesAndBrands);

    // Set States
    setBrands(results.brands ? results.brands : null);

    // Update Redux Store
    if (
      results.brands &&
      (shoulddUpdateCurrentBrandId ||
        !results.brands.some((item) => item.id === brandId))
    ) {
      dispatch(onBrandChange(results.brands[0].id));
    }
  };

  const getAllCategories = async (shoulddUpdateCurrentCategoryId) => {
    const results = await getAllCategoriesFetch(
      token,
      typeOfCategoriesAndBrands
    );

    // Set States
    setCategories(results.categories ? results.categories : null);

    // Update Redux Store
    if (shoulddUpdateCurrentCategoryId) {
      dispatch(
        onCategoryChange(results.categories ? results.categories[0].id : null)
      );
    }
  };

  // Life Cycle
  useEffect(() => {
    if (tabItem === "Categories") {
      getAllCategories(categoryId ? false : true);
    } else {
      getAllBrands();
    }
  }, [tabItem, formSubmitCount, typeOfCategoriesAndBrands]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onCategoryAndBrandActionDialogCanceled}
        onDialogConfirmed={onCategoryAndBrandActionDialogConfirmed}
        // States
        dialogText={categoryAndBrandActionDialogText}
        showDialog={showCategoryAndBrandActionDialog}
      />
      {/* Menu */}
      <CategoryAndBrandActionMenu
        // States
        showCategoryAndBrandActionMenu={showCategoryAndBrandActionMenu}
        // Set States
        setShowCategoryAndBrandActionMenu={setShowCategoryAndBrandActionMenu}
        // Fucntions
        displayCategoryAndBrandActionDialog={
          displayCategoryAndBrandActionDialog
        }
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {tabItem === "Categories" ? t("類別s") : t("品牌s")}
          </Typography>
          <Tooltip
            placement="right"
            title={tabItem === "Categories" ? t("新增類別") : t("新增品牌")}
          >
            <IconButton color={"primary"} onClick={onAddItemBtnClicked}>
              <AddRoundedIcon />
            </IconButton>
          </Tooltip>
        </div>
        {tabItem === "Categories" && (
          <Button
            endIcon={<ChevronRightRoundedIcon />}
            onClick={onChangeTypeOfContentBtnClicked}
            variant="text"
          >
            {typeOfContent === "SubCategoryList"
              ? t("顯示類別資料")
              : t("顯示子類別列表")}
          </Button>
        )}
      </div>
      {/* List */}
      {itemsArr ? (
        itemsArr.map((item) => (
          <CategoryAndBrandBox
            key={item.id}
            // States
            currentItemId={currentItemId}
            hoveredItemId={hoveredItemId}
            item={item}
            tabItem={tabItem}
            // Events
            onItemClicked={onItemClicked}
            onItemRightClicked={onItemRightClicked}
            onItemShowMenuBtnClicked={onItemShowMenuBtnClicked}
            onMouseEnterItem={onMouseEnterItem}
            onMouseLeaveItem={onMouseLeaveItem}
          />
        ))
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {tabItem === "Categories" ? t("未有類別資料") : t("未有品牌資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default CategoryAndBrandBoxesContainer;
