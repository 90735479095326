// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// Styles
import { useStandardItemBoxStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxStyles";

function SalesRecordIntroPaymentInfoBox(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxStyles();

  // Props
  const {
    // States
    item,
  } = props;

  // Handle States
  const isDiscounted = Number(item.sub_total) !== Number(item.total_price);

  return (
    <>
      <div className={classes.itemContainer}>
        <div className={classes.itemContentContainer}>
          <div className={classes.itemContentSubContainer}>
            <Typography
              align={"left"}
              color={stylesConfig.greyTextColor}
              variant="body2"
            >
              {t("小結")}
            </Typography>
          </div>
          <div className={classes.itemContentSubContainerRight}>
            <Typography
              align={"left"}
              color={stylesConfig.greyTextColor}
              variant="body2"
            >
              {item.sub_total_display}
            </Typography>
          </div>
        </div>
      </div>
      <div className={classes.itemContainer}>
        <div className={classes.itemContentContainer}>
          <div className={classes.itemContentSubContainer}>
            <Typography
              align={"left"}
              color={stylesConfig.greyTextColor}
              variant="body2"
            >
              {t("折扣")}
            </Typography>
          </div>
          <div className={classes.itemContentSubContainerRight}>
            <Typography
              align={"left"}
              color={
                isDiscounted
                  ? stylesConfig.redTextColor
                  : stylesConfig.greyTextColor
              }
              variant="body2"
            >
              {item.discount_amount_display}
            </Typography>
          </div>
        </div>
      </div>
      <div className={classes.itemContainer}>
        <div className={classes.itemContentContainer}>
          <div className={classes.itemContentSubContainer}>
            <Typography align={"left"} variant="body1">
              {t("折實價")}
            </Typography>
          </div>
          <div className={classes.itemContentSubContainerRight}>
            <Typography align={"left"} variant="body1">
              {item.total_price_display}
            </Typography>
          </div>
        </div>
      </div>
    </>
  );
}

export default SalesRecordIntroPaymentInfoBox;
