// Components
// Boxes
import PopularShopBox from "./popularShopBox";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { getPopularShopsFetch } from "../../../fetches/salesNumberFetches";

// Helper Functions
import { separateComma } from "../../../helperFunctions/separateComma";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

// Consts
const today = new Date();

function PopularShopBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [popularShops, setPopularShops] = useState(null);

  // Handle States
  const itemsArr = popularShops;

  // Functions
  // Functions - Queries
  const getPopularShops = async () => {
    const results = await getPopularShopsFetch(token, today, null);

    if (results.popularShops) {
      for (let item of results.popularShops) {
        item.revenue = item.revenue
          ? `$ ${separateComma(Number(item.revenue).toFixed(1))}`
          : null;
      }
    }

    // Set States
    setPopularShops(results.popularShops ? results.popularShops : null);
  };

  // Life Cycle
  useEffect(() => {
    getPopularShops();
  }, []);

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("本日最佳店鋪 (營業額)")}
          </Typography>
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        itemsArr.map((item) => (
          <PopularShopBox
            key={item.id}
            // States
            item={item}
          />
        ))
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有最佳店鋪資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default PopularShopBoxesContainer;
