// Components
// Backdrop
import LoadingBackdrop from "../../backdrop/loadingBackdrop";
// Charts
import SalesNumberChart from "./salesNumberChart";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Consts
import { salesNumberTypes } from "../../../consts/salesNumberTypeConsts";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  getSalesNumbersForSalespersonsByShopIdFetch,
  getSalesNumbersForShopBrandsFetch,
  getSalesNumbersForShopsByShopBrandIdFetch,
} from "../../../fetches/salesNumberFetches";

// Helper Functions
import { getDateVarsForSalesNumbers } from "../../../helperFunctions/getDateVarForCharts";
import { getMomentLocale } from "../../../helperFunctions/getMomentLocale";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// Moment
import moment from "moment";
import "moment/min/locales";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function SalesNumberChartContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const comparisonRecordDate = useSelector(
    (state) => state.salesReportsPage.comparisonRecordDate
  );
  const isComparisonShown = useSelector(
    (state) => state.salesReportsPage.isComparisonShown
  );
  const language = useSelector((state) => state.language.language);
  const recordDate = useSelector((state) => state.salesReportsPage.recordDate);
  const recordDateType = useSelector(
    (state) => state.salesReportsPage.recordDateType
  );
  const salesNumberType = useSelector(
    (state) => state.salesReportsPage.salesNumberType
  );
  const shopBrandId = useSelector(
    (state) => state.salesReportsPage.conditionShopBrandId
  );
  const shopId = useSelector((state) => state.salesReportsPage.conditionShopId);
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [salesNumbers, setSalesNumbers] = useState(null);
  const [xAxisDataGridKey, setXAxisDataGridKey] = useState("");
  // Redner
  const [isLoading, setIsLoading] = useState(false);

  // Handle States
  const comparisonRecordDateVar = new Date(comparisonRecordDate);
  const itemsArr = salesNumbers;
  const recordDateVar = new Date(recordDate);
  const salesNumberTypeItem = salesNumberTypes.find(
    (item) => item.type === salesNumberType
  );

  const comparisonRecordDateDisplay =
    recordDateType === "Date"
      ? moment(comparisonRecordDateVar).format(t("Y 年 M 月 D 日"))
      : moment(comparisonRecordDateVar).format(t("Y 年 M 月"));
  const isMoney = salesNumberTypeItem ? salesNumberTypeItem.isMoney : false;
  const recordDateDisplay =
    recordDateType === "Date"
      ? moment(recordDateVar).format(t("Y 年 M 月 D 日"))
      : moment(recordDateVar).format(t("Y 年 M 月"));
  const salesNumberTypeItemName = salesNumberTypeItem
    ? salesNumberTypeItem[t("nameCh")]
    : "";

  // Functions
  // Functions - Queries
  const getSalesNumbersForSalespersonsByShopId = async () => {
    const {
      startDateVar,
      endDateVar,
      comparisonStartDateVar,
      comparisonEndDateVar,
    } = getDateVarsForSalesNumbers(
      recordDateType,
      isComparisonShown,
      recordDateVar,
      comparisonRecordDateVar
    );

    const results = await getSalesNumbersForSalespersonsByShopIdFetch(
      token,
      shopId,
      salesNumberType,
      startDateVar,
      endDateVar,
      comparisonStartDateVar,
      comparisonEndDateVar
    );

    if (results.staffs) {
      for (let item of results.staffs) {
        item.salesNumber = Number(item.salesNumber);
        item.comparisonSalesNumber = item.comparisonSalesNumber
          ? Number(item.comparisonSalesNumber)
          : 0;
      }
    }

    // Set States
    setSalesNumbers(results.staffs ? results.staffs : null);
    setXAxisDataGridKey(results.staffs ? "alias" : "");

    setIsLoading(false);
  };

  const getSalesNumbersForShopBrands = async () => {
    const {
      startDateVar,
      endDateVar,
      comparisonStartDateVar,
      comparisonEndDateVar,
    } = getDateVarsForSalesNumbers(
      recordDateType,
      isComparisonShown,
      recordDateVar,
      comparisonRecordDateVar
    );

    const results = await getSalesNumbersForShopBrandsFetch(
      token,
      salesNumberType,
      startDateVar,
      endDateVar,
      comparisonStartDateVar,
      comparisonEndDateVar
    );

    if (results.brands) {
      for (let item of results.brands) {
        item.salesNumber = Number(item.salesNumber);
        item.comparisonSalesNumber = item.comparisonSalesNumber
          ? Number(item.comparisonSalesNumber)
          : 0;
      }
    }

    // Set States
    setSalesNumbers(results.brands ? results.brands : null);
    setXAxisDataGridKey(results.brands ? "brand_name_en_short" : "");

    setIsLoading(false);
  };

  const getSalesNumbersForShopsByShopBrandId = async () => {
    const {
      startDateVar,
      endDateVar,
      comparisonStartDateVar,
      comparisonEndDateVar,
    } = getDateVarsForSalesNumbers(
      recordDateType,
      isComparisonShown,
      recordDateVar,
      comparisonRecordDateVar
    );

    const results = await getSalesNumbersForShopsByShopBrandIdFetch(
      token,
      shopBrandId,
      salesNumberType,
      startDateVar,
      endDateVar,
      comparisonStartDateVar,
      comparisonEndDateVar
    );

    if (results.shops) {
      for (let item of results.shops) {
        item.salesNumber = Number(item.salesNumber);
        item.comparisonSalesNumber = item.comparisonSalesNumber
          ? Number(item.comparisonSalesNumber)
          : 0;
      }
    }

    // Set States
    setSalesNumbers(results.shops ? results.shops : null);
    setXAxisDataGridKey(results.shops ? "shop_code" : "");

    setIsLoading(false);
  };

  // Life Cycle
  useEffect(() => {
    if (shopBrandId && shopBrandId === "dummy") {
      // Set States
      setIsLoading(true);

      getSalesNumbersForShopBrands();
    }
  }, [
    salesNumberType,
    shopBrandId,
    recordDate,
    comparisonRecordDate,
    recordDateType,
    isComparisonShown,
    language,
  ]);

  useEffect(() => {
    if (
      shopBrandId &&
      shopBrandId !== "dummy" &&
      shopId &&
      shopId === "dummy"
    ) {
      // Set States
      setIsLoading(true);

      getSalesNumbersForShopsByShopBrandId();
    }
  }, [
    salesNumberType,
    shopBrandId,
    shopId,
    recordDate,
    comparisonRecordDate,
    recordDateType,
    isComparisonShown,
    language,
  ]);

  useEffect(() => {
    if (
      shopBrandId &&
      shopBrandId !== "dummy" &&
      shopId &&
      shopId !== "dummy"
    ) {
      // Set States
      setIsLoading(true);

      getSalesNumbersForSalespersonsByShopId();
    }
  }, [
    salesNumberType,
    shopBrandId,
    shopId,
    recordDate,
    comparisonRecordDate,
    recordDateType,
    isComparisonShown,
    language,
  ]);

  useEffect(() => {
    moment.locale(getMomentLocale(language));
  }, [language]);

  return (
    <div className={classes.contentBox}>
      {/* Backdrop */}
      <LoadingBackdrop
        // States
        showBackdrop={isLoading}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {`${t("銷售數據s")} - ${salesNumberTypeItemName}`}
          </Typography>
        </div>
      </div>
      {/* Content */}
      {itemsArr ? (
        <SalesNumberChart
          // States
          comparisonRecordDateDisplay={comparisonRecordDateDisplay}
          isComparisonShown={isComparisonShown}
          isMoney={isMoney}
          itemsArr={itemsArr}
          recordDateDisplay={recordDateDisplay}
          xAxisDataGridKey={xAxisDataGridKey}
        />
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有銷售數據")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default SalesNumberChartContainer;
