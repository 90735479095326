// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function EmptyMerchandiseGroupDetailFormContent() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("商品群組資料")}
          </Typography>
        </div>
      </div>
      {/* Content */}
      <div className={classes.emptyDataContainer}>
        <Typography align={"center"} color={stylesConfig.greyTextColor}>
          {t("不適用")}
        </Typography>
      </div>
    </div>
  );
}

export default EmptyMerchandiseGroupDetailFormContent;
