// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Material-UI
import { createStyles, makeStyles } from "@mui/styles";

export const useModalContainerStyles = makeStyles((theme) =>
  createStyles({
    modalBoxContainer: {
      backgroundColor:
        theme.palette.mode === "light"
          ? stylesConfig.contentBackgroundColorLight
          : stylesConfig.contentBackgroundColorDark,
      borderRadius: stylesConfig.borderRadiusSmall,
      boxShadow:
        theme.palette.mode === "light"
          ? stylesConfig.shadowLargeLight
          : stylesConfig.shadowLargeDark,
      padding: "30px 0",
    },
    modalBoxContent: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      maxHeight: "60vh",
      overflow: "auto",
      padding: "0 30px",
    },
    modalContainer: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      height: "100vh",
      justifyContent: "center",
      position: "fixed",
      width: "100vw",
    },
  })
);
