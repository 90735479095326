// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Typography from "@mui/material/Typography";

function LoadingBox() {
  // Hooks
  // Languages
  const t = useLanguage();

  return (
    <Typography align={"center"} variant="h6">
      {t("載入中...")}
    </Typography>
  );
}

export default LoadingBox;
