export const discountPercentageOptions = [
  { value: 0, textCh: "免費", textEn: "Free" },
  { value: 10, textCh: "1折", textEn: "90% Off" },
  { value: 11, textCh: "11折", textEn: "89% Off" },
  { value: 12, textCh: "12折", textEn: "88% Off" },
  { value: 13, textCh: "13折", textEn: "87% Off" },
  { value: 14, textCh: "14折", textEn: "86% Off" },
  { value: 15, textCh: "15折", textEn: "85% Off" },
  { value: 16, textCh: "16折", textEn: "84% Off" },
  { value: 17, textCh: "17折", textEn: "83% Off" },
  { value: 18, textCh: "18折", textEn: "82% Off" },
  { value: 19, textCh: "19折", textEn: "81% Off" },
  { value: 20, textCh: "2折", textEn: "80% Off" },
  { value: 21, textCh: "21折", textEn: "79% Off" },
  { value: 22, textCh: "22折", textEn: "78% Off" },
  { value: 23, textCh: "23折", textEn: "77% Off" },
  { value: 24, textCh: "24折", textEn: "76% Off" },
  { value: 25, textCh: "25折", textEn: "75% Off" },
  { value: 26, textCh: "26折", textEn: "74% Off" },
  { value: 27, textCh: "27折", textEn: "73% Off" },
  { value: 28, textCh: "28折", textEn: "72% Off" },
  { value: 29, textCh: "29折", textEn: "71% Off" },
  { value: 30, textCh: "3折", textEn: "70% Off" },
  { value: 31, textCh: "31折", textEn: "69% Off" },
  { value: 32, textCh: "32折", textEn: "68% Off" },
  { value: 33, textCh: "33折", textEn: "67% Off" },
  { value: 34, textCh: "34折", textEn: "66% Off" },
  { value: 35, textCh: "35折", textEn: "65% Off" },
  { value: 36, textCh: "36折", textEn: "64% Off" },
  { value: 37, textCh: "37折", textEn: "63% Off" },
  { value: 38, textCh: "38折", textEn: "62% Off" },
  { value: 39, textCh: "39折", textEn: "61% Off" },
  { value: 40, textCh: "4折", textEn: "60% Off" },
  { value: 41, textCh: "41折", textEn: "59% Off" },
  { value: 42, textCh: "42折", textEn: "58% Off" },
  { value: 43, textCh: "43折", textEn: "57% Off" },
  { value: 44, textCh: "44折", textEn: "56% Off" },
  { value: 45, textCh: "45折", textEn: "55% Off" },
  { value: 46, textCh: "46折", textEn: "54% Off" },
  { value: 47, textCh: "47折", textEn: "53% Off" },
  { value: 48, textCh: "48折", textEn: "52% Off" },
  { value: 49, textCh: "49折", textEn: "51% Off" },
  { value: 50, textCh: "半價", textEn: "50% Off" },
  { value: 51, textCh: "51折", textEn: "49% Off" },
  { value: 52, textCh: "52折", textEn: "48% Off" },
  { value: 53, textCh: "53折", textEn: "47% Off" },
  { value: 54, textCh: "54折", textEn: "46% Off" },
  { value: 55, textCh: "55折", textEn: "45% Off" },
  { value: 56, textCh: "56折", textEn: "44% Off" },
  { value: 57, textCh: "57折", textEn: "43% Off" },
  { value: 58, textCh: "58折", textEn: "42% Off" },
  { value: 59, textCh: "59折", textEn: "41% Off" },
  { value: 60, textCh: "6折", textEn: "40% Off" },
  { value: 66, textCh: "61折", textEn: "39% Off" },
  { value: 62, textCh: "62折", textEn: "38% Off" },
  { value: 63, textCh: "63折", textEn: "37% Off" },
  { value: 64, textCh: "64折", textEn: "36% Off" },
  { value: 65, textCh: "65折", textEn: "35% Off" },
  { value: 66, textCh: "66折", textEn: "34% Off" },
  { value: 67, textCh: "67折", textEn: "33% Off" },
  { value: 68, textCh: "68折", textEn: "32% Off" },
  { value: 69, textCh: "69折", textEn: "31% Off" },
  { value: 70, textCh: "7折", textEn: "30% Off" },
  { value: 71, textCh: "71折", textEn: "29% Off" },
  { value: 72, textCh: "72折", textEn: "28% Off" },
  { value: 73, textCh: "73折", textEn: "27% Off" },
  { value: 74, textCh: "74折", textEn: "26% Off" },
  { value: 75, textCh: "75折", textEn: "25% Off" },
  { value: 76, textCh: "76折", textEn: "24% Off" },
  { value: 77, textCh: "77折", textEn: "23% Off" },
  { value: 78, textCh: "78折", textEn: "22% Off" },
  { value: 79, textCh: "79折", textEn: "21% Off" },
  { value: 80, textCh: "8折", textEn: "20% Off" },
  { value: 81, textCh: "81折", textEn: "19% Off" },
  { value: 82, textCh: "82折", textEn: "18% Off" },
  { value: 83, textCh: "83折", textEn: "17% Off" },
  { value: 84, textCh: "84折", textEn: "16% Off" },
  { value: 85, textCh: "85折", textEn: "15% Off" },
  { value: 86, textCh: "86折", textEn: "14% Off" },
  { value: 87, textCh: "87折", textEn: "13% Off" },
  { value: 88, textCh: "88折", textEn: "12% Off" },
  { value: 89, textCh: "89折", textEn: "11% Off" },
  { value: 90, textCh: "9折", textEn: "10% Off" },
  { value: 91, textCh: "91折", textEn: "9% Off" },
  { value: 92, textCh: "92折", textEn: "8% Off" },
  { value: 93, textCh: "93折", textEn: "7% Off" },
  { value: 94, textCh: "94折", textEn: "6% Off" },
  { value: 95, textCh: "95折", textEn: "5% Off" },
  { value: 96, textCh: "96折", textEn: "4% Off" },
  { value: 97, textCh: "97折", textEn: "3% Off" },
  { value: 98, textCh: "98折", textEn: "2% Off" },
  { value: 99, textCh: "99折", textEn: "1% Off" },
];
