// Material UI
// Components
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

// Styles
import { loadingBackdropBackgroundStyles } from "../../styles/componentStyles/backdropStyles/loadingBackdropStyles";

function LoadingBackdrop(props) {
  // Props
  const {
    // States
    showBackdrop,
  } = props;

  // Events
  const onBackdropClicked = () => {
    return;
  };

  return (
    <Backdrop
      sx={loadingBackdropBackgroundStyles}
      open={showBackdrop}
      onClick={onBackdropClicked}
    >
      <CircularProgress />
    </Backdrop>
  );
}

export default LoadingBackdrop;
