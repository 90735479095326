// Components
// List Item
import LeftDrawerList from "./leftDrawerList";

// Material UI
// Components
import Drawer from "@mui/material/Drawer";

// Styles
import { drawerPermStyles } from "../../styles/componentStyles/drawerStyles/leftDrawerStyles";

function LeftDrawerPerm() {
  return (
    <Drawer anchor="left" sx={drawerPermStyles} variant="permanent">
      <LeftDrawerList />
    </Drawer>
  );
}

export default LeftDrawerPerm;
