const colorsConfig = {
  // Demo
  // mainColor: "#1976d2",
  // Jacadi
  mainColor: "#8a98bc",
  // Lycus
  // mainColor: "#a33a31",
};

export default colorsConfig;
