// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Helper Functions
import { getFileURL } from "../../../helperFunctions/file";

// Material UI
// Components
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
// Icons
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";

// Styles
import {
  avatorWithSrcSmallStyles,
  avatorWithTextSmallStyles,
  useStandardItemBoxStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxStyles";

function MerchandiseBox(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxStyles();

  // Props
  const {
    // States
    currentItemId,
    hoveredItemId,
    item,
    // Events
    onItemClicked,
    onItemRightClicked,
    onItemShowMenuBtnClicked,
    onMouseEnterItem,
    onMouseLeaveItem,
  } = props;

  // Handle States
  const isHovered = hoveredItemId && hoveredItemId === item.id;
  const isSelected = currentItemId && currentItemId === item.id;
  const photoURL = item.file_path ? getFileURL(item.file_path) : null;

  return (
    <div
      className={
        isSelected
          ? classes.selectedItemContainer
          : isHovered
          ? classes.hoveredItemContainer
          : classes.itemContainer
      }
      onClick={() => onItemClicked(item.id)}
      onContextMenu={(event) => {
        event.preventDefault();
        onItemRightClicked(item.id, event.currentTarget);
      }}
      onMouseEnter={() => onMouseEnterItem(item.id)}
      onMouseLeave={onMouseLeaveItem}
    >
      <div className={classes.itemContentSubContainerAvator}>
        {photoURL ? (
          <Avatar
            alt={t("圖片")}
            src={photoURL}
            sx={avatorWithSrcSmallStyles}
          />
        ) : (
          <Avatar sx={avatorWithTextSmallStyles}>
            {item.merchandise_code}
          </Avatar>
        )}
      </div>
      <div className={classes.itemContentSubContainerLarge}>
        <Typography
          align={"left"}
          color={
            isSelected
              ? stylesConfig.whiteTextColor
              : isHovered
              ? stylesConfig.mainTextColor
              : null
          }
          variant="body1"
        >
          {`${item.merchandise_code} (${item[t("sub_category_name_ch")]})`}
        </Typography>
        <Typography
          align={"left"}
          color={
            isSelected
              ? stylesConfig.whiteTextColor
              : stylesConfig.greyTextColor
          }
          variant="body2"
        >
          {`${item.brand_name} - 
          ${item[t("merchandise_name_ch")]}`}
        </Typography>
      </div>
      <div className={classes.itemContentSubContainer}>
        <Typography
          align={"left"}
          color={
            isSelected
              ? stylesConfig.whiteTextColor
              : isHovered
              ? stylesConfig.mainTextColor
              : null
          }
          variant="body1"
        >
          {item.base_price}
        </Typography>
        <Typography
          align={"left"}
          color={
            isSelected
              ? stylesConfig.whiteTextColor
              : stylesConfig.greyTextColor
          }
          variant="body2"
        >
          {`${item[t("age_group_name_ch")]} (${
            item[t("gender_type_name_ch")]
          })`}
        </Typography>
      </div>
      <Stack direction="row" spacing={1}>
        <IconButton
          onClick={(event) => {
            onItemShowMenuBtnClicked(item.id, event.currentTarget);
          }}
          sx={isSelected ? { color: stylesConfig.whiteTextColor } : null}
        >
          <MenuRoundedIcon />
        </IconButton>
      </Stack>
    </div>
  );
}

export default MerchandiseBox;
