// Components
// Boxes
import RevenueInfoBox from "./revenueInfoBox";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { getCurrentRevenueBasicInfoFetch } from "../../../fetches/salesNumberFetches";

// Helper Functions
import { separateComma } from "../../../helperFunctions/separateComma";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function RevenueInfoBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [revenueBasicInfo, setRevenueBasicInfo] = useState(null);

  // Handle States
  const item = revenueBasicInfo;

  // Functions
  // Functions - Queries
  const getCurrentRevenueBasicInfo = async () => {
    const results = await getCurrentRevenueBasicInfoFetch(token);

    if (results.revenue && results.averageRevenue) {
      results.revenue = results.revenue
        ? `$ ${separateComma(Number(results.revenue).toFixed(1))}`
        : null;

      results.averageRevenue = results.averageRevenue
        ? `$ ${separateComma(Number(results.averageRevenue).toFixed(1))}`
        : null;
    }

    // Set States
    setRevenueBasicInfo(results.revenue ? results : null);
  };

  // Life Cycle
  useEffect(() => {
    getCurrentRevenueBasicInfo();
  }, []);

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("本日營業額")}
          </Typography>
        </div>
      </div>
      {/* List */}
      {item ? (
        <RevenueInfoBox
          // States
          item={item}
        />
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有營業額資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default RevenueInfoBoxesContainer;
