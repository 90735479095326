// Actions
import { onLanguageChange } from "../../redux/shared/language/languageActions";
import { onThemeChange } from "../../redux/shared/theme/themeActions";

// Configs
import keyConfig from "../../configs/keyConfig";
import stylesConfig from "../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../customHooks/getLanguage";

// Helper Functions
import { getChangeLanguageItem } from "../../helperFunctions/getLanguageText";

// Material UI
// Components
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
// Icons
import Brightness4RoundedIcon from "@mui/icons-material/Brightness4Rounded";
import Brightness7RoundedIcon from "@mui/icons-material/Brightness7Rounded";
import TranslateRoundedIcon from "@mui/icons-material/TranslateRounded";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import {
  appBarContainerStyles,
  useAppBarStyles,
} from "../../styles/componentStyles/appBarStyles/appBarStyles";

function SimpleAppBar() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useAppBarStyles();

  // Redux Store
  const language = useSelector((state) => state.language.language);
  const themeMode = useSelector((state) => state.theme.themeMode);

  // Handle States
  const changeLanguageItem = getChangeLanguageItem(language);

  // Events
  // Events - Btns
  const onChangeLanguageBtnClicked = () => {
    // Update Redux Store
    dispatch(onLanguageChange(changeLanguageItem.value));

    storeLanguageToStorage(changeLanguageItem.value);
  };

  const onToggleThemeBtnClicked = () => {
    const themeModeVar = themeMode === "light" ? "dark" : "light";

    // Update Redux Store
    dispatch(onThemeChange(themeModeVar));

    storeThemeModeToStorage(themeModeVar);
  };

  // Functions
  // Functions - Normal
  const storeLanguageToStorage = (languageVar) => {
    // Local Storage
    localStorage.setItem(keyConfig.languageKey, languageVar);
  };

  const storeThemeModeToStorage = (themeModeVar) => {
    // Local Storage
    localStorage.setItem(keyConfig.themeKey, themeModeVar);
  };

  return (
    <AppBar
      sx={{
        ...appBarContainerStyles,
        backgroundColor:
          themeMode === "light"
            ? stylesConfig.appBarContainerBackgroundColorLight
            : stylesConfig.appBarContainerBackgroundColorDark,
      }}
    >
      <Toolbar className={classes.contentContainer} disableGutters>
        <div className={classes.rightContainer} />
        <div className={classes.rightContainer}>
          <div className={classes.searchBtnContainer}>
            <Tooltip
              title={`${t("Change to ")}${changeLanguageItem.description}`}
            >
              <Button
                onClick={onChangeLanguageBtnClicked}
                startIcon={<TranslateRoundedIcon />}
                variant="text"
              >
                {changeLanguageItem.text}
              </Button>
            </Tooltip>
          </div>
          <div className={classes.searchBtnContainer}>
            <Tooltip
              title={
                themeMode === "light"
                  ? t("設換至夜間模式")
                  : t("設換至日間模式")
              }
            >
              <IconButton onClick={onToggleThemeBtnClicked}>
                {themeMode === "light" ? (
                  <Brightness4RoundedIcon />
                ) : (
                  <Brightness7RoundedIcon />
                )}
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default SimpleAppBar;
