// Components
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";

// Consts
import { discountPercentageOptions } from "../../../consts/discountPercentageConsts";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  createDiscountRuleFetch,
  editDiscountRuleFetch,
  getDiscountRuleDetailsByIdFetch,
} from "../../../fetches/discountRuleFetches";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function DiscountRuleActionModal(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // Events
    onModalClosed,
    // States
    discountConditionTypeNameCh,
    discountConditionTypeNameEn,
    discountRuleId,
    discountRuleTypeNameCh,
    discountRuleTypeNameEn,
  } = props;

  // Redux Store
  const discountId = useSelector(
    (state) => state.discountProfilePage.discountId
  );
  const language = useSelector((state) => state.language.language);
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Dialog
  const [discountRuleActionDialogText, setDiscountRuleActionDialogText] =
    useState("");
  const [discountRuleActionDialogType, setDiscountRuleActionDialogType] =
    useState(null);
  const [showDiscountRuleActionDialog, setShowDiscountRuleActionDialog] =
    useState(false);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Option Fields
  const [discountNumField, setDiscountNumField] = useState(null);
  // Text Fields
  const [discountNum, setDiscountNum] = useState("");
  const [ruleTypeNum, setRuleTypeNum] = useState("");

  // Handle States
  const discountRuleTypeName =
    language === "zh-hk" ? discountRuleTypeNameCh : discountRuleTypeNameEn;

  // Events
  // Events - Dialogs
  const onDiscountRuleActionDialogCanceled = () => {
    // Set States
    setShowDiscountRuleActionDialog(false);
  };

  const onDiscountRuleActionDialogConfirmed = () => {
    switch (discountRuleActionDialogType) {
      case "CreateDiscountRule":
        createDiscountRule();
        break;
      case "EditDiscountRule":
        editDiscountRule();
        break;
      default:
        break;
    }

    // Set States
    setShowDiscountRuleActionDialog(false);
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "discountNum":
        setDiscountNum(value);
        break;
      case "discountNumField":
        setDiscountNumField(value);
        break;
      case "ruleTypeNum":
        setRuleTypeNum(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked();
    }
  };

  // Events - Forms
  const onSubmitBtnClicked = () => {
    let isError = false;

    if (discountConditionTypeNameEn !== "No Restriction") {
      if (!ruleTypeNum) {
        addToErrorFields(
          "ruleTypeNum",
          `${t("請先填寫")} ${
            language === "zh-hk"
              ? discountConditionTypeNameCh
              : discountConditionTypeNameEn
          }`
        );
        isError = true;
      } else {
        if (isNaN(ruleTypeNum)) {
          addToErrorFields(
            "ruleTypeNum",
            `${
              language === "zh-hk"
                ? discountConditionTypeNameCh
                : discountConditionTypeNameEn
            } ${t("必須為數字")}`
          );
          isError = true;
        }
      }
    }

    if (discountRuleTypeNameEn === "Percentage Discount") {
      if (!discountNumField) {
        addToErrorFields(
          "discountNumField",
          `${t("請先填寫")} ${discountRuleTypeName}`
        );
        isError = true;
      }
    } else {
      if (!discountNum) {
        addToErrorFields(
          "discountNum",
          `${t("請先填寫")} ${discountRuleTypeName}`
        );
        isError = true;
      } else {
        if (isNaN(discountNum)) {
          addToErrorFields(
            "discountNum",
            `${discountRuleTypeName} ${t("必須為數字")}`
          );
          isError = true;
        }
      }
    }

    if (isError) {
      return;
    }

    displayDiscountRuleActionDialog(
      discountRuleId ? "EditDiscountRule" : "CreateDiscountRule"
    );
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const clearErrorFields = () => {
    // Set States
    setErrorFields([]);
  };

  const displayDiscountRuleActionDialog = (discountRuleActionType) => {
    // Set States
    setDiscountRuleActionDialogType(discountRuleActionType);

    switch (discountRuleActionType) {
      case "CreateDiscountRule":
        setDiscountRuleActionDialogText(t("確認要新增 優惠規則 嗎？"));
        break;
      case "EditDiscountRule":
        setDiscountRuleActionDialogText(t("確認要編輯 優惠規則 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowDiscountRuleActionDialog(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const createDiscountRule = async () => {
    const results = await createDiscountRuleFetch(
      token,
      discountId,
      discountConditionTypeNameEn !== "No Restriction" ? ruleTypeNum : null,
      discountRuleTypeNameEn === "Percentage Discount"
        ? discountNumField
          ? discountNumField.value
          : null
        : discountNum
    );

    if (results.success) {
      onModalClosed();
    } else if (results.isDuplicate) {
      showFormAlert("warning", t("已有相關 優惠規則"));
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  const editDiscountRule = async () => {
    const results = await editDiscountRuleFetch(
      token,
      discountRuleId,
      discountConditionTypeNameEn !== "No Restriction" ? ruleTypeNum : null,
      discountRuleTypeNameEn === "Percentage Discount"
        ? discountNumField
          ? discountNumField.value
          : null
        : discountNum
    );

    if (results.success) {
      onModalClosed();
    } else if (results.isDuplicate) {
      showFormAlert("warning", t("已有相關 優惠規則"));
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  // Functions = Queries
  const getDiscountRuleDetailsById = async () => {
    const results = await getDiscountRuleDetailsByIdFetch(
      token,
      discountRuleId
    );

    if (results.discountRuleDetails) {
      const { rule_type_num, discount_num } = results.discountRuleDetails;

      // Set States
      if (discountConditionTypeNameEn !== "No Restriction") {
        setRuleTypeNum(rule_type_num ? rule_type_num : "");
      }

      if (discountRuleTypeNameEn === "Percentage Discount") {
        setDiscountNumField(
          discount_num !== undefined
            ? discountPercentageOptions.find(
                (item) => item.value === parseInt(discount_num)
              )
            : null
        );
      } else {
        setDiscountNum(discount_num ? discount_num : "");
      }
    }

    clearErrorFields();
  };

  // Life Cycle
  useEffect(() => {
    if (discountRuleId) {
      getDiscountRuleDetailsById();
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [discountRuleId]);

  return (
    <div className={classes.modalContainerSmall}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onDiscountRuleActionDialogCanceled}
        onDialogConfirmed={onDiscountRuleActionDialogConfirmed}
        // States
        dialogText={discountRuleActionDialogText}
        showDialog={showDiscountRuleActionDialog}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {discountRuleId ? t("編輯優惠規則") : t("新增優惠規則")}
          </Typography>
        </div>
      </div>
      {/* Content */}
      <div>
        <Grid
          className={classes.formContainer}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          container
          rowSpacing={1}
        >
          {discountConditionTypeNameEn !== "No Restriction" && (
            <Grid item xs={12}>
              <TextField
                error={checkIsFieldError("ruleTypeNum")}
                fullWidth
                helperText={getErrorFieldMessage("ruleTypeNum")}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {discountConditionTypeNameEn === "Amount"
                        ? t("滿 $ ")
                        : discountConditionTypeNameEn === "Quantity"
                        ? t("滿 ")
                        : t("第 ")}
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {discountConditionTypeNameEn === "Amount"
                        ? t("滿a")
                        : discountConditionTypeNameEn === "Quantity"
                        ? t("件a")
                        : t("件th")}
                    </InputAdornment>
                  ),
                }}
                label={
                  language === "zh-hk"
                    ? discountConditionTypeNameCh
                    : discountConditionTypeNameEn
                }
                margin="dense"
                ruleTypeNum="ruleTypeNum"
                onChange={(event) =>
                  onInputFieldChange("ruleTypeNum", event.target.value)
                }
                onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                required
                value={ruleTypeNum}
                variant="standard"
              />
            </Grid>
          )}
          {discountRuleTypeNameEn === "Percentage Discount" ? (
            <Grid className={classes.formAutoCompleteContainer} item xs={12}>
              <Autocomplete
                disablePortal
                getOptionLabel={(option) => option[t("textCh")]}
                onChange={(event, value) =>
                  onInputFieldChange("discountNumField", value)
                }
                onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                options={discountPercentageOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={checkIsFieldError("discountNumField")}
                    helperText={getErrorFieldMessage("discountNumField")}
                    label={discountRuleTypeName}
                    variant="standard"
                  />
                )}
                value={discountNumField}
              />
            </Grid>
          ) : (
            <Grid item xs={12}>
              <TextField
                error={checkIsFieldError("discountNum")}
                fullWidth
                helperText={getErrorFieldMessage("discountNum")}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {discountRuleTypeNameEn === "Fixed Discount"
                        ? t("減 $ ")
                        : "$ "}
                    </InputAdornment>
                  ),
                }}
                label={discountRuleTypeName}
                margin="dense"
                discountNum="discountNum"
                onChange={(event) =>
                  onInputFieldChange("discountNum", event.target.value)
                }
                onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                required
                value={discountNum}
                variant="standard"
              />
            </Grid>
          )}
          <Grid item xs={12} className={classes.formSubmitBtnContainer}>
            <Button
              onClick={onSubmitBtnClicked}
              variant="contained"
              sx={formSubmitBtnStyles}
            >
              {t("確定")}
            </Button>
          </Grid>
          {/* Alert */}
          {shouldShowFormAlert && (
            <Grid item xs={12}>
              <Alert severity={formAlertType}>{formAlertText}</Alert>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
}

export default DiscountRuleActionModal;
