// Actions
import { onSalesRecordChange } from "../../../redux/pages/salesRecordProfilePage/salesRecordProfilePageActions";

// Components
// Boxes
import SalesRecordBox from "./salesRecordBox";

// Configs
import paginationConfig from "../../../configs/paginationConfig";
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { getAllSalesRecordsByConditionsWithPaginationFetch } from "../../../fetches/salesRecordFetches";

// Helper Functions
import { getMomentLocale } from "../../../helperFunctions/getMomentLocale";
import { separateComma } from "../../../helperFunctions/separateComma";

// Material UI
// Components
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

// Moment
import moment from "moment";
import "moment/min/locales";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// React-Router
import { useNavigate } from "react-router-dom";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

// Consts
const limitNum = paginationConfig.paginationLimitNum;

function SalesRecordBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Router
  const navigate = useNavigate();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const formSubmitCount = useSelector(
    (state) => state.salesRecordsPage.formSubmitCount
  );
  const language = useSelector((state) => state.language.language);
  const recordDate = useSelector((state) => state.salesRecordsPage.recordDate);
  const shopId = useSelector((state) => state.salesRecordsPage.conditionShopId);
  const staffId = useSelector(
    (state) => state.salesRecordsPage.conditionStaffId
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [salesRecords, setSalesRecords] = useState(null);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Pagination
  const [pageCount, setPageCount] = useState(null);
  const [pageNum, setPageNum] = useState(1);

  // Handle States
  const itemsArr = salesRecords;
  const recordDateVar = new Date(recordDate);

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Update Redux Store
    dispatch(onSalesRecordChange(itemId));

    // Navigate
    navigate("/salesRecordProfile");
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Pagination
  const onPageChange = (value) => {
    // Set States
    setPageNum(value);
  };

  // Functions
  // Functions - Queries
  const getAllSalesRecordsByConditionsWithPagination = async () => {
    const results = await getAllSalesRecordsByConditionsWithPaginationFetch(
      token,
      shopId,
      staffId !== "dummy" ? staffId : null,
      recordDateVar,
      pageNum,
      limitNum
    );

    if (results.salesRecords) {
      for (let item of results.salesRecords) {
        item.total_price = item.total_price
          ? `$ ${separateComma(Number(item.total_price).toFixed(1))}`
          : null;

        item.time_created = item.time_created
          ? moment(item.time_created).format(t("a h:mm"))
          : null;
      }
    }

    // Set States
    setPageCount(results.pageCount ? results.pageCount : 0);
    setSalesRecords(results.salesRecords ? results.salesRecords : null);

    if (results.pageCount && !results.salesRecords) {
      setPageNum(results.pageCount);
    }
  };

  // Life Cycle
  useEffect(() => {
    if (shopId) {
      getAllSalesRecordsByConditionsWithPagination();
    }
  }, [shopId, staffId, recordDate, pageNum, formSubmitCount, language]);

  useEffect(() => {
    moment.locale(getMomentLocale(language));
  }, [language]);

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("銷售紀錄s")}
          </Typography>
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        <>
          {itemsArr.map((item) => (
            <SalesRecordBox
              key={item.id}
              // States
              hoveredItemId={hoveredItemId}
              item={item}
              // Events
              onItemClicked={onItemClicked}
              onMouseEnterItem={onMouseEnterItem}
              onMouseLeaveItem={onMouseLeaveItem}
            />
          ))}
          <Stack className={classes.paginationStack} spacing={2}>
            <Pagination
              color={"primary"}
              count={pageCount}
              onChange={(event, page) => onPageChange(page)}
              page={pageNum}
            />
          </Stack>
        </>
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有銷售紀錄資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default SalesRecordBoxesContainer;
