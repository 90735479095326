export const languageZhHk = {
  // Data
  age_group_name_ch: "age_group_name_ch",
  attribute_name_ch: "attribute_name_ch",
  brand_name_ch: "brand_name_ch",
  category_name_ch: "category_name_ch",
  conditionTextCh: "conditionTextCh",
  discount_calculation_type_name_ch: "discount_calculation_type_name_ch",
  discount_condition_type_name_ch: "discount_condition_type_name_ch",
  discount_name_ch: "discount_name_ch",
  discount_offer_type_name_ch: "discount_offer_type_name_ch",
  discount_payment_type_name_ch: "discount_payment_type_name_ch",
  discount_rule_type_name_ch: "discount_rule_type_name_ch",
  discount_status_type_name_ch: "discount_status_type_name_ch",
  full_name_ch: "full_name_ch",
  gender_type_name_ch: "gender_type_name_ch",
  merc_grp_age_type_name_ch: "merc_grp_age_type_name_ch",
  merc_grp_brd_type_name_ch: "merc_grp_brd_type_name_ch",
  merc_grp_cat_type_name_ch: "merc_grp_cat_type_name_ch",
  merc_grp_gen_type_name_ch: "merc_grp_gen_type_name_ch",
  merc_grp_type_name_ch: "merc_grp_type_name_ch",
  merchandiseGroupTextCh: "merchandiseGroupTextCh",
  merchandise_name_ch: "merchandise_name_ch",
  nameCh: "nameCh",
  payment_method_name_ch: "payment_method_name_ch",
  property_name_ch: "property_name_ch",
  remarks_ch: "remarks_ch",
  ruleTextCh: "ruleTextCh",
  shop_name_ch: "shop_name_ch",
  sub_category_name_ch: "sub_category_name_ch",
  textCh: "textCh",
  title_name_ch: "title_name_ch",
  // Moment
  "a h:mm": "a h:mm",
  D日: "D日",
  M月: "M月",
  "M 月 D 日": "M 月 D 日",
  "M 月": "M 月",
  "Y 年 M 月 D 日": "Y 年 M 月 D 日",
  "Y 年 M 月": "Y 年 M 月",
  "Y 年": "Y 年",
  // Text
  "確認要 登出 嗎？": "確認要 登出 嗎？",
  "Change to ": "Change to ",
  顯示選單: "顯示選單",
  設換至夜間模式: "設換至夜間模式",
  設換至日間模式: "設換至日間模式",
  登出: "登出",
  零售銷售管理系統: "零售銷售管理系統",
  主頁: "主頁",
  銷售報告s: "銷售報告",
  商品銷量s: "商品銷量",
  優惠s: "優惠",
  銷售紀錄s: "銷售紀錄",
  庫存s: "庫存",
  商品s: "商品",
  "類別 / 品牌s": "類別 / 品牌",
  年齡層s: "年齡層",
  優惠概覧: "優惠概覧",
  銷售紀錄概覧: "銷售紀錄概覧",
  概要s: "概要",
  本日營業額: "本日營業額",
  未有營業額資料: "未有營業額資料",
  營業額: "營業額",
  "(平均)": "(平均)",
  開單數量: "開單數量",
  " 張": " 張",
  商品銷量: "商品銷量",
  " 件": " 件",
  營業店鋪數量: "營業店鋪數量",
  " 間": " 間",
  "本日最佳店鋪 (營業額)": "本日最佳店鋪 (營業額)",
  未有最佳店鋪資料: "未有最佳店鋪資料",
  "本日最佳員工 (營業額)": "本日最佳員工 (營業額)",
  未有最佳員工資料: "未有最佳員工資料",
  "本日暢銷商品 (銷量)": "本日暢銷商品 (銷量)",
  未有暢銷商品資料: "未有暢銷商品資料",
  付款方式s: "付款方式",
  未有付款方式資料: "未有付款方式資料",
  銷售數據s: "銷售數據",
  趨勢分析s: "趨勢分析",
  隠藏對照日期: "隠藏對照日期",
  顯示對照日期: "顯示對照日期",
  隠藏對照月份: "隠藏對照月份",
  顯示對照月份: "顯示對照月份",
  隠藏對照年份: "隠藏對照年份",
  顯示對照年份: "顯示對照年份",
  "對照日期 (日/月/年)": "對照日期 (日/月/年)",
  "日期 (日/月/年)": "日期 (日/月/年)",
  "對照月份 (月/年)": "對照月份 (月/年)",
  "月份 (月/年)": "月份 (月/年)",
  "對照年份 (年)": "對照年份 (年)",
  "年份 (年)": "年份 (年)",
  返回本日: "返回本日",
  返回本月: "返回本月",
  返回本年: "返回本年",
  銷售數據類別: "銷售數據類別",
  以月為顯示單位: "以月為顯示單位",
  以日為顯示單位: "以日為顯示單位",
  店舖: "店舖",
  店舖品牌: "店舖品牌",
  員工: "員工",
  全公司: "全公司",
  暢銷商品s: "暢銷商品",
  圖片: "圖片",
  未有銷售數據: "未有銷售數據",
  未有趨勢分析: "未有趨勢分析",
  商品排行s: "商品排行",
  搜尋商品: "搜尋商品",
  關鍵字: "關鍵字",
  類別: "類別",
  子類別: "子類別",
  品牌: "品牌",
  未有商品排行: "未有商品排行",
  商品選項排行: "商品選項排行",
  未有商品選項排行: "未有商品選項排行",
  "請先填寫 英文全名 或 員工編號": "請先填寫 英文全名 或 員工編號",
  "請先填寫 密碼": "請先填寫 密碼",
  未有權限: "未有權限",
  未能登入: "未能登入",
  "英文全名 或 員工編號": "英文全名 或 員工編號",
  密碼: "密碼",
  登入: "登入",
  顯示有效優惠: "顯示有效優惠",
  顯示已刪除優惠: "顯示已刪除優惠",
  搜尋優惠: "搜尋優惠",
  優惠方式: "優惠方式",
  優惠狀態: "優惠狀態",
  "確認要刪除 優惠 嗎？": "確認要刪除 優惠 嗎？",
  "確認要還原 優惠 嗎？": "確認要還原 優惠 嗎？",
  優惠列表: "優惠列表",
  新增優惠: "新增優惠",
  未有優惠資料: "未有優惠資料",
  刪除: "刪除",
  還原: "還原",
  至: "至",
  開始b: "開始",
  "請先填寫 優惠方式": "請先填寫 優惠方式",
  "請先填寫 計算方式": "請先填寫 計算方式",
  "請先填寫 優惠條件": "請先填寫 優惠條件",
  "請先填寫 付款方式類別": "請先填寫 付款方式類別",
  "請先填寫 付款方式": "請先填寫 付款方式",
  "請先填寫 折扣方式": "請先填寫 折扣方式",
  "請先填寫 代號": "請先填寫 代號",
  "請先填寫 結束日期": "請先填寫 結束日期",
  "請先填寫 中文名稱": "請先填寫 中文名稱",
  "請先填寫 英文名稱": "請先填寫 英文名稱",
  "請先填寫 開始日期": "請先填寫 開始日期",
  "確認要新增 優惠 嗎？": "確認要新增 優惠 嗎？",
  "確認要編輯 優惠 嗎？": "確認要編輯 優惠 嗎？",
  未能提交: "未能提交",
  編輯優惠: "編輯優惠",
  代號: "代號",
  中文名稱: "中文名稱",
  英文名稱: "英文名稱",
  有效: "有效",
  計算方式: "計算方式",
  優惠條件: "優惠條件",
  折扣方式: "折扣方式",
  付款方式類別: "付款方式類別",
  可與其他優惠一併使用: "可與其他優惠一併使用",
  計算時包括加購商品: "計算時包括加購商品",
  期限: "期限",
  有適用期限: "有適用期限",
  "開始日期 (日/月/年)": "開始日期 (日/月/年)",
  "結束日期 (日/月/年)": "結束日期 (日/月/年)",
  確定: "確定",
  確認: "確認",
  取消: "取消",
  概要: "概要",
  使用狀況: "使用狀況",
  優惠: "優惠",
  無適用期限: "無適用期限",
  "確認要刪除 商品群組 嗎？": "確認要刪除 商品群組 嗎？",
  商品群組類別: "商品群組類別",
  商品群組s: "商品群組",
  新增商品群組: "新增商品群組",
  未有商品群組資料: "未有商品群組資料",
  不適用: "不適用",
  "確認要從新增 商品 到 群組 嗎？": "確認要從新增 商品 到 群組 嗎？",
  "確認要從 群組 刪除 商品 嗎？": "確認要從 群組 刪除 商品 嗎？",
  指定商品s: "指定商品",
  新增商品a: "新增商品",
  "顯示 ": "顯示",
  所有: "所有",
  已選擇: "已選擇",
  " 商品s": "商品",
  移除: "移除",
  未有商品資料: "未有商品資料",
  清除已選擇商品: "清除已選擇商品",
  未有相關資料: "未有相關資料",
  新增a: "新增",
  商品群組: "商品群組",
  商品群組資料: "商品群組資料",
  "請先填寫 年齡層類別": "請先填寫 年齡層類別",
  "請先填寫 年齡層": "請先填寫 年齡層",
  "請先填寫 品牌類別": "請先填寫 品牌類別",
  "請先填寫 品牌": "請先填寫 品牌",
  "請先填寫 商品分類類別": "請先填寫 商品分類類別",
  "請先填寫 商品類別": "請先填寫 商品類別",
  "請先填寫 商品子類別": "請先填寫 商品子類別",
  "請先填寫 適用性別類別": "請先填寫 適用性別類別",
  "請先填寫 適用性別": "請先填寫 適用性別",
  "確認要新增 商品群組 嗎？": "確認要新增 商品群組 嗎？",
  "確認要編輯 商品群組資料 嗎？": "確認要編輯 商品群組資料 嗎？",
  成功提交: "成功提交",
  品牌類別: "品牌類別",
  商品分類類別: "商品分類類別",
  適用性別類別: "適用性別類別",
  年齡層類別: "年齡層類別",
  修改: "修改",
  詳細設定: "詳細設定",
  商品類別: "商品類別",
  商品子類別: "商品子類別",
  適用性別: "適用性別",
  年齡層: "年齡層",
  編輯: "編輯",
  "確認要刪除 優惠規則 嗎？": "確認要刪除 優惠規則 嗎？",
  優惠規則s: "優惠規則",
  新增優惠規則: "新增優惠規則",
  未有優惠規則資料: "未有優惠規則資料",
  請先填寫: "請先填寫",
  必須為數字: "必須為數字",
  "確認要新增 優惠規則 嗎？": "確認要新增 優惠規則 嗎？",
  "確認要編輯 優惠規則 嗎？": "確認要編輯 優惠規則 嗎？",
  "已有相關 優惠規則": "已有相關 優惠規則",
  編輯優惠規則: "編輯優惠規則",
  "滿 $ ": "滿 $ ",
  "滿 ": "滿 ",
  "第 ": "第 ",
  滿a: "",
  件a: "件",
  件th: "件",
  "減 $ ": "減 $ ",
  優惠數據類別: "優惠數據類別",
  "使用狀況 (總計)": "使用狀況 (總計)",
  未有使用狀況資料: "未有使用狀況資料",
  使用次數: "使用次數",
  次s: "次",
  趨勢分析: "趨勢分析",
  未有銷售紀錄資料: "未有銷售紀錄資料",
  件商品: "件商品",
  銷售紀錄: "銷售紀錄",
  未有銷售員資料: "未有銷售員資料",
  結算: "結算",
  小結: "小結",
  折扣: "折扣",
  折實價: "折實價",
  已使用優惠s: "已使用優惠",
  未有使用優惠: "未有使用優惠",
  賣出商品s: "賣出商品",
  未有賣出商品資料: "未有賣出商品資料",
  商品數量: "商品數量",
  單價: "單價",
  總價: "總價",
  優惠商品s: "優惠商品",
  未有優惠商品: "未有優惠商品",
  加購商品: "加購商品",
  一般商品: "一般商品",
  原價: "原價",
  已應用優惠s: "已應用優惠",
  未有應用優惠: "未有應用優惠",
  強制更新: "強制更新",
  庫存數據類別: "庫存數據類別",
  倉庫: "倉庫",
  商品庫存: "商品庫存",
  未有商品庫存: "未有商品庫存",
  商品選項庫存s: "商品選項庫存",
  未有商品選項庫存: "未有商品選項庫存",
  店鋪: "店鋪",
  " 庫存s": "庫存",
  商品列表: "商品列表",
  商品特徵s: "商品特徵",
  顯示有效商品: "顯示有效商品",
  顯示已刪除商品: "顯示已刪除商品",
  顯示有效特徵: "顯示有效特徵",
  顯示已刪除特徵: "顯示已刪除特徵",
  顯示有效選項: "顯示有效選項",
  顯示已刪除選項: "顯示已刪除選項",
  "確認要刪除 商品 嗎？": "確認要刪除 商品 嗎？",
  "確認要還原 商品 嗎？": "確認要還原 商品 嗎？",
  新增商品: "新增商品",
  "請先填寫 類別": "請先填寫 類別",
  "請先填寫 子類別": "請先填寫 子類別",
  "請先填寫 基礎價格": "請先填寫 基礎價格",
  "基礎價格 必須為數字": "基礎價格 必須為數字",
  "確認要新增 商品 嗎？": "確認要新增 商品 嗎？",
  "確認要編輯 商品資料 嗎？": "確認要編輯 商品資料 嗎？",
  "此 商品 已存在": "此 商品 已存在",
  商品資料: "商品資料",
  分類: "分類",
  其他s: "其他",
  基礎價格: "基礎價格",
  "重新 ": "重新",
  上載商品照片: "上載商品照片",
  新增: "新增",
  上載圖片: "上載圖片",
  刪除圖片: "刪除圖片",
  "確認要刪除 圖片 嗎？": "確認要刪除 圖片 嗎？",
  檔案不可超過: "檔案不可超過",
  "未能上載，請再試一次": "未能上載，請再試一次",
  選項s: "選項",
  編輯選項: "編輯選項",
  新增選項: "新增選項",
  未有選項資料: "未有選項資料",
  "特徵 不可重覆": "特徵 不可重覆",
  "請先填寫 特徵": "請先填寫 特徵",
  "請先填寫 選項": "請先填寫 選項",
  "確認要編輯 選項 嗎？": "確認要編輯 選項 嗎？",
  "確認要刪除 選項 嗎？": "確認要刪除 選項 嗎？",
  新增特徵: "新增特徵",
  特徵: "特徵",
  "如不需填寫特徵，請按此刪除": "如不需填寫特徵，請按此刪除",
  刪除特徵: "刪除特徵",
  選項: "選項",
  "確認要刪除 特徵 嗎？": "確認要刪除 特徵 嗎？",
  "確認要還原 特徵 嗎？": "確認要還原 特徵 嗎？",
  特徵s: "特徵",
  特徵資料: "特徵資料",
  選項列表: "選項列表",
  未有特徵資料: "未有特徵資料",
  "確認要還原 選項 嗎？": "確認要還原 選項 嗎？",
  "確認要新增 特徵 嗎？": "確認要新增 特徵 嗎？",
  "確認要編輯 特徵資料 嗎？": "確認要編輯 特徵資料 嗎？",
  "此 特徵 已存在": "此 特徵 已存在",
  "備註 (中文)": "備註 (中文)",
  "備註 (英文)": "備註 (英文)",
  "確認要新增 選項 嗎？": "確認要新增 選項 嗎？",
  "確認要編輯 選項資料 嗎？": "確認要編輯 選項資料 嗎？",
  "此 選項 已存在": "此 選項 已存在",
  選項資料: "選項資料",
  類別s: "類別",
  品牌s: "品牌",
  顯示有效類別: "顯示有效類別",
  顯示有效品牌: "顯示有效品牌",
  顯示已刪除類別: "顯示已刪除類別",
  顯示已刪除品牌: "顯示已刪除品牌",
  顯示有效子類別: "顯示有效子類別",
  顯示已刪除子類別: "顯示已刪除子類別",
  "確認要刪除 類別 嗎？": "確認要刪除 類別 嗎？",
  "確認要刪除 品牌 嗎？": "確認要刪除 品牌 嗎？",
  "確認要還原 類別 嗎？": "確認要還原 類別 嗎？",
  "確認要還原 品牌 嗎？": "確認要還原 品牌 嗎？",
  新增類別: "新增類別",
  新增品牌: "新增品牌",
  顯示類別資料: "顯示類別資料",
  顯示子類別列表: "顯示子類別列表",
  未有類別資料: "未有類別資料",
  未有品牌資料: "未有品牌資料",
  "確認要刪除 子類別 嗎？": "確認要刪除 子類別 嗎？",
  "確認要還原 子類別 嗎？": "確認要還原 子類別 嗎？",
  子類別s: "子類別",
  新增子類別: "新增子類別",
  未有子類別資料: "未有子類別資料",
  "請先填寫 名稱": "請先填寫 名稱",
  "確認要新增 品牌 嗎？": "確認要新增 品牌 嗎？",
  "確認要編輯 品牌資料 嗎？": "確認要編輯 品牌資料 嗎？",
  "此 品牌 已存在": "此 品牌 已存在",
  品牌資料: "品牌資料",
  名稱: "名稱",
  "確認要新增 類別 嗎？": "確認要新增 類別 嗎？",
  "確認要編輯 類別資料 嗎？": "確認要編輯 類別資料 嗎？",
  "此 類別 已存在": "此 類別 已存在",
  類別資料: "類別資料",
  "確認要新增 子類別 嗎？": "確認要新增 子類別 嗎？",
  "確認要編輯 子類別資料 嗎？": "確認要編輯 子類別資料 嗎？",
  "此 子類別 已存在": "此 子類別 已存在",
  子類別資料: "子類別資料",
  顯示有效年齡層: "顯示有效年齡層",
  顯示已刪除年齡層: "顯示已刪除年齡層",
  "確認要刪除 年齡層 嗎？": "確認要刪除 年齡層 嗎？",
  "確認要還原 年齡層 嗎？": "確認要還原 年齡層 嗎？",
  新增年齡層: "新增年齡層",
  未有年齡層資料: "未有年齡層資料",
  歲: "歲",
  歲以上: "歲以上",
  歲以下: "歲以下",
  "請先填寫 開始年齡 或 結束年齡": "請先填寫 開始年齡 或 結束年齡",
  "確認要新增 年齡層 嗎？": "確認要新增 年齡層 嗎？",
  "確認要編輯 年齡層資料 嗎？": "確認要編輯 年齡層資料 嗎？",
  "此 年齡層 已存在": "此 年齡層 已存在",
  年齡層資料: "年齡層資料",
  開始年齡: "開始年齡",
  結束年齡: "結束年齡",
  "載入中...": "載入中...",
};
