// Actions
import { onFormSubmitCountIncrease } from "../../../redux/pages/discountProfilePage/discountProfilePageActions";

// Components
// Boxes
import DiscountIntroBasicInfoBox from "./discountIntroBasicInfoBox";
import DiscountIntroDateInfoBox from "./discountIntroDateInfoBox";
// Modals
import ModalContainer from "../../modals/modalContainer";
import DiscountActionModalContainer from "../../modals/discountProfilePage/discountActionModalContainer";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { getDiscountDetailsByIdFetch } from "../../../fetches/discountFetches";

// Helper Functions
import { getMomentLocale } from "../../../helperFunctions/getMomentLocale";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// Moment
import moment from "moment";
import "moment/min/locales";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function DiscountIntroBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const formSubmitCount = useSelector(
    (state) => state.discountProfilePage.formSubmitCount
  );
  const discountId = useSelector(
    (state) => state.discountProfilePage.discountId
  );
  const language = useSelector((state) => state.language.language);
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [discountDetails, setDiscountDetails] = useState(null);
  useState(false);
  // Modals
  const [showDiscountActionModal, setShowDiscountActionModal] = useState(false);

  // Handle States
  const item = discountDetails;

  // Events
  // Events - Box
  const onDiscountItemClicked = () => {
    displayDiscountActionModal();
  };

  // Events - Modal
  const onDiscountActionModalClosed = () => {
    // Set States
    setShowDiscountActionModal(false);

    // Update Redux Store
    dispatch(onFormSubmitCountIncrease());
  };

  // Functions
  // Functions - Normal
  const displayDiscountActionModal = () => {
    setShowDiscountActionModal(true);
  };

  // Functions - Queries
  const getDiscountDetailsById = async () => {
    const results = await getDiscountDetailsByIdFetch(token, discountId);

    if (results.discountDetails) {
      results.discountDetails.startDateDisplay = results.discountDetails
        .startDate
        ? moment(results.discountDetails.startDate).format(t("Y 年 M 月 D 日"))
        : null;

      results.discountDetails.endDateDisplay = results.discountDetails.endDate
        ? moment(results.discountDetails.endDate).format(t("Y 年 M 月 D 日"))
        : null;
    }

    // Set States
    setDiscountDetails(
      results.discountDetails ? results.discountDetails : null
    );
  };

  // Life Cycle
  useEffect(() => {
    if (discountId) {
      getDiscountDetailsById();
    }
  }, [discountId, formSubmitCount, language]);

  useEffect(() => {
    moment.locale(getMomentLocale(language));
  }, [language]);

  return (
    <div className={classes.contentBox}>
      {/* Modal */}
      <ModalContainer
        // Events
        onModalClosed={onDiscountActionModalClosed}
        // States
        showModal={showDiscountActionModal}
      >
        <DiscountActionModalContainer
          // Events
          onModalClosed={onDiscountActionModalClosed}
          // States
          defaultAttributeId={null}
          defaultAttributeType={null}
          discountId={discountId}
        />
      </ModalContainer>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("優惠")}
          </Typography>
        </div>
      </div>
      {/* Content */}
      {item ? (
        <>
          <DiscountIntroBasicInfoBox
            // States
            item={item}
            // Events
            onItemClicked={onDiscountItemClicked}
          />
          <DiscountIntroDateInfoBox
            // States
            item={item}
            // Events
            onItemClicked={onDiscountItemClicked}
          />
        </>
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有優惠資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default DiscountIntroBoxesContainer;
