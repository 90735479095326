// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Helper Functions
import { separateComma } from "../../../helperFunctions/separateComma";

// React-Redux
import { useSelector } from "react-redux";

// Recharts
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

function SalesNumberChart(props) {
  // Props
  const {
    // States
    comparisonRecordDateDisplay,
    isComparisonShown,
    isMoney,
    itemsArr,
    recordDateDisplay,
    xAxisDataGridKey,
  } = props;

  // Redux Store
  const themeMode = useSelector((state) => state.theme.themeMode);

  return (
    <ResponsiveContainer
      height={stylesConfig.pieChartContainerHeight}
      width={stylesConfig.pieChartContainerWidth}
    >
      <BarChart
        data={itemsArr}
        height={stylesConfig.pieChartHeight}
        margin={stylesConfig.barChartMargin}
        width={stylesConfig.pieChartWidth}
      >
        {isComparisonShown && (
          <Bar
            dataKey="comparisonSalesNumber"
            label={{
              fill: stylesConfig.mainTextColorDark,
            }}
            fill={stylesConfig.greenTextColor}
            name={comparisonRecordDateDisplay}
          />
        )}
        <Bar
          dataKey="salesNumber"
          label={{
            fill: stylesConfig.mainTextColorDark,
          }}
          fill={stylesConfig.mainTextColor}
          name={recordDateDisplay}
        />
        <CartesianGrid strokeDasharray="3 3" />
        <Legend />
        <Tooltip
          contentStyle={{
            backgroundColor:
              themeMode === "light"
                ? stylesConfig.contentBackgroundColorLight
                : stylesConfig.contentBackgroundColorDark,
          }}
          cursor={false}
          formatter={(name) =>
            isMoney
              ? `$ ${separateComma(Number(name).toFixed(1))}`
              : `${separateComma(Number(name).toFixed(1))}`
          }
        />
        <XAxis dataKey={xAxisDataGridKey} />
        <YAxis />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default SalesNumberChart;
