// Configs
import apisConfig from "../configs/apisConfig";

export const createCategoryFetch = async (
  token,
  categoryCode,
  categoryNameCh,
  categoryNameEn,
  isActive
) => {
  try {
    const queryRoute = "/category/createCategory";

    const reqBody = {
      categoryCode,
      categoryNameCh,
      categoryNameEn,
      isActive,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const deleteOrRestoreCategoryFetch = async (
  token,
  categoryId,
  typeOfCategories
) => {
  try {
    const queryRoute = `/category/deleteOrRestoreCategory/${categoryId}`;

    const reqBody = { typeOfCategories };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editCategoryFetch = async (
  token,
  categoryId,
  categoryCode,
  categoryNameCh,
  categoryNameEn,
  isActive
) => {
  try {
    const queryRoute = `/category/editCategory/${categoryId}`;

    const reqBody = {
      categoryCode,
      categoryNameCh,
      categoryNameEn,
      isActive,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllCategoriesFetch = async (token, typeOfCategories) => {
  try {
    const queryRoute = "/category/getAllCategories";

    const reqBody = { typeOfCategories };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getCategoryDetailsByIdFetch = async (token, categoryId) => {
  try {
    const queryRoute = `/category/getCategoryDetailsById/${categoryId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
