// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// Styles
import { useStandardItemBoxStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxStyles";

function RankedVariationBox(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxStyles();

  // Props
  const {
    // States
    item,
    salesNumberType,
  } = props;

  return (
    <div className={classes.itemContainer}>
      <div className={classes.itemContentSubContainerLarge}>
        <Typography align={"left"} variant="body1">
          {item.attributeDisplayText}
        </Typography>
      </div>
      <div className={classes.itemContentSubContainerRight}>
        <Typography align={"left"} variant="body1">
          {salesNumberType === "Revenue"
            ? item.revenue
            : `${item.num_of_merchandise_solds}${t(" 件")}`}
        </Typography>
      </div>
    </div>
  );
}

export default RankedVariationBox;
