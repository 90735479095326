// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// Styles
import { useStandardItemBoxStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxStyles";

function RevenueInfoBox(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxStyles();

  // Props
  const {
    // States
    item,
  } = props;

  return (
    <>
      {/* 營業額 */}
      <div className={classes.itemContainer}>
        <div className={classes.itemContentContainer}>
          <div className={classes.itemContentSubContainerLarge}>
            <Typography align={"left"} variant="body1">
              {t("營業額")}
            </Typography>
            <Typography
              align={"left"}
              color={stylesConfig.greyTextColor}
              variant="body2"
            >
              {t("(平均)")}
            </Typography>
          </div>
          <div className={classes.itemContentSubContainerRight}>
            <Typography align={"left"} variant="body1">
              {item.revenue}
            </Typography>
            <Typography
              align={"left"}
              color={stylesConfig.greyTextColor}
              variant="body2"
            >
              {`(${item.averageRevenue})`}
            </Typography>
          </div>
        </div>
      </div>
      {/* 開單數量 */}
      <div className={classes.itemContainer}>
        <div className={classes.itemContentContainer}>
          <div className={classes.itemContentSubContainerLarge}>
            <Typography align={"left"} variant="body1">
              {t("開單數量")}
            </Typography>
            <Typography
              align={"left"}
              color={stylesConfig.greyTextColor}
              variant="body2"
            >
              {t("(平均)")}
            </Typography>
          </div>
          <div className={classes.itemContentSubContainerRight}>
            <Typography align={"left"} variant="body1">
              {`${item.numOfSalesRecords}${t(" 張")}`}
            </Typography>
            <Typography
              align={"left"}
              color={stylesConfig.greyTextColor}
              variant="body2"
            >
              {`(${item.averageNumOfSalesRecords}${t(" 張")})`}
            </Typography>
          </div>
        </div>
      </div>
      {/* 商品銷量 */}
      <div className={classes.itemContainer}>
        <div className={classes.itemContentContainer}>
          <div className={classes.itemContentSubContainerLarge}>
            <Typography align={"left"} variant="body1">
              {t("商品銷量")}
            </Typography>
            <Typography
              align={"left"}
              color={stylesConfig.greyTextColor}
              variant="body2"
            >
              {t("(平均)")}
            </Typography>
          </div>
          <div className={classes.itemContentSubContainerRight}>
            <Typography align={"left"} variant="body1">
              {`${item.numOfMerchandiseSolds}${t(" 件")}`}
            </Typography>
            <Typography
              align={"left"}
              color={stylesConfig.greyTextColor}
              variant="body2"
            >
              {`(${item.averageNumOfMerchandiseSolds}${t(" 件")})`}
            </Typography>
          </div>
        </div>
      </div>
      {/* 營業店鋪數量 */}
      <div className={classes.itemContainer}>
        <div className={classes.itemContentContainer}>
          <div className={classes.itemContentSubContainerLarge}>
            <Typography align={"left"} variant="body1">
              {t("營業店鋪數量")}
            </Typography>
          </div>
          <div className={classes.itemContentSubContainerRight}>
            <Typography align={"left"} variant="body1">
              {`${item.numberOfShopOpening}${t(" 間")}`}
            </Typography>
          </div>
        </div>
      </div>
    </>
  );
}

export default RevenueInfoBox;
