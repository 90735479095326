// Actions
import { enterPage } from "../redux/shared/location/locationActions";

// Components
// Boxes
import SalesRecordBoxesContainer from "../components/boxes/salesRecordsPage/salesRecordBoxesContainer";
import SalesRecordConditionBoxesContainer from "../components/boxes/salesRecordsPage/salesRecordConditionBoxesContainer";
// Headers
import SalesRecordsPageHeader from "../components/headers/salesRecordsPage/salesRecordsPageHeader";
// Spacing Boxes
import SpacingBox from "../components/boxes/spacing/spacingBox";

// Configs
import stylesConfig from "../configs/stylesConfig";

// Material UI
// Components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// React
import { useEffect } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

function SalesRecordsPage() {
  // Hooks
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const tabItem = useSelector((state) => state.salesRecordsPage.tabItem);

  // Life Cycle
  useEffect(() => {
    // Update Redux Store
    dispatch(enterPage("SalesRecords"));
  }, []);

  return (
    <Container maxWidth={false}>
      {/* Header */}
      <SpacingBox
        // Render
        height={stylesConfig.appBarHeight}
      />
      <SalesRecordsPageHeader />
      {/* Content */}
      <Grid container spacing={4}>
        <Grid item xs={12} md={4} lg={3}>
          {tabItem === "SalesRecords" && <SalesRecordConditionBoxesContainer />}
          <SpacingBox
            // Render
            height={stylesConfig.spacingBoxMarginBottom}
          />
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          {tabItem === "SalesRecords" && (
            <>
              <SalesRecordBoxesContainer />
              <SpacingBox
                // Render
                height={stylesConfig.spacingBoxMarginBottom}
              />
            </>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}

export default SalesRecordsPage;
