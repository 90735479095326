export function onComparisonRecordDateChange(comparisonRecordDate) {
  return {
    type: "ON_SALES_REPORTS_PAGE_COMPARISON_RECORD_DATE_CHANGE",
    comparisonRecordDate,
  };
}

export function onConditionShopBrandChange(conditionShopBrandId) {
  return {
    type: "ON_SALES_REPORTS_PAGE_CONDITION_SHOP_BRAND_CHANGE",
    conditionShopBrandId,
  };
}

export function onConditionShopBrandItemChange(conditionShopBrandItem) {
  return {
    type: "ON_SALES_REPORTS_PAGE_CONDITION_SHOP_BRAND_ITEM_CHANGE",
    conditionShopBrandItem,
  };
}

export function onConditionShopChange(conditionShopId) {
  return {
    type: "ON_SALES_REPORTS_PAGE_CONDITION_SHOP_CHANGE",
    conditionShopId,
  };
}

export function onConditionShopItemChange(conditionShopItem) {
  return {
    type: "ON_SALES_REPORTS_PAGE_CONDITION_SHOP_ITEM_CHANGE",
    conditionShopItem,
  };
}

export function onConditionStaffChange(conditionStaffId) {
  return {
    type: "ON_SALES_REPORTS_PAGE_CONDITION_STAFF_CHANGE",
    conditionStaffId,
  };
}

export function onConditionStaffItemChange(conditionStaffItem) {
  return {
    type: "ON_SALES_REPORTS_PAGE_CONDITION_STAFF_ITEM_CHANGE",
    conditionStaffItem,
  };
}

export function onFormSubmitCountIncrease() {
  return {
    type: "ON_SALES_REPORTS_PAGE_FORM_SUBMIT_COUNT_INCREASE",
  };
}

export function onIsComparisonShownChange(isComparisonShown) {
  return {
    type: "ON_SALES_REPORTS_PAGE_IS_COMPARISON_CHANGE",
    isComparisonShown,
  };
}

export function onRecordDateChange(recordDate) {
  return {
    type: "ON_SALES_REPORTS_PAGE_RECORD_DATE_CHANGE",
    recordDate,
  };
}

export function onRecordDateTypeChange(recordDateType) {
  return {
    type: "ON_SALES_REPORTS_PAGE_RECORD_DATE_TYPE_CHANGE",
    recordDateType,
  };
}

export function onSalesNumberTypeChange(salesNumberType) {
  return {
    type: "ON_SALES_REPORTS_PAGE_SALES_NUMBER_TYPE_CHANGE",
    salesNumberType,
  };
}

export function onTabItemChange(tabItem) {
  return {
    type: "ON_SALES_REPORTS_PAGE_TAB_ITEM_CHANGE",
    tabItem,
  };
}
