// Actions
import { onMerchandiseSoldItemChange } from "../../../redux/pages/salesRecordProfilePage/salesRecordProfilePageActions";

// Components
// Boxes
import DiscountedMerchandiseSoldItemBox from "./discountedMerchandiseSoldItemBox";

// Configs
import paginationConfig from "../../../configs/paginationConfig";
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { getAllDiscountedMerchandiseSoldItemsByMerchandiseSoldIdWithPaginationFetch } from "../../../fetches/merchandiseSoldItemFetches";

// Helper Functions
import { separateComma } from "../../../helperFunctions/separateComma";

// Material UI
// Components
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

// Consts
const limitNum = paginationConfig.paginationLimitNumSmall;

function DiscountedMerchandiseSoldItemBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const formSubmitCount = useSelector(
    (state) => state.salesRecordProfilePage.formSubmitCount
  );
  const merchandiseSoldId = useSelector(
    (state) => state.salesRecordProfilePage.merchandiseSoldId
  );
  const merchandiseSoldItemId = useSelector(
    (state) => state.salesRecordProfilePage.merchandiseSoldItemId
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [merchandiseSoldItems, setMerchandiseSoldItems] = useState(null);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Pagination
  const [pageCount, setPageCount] = useState(null);
  const [pageNum, setPageNum] = useState(1);

  // Handle States
  const currentItemId = merchandiseSoldItemId;
  const itemsArr = merchandiseSoldItems;

  // Events
  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Update Redux Store
    dispatch(onMerchandiseSoldItemChange(itemId));
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Pagination
  const onPageChange = (value) => {
    // Set States
    setPageNum(value);
  };

  // Functions
  // Functions - Queries
  const getAllDiscountedMerchandiseSoldItemsByMerchandiseSoldIdWithPagination =
    async () => {
      const results =
        await getAllDiscountedMerchandiseSoldItemsByMerchandiseSoldIdWithPaginationFetch(
          token,
          merchandiseSoldId,
          pageNum,
          limitNum
        );

      if (results.merchandiseSoldItems) {
        for (let item of results.merchandiseSoldItems) {
          item.product_price_display = item.product_price
            ? `$ ${separateComma(Number(item.product_price).toFixed(1))}`
            : null;

          item.actual_price_display = item.actual_price
            ? `$ ${separateComma(Number(item.actual_price).toFixed(1))}`
            : null;
        }
      }

      // Set States
      setPageCount(results.pageCount ? results.pageCount : 0);
      setMerchandiseSoldItems(
        results.merchandiseSoldItems ? results.merchandiseSoldItems : null
      );

      if (results.pageCount && !results.merchandiseSoldItems) {
        setPageNum(results.pageCount);
      }

      // Update Redux Store
      if (results.merchandiseSoldItems) {
        if (
          !(
            merchandiseSoldItemId &&
            results.merchandiseSoldItems.some(
              (item) => item.id === merchandiseSoldItemId
            )
          )
        ) {
          dispatch(
            onMerchandiseSoldItemChange(results.merchandiseSoldItems[0].id)
          );
        }
      } else {
        dispatch(onMerchandiseSoldItemChange(null));
      }
    };

  // Life Cycle
  useEffect(() => {
    if (merchandiseSoldId) {
      getAllDiscountedMerchandiseSoldItemsByMerchandiseSoldIdWithPagination();
    }
  }, [merchandiseSoldId, pageNum, formSubmitCount]);

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("優惠商品s")}
          </Typography>
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        <>
          {itemsArr.map((item, index) => (
            <DiscountedMerchandiseSoldItemBox
              key={item.id}
              // States
              currentItemId={currentItemId}
              hoveredItemId={hoveredItemId}
              index={index}
              item={item}
              // Events
              onItemClicked={onItemClicked}
              onMouseEnterItem={onMouseEnterItem}
              onMouseLeaveItem={onMouseLeaveItem}
            />
          ))}
          <Stack className={classes.paginationStack} spacing={2}>
            <Pagination
              color={"primary"}
              count={pageCount}
              onChange={(event, page) => onPageChange(page)}
              page={pageNum}
            />
          </Stack>
        </>
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有優惠商品")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default DiscountedMerchandiseSoldItemBoxesContainer;
