// Components
// Boxes
import MerchandisePhotoBoxesContainer from "../../boxes/merchandisesPage/merchandisePhotoBoxesContainer";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
// Icons
import AddAPhotoRoundedIcon from "@mui/icons-material/AddAPhotoRounded";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function MerchandiseDetailFormContent(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // States
    ageGroupField,
    ageGroupOptions,
    basePrice,
    brandField,
    brandOptions,
    categoryField,
    categoryOptions,
    code,
    formAlertText,
    formAlertType,
    genderTypeField,
    genderTypeOptions,
    isItemActive,
    merchandiseId,
    nameCh,
    nameEn,
    photo,
    shouldShowFormAlert,
    subCategoryField,
    subCategoryOptions,
    // Events
    onInputFieldChange,
    onInputFieldKeyPressed,
    onSubmitBtnClicked,
    // Functions
    checkIsFieldError,
    getErrorFieldMessage,
  } = props;

  return (
    <div className={classes.contentBox}>
      {/* Content */}
      <MerchandisePhotoBoxesContainer />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("商品資料")}
          </Typography>
        </div>
      </div>
      {/* Form */}
      <Grid
        className={classes.formContainer}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        container
        rowSpacing={1}
      >
        <Grid item xs={6}>
          <TextField
            error={checkIsFieldError("code")}
            fullWidth
            helperText={getErrorFieldMessage("code")}
            label={t("代號")}
            margin="dense"
            name="code"
            onChange={(event) => onInputFieldChange("code", event.target.value)}
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={code}
            variant="standard"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            error={checkIsFieldError("nameCh")}
            fullWidth
            helperText={getErrorFieldMessage("nameCh")}
            label={t("中文名稱")}
            margin="dense"
            name="nameCh"
            onChange={(event) =>
              onInputFieldChange("nameCh", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            required
            value={nameCh}
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={checkIsFieldError("nameEn")}
            fullWidth
            helperText={getErrorFieldMessage("nameEn")}
            label={t("英文名稱")}
            margin="dense"
            name="nameEn"
            onChange={(event) =>
              onInputFieldChange("nameEn", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={nameEn}
            variant="standard"
          />
        </Grid>
      </Grid>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("分類")}
          </Typography>
        </div>
      </div>
      {/* Form */}
      <Grid
        className={classes.formContainer}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        container
        rowSpacing={1}
      >
        <Grid className={classes.formAutoCompleteContainer} item xs={6}>
          {categoryOptions && (
            <Autocomplete
              disablePortal
              getOptionLabel={(option) => option[t("category_name_ch")]}
              onChange={(event, value) =>
                onInputFieldChange("categoryField", value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              options={categoryOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={checkIsFieldError("categoryField")}
                  helperText={getErrorFieldMessage("categoryField")}
                  label={t("類別")}
                  variant="standard"
                />
              )}
              value={categoryField}
            />
          )}
        </Grid>
        {categoryField && (
          <Grid className={classes.formAutoCompleteContainer} item xs={6}>
            {subCategoryOptions && (
              <Autocomplete
                disablePortal
                getOptionLabel={(option) => option[t("sub_category_name_ch")]}
                onChange={(event, value) =>
                  onInputFieldChange("subCategoryField", value)
                }
                onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                options={subCategoryOptions.filter(
                  (option) => option.category_id === categoryField.id
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={checkIsFieldError("subCategoryField")}
                    helperText={getErrorFieldMessage("subCategoryField")}
                    label={t("子類別")}
                    variant="standard"
                  />
                )}
                value={subCategoryField}
              />
            )}
          </Grid>
        )}
        <Grid className={classes.formAutoCompleteContainer} item xs={6}>
          {brandOptions && (
            <Autocomplete
              disablePortal
              getOptionLabel={(option) => option.brand_name}
              onChange={(event, value) =>
                onInputFieldChange("brandField", value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              options={brandOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={checkIsFieldError("brandField")}
                  helperText={getErrorFieldMessage("brandField")}
                  label={t("品牌")}
                  variant="standard"
                />
              )}
              value={brandField}
            />
          )}
        </Grid>
        <Grid className={classes.formAutoCompleteContainer} item xs={6}>
          {ageGroupOptions && (
            <Autocomplete
              disablePortal
              getOptionLabel={(option) => option[t("age_group_name_ch")]}
              onChange={(event, value) =>
                onInputFieldChange("ageGroupField", value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              options={ageGroupOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={checkIsFieldError("ageGroupField")}
                  helperText={getErrorFieldMessage("ageGroupField")}
                  label={t("年齡層")}
                  variant="standard"
                />
              )}
              value={ageGroupField}
            />
          )}
        </Grid>
        <Grid className={classes.formAutoCompleteContainer} item xs={6}>
          {genderTypeOptions && (
            <Autocomplete
              disablePortal
              getOptionLabel={(option) => option[t("gender_type_name_ch")]}
              onChange={(event, value) =>
                onInputFieldChange("genderTypeField", value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              options={genderTypeOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={checkIsFieldError("genderTypeField")}
                  helperText={getErrorFieldMessage("genderTypeField")}
                  label={t("適用性別")}
                  variant="standard"
                />
              )}
              value={genderTypeField}
            />
          )}
        </Grid>
      </Grid>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("其他s")}
          </Typography>
        </div>
      </div>
      {/* Form */}
      <Grid
        className={classes.formContainer}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        container
        rowSpacing={1}
      >
        <Grid item xs={6}>
          <TextField
            error={checkIsFieldError("basePrice")}
            fullWidth
            helperText={getErrorFieldMessage("basePrice")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            label={t("基礎價格")}
            margin="dense"
            name="basePrice"
            onChange={(event) =>
              onInputFieldChange("basePrice", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={basePrice}
            variant="standard"
          />
        </Grid>
        <Grid item xs={6} className={classes.formSwitchContainer}>
          <Typography align={"left"} variant="body1">
            {t("有效")}
          </Typography>
          <Switch
            checked={isItemActive}
            onChange={() => onInputFieldChange("isItemActive", !isItemActive)}
          />
        </Grid>
        {!merchandiseId && (
          <Grid item xs={12} className={classes.formSubmitBtnContainer}>
            <Button
              component="label"
              endIcon={<AddAPhotoRoundedIcon />}
              variant="outlined"
              sx={formSubmitBtnStyles}
            >
              {photo && t("重新")}
              {t("上載商品照片")}
              <input
                accept={"image/*"}
                hidden
                onChange={(event) =>
                  onInputFieldChange("photo", event.target.files[0])
                }
                type="file"
              />
            </Button>
          </Grid>
        )}
        <Grid item xs={12} className={classes.formSubmitBtnContainer}>
          <Button
            onClick={onSubmitBtnClicked}
            variant="contained"
            sx={formSubmitBtnStyles}
          >
            {merchandiseId ? t("修改") : t("新增")}
          </Button>
        </Grid>
        {/* Alert */}
        {shouldShowFormAlert && (
          <Grid item xs={12}>
            <Alert severity={formAlertType}>{formAlertText}</Alert>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default MerchandiseDetailFormContent;
