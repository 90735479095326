export const createInitialPropertyItem = () => {
  return {
    id: 1,
    attributeField: [],
    propertyField: null,
  };
};

export const createPropertyItem = (currentProperties) => {
  const itemId = currentProperties[0]
    ? currentProperties[currentProperties.length - 1].id + 1
    : 1;

  return {
    id: itemId,
    attributeField: [],
    propertyField: null,
  };
};
