// Redux
import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";

// Reducers
// Pages
import { ageGroupsPageReducers } from "./pages/ageGroupsPage/ageGroupsPageReducers";
import { categoriesAndBrandsPageReducers } from "./pages/categoriesAndBrandsPage/categoriesAndBrandsPageReducers";
import { dashboardPageReducers } from "./pages/dashboardPage/dashboardPageReducers";
import { discountsPageReducers } from "./pages/discountsPage/discountsPageReducers";
import { discountProfilePageReducers } from "./pages/discountProfilePage/discountProfilePageReducers";
import { merchandisesPageReducers } from "./pages/merchandisesPage/merchandisesPageReducers";
import { merchandiseSalesPageReducers } from "./pages/merchandiseSalesPage/merchandiseSalesPageReducers";
import { salesRecordsPageReducers } from "./pages/salesRecordsPage/salesRecordsPageReducers";
import { salesRecordProfilePageReducers } from "./pages/salesRecordProfilePage/salesRecordProfilePageReducers";
import { salesReportsPageReducers } from "./pages/salesReportsPage/salesReportsPageReducers";
import { stocksPageReducers } from "./pages/stocksPage/stocksPageReducers";
// Shared
import { drawerReducers } from "./shared/drawer/drawerReducers";
import { languageReducers } from "./shared/language/languageReducers";
import { locationReducers } from "./shared/location/locationReducers";
import { staffReducers } from "./shared/staff/staffReducers";
import { themeReducers } from "./shared/theme/themeReducers";

const rootReducer = combineReducers({
  // Pages
  ageGroupsPage: ageGroupsPageReducers,
  categoriesAndBrandsPage: categoriesAndBrandsPageReducers,
  dashboardPage: dashboardPageReducers,
  discountsPage: discountsPageReducers,
  discountProfilePage: discountProfilePageReducers,
  merchandisesPage: merchandisesPageReducers,
  merchandiseSalesPage: merchandiseSalesPageReducers,
  salesRecordsPage: salesRecordsPageReducers,
  salesRecordProfilePage: salesRecordProfilePageReducers,
  salesReportsPage: salesReportsPageReducers,
  stocksPage: stocksPageReducers,
  // Shared
  drawer: drawerReducers,
  language: languageReducers,
  location: locationReducers,
  staff: staffReducers,
  theme: themeReducers,
});

export const store = configureStore({ reducer: rootReducer });
