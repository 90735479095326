// Actions
import { onTabItemChange } from "../../../redux/pages/dashboardPage/dashboardPageActions";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardListPageStyles } from "../../../styles/pageStyles/standardListPageStyles";

function DashboardPageHeader() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardListPageStyles();

  // Redux Store
  const tabItem = useSelector((state) => state.dashboardPage.tabItem);

  // Events
  // Events - Tabs
  const onTabClicked = (event, value) => {
    // Update Redux Store
    dispatch(onTabItemChange(value));
  };

  return (
    <Box className={classes.divisionsContainer}>
      {/* Tabs */}
      <div className={classes.tabsContainer}>
        <Tabs
          scrollButtons={false}
          value={tabItem}
          variant="scrollable"
          onChange={onTabClicked}
        >
          <Tab
            disableRipple
            key={"Summary"}
            label={
              <Typography variant="h6" align={"center"} gutterBottom>
                {t("概要s")}
              </Typography>
            }
            value={"Summary"}
          />
        </Tabs>
      </div>
    </Box>
  );
}

export default DashboardPageHeader;
