// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function MerchandiseGroupDetailFormContent(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // States
    ageGroupField,
    ageGroupOptions,
    brandField,
    brandOptions,
    categoryField,
    categoryOptions,
    formAlertText,
    formAlertType,
    genderTypeField,
    genderTypeOptions,
    mercGrpAgeTypeField,
    mercGrpAgeTypeOptions,
    mercGrpBrdTypeField,
    mercGrpBrdTypeOptions,
    mercGrpCatTypeField,
    mercGrpCatTypeOptions,
    mercGrpGenTypeField,
    mercGrpGenTypeOptions,
    merchandiseGroupId,
    shouldShowFormAlert,
    subCategoryField,
    subCategoryOptions,
    // Events
    onInputFieldChange,
    onInputFieldKeyPressed,
    onSubmitBtnClicked,
    // Functions
    checkIsFieldError,
    getErrorFieldMessage,
  } = props;

  // Handle States
  const isAllFieldsDiabled =
    mercGrpCatTypeField &&
    mercGrpCatTypeField.merc_grp_cat_type_name_en === "Specific Merchandise";

  const shouldShowAgeGroupField =
    mercGrpAgeTypeField &&
    mercGrpAgeTypeField.merc_grp_age_type_name_en !== "All";
  const shouldShowBrandField =
    mercGrpBrdTypeField &&
    mercGrpBrdTypeField.merc_grp_brd_type_name_en !== "All";
  const shouldShowCategoryField =
    mercGrpCatTypeField &&
    mercGrpCatTypeField.merc_grp_cat_type_name_en === "Specific Category";
  const shouldShowGenderTypeField =
    mercGrpGenTypeField &&
    mercGrpGenTypeField.merc_grp_gen_type_name_en !== "All";
  const shouldShowSubCategoryField =
    mercGrpCatTypeField &&
    mercGrpCatTypeField.merc_grp_cat_type_name_en === "Specific Sub Category";

  const shouldShowDetailedSettings =
    shouldShowAgeGroupField ||
    shouldShowBrandField ||
    shouldShowCategoryField ||
    shouldShowGenderTypeField ||
    shouldShowSubCategoryField;

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("商品群組資料")}
          </Typography>
        </div>
      </div>
      {/* Form */}
      <Grid
        className={classes.formContainer}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        container
        rowSpacing={1}
      >
        <Grid className={classes.formAutoCompleteContainer} item xs={6}>
          {mercGrpBrdTypeOptions && (
            <Autocomplete
              disabled={isAllFieldsDiabled}
              disablePortal
              getOptionLabel={(option) =>
                option[t("merc_grp_brd_type_name_ch")]
              }
              onChange={(event, value) =>
                onInputFieldChange("mercGrpBrdTypeField", value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              options={mercGrpBrdTypeOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={checkIsFieldError("mercGrpBrdTypeField")}
                  helperText={getErrorFieldMessage("mercGrpBrdTypeField")}
                  label={t("品牌類別")}
                  variant="standard"
                />
              )}
              value={mercGrpBrdTypeField}
            />
          )}
        </Grid>
        <Grid className={classes.formAutoCompleteContainer} item xs={6}>
          {mercGrpCatTypeOptions && (
            <Autocomplete
              disablePortal
              getOptionLabel={(option) =>
                option[t("merc_grp_cat_type_name_ch")]
              }
              onChange={(event, value) =>
                onInputFieldChange("mercGrpCatTypeField", value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              options={mercGrpCatTypeOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={checkIsFieldError("mercGrpCatTypeField")}
                  helperText={getErrorFieldMessage("mercGrpCatTypeField")}
                  label={t("商品分類類別")}
                  variant="standard"
                />
              )}
              value={mercGrpCatTypeField}
            />
          )}
        </Grid>
        <Grid className={classes.formAutoCompleteContainer} item xs={6}>
          {mercGrpGenTypeOptions && (
            <Autocomplete
              disabled={isAllFieldsDiabled}
              disablePortal
              getOptionLabel={(option) =>
                option[t("merc_grp_gen_type_name_ch")]
              }
              onChange={(event, value) =>
                onInputFieldChange("mercGrpGenTypeField", value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              options={mercGrpGenTypeOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={checkIsFieldError("mercGrpGenTypeField")}
                  helperText={getErrorFieldMessage("mercGrpGenTypeField")}
                  label={t("適用性別類別")}
                  variant="standard"
                />
              )}
              value={mercGrpGenTypeField}
            />
          )}
        </Grid>
        <Grid className={classes.formAutoCompleteContainer} item xs={6}>
          {mercGrpAgeTypeOptions && (
            <Autocomplete
              disabled={isAllFieldsDiabled}
              disablePortal
              getOptionLabel={(option) =>
                option[t("merc_grp_age_type_name_ch")]
              }
              onChange={(event, value) =>
                onInputFieldChange("mercGrpAgeTypeField", value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              options={mercGrpAgeTypeOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={checkIsFieldError("mercGrpAgeTypeField")}
                  helperText={getErrorFieldMessage("mercGrpAgeTypeField")}
                  label={t("年齡層類別")}
                  variant="standard"
                />
              )}
              value={mercGrpAgeTypeField}
            />
          )}
        </Grid>
        {!shouldShowDetailedSettings && (
          <>
            <Grid item xs={12} className={classes.formSubmitBtnContainer}>
              <Button
                onClick={onSubmitBtnClicked}
                variant="contained"
                sx={formSubmitBtnStyles}
              >
                {merchandiseGroupId ? t("修改") : t("新增")}
              </Button>
            </Grid>
            {/* Alert */}
            {shouldShowFormAlert && (
              <Grid item xs={12}>
                <Alert severity={formAlertType}>{formAlertText}</Alert>
              </Grid>
            )}
          </>
        )}
      </Grid>
      {shouldShowDetailedSettings && (
        <>
          {/* Title */}
          <div className={classes.titleContainer}>
            <div className={classes.titleTextContainer}>
              <Typography variant="h6" align={"left"}>
                {t("詳細設定")}
              </Typography>
            </div>
          </div>
          {/* Form */}
          <Grid
            className={classes.formContainer}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            container
            rowSpacing={1}
          >
            {shouldShowBrandField && (
              <Grid className={classes.formAutoCompleteContainer} item xs={12}>
                {brandField && brandOptions && (
                  <Autocomplete
                    disablePortal
                    getOptionLabel={(option) => option.brand_name}
                    multiple
                    onChange={(event, value) =>
                      onInputFieldChange("brandField", value)
                    }
                    onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                    options={brandOptions}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={checkIsFieldError("brandField")}
                        helperText={getErrorFieldMessage("brandField")}
                        label={t("品牌")}
                        variant="standard"
                      />
                    )}
                    value={brandField}
                  />
                )}
              </Grid>
            )}
            {shouldShowCategoryField && (
              <Grid className={classes.formAutoCompleteContainer} item xs={12}>
                {categoryField && categoryOptions && (
                  <Autocomplete
                    disablePortal
                    getOptionLabel={(option) => option[t("category_name_ch")]}
                    multiple
                    onChange={(event, value) =>
                      onInputFieldChange("categoryField", value)
                    }
                    onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                    options={categoryOptions}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={checkIsFieldError("categoryField")}
                        helperText={getErrorFieldMessage("categoryField")}
                        label={t("商品類別")}
                        variant="standard"
                      />
                    )}
                    value={categoryField}
                  />
                )}
              </Grid>
            )}
            {shouldShowSubCategoryField && (
              <Grid className={classes.formAutoCompleteContainer} item xs={12}>
                {subCategoryField && subCategoryOptions && (
                  <Autocomplete
                    disablePortal
                    getOptionLabel={(option) =>
                      option[t("sub_category_name_ch")]
                    }
                    multiple
                    onChange={(event, value) =>
                      onInputFieldChange("subCategoryField", value)
                    }
                    onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                    options={subCategoryOptions}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={checkIsFieldError("subCategoryField")}
                        helperText={getErrorFieldMessage("subCategoryField")}
                        label={t("商品子類別")}
                        variant="standard"
                      />
                    )}
                    value={subCategoryField}
                  />
                )}
              </Grid>
            )}
            {shouldShowGenderTypeField && (
              <Grid className={classes.formAutoCompleteContainer} item xs={6}>
                {genderTypeField && genderTypeOptions && (
                  <Autocomplete
                    disablePortal
                    getOptionLabel={(option) =>
                      option[t("gender_type_name_ch")]
                    }
                    multiple
                    onChange={(event, value) =>
                      onInputFieldChange("genderTypeField", value)
                    }
                    onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                    options={genderTypeOptions}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={checkIsFieldError("genderTypeField")}
                        helperText={getErrorFieldMessage("genderTypeField")}
                        label={t("適用性別")}
                        variant="standard"
                      />
                    )}
                    value={genderTypeField}
                  />
                )}
              </Grid>
            )}
            {shouldShowAgeGroupField && (
              <Grid className={classes.formAutoCompleteContainer} item xs={6}>
                {ageGroupField && ageGroupOptions && (
                  <Autocomplete
                    disablePortal
                    getOptionLabel={(option) => option[t("age_group_name_ch")]}
                    multiple
                    onChange={(event, value) =>
                      onInputFieldChange("ageGroupField", value)
                    }
                    onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                    options={ageGroupOptions}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={checkIsFieldError("ageGroupField")}
                        helperText={getErrorFieldMessage("ageGroupField")}
                        label={t("年齡層")}
                        variant="standard"
                      />
                    )}
                    value={ageGroupField}
                  />
                )}
              </Grid>
            )}
            <Grid item xs={12} className={classes.formSubmitBtnContainer}>
              <Button
                onClick={onSubmitBtnClicked}
                variant="contained"
                sx={formSubmitBtnStyles}
              >
                {merchandiseGroupId ? t("修改") : t("新增")}
              </Button>
            </Grid>
            {/* Alert */}
            {shouldShowFormAlert && (
              <Grid item xs={12}>
                <Alert severity={formAlertType}>{formAlertText}</Alert>
              </Grid>
            )}
          </Grid>
        </>
      )}
    </div>
  );
}

export default MerchandiseGroupDetailFormContent;
