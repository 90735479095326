// Actions
import {
  onConditionAgeGroupChange,
  onConditionAgeGroupItemChange,
  onConditionBrandChange,
  onConditionBrandItemChange,
  onConditionCategoryChange,
  onConditionCategoryItemChange,
  onConditionGenderTypeChange,
  onConditionGenderTypeItemChange,
  onConditionSubCategoryChange,
  onConditionSubCategoryItemChange,
  onKeywordChange,
} from "../../../redux/pages/merchandisesPage/merchandisesPageActions";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Dummy Datum
import { ageGroupDummyData } from "../../../dummyDatum/ageGroupDummyData";
import { brandDummyData } from "../../../dummyDatum/brandDummyData";
import { categoryDummyData } from "../../../dummyDatum/categoryDummyData";
import { genderTypeDummyData } from "../../../dummyDatum/genderTypeDummyData";
import { subCategoryDummyData } from "../../../dummyDatum/subCategoryDummyData";

// Fetches
import { getAllAgeGroupsFetch } from "../../../fetches/ageGroupFetches";
import { getAllBrandsFetch } from "../../../fetches/brandFetches";
import { getAllCategoriesFetch } from "../../../fetches/categoryFetches";
import { getAllGenderTypesFetch } from "../../../fetches/genderTypeFetches";
import { getAllSubCategoriesByCategoryIdFetch } from "../../../fetches/subCategoryFetches";

// Material UI
// Components
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function MerchandiseConditionBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const ageGroupId = useSelector(
    (state) => state.merchandisesPage.conditionAgeGroupId
  );
  const ageGroupItem = useSelector(
    (state) => state.merchandisesPage.conditionAgeGroupItem
  );
  const brandId = useSelector(
    (state) => state.merchandisesPage.conditionBrandId
  );
  const brandItem = useSelector(
    (state) => state.merchandisesPage.conditionBrandItem
  );
  const categoryId = useSelector(
    (state) => state.merchandisesPage.conditionCategoryId
  );
  const categoryItem = useSelector(
    (state) => state.merchandisesPage.conditionCategoryItem
  );
  const genderTypeId = useSelector(
    (state) => state.merchandisesPage.conditionGenderTypeId
  );
  const genderTypeItem = useSelector(
    (state) => state.merchandisesPage.conditionGenderTypeItem
  );
  const keyword = useSelector((state) => state.merchandisesPage.keyword);
  const subCategoryId = useSelector(
    (state) => state.merchandisesPage.conditionSubCategoryId
  );
  const subCategoryItem = useSelector(
    (state) => state.merchandisesPage.conditionSubCategoryItem
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [ageGroups, setAgeGroups] = useState(null);
  const [brands, setBrands] = useState(null);
  const [categories, setCategories] = useState(null);
  const [genderTypes, setGenderTypes] = useState(null);
  const [subCategories, setSubCategories] = useState(null);

  // Events
  // Events - Form
  const onInputFieldChange = (field, value) => {
    switch (field) {
      // Update Redux Store
      case "ageGroup":
        if (value) {
          dispatch(onConditionAgeGroupChange(value.id));
        }
        break;
      case "brand":
        if (value) {
          dispatch(onConditionBrandChange(value.id));
        }
        break;
      case "category":
        if (value) {
          dispatch(onConditionCategoryChange(value.id));
          dispatch(
            onConditionSubCategoryChange(
              value.id === "dummy" ? null : subCategoryDummyData.id
            )
          );
        }
        break;
      case "genderType":
        if (value) {
          dispatch(onConditionGenderTypeChange(value.id));
        }
        break;
      case "keyword":
        dispatch(onKeywordChange(value));
        break;
      case "subCategory":
        if (value) {
          dispatch(onConditionSubCategoryChange(value.id));
        }
        break;
      default:
        break;
    }
  };

  // Functions
  // Functions - Normal
  const getAllConditions = () => {
    getAllAgeGroups();
    getAllBrands();
    getAllCategories();
    getAllGenderTypes();
  };

  // Functions - Queries
  const getAllAgeGroups = async () => {
    const results = await getAllAgeGroupsFetch(token, "Current");

    // Set States
    setAgeGroups(
      results.ageGroups ? [ageGroupDummyData, ...results.ageGroups] : null
    );

    // Update Redux Store
    if (results.ageGroups && !ageGroupId) {
      dispatch(onConditionAgeGroupChange(ageGroupDummyData.id));
    }
  };

  const getAllBrands = async () => {
    const results = await getAllBrandsFetch(token, "Current");

    // Set States
    setBrands(results.brands ? [brandDummyData, ...results.brands] : null);

    // Update Redux Store
    if (results.brands && !brandId) {
      dispatch(onConditionBrandChange(brandDummyData.id));
    }
  };

  const getAllCategories = async () => {
    const results = await getAllCategoriesFetch(token, "Current");

    // Set States
    setCategories(
      results.categories ? [categoryDummyData, ...results.categories] : null
    );

    // Update Redux Store
    if (results.categories && !categoryId) {
      dispatch(onConditionCategoryChange(categoryDummyData.id));
    }
  };

  const getAllGenderTypes = async () => {
    const results = await getAllGenderTypesFetch(token);

    // Set States
    setGenderTypes(
      results.genderTypes ? [genderTypeDummyData, ...results.genderTypes] : null
    );

    // Update Redux Store
    if (results.genderTypes && !genderTypeId) {
      dispatch(onConditionGenderTypeChange(genderTypeDummyData.id));
    }
  };

  const getAllSubCategoriesByCategoryId = async () => {
    const results = await getAllSubCategoriesByCategoryIdFetch(
      token,
      categoryId,
      "Current"
    );

    // Set States
    setSubCategories(
      results.subCategories
        ? [subCategoryDummyData, ...results.subCategories]
        : null
    );

    // Update Redux Store
    if (results.subCategories && !subCategoryId) {
      dispatch(onConditionSubCategoryChange(subCategoryDummyData.id));
    }
  };

  // Life Cycle
  useEffect(() => {
    getAllConditions();
  }, []);

  useEffect(() => {
    if (categoryId && categoryId !== "dummy") {
      getAllSubCategoriesByCategoryId();
    }
  }, [categoryId]);

  useEffect(() => {
    // Update Redux Store
    if (ageGroups && ageGroupId) {
      dispatch(
        onConditionAgeGroupItemChange(
          ageGroups.find((item) => item.id === ageGroupId)
        )
      );
    }
  }, [ageGroups, ageGroupId]);

  useEffect(() => {
    // Update Redux Store
    if (brands && brandId) {
      dispatch(
        onConditionBrandItemChange(brands.find((item) => item.id === brandId))
      );
    }
  }, [brands, brandId]);

  useEffect(() => {
    // Update Redux Store
    if (categories && categoryId) {
      dispatch(
        onConditionCategoryItemChange(
          categories.find((item) => item.id === categoryId)
        )
      );
    }
  }, [categories, categoryId]);

  useEffect(() => {
    // Update Redux Store
    if (genderTypes && genderTypeId) {
      dispatch(
        onConditionGenderTypeItemChange(
          genderTypes.find((item) => item.id === genderTypeId)
        )
      );
    }
  }, [genderTypes, genderTypeId]);

  useEffect(() => {
    // Update Redux Store
    if (subCategories && subCategoryId) {
      dispatch(
        onConditionSubCategoryItemChange(
          subCategories.find((item) => item.id === subCategoryId)
        )
      );
    }
  }, [subCategories, subCategoryId]);

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("搜尋商品")}
          </Typography>
        </div>
      </div>
      {/* Text Field */}
      <div className={classes.autoCompleteContainer}>
        <TextField
          autoFocus
          fullWidth
          label={t("關鍵字")}
          margin="dense"
          name="keyword"
          onChange={(event) =>
            onInputFieldChange("keyword", event.target.value)
          }
          required
          value={keyword}
          variant="standard"
        />
      </div>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("類別")}
          </Typography>
        </div>
      </div>
      {/* Select Box */}
      {categories && (
        <div className={classes.autoCompleteContainer}>
          <Autocomplete
            disablePortal
            fullWidth
            getOptionLabel={(option) => option[t("category_name_ch")]}
            onChange={(event, value) => onInputFieldChange("category", value)}
            options={categories}
            renderInput={(params) => (
              <TextField {...params} label={t("類別")} variant="outlined" />
            )}
            value={categoryItem}
          />
        </div>
      )}
      {categoryId && categoryId !== "dummy" && (
        <>
          {/* Select Box */}
          {subCategories && (
            <div className={classes.autoCompleteContainer}>
              <Autocomplete
                disablePortal
                fullWidth
                getOptionLabel={(option) => option[t("sub_category_name_ch")]}
                onChange={(event, value) =>
                  onInputFieldChange("subCategory", value)
                }
                options={subCategories}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("子類別")}
                    variant="outlined"
                  />
                )}
                value={subCategoryItem}
              />
            </div>
          )}
        </>
      )}
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("品牌")}
          </Typography>
        </div>
      </div>
      {/* Select Box */}
      {brands && (
        <div className={classes.autoCompleteContainer}>
          <Autocomplete
            disablePortal
            fullWidth
            getOptionLabel={(option) =>
              option.id !== "dummy"
                ? option.brand_name
                : option[t("brand_name_ch")]
            }
            onChange={(event, value) => onInputFieldChange("brand", value)}
            options={brands}
            renderInput={(params) => (
              <TextField {...params} label={t("品牌")} variant="outlined" />
            )}
            value={brandItem}
          />
        </div>
      )}
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("適用性別")}
          </Typography>
        </div>
      </div>
      {/* Select Box */}
      {genderTypes && (
        <div className={classes.autoCompleteContainer}>
          <Autocomplete
            disablePortal
            fullWidth
            getOptionLabel={(option) => option[t("gender_type_name_ch")]}
            onChange={(event, value) => onInputFieldChange("genderType", value)}
            options={genderTypes}
            renderInput={(params) => (
              <TextField {...params} label={t("適用性別")} variant="outlined" />
            )}
            value={genderTypeItem}
          />
        </div>
      )}
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("年齡層")}
          </Typography>
        </div>
      </div>
      {/* Select Box */}
      {ageGroups && (
        <div className={classes.autoCompleteContainer}>
          <Autocomplete
            disablePortal
            fullWidth
            getOptionLabel={(option) => option[t("age_group_name_ch")]}
            onChange={(event, value) => onInputFieldChange("ageGroup", value)}
            options={ageGroups}
            renderInput={(params) => (
              <TextField {...params} label={t("年齡層")} variant="outlined" />
            )}
            value={ageGroupItem}
          />
        </div>
      )}
    </div>
  );
}

export default MerchandiseConditionBoxesContainer;
