// Actions
import { enterPage } from "../redux/shared/location/locationActions";

// Components
// Boxes
import PopularMerchandiseBoxesContainer from "../components/boxes/salesReportsPage/popularMerchandiseBoxesContainer";
import SalesReportConditionBoxesContainer from "../components/boxes/salesReportsPage/salesReportConditionBoxesContainer";
// Charts
import SalesNumberChartContainer from "../components/charts/salesReportsPage/salesNumberChartContainer";
import SalesTrendChartContainer from "../components/charts/salesReportsPage/salesTrendChartContainer";
// Headers
import SalesReportsPageHeader from "../components/headers/salesReportsPage/salesReportsPageHeader";
// Spacing Boxes
import SpacingBox from "../components/boxes/spacing/spacingBox";

// Configs
import stylesConfig from "../configs/stylesConfig";

// Material UI
// Components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// React
import { useEffect } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

function SalesReportsPage() {
  // Hooks
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const tabItem = useSelector((state) => state.salesReportsPage.tabItem);

  // Life Cycle
  useEffect(() => {
    // Update Redux Store
    dispatch(enterPage("SalesReports"));
  }, []);

  return (
    <Container maxWidth={false}>
      {/* Header */}
      <SpacingBox
        // Render
        height={stylesConfig.appBarHeight}
      />
      <SalesReportsPageHeader />
      {/* Content */}
      <Grid container spacing={4}>
        <Grid item xs={12} md={4} lg={3}>
          {["SalesNumbers", "SalesTrends"].includes(tabItem) && (
            <SalesReportConditionBoxesContainer />
          )}
          <SpacingBox
            // Render
            height={stylesConfig.spacingBoxMarginBottom}
          />
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={8}>
              {tabItem === "SalesNumbers" && <SalesNumberChartContainer />}
              {tabItem === "SalesTrends" && <SalesTrendChartContainer />}
              <SpacingBox
                // Render
                height={stylesConfig.spacingBoxMarginBottom}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <PopularMerchandiseBoxesContainer />
              <SpacingBox
                // Render
                height={stylesConfig.spacingBoxMarginBottom}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default SalesReportsPage;
