const initialState = {
  conditionBrandId: null,
  conditionBrandItem: null,
  conditionCategoryId: null,
  conditionCategoryItem: null,
  conditionShopId: null,
  conditionShopItem: null,
  conditionSubCategoryId: null,
  conditionSubCategoryItem: null,
  conditionWarehouseId: null,
  conditionWarehouseItem: null,
  conditionWarehouseType: "All",
  formSubmitCount: 0,
  keyword: "",
  merchandiseId: null,
  merchandiseCodeText: null,
  tabItem: "Stocks",
  typeOfRankedWarehouseContent: "Shops",
  variationId: null,
  variationAttributeText: null,
};

export const stocksPageReducers = (state = initialState, action) => {
  switch (action.type) {
    case "ON_STOCKS_PAGE_CONDITION_BRAND_CHANGE":
      const { conditionBrandId } = action;
      return {
        ...state,
        conditionBrandId,
      };
    case "ON_STOCKS_PAGE_CONDITION_BRAND_ITEM_CHANGE":
      const { conditionBrandItem } = action;
      return {
        ...state,
        conditionBrandItem,
      };
    case "ON_STOCKS_PAGE_CONDITION_CATEGORY_CHANGE":
      const { conditionCategoryId } = action;
      return {
        ...state,
        conditionCategoryId,
      };
    case "ON_STOCKS_PAGE_CONDITION_CATEGORY_ITEM_CHANGE":
      const { conditionCategoryItem } = action;
      return {
        ...state,
        conditionCategoryItem,
      };
    case "ON_STOCKS_PAGE_CONDITION_SHOP_CHANGE":
      const { conditionShopId } = action;
      return {
        ...state,
        conditionShopId,
      };
    case "ON_STOCKS_PAGE_CONDITION_SHOP_ITEM_CHANGE":
      const { conditionShopItem } = action;
      return {
        ...state,
        conditionShopItem,
      };
    case "ON_STOCKS_PAGE_CONDITION_SUB_CATEGORY_CHANGE":
      const { conditionSubCategoryId } = action;
      return {
        ...state,
        conditionSubCategoryId,
      };
    case "ON_STOCKS_PAGE_CONDITION_SUB_CATEGORY_ITEM_CHANGE":
      const { conditionSubCategoryItem } = action;
      return {
        ...state,
        conditionSubCategoryItem,
      };
    case "ON_STOCKS_PAGE_CONDITION_WAREHOUSE_CHANGE":
      const { conditionWarehouseId } = action;
      return {
        ...state,
        conditionWarehouseId,
      };
    case "ON_STOCKS_PAGE_CONDITION_WAREHOUSE_ITEM_CHANGE":
      const { conditionWarehouseItem } = action;
      return {
        ...state,
        conditionWarehouseItem,
      };
    case "ON_STOCKS_PAGE_CONDITION_WAREHOUSE_TYPE_CHANGE":
      const { conditionWarehouseType } = action;
      return {
        ...state,
        conditionWarehouseType,
      };
    case "ON_STOCKS_PAGE_FORM_SUBMIT_COUNT_INCREASE":
      return {
        ...state,
        formSubmitCount: state.formSubmitCount + 1,
      };
    case "ON_MERCHANDISES_SALES_PAGE_KEYWORD_CHANGE":
      const { keyword } = action;
      return {
        ...state,
        keyword,
      };
    case "ON_STOCKS_PAGE_MERCHANDISE_CHANGE":
      const { merchandiseId, merchandiseCodeText } = action;
      return {
        ...state,
        merchandiseId,
        merchandiseCodeText,
      };
    case "ON_STOCKS_PAGE_TAB_ITEM_CHANGE":
      const { tabItem } = action;
      return {
        ...state,
        tabItem,
      };
    case "ON_STOCKS_PAGE_TYPE_OF_RANKD_WAREHOUSE_CONTENT_CHANGE":
      const { typeOfRankedWarehouseContent } = action;
      return {
        ...state,
        typeOfRankedWarehouseContent,
      };
    case "ON_STOCKS_PAGE_VARIATION_CHANGE":
      const { variationId, variationAttributeText } = action;
      return {
        ...state,
        variationId,
        variationAttributeText,
      };
    default:
      return state;
  }
};
