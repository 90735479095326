const initialState = {
  conditionDiscountOfferTypeId: null,
  conditionDiscountOfferTypeItem: null,
  conditionDiscountStatusTypeId: null,
  conditionDiscountStatusTypeItem: null,
  formSubmitCount: 0,
  keyword: "",
  tabItem: "Discounts",
  typeOfDiscounts: "Current",
};

export const discountsPageReducers = (state = initialState, action) => {
  switch (action.type) {
    case "ON_DISCOUNTS_PAGE_CONDITION_DISCOUNT_OFFER_TYPE_CHANGE":
      const { conditionDiscountOfferTypeId } = action;
      return {
        ...state,
        conditionDiscountOfferTypeId,
      };
    case "ON_DISCOUNTS_PAGE_CONDITION_DISCOUNT_OFFER_TYPE_ITEM_CHANGE":
      const { conditionDiscountOfferTypeItem } = action;
      return {
        ...state,
        conditionDiscountOfferTypeItem,
      };
    case "ON_DISCOUNTS_PAGE_CONDITION_DISCOUNT_STATUS_TYPE_CHANGE":
      const { conditionDiscountStatusTypeId } = action;
      return {
        ...state,
        conditionDiscountStatusTypeId,
      };
    case "ON_DISCOUNTS_PAGE_CONDITION_DISCOUNT_STATUS_TYPE_ITEM_CHANGE":
      const { conditionDiscountStatusTypeItem } = action;
      return {
        ...state,
        conditionDiscountStatusTypeItem,
      };
    case "ON_DISCOUNTS_PAGE_FORM_SUBMIT_COUNT_INCREASE":
      return {
        ...state,
        formSubmitCount: state.formSubmitCount + 1,
      };
    case "ON_DISCOUNTS_PAGE_KEYWORD_CHANGE":
      const { keyword } = action;
      return {
        ...state,
        keyword,
      };
    case "ON_DISCOUNTS_PAGE_TAB_ITEM_CHANGE":
      const { tabItem } = action;
      return {
        ...state,
        tabItem,
      };
    case "ON_DISCOUNTS_PAGE_TYPE_OF_DISCOUNTS_CHANGE":
      const { typeOfDiscounts } = action;
      return {
        ...state,
        typeOfDiscounts,
      };
    default:
      return state;
  }
};
