const initialState = {
  brandId: null,
  categoryId: null,
  formSubmitCount: 0,
  subCategoryId: null,
  tabItem: "Categories",
  typeOfCategoriesAndBrands: "Current",
  typeOfContent: "SubCategoryList",
  typeOfSubCategories: "Current",
};

export const categoriesAndBrandsPageReducers = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "ON_CATEGORIES_AND_BRANDS_PAGE_BRAND_CHANGE":
      const { brandId } = action;
      return {
        ...state,
        brandId,
      };
    case "ON_CATEGORIES_AND_BRANDS_PAGE_CATEGORY_CHANGE":
      const { categoryId } = action;
      return {
        ...state,
        categoryId,
      };
    case "ON_CATEGORIES_AND_BRANDS_PAGE_FORM_SUBMIT_COUNT_INCREASE":
      return {
        ...state,
        formSubmitCount: state.formSubmitCount + 1,
      };
    case "ON_CATEGORIES_AND_BRANDS_PAGE_SUB_CATEGORY_CHANGE":
      const { subCategoryId } = action;
      return {
        ...state,
        subCategoryId,
      };
    case "ON_CATEGORIES_AND_BRANDS_PAGE_TAB_ITEM_CHANGE":
      const { tabItem } = action;
      return {
        ...state,
        tabItem,
      };
    case "ON_CATEGORIES_AND_BRANDS_PAGE_TYPE_OF_CATEGORIES_AND_BRANDS_CHANGE":
      const { typeOfCategoriesAndBrands } = action;
      return {
        ...state,
        typeOfCategoriesAndBrands,
      };
    case "ON_CATEGORIES_AND_BRANDS_PAGE_TYPE_OF_CONTENT_CHANGE":
      const { typeOfContent } = action;
      return {
        ...state,
        typeOfContent,
      };
    case "ON_CATEGORIES_AND_BRANDS_PAGE_TYPE_OF_SUB_CATEGORIES_CHANGE":
      const { typeOfSubCategories } = action;
      return {
        ...state,
        typeOfSubCategories,
      };
    default:
      return state;
  }
};
