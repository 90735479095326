const today = new Date();

const initialState = {
  comparisonRecordDate: today.toString(),
  conditionShopBrandId: null,
  conditionShopBrandItem: null,
  conditionShopId: null,
  conditionShopItem: null,
  conditionStaffId: null,
  conditionStaffItem: null,
  formSubmitCount: 0,
  isComparisonShown: false,
  recordDate: today.toString(),
  recordDateType: "Date",
  salesNumberType: "Revenue",
  tabItem: "SalesNumbers",
};

export const salesReportsPageReducers = (state = initialState, action) => {
  switch (action.type) {
    case "ON_SALES_REPORTS_PAGE_COMPARISON_RECORD_DATE_CHANGE":
      const { comparisonRecordDate } = action;
      return {
        ...state,
        comparisonRecordDate: comparisonRecordDate.toString(),
      };
    case "ON_SALES_REPORTS_PAGE_CONDITION_SHOP_BRAND_CHANGE":
      const { conditionShopBrandId } = action;
      return {
        ...state,
        conditionShopBrandId,
      };
    case "ON_SALES_REPORTS_PAGE_CONDITION_SHOP_BRAND_ITEM_CHANGE":
      const { conditionShopBrandItem } = action;
      return {
        ...state,
        conditionShopBrandItem,
      };
    case "ON_SALES_REPORTS_PAGE_CONDITION_SHOP_CHANGE":
      const { conditionShopId } = action;
      return {
        ...state,
        conditionShopId,
      };
    case "ON_SALES_REPORTS_PAGE_CONDITION_SHOP_ITEM_CHANGE":
      const { conditionShopItem } = action;
      return {
        ...state,
        conditionShopItem,
      };
    case "ON_SALES_REPORTS_PAGE_CONDITION_STAFF_CHANGE":
      const { conditionStaffId } = action;
      return {
        ...state,
        conditionStaffId,
      };
    case "ON_SALES_REPORTS_PAGE_CONDITION_STAFF_ITEM_CHANGE":
      const { conditionStaffItem } = action;
      return {
        ...state,
        conditionStaffItem,
      };
    case "ON_SALES_REPORTS_PAGE_FORM_SUBMIT_COUNT_INCREASE":
      return {
        ...state,
        formSubmitCount: state.formSubmitCount + 1,
      };
    case "ON_SALES_REPORTS_PAGE_IS_COMPARISON_CHANGE":
      const { isComparisonShown } = action;
      return {
        ...state,
        isComparisonShown,
      };
    case "ON_SALES_REPORTS_PAGE_RECORD_DATE_CHANGE":
      const { recordDate } = action;
      return {
        ...state,
        recordDate: recordDate.toString(),
      };
    case "ON_SALES_REPORTS_PAGE_RECORD_DATE_TYPE_CHANGE":
      const { recordDateType } = action;
      return {
        ...state,
        recordDateType,
      };
    case "ON_SALES_REPORTS_PAGE_SALES_NUMBER_TYPE_CHANGE":
      const { salesNumberType } = action;
      return {
        ...state,
        salesNumberType,
      };
    case "ON_SALES_REPORTS_PAGE_TAB_ITEM_CHANGE":
      const { tabItem } = action;
      return {
        ...state,
        tabItem,
      };
    default:
      return state;
  }
};
