export const languageEnUs = {
  // Data
  age_group_name_ch: "age_group_name_en",
  attribute_name_ch: "attribute_name_en",
  brand_name_ch: "brand_name_en_full",
  category_name_ch: "category_name_en",
  conditionTextCh: "conditionTextEn",
  discount_calculation_type_name_ch: "discount_calculation_type_name_en",
  discount_condition_type_name_ch: "discount_condition_type_name_en",
  discount_name_ch: "discount_name_en",
  discount_offer_type_name_ch: "discount_offer_type_name_en",
  discount_payment_type_name_ch: "discount_payment_type_name_en",
  discount_rule_type_name_ch: "discount_rule_type_name_en",
  discount_status_type_name_ch: "discount_status_type_name_en",
  full_name_ch: "full_name_en",
  gender_type_name_ch: "gender_type_name_en",
  merc_grp_age_type_name_ch: "merc_grp_age_type_name_en",
  merc_grp_brd_type_name_ch: "merc_grp_brd_type_name_en",
  merc_grp_cat_type_name_ch: "merc_grp_cat_type_name_en",
  merc_grp_gen_type_name_ch: "merc_grp_gen_type_name_en",
  merc_grp_type_name_ch: "merc_grp_type_name_en",
  merchandiseGroupTextCh: "merchandiseGroupTextEn",
  merchandise_name_ch: "merchandise_name_en",
  nameCh: "nameEn",
  payment_method_name_ch: "payment_method_name_en",
  property_name_ch: "property_name_en",
  remarks_ch: "remarks_en",
  ruleTextCh: "ruleTextEn",
  shop_name_ch: "shop_name_en",
  sub_category_name_ch: "sub_category_name_en",
  textCh: "textEn",
  title_name_ch: "title_name_en_full",
  // Moment
  "a h:mm": "h:mm a",
  D日: "D",
  M月: "MMM",
  "M 月 D 日": "D MMM",
  "M 月": "MMM",
  "Y 年 M 月 D 日": "D MMM YYYY",
  "Y 年 M 月": "MMM YYYY",
  "Y 年": "YYYY",
  // Text
  "確認要 登出 嗎？": "Confirm to Logout?",
  "Change to ": "設換至",
  顯示選單: "Show Menu",
  設換至夜間模式: "Change to Dark Theme",
  設換至日間模式: "Change to Light Theme",
  登出: "Logout",
  零售銷售管理系統: "Retail POS",
  主頁: "Dashboard",
  銷售報告s: "Sales Reports",
  商品銷量s: "Merchandise Sales",
  優惠s: "Discounts",
  銷售紀錄s: "Sales Records",
  庫存s: "Stocks",
  商品s: "Merchandises",
  "類別 / 品牌s": "Categories / Brands",
  年齡層s: "Age Groups",
  優惠概覧: "Discount Profile",
  銷售紀錄概覧: "Sales Record Profile",
  概要s: "Summaries",
  本日營業額: "Today's Revenue",
  未有營業額資料: "No Revenue Data",
  營業額: "Revenue",
  "(平均)": "(Average)",
  開單數量: "Number of Sales",
  " 張": "",
  商品銷量: "Merchandise Sales",
  " 件": " Pieces",
  營業店鋪數量: "Number of Shops Opening",
  " 間": "",
  "本日最佳店鋪 (營業額)": "Today's Best Shops (Revenue)",
  未有最佳店鋪資料: "No Best Shop Data",
  "本日最佳員工 (營業額)": "Today's Best Staffs (Revenue)",
  未有最佳員工資料: "No Best Staff Data",
  "本日暢銷商品 (銷量)": "Today's Popular Merchandises (Sales Volume)",
  未有暢銷商品資料: "No Popular Merchandise Data",
  付款方式s: "Payment Methods",
  未有付款方式資料: "No Payment Method Data",
  銷售數據s: "Sales Figures",
  趨勢分析s: "Trend Analysis",
  隠藏對照日期: "Hide Comparison Date",
  顯示對照日期: "Show Comparison Date",
  隠藏對照月份: "Hide Comparison Month",
  顯示對照月份: "Show Comparison Month",
  隠藏對照年份: "Hide Comparison Year",
  顯示對照年份: "Show Comparison Year",
  "對照日期 (日/月/年)": "Comparison Date (DD/MM/YY)",
  "日期 (日/月/年)": "Date (DD/MM/YY)",
  "對照月份 (月/年)": "Comparison Month (MM/YY)",
  "月份 (月/年)": "Month (MM/YY)",
  "對照年份 (年)": "Comparison Year (YY)",
  "年份 (年)": "Year (YY)",
  返回本日: "Back to Today",
  返回本月: "Back to Current Month",
  返回本年: "Back to Current Year",
  銷售數據類別: "Sales Figure Type",
  以月為顯示單位: "Display as Months",
  以日為顯示單位: "Display as Days",
  店舖: "Shop",
  店舖品牌: "Shop Brand",
  員工: "Staff",
  全公司: "Whole COmpany",
  暢銷商品s: "Popular Merchandises",
  未有銷售數據: "No Sales Figures",
  未有趨勢分析: "No Trend Analysis",
  商品排行s: "Merchandise Rankings",
  搜尋商品: "Search Merchandise",
  關鍵字: "Keyword",
  類別: "Category",
  子類別: "Sub-Category",
  品牌: "Brand",
  未有商品排行: "No Merchandise Ranking",
  圖片: "Photo",
  商品選項排行: "Variation Rankings",
  未有商品選項排行: "No Variation Ranking",
  "請先填寫 英文全名 或 員工編號":
    "Please fill in English Full Name / Staff Code",
  "請先填寫 密碼": "Please fill in Password",
  未有權限: "Permission Denied",
  未能登入: "Unable to Login",
  "英文全名 或 員工編號": "English Full Name / Staff Code",
  密碼: "Password",
  登入: "Login",
  顯示有效優惠: "Show Active Discounts",
  顯示已刪除優惠: "Show Inactive Discounts",
  搜尋優惠: "Search Discount",
  優惠方式: "Discount Offer Type",
  優惠狀態: "Discount Status Type",
  "確認要刪除 優惠 嗎？": "Confirm to Delete Discount?",
  "確認要還原 優惠 嗎？": "Confirm to Restore Discount?",
  優惠列表: "Discount List",
  新增優惠: "Create Discount",
  未有優惠資料: "No Discount Data",
  刪除: "Delete",
  還原: "Restore",
  至: "to",
  開始b: "Begins",
  "請先填寫 優惠方式": "Please fill in Discount Offer Type",
  "請先填寫 計算方式": "Please fill in Calculation Type",
  "請先填寫 優惠條件": "Please fill in Condition Type",
  "請先填寫 付款方式類別": "Please fill in Payment type",
  "請先填寫 付款方式": "Please fill in Payment Method",
  "請先填寫 折扣方式": "Please fill in Rule Type",
  "請先填寫 代號": "Please fill in Code",
  "請先填寫 結束日期": "Please fill in End Date",
  "請先填寫 中文名稱": "Please fill in Chinese Name",
  "請先填寫 英文名稱": "Please fill in English Name",
  "請先填寫 開始日期": "Please fill in Start Date",
  "確認要新增 優惠 嗎？": "Confirm to Create Discount?",
  "確認要編輯 優惠 嗎？": "Confirm to Edit Discount?",
  未能提交: "Unable to Submit",
  編輯優惠: "Edit Discount",
  代號: "Code",
  中文名稱: "Chinese Name",
  英文名稱: "English Name",
  有效: "Active",
  計算方式: "Calculation Type",
  優惠條件: "Condition Type",
  折扣方式: "Rule Type",
  付款方式類別: "Payment Type",
  可與其他優惠一併使用: "Cumulative With Other Discounts",
  計算時包括加購商品: "Include Add-On Merchandises in Calculation",
  期限: "Time Limit",
  有適用期限: "Is Time Limit",
  "開始日期 (日/月/年)": "Start Date (DD/MM/YY)",
  "結束日期 (日/月/年)": "End Date (DD/MM/YY)",
  確定: "Submit",
  確認: "Confirm",
  取消: "Cancel",
  概要: "Summary",
  使用狀況: "Usage",
  優惠: "Discount",
  無適用期限: "No Time Limit",
  "確認要刪除 商品群組 嗎？": "Confirm to Delete Merchandise Group?",
  商品群組類別: "Merchandise Group Type",
  商品群組s: "Merchandise Groups",
  新增商品群組: "Create Merchandise Group",
  未有商品群組資料: "No Merchandise Group Data",
  不適用: "N/A",
  "確認要從新增 商品 到 群組 嗎？": "Confirm to Add Merchandise to Group?",
  "確認要從 群組 刪除 商品 嗎？": "Confirm to Remove Merchandise from Group?",
  指定商品s: "Specific Merchandises",
  新增商品a: "Add Merchandise",
  "顯示 ": "Show ",
  所有: "All",
  已選擇: "Selected",
  " 商品s": " Merchandises",
  移除: "Remove",
  未有商品資料: "No Merchandise Data",
  清除已選擇商品: "Clear Selected Merchandises",
  未有相關資料: "No Related Data",
  新增a: "Add",
  商品群組: "Merchandise Group",
  商品群組資料: "Merchandise Group Info",
  "請先填寫 年齡層類別": "Please fill in Age Group Type",
  "請先填寫 年齡層": "Please fill in Age Group",
  "請先填寫 品牌類別": "Please fill in Brand Type",
  "請先填寫 品牌": "Please fill in Brand",
  "請先填寫 商品分類類別": "Please fill in Category Type",
  "請先填寫 商品類別": "Please fill in Category",
  "請先填寫 商品子類別": "Please fill in Sub-Category",
  "請先填寫 適用性別類別": "Please fill in Gender Type",
  "請先填寫 適用性別": "Please fill in Gender",
  "確認要新增 商品群組 嗎？": "Confirm to Create Merchandise Group?",
  "確認要編輯 商品群組資料 嗎？": "Confirm to Edit Merchandise Group?",
  成功提交: "Submitted",
  品牌類別: "Brand Type",
  商品分類類別: "Category Type",
  適用性別類別: "Gender Type",
  年齡層類別: "Age group Type",
  修改: "Edit",
  詳細設定: "Details",
  商品類別: "Category",
  商品子類別: "Sub-Category",
  適用性別: "Gender",
  年齡層: "Age Group",
  編輯: "Edit",
  "確認要刪除 優惠規則 嗎？": "Confirm to Delete Discount Rule?",
  優惠規則s: "Discount Rules",
  新增優惠規則: "Create Discount Rule",
  未有優惠規則資料: "No Discount Rule Data",
  請先填寫: "Please fill in",
  必須為數字: "Must Be Number",
  "確認要新增 優惠規則 嗎？": "Confirm to Create Discount Rule?",
  "確認要編輯 優惠規則 嗎？": "Confirm to Edit Discount Rule?",
  "已有相關 優惠規則": "Discount Rule Already Exist",
  編輯優惠規則: "Edit Discount Rule",
  "滿 $ ": "$ ",
  "滿 ": "",
  "第 ": "",
  滿a: " Or Above",
  件a: "Pieces Or Above",
  件th: "th Piece",
  "減 $ ": "Discount $ ",
  優惠數據類別: "Discount Number Type",
  "使用狀況 (總計)": "Usage (Total)",
  未有使用狀況資料: "No Usage Data",
  使用次數: "Number of Usage",
  次s: "Times",
  趨勢分析: "Trend Analysis",
  未有銷售紀錄資料: "No Sales Record Data",
  件商品: "Pieces",
  銷售紀錄: "Sales Record",
  未有銷售員資料: "No Salesperson Data",
  結算: "Settlement",
  小結: "Sub-Total",
  折扣: "Discount",
  折實價: "Actual Price",
  已使用優惠s: "Applied Discounts",
  未有使用優惠: "No Discount Applied",
  賣出商品s: "Merchandises Sold",
  未有賣出商品資料: "No Merchandise Sold Data",
  商品數量: "Quantity",
  單價: "Unit Price",
  總價: "Total Price",
  優惠商品s: "Discounted Merchandises",
  未有優惠商品: "No Discounted Merchandise",
  加購商品: "Add-Ons",
  一般商品: "Normal Merchandise",
  原價: "Original Price",
  已應用優惠s: "Applied Discounts",
  未有應用優惠: "No Discount Applied",
  強制更新: "Force Update",
  庫存數據類別: "Stock Number Type",
  倉庫: "Warehouse",
  商品庫存: "Merchandise Stocks",
  未有商品庫存: "No Merchandise Stock",
  商品選項庫存s: "Variation Stocks",
  未有商品選項庫存: "No Variation Stock",
  店鋪: "Shop",
  " 庫存s": " Stocks",
  商品列表: "Merchandise List",
  商品特徵s: "Properties",
  顯示有效商品: "Show Active Merchandises",
  顯示已刪除商品: "Show Inactive Merchandises",
  顯示有效特徵: "Show Active Properties",
  顯示已刪除特徵: "Show Inactive Properties",
  顯示有效選項: "Show Active Attributes",
  顯示已刪除選項: "Show Inactive Attributes",
  "確認要刪除 商品 嗎？": "Confirm to Delete Merchandise?",
  "確認要還原 商品 嗎？": "Confirm to Restore Merchandise?",
  新增商品: "Create Merchandise",
  "請先填寫 類別": "Please fill in Category",
  "請先填寫 子類別": "Please fill in Sub-Category",
  "請先填寫 基礎價格": "Please fill in Base Price",
  "基礎價格 必須為數字": "Base Price Must be Number",
  "確認要新增 商品 嗎？": "Confirm to Create Merchandise?",
  "確認要編輯 商品資料 嗎？": "Confirm to Edit Merchandise Info?",
  "此 商品 已存在": "This Merchandise Already Exist",
  商品資料: "Merchandise Info",
  分類: "Classification",
  其他s: "Others",
  基礎價格: "Base Price",
  "重新 ": "Re-",
  上載商品照片: "Upload Merchandise Photo",
  新增: "Create",
  上載圖片: "Upload Photo",
  刪除圖片: "Delete Photo",
  "確認要刪除 圖片 嗎？": "Confirm to Delete Photo?",
  檔案不可超過: "File cannot Exceed",
  "未能上載，請再試一次": "Unable to Upload",
  選項s: "Attributes",
  編輯選項: "Edit Attribute",
  新增選項: "Create Attribute",
  未有選項資料: "No Attribute Data",
  "特徵 不可重覆": "Properties Cannot Duplicate",
  "請先填寫 特徵": "Please fill in Property",
  "請先填寫 選項": "Please fill in Attribute",
  "確認要編輯 選項 嗎？": "Confirm to Edit Property?",
  "確認要刪除 選項 嗎？": "Confirm to Delete Property?",
  新增特徵: "Create Property",
  特徵: "Property",
  "如不需填寫特徵，請按此刪除": "Click to Delete Property (If not applicable)",
  刪除特徵: "Delete Property",
  選項: "Attribute",
  "確認要刪除 特徵 嗎？": "Confirm to Delete Property?",
  "確認要還原 特徵 嗎？": "Confirm to Restore Property?",
  特徵s: "Properties",
  特徵資料: "Property Info",
  選項列表: "Attribute List",
  未有特徵資料: "No Property Data",
  "確認要還原 選項 嗎？": "Confirm to Restore Attribute?",
  "確認要新增 特徵 嗎？": "Confirm to Create Property?",
  "確認要編輯 特徵資料 嗎？": "Confirm to Edit Property Info?",
  "此 特徵 已存在": "This Property Already Exist",
  "備註 (中文)": "Remarks (Chinese)",
  "備註 (英文)": "Remarks (English)",
  "確認要新增 選項 嗎？": "Confirm to Create Attribute?",
  "確認要編輯 選項資料 嗎？": "Confirm to Edit Attribute Info?",
  "此 選項 已存在": "This Attribute Already Exist",
  選項資料: "Attribute",
  類別s: "Categories",
  品牌s: "Brands",
  顯示有效類別: "Show Active Categories",
  顯示有效品牌: "Show Active Brands",
  顯示已刪除類別: "Show Inactive Categories",
  顯示已刪除品牌: "Show Inactive Brands",
  顯示有效子類別: "Show Active Sub-Categories",
  顯示已刪除子類別: "Show Inactive Sub-Categories",
  "確認要刪除 類別 嗎？": "Confirm to Delete Category?",
  "確認要刪除 品牌 嗎？": "Confirm to Delete Brand?",
  "確認要還原 類別 嗎？": "Confirm to Restore Category?",
  "確認要還原 品牌 嗎？": "Confirm to Restore Brand?",
  新增類別: "Create Category",
  新增品牌: "Create Brand",
  顯示類別資料: "Show Category Info",
  顯示子類別列表: "Show Sub-Category List",
  未有類別資料: "No Category Data",
  未有品牌資料: "No Brand Data",
  "確認要刪除 子類別 嗎？": "Confirm to Delete Sub-Category?",
  "確認要還原 子類別 嗎？": "Confirm to Restore Sub-Category?",
  子類別s: "Sub-Categories",
  新增子類別: "Create Sub-Category",
  未有子類別資料: "No Sub-Category Data",
  "請先填寫 名稱": "Please fill in Name",
  "確認要新增 品牌 嗎？": "Confirm to Create Brand?",
  "確認要編輯 品牌資料 嗎？": "Confirm to Edit Brand Info?",
  "此 品牌 已存在": "This Brand Already Exist",
  品牌資料: "Brand Info",
  名稱: "Name",
  "確認要新增 類別 嗎？": "Confirm to Create Category?",
  "確認要編輯 類別資料 嗎？": "Confirm to Edit Category Info?",
  "此 類別 已存在": "This Category Already Exist",
  類別資料: "Category Info",
  "確認要新增 子類別 嗎？": "Confirm to Create Sub-Category?",
  "確認要編輯 子類別資料 嗎？": "Confirm to Edit Sub-Category Info?",
  "此 子類別 已存在": "This Sub-Category Already Exist",
  子類別資料: "Sub-Category Info",
  顯示有效年齡層: "Show Active Age Groups",
  顯示已刪除年齡層: "Show Inactive Age Groups",
  "確認要刪除 年齡層 嗎？": "Confirm to Delete Age Group?",
  "確認要還原 年齡層 嗎？": "Confirm to Restore Age Group?",
  新增年齡層: "Create Age Group",
  未有年齡層資料: "No Age Group Data",
  歲: "Years Old",
  歲以上: "Years Old Or Above",
  歲以下: "Years Old Or Below",
  "請先填寫 開始年齡 或 結束年齡": "Please fill in Start Age Or End Age",
  "確認要新增 年齡層 嗎？": "Confirm to Create Age Group?",
  "確認要編輯 年齡層資料 嗎？": "Confirm to Edit Age Group Info?",
  "此 年齡層 已存在": "This Age Group Already Exist",
  年齡層資料: "Age Group Info",
  開始年齡: "Start Age",
  結束年齡: "End Age",
  "載入中...": "Loading...",
};
