// Actions
import {
  onConditionShopBrandChange,
  onConditionShopBrandItemChange,
  onConditionShopChange,
  onConditionShopItemChange,
  onConditionStaffChange,
  onConditionStaffItemChange,
  onRecordDateTypeChange,
  onSalesNumberTypeChange,
} from "../../../redux/pages/salesReportsPage/salesReportsPageActions";

// Consts
import { salesNumberTypes } from "../../../consts/salesNumberTypeConsts";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Dummy Datum
import { shopBrandDummyData } from "../../../dummyDatum/shopBrandDummyData";
import { shopDummyData } from "../../../dummyDatum/shopDummyData";
import { staffDummyData } from "../../../dummyDatum/staffDummyData";

// Fetches
import { getAllBrandsFetch } from "../../../fetches/shopBrandFetches";
import { getAllShopsByBrandIdFetch } from "../../../fetches/shopFetches";
import { getAllStaffsByShopIdFetch } from "../../../fetches/staffFetches";

// Material UI
// Components
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
// Icons
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function SalesReportConditionBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const recordDateType = useSelector(
    (state) => state.salesReportsPage.recordDateType
  );
  const salesNumberType = useSelector(
    (state) => state.salesReportsPage.salesNumberType
  );
  const shopBrandId = useSelector(
    (state) => state.salesReportsPage.conditionShopBrandId
  );
  const shopBrandItem = useSelector(
    (state) => state.salesReportsPage.conditionShopBrandItem
  );
  const shopId = useSelector((state) => state.salesReportsPage.conditionShopId);
  const shopItem = useSelector(
    (state) => state.salesReportsPage.conditionShopItem
  );
  const staffId = useSelector(
    (state) => state.salesReportsPage.conditionStaffId
  );
  const staffItem = useSelector(
    (state) => state.salesReportsPage.conditionStaffItem
  );
  const tabItem = useSelector((state) => state.salesReportsPage.tabItem);
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [shopBrands, setShopBrands] = useState(null);
  const [shops, setShops] = useState(null);
  const [staffs, setStaffs] = useState(null);

  // Handle States
  const salesNumberTypeItem = salesNumberTypes.find(
    (item) => item.type === salesNumberType
  );

  // Events
  // Events - Form
  const onInputFieldChange = (field, value) => {
    switch (field) {
      // Update Redux Store
      case "salesNumberType":
        dispatch(onSalesNumberTypeChange(value.type));
        break;
      case "shopBrand":
        if (value) {
          dispatch(onConditionShopBrandChange(value.id));

          if (value === "dummy") {
            dispatch(onConditionShopChange("dummy"));
            dispatch(onConditionStaffChange("dummy"));
          }
        }
        break;
      case "shop":
        if (value) {
          dispatch(onConditionShopChange(value.id));

          if (value === "dummy") {
            dispatch(onConditionStaffChange("dummy"));
          }
        }
        break;
      case "staff":
        if (value) {
          dispatch(onConditionStaffChange(value.id));
        }
        break;
      default:
        break;
    }
  };

  // Events - Title
  const onChangeRecordDateTypeBtnClicked = () => {
    // Update Redux Store
    dispatch(
      onRecordDateTypeChange(recordDateType === "Date" ? "Month" : "Date")
    );
  };

  // Functions
  // Functions - Queries
  const getAllBrands = async () => {
    const results = await getAllBrandsFetch(token, "Current");

    // Set States
    setShopBrands(
      results.brands ? [shopBrandDummyData, ...results.brands] : null
    );

    // Update Redux Store
    if (results.brands && !shopBrandId) {
      dispatch(onConditionShopBrandChange(shopBrandDummyData.id));
    }
  };

  const getAllShopsByBrandId = async () => {
    const results = await getAllShopsByBrandIdFetch(
      token,
      shopBrandId,
      "Current"
    );

    // Set States
    setShops(results.shops ? [shopDummyData, ...results.shops] : null);

    // Update Redux Store
    if (
      results.shops &&
      (!shopId || !results.shops.some((item) => item.id === shopId))
    ) {
      dispatch(onConditionShopChange(shopDummyData.id));
    }
  };

  const getAllStaffsByShopId = async () => {
    const results = await getAllStaffsByShopIdFetch(token, shopId);

    // Set States
    setStaffs(results.staffs ? [staffDummyData, ...results.staffs] : null);

    // Update Redux Store
    if (
      results.staffs &&
      (!staffId || !results.staffs.some((item) => item.id === staffId))
    ) {
      dispatch(onConditionStaffChange(staffDummyData.id));
    }
  };

  // Life Cycle
  useEffect(() => {
    getAllBrands();
  }, []);

  useEffect(() => {
    if (shopBrandId && shopBrandId !== "dummy") {
      getAllShopsByBrandId();
    }
  }, [shopBrandId]);

  useEffect(() => {
    if (shopId && shopId !== "dummy") {
      getAllStaffsByShopId();
    }
  }, [shopId]);

  useEffect(() => {
    // Update Redux Store
    if (shopBrands && shopBrandId) {
      dispatch(
        onConditionShopBrandItemChange(
          shopBrands.find((item) => item.id === shopBrandId)
        )
      );
    }
  }, [shopBrands, shopBrandId]);

  useEffect(() => {
    // Update Redux Store
    if (shops && shopId) {
      dispatch(
        onConditionShopItemChange(shops.find((item) => item.id === shopId))
      );
    }
  }, [shops, shopId]);

  useEffect(() => {
    // Update Redux Store
    if (staffs && staffId) {
      dispatch(
        onConditionStaffItemChange(staffs.find((item) => item.id === staffId))
      );
    }
  }, [staffs, staffId]);

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("銷售數據類別")}
          </Typography>
        </div>
        <Button
          endIcon={<ChevronRightRoundedIcon />}
          onClick={onChangeRecordDateTypeBtnClicked}
          variant="text"
        >
          {recordDateType === "Date"
            ? t("以月為顯示單位")
            : t("以日為顯示單位")}
        </Button>
      </div>
      {/* Select Box */}
      <div className={classes.autoCompleteContainer}>
        <Autocomplete
          disablePortal
          fullWidth
          getOptionLabel={(option) => option[t("nameCh")]}
          onChange={(event, value) =>
            onInputFieldChange("salesNumberType", value)
          }
          options={salesNumberTypes}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("銷售數據類別")}
              variant="outlined"
            />
          )}
          value={salesNumberTypeItem}
        />
      </div>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("店舖")}
          </Typography>
        </div>
      </div>
      {/* Select Box */}
      {shopBrands && (
        <div className={classes.autoCompleteContainer}>
          <Autocomplete
            disablePortal
            fullWidth
            getOptionLabel={(option) =>
              option.id !== "dummy"
                ? option.brand_name_en_full
                : option[t("brand_name_ch")]
            }
            onChange={(event, value) => onInputFieldChange("shopBrand", value)}
            options={shopBrands}
            renderInput={(params) => (
              <TextField {...params} label={t("店舖品牌")} variant="outlined" />
            )}
            value={shopBrandItem}
          />
        </div>
      )}
      {shopBrandId && shopBrandId !== "dummy" && (
        <>
          {/* Select Box */}
          {shops && (
            <div className={classes.autoCompleteContainer}>
              <Autocomplete
                disablePortal
                fullWidth
                getOptionLabel={(option) =>
                  option.id !== "dummy"
                    ? option.shop_code
                    : option[t("shop_name_ch")]
                }
                onChange={(event, value) => onInputFieldChange("shop", value)}
                options={shops}
                renderInput={(params) => (
                  <TextField {...params} label={t("店舖")} variant="outlined" />
                )}
                value={shopItem}
              />
            </div>
          )}
        </>
      )}
      {tabItem === "SalesTrends" &&
        shopBrandId &&
        shopBrandId !== "dummy" &&
        shopId &&
        shopId !== "dummy" && (
          <>
            {/* Title */}
            <div className={classes.titleContainer}>
              <div className={classes.titleTextContainer}>
                <Typography variant="h6" align={"left"}>
                  {t("員工")}
                </Typography>
              </div>
            </div>
            {/* Select Box */}
            {staffs && (
              <div className={classes.autoCompleteContainer}>
                <Autocomplete
                  disablePortal
                  fullWidth
                  getOptionLabel={(option) =>
                    option.id !== "dummy"
                      ? option.full_name_en
                        ? `${option.staff_code} - ${option.full_name_en}`
                        : option.staff_code
                      : option[t("full_name_ch")]
                  }
                  onChange={(event, value) =>
                    onInputFieldChange("staff", value)
                  }
                  options={staffs}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("員工")}
                      variant="outlined"
                    />
                  )}
                  value={staffItem}
                />
              </div>
            )}
          </>
        )}
    </div>
  );
}

export default SalesReportConditionBoxesContainer;
