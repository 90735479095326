export const getPagePath = (titleType) => {
  let path;

  switch (titleType) {
    case "AgeGroups":
      path = "/ageGroups";
      break;
    case "CategoriesAndBrands":
      path = "/categoriesAndBrands";
      break;
    case "Dashboard":
      path = "/dashboard";
      break;
    case "Discounts":
      path = "/discounts";
      break;
    case "DiscountProfile":
      path = "/discountProfile";
      break;
    case "Merchandises":
      path = "/merchandises";
      break;
    case "MerchandiseSales":
      path = "/merchandiseSales";
      break;
    case "SalesRecords":
      path = "/salesRecords";
      break;
    case "SalesRecordProfile":
      path = "/salesRecordProfile";
      break;
    case "SalesReports":
      path = "/salesReports";
      break;
    case "Stocks":
      path = "/stocks";
      break;
    default:
      path = "/";
      break;
  }

  return path;
};
