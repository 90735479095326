export function staffLogin(token, alias, deptNameDisplay, fullNameEn, id) {
  return {
    type: "STAFF_LOGIN",
    token,
    alias,
    deptNameDisplay,
    fullNameEn,
    id,
  };
}

export function staffLogout() {
  return {
    type: "STAFF_LOGOUT",
  };
}
