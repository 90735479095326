// Configs
import apisConfig from "../configs/apisConfig";

// Consts
import { fileSizeUnits } from "../consts/fileSizeConsts";

export const checkFileSize = (files, maxFileUploadSize, unit) => {
  try {
    unit = unit.toLowerCase();

    switch (unit) {
      case "kb":
        maxFileUploadSize = maxFileUploadSize * 1000;
        break;
      case "mb":
        maxFileUploadSize = maxFileUploadSize * 1000000;
        break;
      default:
        break;
    }

    let totalFileSize = 0;

    for (let item of files) {
      totalFileSize += item.size;
    }

    return totalFileSize <= maxFileUploadSize;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const displayFileSizeText = (fileSize) => {
  let tempFileSize = fileSize;
  let fileSizeUnitIndex = 0;

  while (tempFileSize > 1000) {
    tempFileSize = tempFileSize / 1000;
    fileSizeUnitIndex++;
  }

  if (!fileSizeUnits[fileSizeUnitIndex]) {
    return null;
  }

  tempFileSize = Math.round(tempFileSize);

  return `${tempFileSize} ${fileSizeUnits[fileSizeUnitIndex]}`;
};

export const getFileURL = (filePath) => {
  return `${apisConfig.fileURL}${filePath}`;
};

export const openFileInNewTab = (filePath) => {
  const fileUrl = `${apisConfig.fileURL}${filePath}`;

  window.open(fileUrl, "_blank", "noopener,noreferrer");
};
