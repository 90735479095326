// Actions
import {
  onTabItemChange,
  onTypeOfAttributesChange,
  onTypeOfMerchandisesChange,
  onTypeOfPropertiesChange,
} from "../../../redux/pages/merchandisesPage/merchandisesPageActions";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import {
  selectBoxContainerStyles,
  useStandardListPageStyles,
} from "../../../styles/pageStyles/standardListPageStyles";

function MerchandisesPageHeader() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardListPageStyles();

  // Redux Store
  const tabItem = useSelector((state) => state.merchandisesPage.tabItem);
  const typeOfAttributes = useSelector(
    (state) => state.merchandisesPage.typeOfAttributes
  );
  const typeOfContent = useSelector(
    (state) => state.merchandisesPage.typeOfContent
  );
  const typeOfMerchandises = useSelector(
    (state) => state.merchandisesPage.typeOfMerchandises
  );
  const typeOfProperties = useSelector(
    (state) => state.merchandisesPage.typeOfProperties
  );

  // Events
  // Events - Select Box
  const onTypeOfAttributesSelectBoxValueChanged = (value) => {
    // Update Redux Store
    dispatch(onTypeOfAttributesChange(value));
  };

  const onTypeOfMerchandisesSelectBoxValueChanged = (value) => {
    // Update Redux Store
    dispatch(onTypeOfMerchandisesChange(value));
  };

  const onTypeOfPropertiesSelectBoxValueChanged = (value) => {
    // Update Redux Store
    dispatch(onTypeOfPropertiesChange(value));
  };

  // Events - Tabs
  const onTabClicked = (event, value) => {
    // Update Redux Store
    dispatch(onTabItemChange(value));
  };

  return (
    <Box className={classes.divisionsContainer}>
      {/* Tabs */}
      <div className={classes.tabsContainer}>
        {tabItem && (
          <Tabs
            scrollButtons={false}
            value={tabItem}
            variant="scrollable"
            onChange={onTabClicked}
          >
            <Tab
              disableRipple
              key={"MerchandiseList"}
              label={
                <Typography variant="h6" align={"center"} gutterBottom>
                  {t("商品列表")}
                </Typography>
              }
              value={"MerchandiseList"}
            />
            <Tab
              disableRipple
              key={"MerchandiseProperties"}
              label={
                <Typography variant="h6" align={"center"} gutterBottom>
                  {t("商品特徵s")}
                </Typography>
              }
              value={"MerchandiseProperties"}
            />
          </Tabs>
        )}
      </div>
      {/* Select Boxes */}
      <div>
        {tabItem === "MerchandiseList" && (
          <>
            <FormControl variant="standard" sx={selectBoxContainerStyles}>
              <Select
                value={typeOfMerchandises}
                onChange={(event) =>
                  onTypeOfMerchandisesSelectBoxValueChanged(event.target.value)
                }
              >
                <MenuItem value={"Current"}>{t("顯示有效商品")}</MenuItem>
                <MenuItem value={"Deleted"}>{t("顯示已刪除商品")}</MenuItem>
              </Select>
            </FormControl>
          </>
        )}
        {tabItem === "MerchandiseProperties" && (
          <>
            <FormControl variant="standard" sx={selectBoxContainerStyles}>
              <Select
                value={typeOfProperties}
                onChange={(event) =>
                  onTypeOfPropertiesSelectBoxValueChanged(event.target.value)
                }
              >
                <MenuItem value={"Current"}>{t("顯示有效特徵")}</MenuItem>
                <MenuItem value={"Deleted"}>{t("顯示已刪除特徵")}</MenuItem>
              </Select>
            </FormControl>
            {typeOfContent === "AttributeList" && (
              <FormControl variant="standard" sx={selectBoxContainerStyles}>
                <Select
                  value={typeOfAttributes}
                  onChange={(event) =>
                    onTypeOfAttributesSelectBoxValueChanged(event.target.value)
                  }
                >
                  <MenuItem value={"Current"}>{t("顯示有效選項")}</MenuItem>
                  <MenuItem value={"Deleted"}>{t("顯示已刪除選項")}</MenuItem>
                </Select>
              </FormControl>
            )}
          </>
        )}
      </div>
    </Box>
  );
}

export default MerchandisesPageHeader;
