const initialState = {
  tabItem: "Summary",
};

export const dashboardPageReducers = (state = initialState, action) => {
  switch (action.type) {
    case "ON_DASHBOARD_PAGE_TAB_ITEM_CHANGE":
      const { tabItem } = action;
      return {
        ...state,
        tabItem,
      };
    default:
      return state;
  }
};
