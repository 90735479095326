const initialState = {
  token: null,
  alias: null,
  deptNameDisplay: null,
  fullNameEn: null,
  id: null,
};

export const staffReducers = (state = initialState, action) => {
  switch (action.type) {
    case "STAFF_LOGIN":
      const { token, alias, deptNameDisplay, fullNameEn, id } = action;
      return {
        token,
        alias,
        deptNameDisplay,
        fullNameEn,
        id,
      };
    case "STAFF_LOGOUT":
      return {
        token: null,
        alias: null,
        deptNameDisplay: null,
        fullNameEn: null,
        id: null,
      };
    default:
      return state;
  }
};
