// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function DiscountActionModalContent(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // Events
    onInputFieldChange,
    onInputFieldKeyPressed,
    onSubmitBtnClicked,
    // Functions
    checkIsFieldError,
    getErrorFieldMessage,
    // States
    code,
    discountCalculationTypeField,
    discountCalculationTypeOptions,
    discountConditionTypeField,
    discountConditionTypeOptions,
    discountId,
    discountOfferTypeField,
    discountOfferTypeOptions,
    discountPaymentTypeField,
    discountPaymentTypeOptions,
    discountRuleTypeField,
    discountRuleTypeOptions,
    endDateVar,
    formAlertText,
    formAlertType,
    isCumulative,
    isAddOnsIncluded,
    isItemActive,
    isTimeLimit,
    nameCh,
    nameEn,
    paymentMethodField,
    paymentMethodOptions,
    shouldShowFormAlert,
    startDateVar,
  } = props;

  // Handle States
  const availablediscountConditionTypeOptions =
    discountOfferTypeField &&
    discountOfferTypeField.discount_offer_type_name_en === "Merchandises"
      ? discountConditionTypeOptions
      : discountConditionTypeOptions.filter((item) =>
          ["Amount", "Quantity", "No Restriction"].includes(
            item.discount_condition_type_name_en
          )
        );
  const isDiscountCalculationTypeFieldEditable =
    discountOfferTypeField &&
    discountOfferTypeField.discount_offer_type_name_en !== "Merchandises" &&
    discountConditionTypeField &&
    discountConditionTypeField.discount_condition_type_name_en === "Amount";

  const shouldShowIsAddOnsIncludedField =
    discountOfferTypeField &&
    discountOfferTypeField.discount_offer_type_name_en === "Whole Order";
  const shouldShowPaymentMethodField =
    discountPaymentTypeField &&
    discountPaymentTypeField.discount_payment_type_name_en !== "All";

  return (
    <div className={classes.modalContainerLarge}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {discountId ? t("編輯優惠") : t("新增優惠")}
          </Typography>
        </div>
      </div>
      {/* Content */}
      <div>
        <Grid
          className={classes.formContainer}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          container
          rowSpacing={1}
        >
          <Grid item xs={12}>
            <TextField
              error={checkIsFieldError("code")}
              fullWidth
              helperText={getErrorFieldMessage("code")}
              label={t("代號")}
              margin="dense"
              name="code"
              onChange={(event) =>
                onInputFieldChange("code", event.target.value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              value={code}
              variant="standard"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={checkIsFieldError("nameCh")}
              fullWidth
              helperText={getErrorFieldMessage("nameCh")}
              label={t("中文名稱")}
              margin="dense"
              name="nameCh"
              onChange={(event) =>
                onInputFieldChange("nameCh", event.target.value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              required
              value={nameCh}
              variant="standard"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={checkIsFieldError("nameEn")}
              fullWidth
              helperText={getErrorFieldMessage("nameEn")}
              label={t("英文名稱")}
              margin="dense"
              name="nameEn"
              onChange={(event) =>
                onInputFieldChange("nameEn", event.target.value)
              }
              onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
              required
              value={nameEn}
              variant="standard"
            />
          </Grid>
          <Grid item xs={12} className={classes.formSwitchContainer}>
            <Typography align={"left"} variant="body1">
              {t("有效")}
            </Typography>
            <Switch
              checked={isItemActive}
              onChange={() => onInputFieldChange("isItemActive", !isItemActive)}
            />
          </Grid>
        </Grid>
      </div>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("優惠方式")}
          </Typography>
        </div>
      </div>
      {/* Content */}
      <div>
        <Grid
          className={classes.formContainer}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          container
          rowSpacing={1}
        >
          {discountOfferTypeOptions && (
            <Grid className={classes.formAutoCompleteContainer} item xs={6}>
              <Autocomplete
                disablePortal
                getOptionLabel={(option) =>
                  option[t("discount_offer_type_name_ch")]
                }
                onChange={(event, value) =>
                  onInputFieldChange("discountOfferTypeField", value)
                }
                onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                options={discountOfferTypeOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={checkIsFieldError("discountOfferTypeField")}
                    helperText={getErrorFieldMessage("discountOfferTypeField")}
                    label={t("優惠方式")}
                    variant="standard"
                  />
                )}
                value={discountOfferTypeField}
              />
            </Grid>
          )}
          {discountCalculationTypeOptions && (
            <Grid className={classes.formAutoCompleteContainer} item xs={6}>
              <Autocomplete
                disabled={!isDiscountCalculationTypeFieldEditable}
                disablePortal
                getOptionLabel={(option) =>
                  option[t("discount_calculation_type_name_ch")]
                }
                onChange={(event, value) =>
                  onInputFieldChange("discountCalculationTypeField", value)
                }
                onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                options={discountCalculationTypeOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={checkIsFieldError("discountCalculationTypeField")}
                    helperText={getErrorFieldMessage(
                      "discountCalculationTypeField"
                    )}
                    label={t("計算方式")}
                    variant="standard"
                  />
                )}
                value={discountCalculationTypeField}
              />
            </Grid>
          )}
          {discountConditionTypeOptions && (
            <Grid className={classes.formAutoCompleteContainer} item xs={6}>
              <Autocomplete
                disablePortal
                getOptionLabel={(option) =>
                  option[t("discount_condition_type_name_ch")]
                }
                onChange={(event, value) =>
                  onInputFieldChange("discountConditionTypeField", value)
                }
                onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                options={availablediscountConditionTypeOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={checkIsFieldError("discountConditionTypeField")}
                    helperText={getErrorFieldMessage(
                      "discountConditionTypeField"
                    )}
                    label={t("優惠條件")}
                    variant="standard"
                  />
                )}
                value={discountConditionTypeField}
              />
            </Grid>
          )}
          {discountRuleTypeOptions && (
            <Grid className={classes.formAutoCompleteContainer} item xs={6}>
              <Autocomplete
                disablePortal
                getOptionLabel={(option) =>
                  option[t("discount_rule_type_name_ch")]
                }
                onChange={(event, value) =>
                  onInputFieldChange("discountRuleTypeField", value)
                }
                onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                options={discountRuleTypeOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={checkIsFieldError("discountRuleTypeField")}
                    helperText={getErrorFieldMessage("discountRuleTypeField")}
                    label={t("折扣方式")}
                    variant="standard"
                  />
                )}
                value={discountRuleTypeField}
              />
            </Grid>
          )}
          {discountPaymentTypeOptions && (
            <Grid className={classes.formAutoCompleteContainer} item xs={6}>
              <Autocomplete
                disablePortal
                getOptionLabel={(option) =>
                  option[t("discount_payment_type_name_ch")]
                }
                onChange={(event, value) =>
                  onInputFieldChange("discountPaymentTypeField", value)
                }
                onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                options={discountPaymentTypeOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={checkIsFieldError("discountPaymentTypeField")}
                    helperText={getErrorFieldMessage(
                      "discountPaymentTypeField"
                    )}
                    label={t("付款方式類別")}
                    variant="standard"
                  />
                )}
                value={discountPaymentTypeField}
              />
            </Grid>
          )}
          {shouldShowPaymentMethodField && (
            <Grid className={classes.formAutoCompleteContainer} item xs={12}>
              {paymentMethodField && paymentMethodOptions && (
                <Autocomplete
                  disablePortal
                  getOptionLabel={(option) =>
                    option[t("payment_method_name_ch")]
                  }
                  multiple
                  onChange={(event, value) =>
                    onInputFieldChange("paymentMethodField", value)
                  }
                  onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                  options={paymentMethodOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={checkIsFieldError("paymentMethodField")}
                      helperText={getErrorFieldMessage("paymentMethodField")}
                      label={t("付款方式s")}
                      variant="standard"
                    />
                  )}
                  value={paymentMethodField}
                />
              )}
            </Grid>
          )}
          <Grid item xs={12} className={classes.formSwitchContainer}>
            <Typography align={"left"} variant="body1">
              {t("可與其他優惠一併使用")}
            </Typography>
            <Switch
              checked={isCumulative}
              onChange={() => onInputFieldChange("isCumulative", !isCumulative)}
            />
          </Grid>
          {shouldShowIsAddOnsIncludedField && (
            <Grid item xs={12} className={classes.formSwitchContainer}>
              <Typography align={"left"} variant="body1">
                {t("計算時包括加購商品")}
              </Typography>
              <Switch
                checked={isAddOnsIncluded}
                onChange={() =>
                  onInputFieldChange("isAddOnsIncluded", !isAddOnsIncluded)
                }
              />
            </Grid>
          )}
        </Grid>
      </div>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("期限")}
          </Typography>
        </div>
      </div>
      {/* Content */}
      <div>
        <Grid
          className={classes.formContainer}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          container
          rowSpacing={1}
        >
          <Grid item xs={12} className={classes.formSwitchContainer}>
            <Typography align={"left"} variant="body1">
              {t("有適用期限")}
            </Typography>
            <Switch
              checked={isTimeLimit}
              onChange={() => onInputFieldChange("isTimeLimit", !isTimeLimit)}
            />
          </Grid>
          <Grid
            item
            xs={isTimeLimit ? 5 : 12}
            className={classes.formDatePickerContainer}
          >
            <DatePicker
              format="dd/MM/yyyy"
              label={t("開始日期 (日/月/年)")}
              onChange={(value) => onInputFieldChange("startDate", value)}
              slotProps={{
                textField: {
                  error: checkIsFieldError("startDate"),
                  helperText: getErrorFieldMessage("startDate"),
                  variant: "outlined",
                },
              }}
              value={startDateVar}
              views={["year", "month", "day"]}
            />
          </Grid>
          {isTimeLimit && (
            <>
              <Grid className={classes.formTwinInputContainerText} item xs={2}>
                <Typography align={"left"} variant="body1">
                  {t("至")}
                </Typography>
              </Grid>
              <Grid item xs={5} className={classes.formDatePickerContainer}>
                <DatePicker
                  format="dd/MM/yyyy"
                  label={t("結束日期 (日/月/年)")}
                  onChange={(value) => onInputFieldChange("endDate", value)}
                  slotProps={{
                    textField: {
                      error: checkIsFieldError("endDate"),
                      helperText: getErrorFieldMessage("endDate"),
                      variant: "outlined",
                    },
                  }}
                  value={endDateVar}
                  views={["year", "month", "day"]}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} className={classes.formSubmitBtnContainer}>
            <Button
              onClick={onSubmitBtnClicked}
              variant="contained"
              sx={formSubmitBtnStyles}
            >
              {t("確定")}
            </Button>
          </Grid>
          {/* Alert */}
          {shouldShowFormAlert && (
            <Grid item xs={12}>
              <Alert severity={formAlertType}>{formAlertText}</Alert>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
}

export default DiscountActionModalContent;
