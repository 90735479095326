// Actions
import { onAppliedDiscountChange } from "../../../redux/pages/salesRecordProfilePage/salesRecordProfilePageActions";

// Components
// Boxes
import AppliedDiscountBox from "./appliedDiscountBox";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { getAllAppliedDiscountsBySalesRecordIdFetch } from "../../../fetches/appliedDiscountFetches";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function AppliedDiscountBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const appliedDiscountId = useSelector(
    (state) => state.salesRecordProfilePage.appliedDiscountId
  );
  const formSubmitCount = useSelector(
    (state) => state.salesRecordProfilePage.formSubmitCount
  );
  const salesRecordId = useSelector(
    (state) => state.salesRecordProfilePage.salesRecordId
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [appliedDiscounts, setAppliedDiscounts] = useState(null);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);

  // Handle States
  const currentItemId = appliedDiscountId;
  const itemsArr = appliedDiscounts;

  // Events
  // Events - Box
  const onItemClicked = (
    itemId,
    itemDiscountId,
    itemDiscountOfferTypeNameEn
  ) => {
    // Update Redux Store
    if (currentItemId && itemId === currentItemId) {
      dispatch(onAppliedDiscountChange(null, null, null));
    } else {
      dispatch(
        onAppliedDiscountChange(
          itemId,
          itemDiscountId,
          itemDiscountOfferTypeNameEn === "Whole Order"
            ? "AppliedToSalesRecord"
            : "AppliedToMerchandise"
        )
      );
    }
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Functions
  // Functions - Queries
  const getAllAppliedDiscountsBySalesRecordId = async () => {
    const results = await getAllAppliedDiscountsBySalesRecordIdFetch(
      token,
      salesRecordId
    );

    // Set States
    setAppliedDiscounts(
      results.appliedDiscounts ? results.appliedDiscounts : null
    );
  };

  // Life Cycle
  useEffect(() => {
    if (salesRecordId) {
      getAllAppliedDiscountsBySalesRecordId();
    }
  }, [salesRecordId, formSubmitCount]);

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("已使用優惠s")}
          </Typography>
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        itemsArr.map((item) => (
          <AppliedDiscountBox
            key={item.id}
            // States
            currentItemId={currentItemId}
            hoveredItemId={hoveredItemId}
            item={item}
            // Events
            onItemClicked={onItemClicked}
            onMouseEnterItem={onMouseEnterItem}
            onMouseLeaveItem={onMouseLeaveItem}
          />
        ))
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有使用優惠")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default AppliedDiscountBoxesContainer;
