export function onConditionDiscountOfferTypeChange(
  conditionDiscountOfferTypeId
) {
  return {
    type: "ON_DISCOUNTS_PAGE_CONDITION_DISCOUNT_OFFER_TYPE_CHANGE",
    conditionDiscountOfferTypeId,
  };
}

export function onConditionDiscountOfferTypeItemChange(
  conditionDiscountOfferTypeItem
) {
  return {
    type: "ON_DISCOUNTS_PAGE_CONDITION_DISCOUNT_OFFER_TYPE_ITEM_CHANGE",
    conditionDiscountOfferTypeItem,
  };
}

export function onConditionDiscountStatusTypeChange(
  conditionDiscountStatusTypeId
) {
  return {
    type: "ON_DISCOUNTS_PAGE_CONDITION_DISCOUNT_STATUS_TYPE_CHANGE",
    conditionDiscountStatusTypeId,
  };
}

export function onConditionDiscountStatusTypeItemChange(
  conditionDiscountStatusTypeItem
) {
  return {
    type: "ON_DISCOUNTS_PAGE_CONDITION_DISCOUNT_STATUS_TYPE_ITEM_CHANGE",
    conditionDiscountStatusTypeItem,
  };
}

export function onFormSubmitCountIncrease() {
  return {
    type: "ON_DISCOUNTS_PAGE_FORM_SUBMIT_COUNT_INCREASE",
  };
}

export function onKeywordChange(keyword) {
  return {
    type: "ON_DISCOUNTS_PAGE_KEYWORD_CHANGE",
    keyword,
  };
}

export function onTabItemChange(tabItem) {
  return {
    type: "ON_DISCOUNTS_PAGE_TAB_ITEM_CHANGE",
    tabItem,
  };
}

export function onTypeOfDiscountsChange(typeOfDiscounts) {
  return {
    type: "ON_DISCOUNTS_PAGE_TYPE_OF_DISCOUNTS_CHANGE",
    typeOfDiscounts,
  };
}
