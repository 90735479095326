// Material UI
// Components
import Box from "@mui/material/Box";

function SpacingBox(props) {
  // Props
  const {
    // Render
    height,
  } = props;

  return <Box sx={{ height: height }} />;
}

export default SpacingBox;
