// Actions
import { onVariationChange } from "../../../redux/pages/stocksPage/stocksPageActions";

// Components
// Boxes
import RankedVariationBox from "./rankedVariationBox";

// Configs
import paginationConfig from "../../../configs/paginationConfig";
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { getRankedVariationStocksByMerchandiseIdWithPaginationFetch } from "../../../fetches/merchandiseStockFetches";

// Material UI
// Components
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

// Consts
const limitNum = paginationConfig.paginationLimitNumSmall;

function RankedVariationBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const formSubmitCount = useSelector(
    (state) => state.stocksPage.formSubmitCount
  );
  const merchandiseId = useSelector((state) => state.stocksPage.merchandiseId);
  const shopId = useSelector((state) => state.stocksPage.conditionShopId);
  const shopItem = useSelector((state) => state.stocksPage.conditionShopItem);
  const token = useSelector((state) => state.staff.token);
  const warehouseId = useSelector(
    (state) => state.stocksPage.conditionWarehouseId
  );
  const warehouseItem = useSelector(
    (state) => state.stocksPage.conditionWarehouseItem
  );
  const warehouseType = useSelector(
    (state) => state.stocksPage.conditionWarehouseType
  );
  const variationId = useSelector((state) => state.stocksPage.variationId);

  // States
  // Data
  const [rankedVariations, setRankedVariations] = useState(null);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Pagination
  const [pageCount, setPageCount] = useState(null);
  const [pageNum, setPageNum] = useState(1);

  // Handle States
  const currentItemId = variationId;
  const itemsArr = rankedVariations;
  const titleDisplay =
    warehouseType === "Shop" && shopItem
      ? shopItem.shop_code
      : warehouseType === "Warehouse" && warehouseItem
      ? warehouseItem.warehouse_name_en_short
      : "";

  // Events
  // Events - Box
  const onItemClicked = (itemId, itemAttributeText) => {
    // Update Redux Store
    if (currentItemId && itemId === currentItemId) {
      dispatch(onVariationChange(null, null));
    } else {
      dispatch(onVariationChange(itemId, itemAttributeText));
    }
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Pagination
  const onPageChange = (value) => {
    // Set States
    setPageNum(value);
  };

  // Functions
  // Functions - Queries
  const getRankedVariationStocksByMerchandiseIdWithPagination = async () => {
    const results =
      await getRankedVariationStocksByMerchandiseIdWithPaginationFetch(
        token,
        merchandiseId,
        warehouseType === "Shop" && shopId ? shopId : null,
        warehouseType === "Warehouse" && warehouseId ? warehouseId : null,
        pageNum,
        limitNum
      );

    // Set States
    setPageCount(results.pageCount ? results.pageCount : 0);
    setRankedVariations(
      results.rankedVariations ? results.rankedVariations : null
    );

    if (results.pageCount && !results.rankedVariations) {
      setPageNum(results.pageCount);
    }

    // Update Redux Store
    if (
      !(
        variationId &&
        results.rankedVariations &&
        results.rankedVariations.some((item) => item.id === variationId)
      )
    ) {
      dispatch(onVariationChange(null, null));
    }
  };

  // Life Cycle
  useEffect(() => {
    if (merchandiseId) {
      getRankedVariationStocksByMerchandiseIdWithPagination();
    }
  }, [
    merchandiseId,
    shopId,
    warehouseId,
    warehouseType,
    pageNum,
    formSubmitCount,
  ]);

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {titleDisplay
              ? `${t("商品選項庫存s")} - ${titleDisplay}`
              : t("商品選項庫存s")}
          </Typography>
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        <>
          {itemsArr.map((item) => (
            <RankedVariationBox
              key={item.id}
              // States
              currentItemId={currentItemId}
              hoveredItemId={hoveredItemId}
              item={item}
              // Events
              onItemClicked={onItemClicked}
              onMouseEnterItem={onMouseEnterItem}
              onMouseLeaveItem={onMouseLeaveItem}
            />
          ))}
          <Stack className={classes.paginationStack} spacing={2}>
            <Pagination
              color={"primary"}
              count={pageCount}
              onChange={(event, page) => onPageChange(page)}
              page={pageNum}
            />
          </Stack>
        </>
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有商品選項庫存")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default RankedVariationBoxesContainer;
