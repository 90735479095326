const today = new Date();

const initialState = {
  comparisonRecordDate: today.toString(),
  discountId: null,
  discountNumberType: "Revenue",
  formSubmitCount: 0,
  isComparisonShown: false,
  isMercGrpAvailable: false,
  mercGrpTypeId: null,
  mercGrpTypeItem: null,
  merchandiseGroupId: null,
  merchandiseGroupItem: null,
  recordDate: today.toString(),
  recordDateType: "Date",
  tabItem: "BasicInfo",
};

export const discountProfilePageReducers = (state = initialState, action) => {
  switch (action.type) {
    case "ON_DISCOUNT_PROFILE_PAGE_COMPARISON_RECORD_DATE_CHANGE":
      const { comparisonRecordDate } = action;
      return {
        ...state,
        comparisonRecordDate: comparisonRecordDate.toString(),
      };
    case "ON_DISCOUNT_PROFILE_PAGE_DISCOUNT_CHANGE":
      const { discountId } = action;
      return {
        ...state,
        discountId,
      };
    case "ON_DISCOUNT_PROFILE_PAGE_DISCOUNT_NUMBER_TYPE_CHANGE":
      const { discountNumberType } = action;
      return {
        ...state,
        discountNumberType,
      };
    case "ON_DISCOUNT_PROFILE_PAGE_FORM_SUBMIT_COUNT_INCREASE":
      return {
        ...state,
        formSubmitCount: state.formSubmitCount + 1,
      };
    case "ON_DISCOUNT_PROFILE_PAGE_IS_COMPARISON_CHANGE":
      const { isComparisonShown } = action;
      return {
        ...state,
        isComparisonShown,
      };
    case "ON_DISCOUNT_PROFILE_PAGE_IS_MERC_GRP_AVAILABLE_CHANGE":
      const { isMercGrpAvailable } = action;
      return {
        ...state,
        isMercGrpAvailable,
      };
    case "ON_DISCOUNT_PROFILE_PAGE_MERC_GRP_TYPE_CHANGE":
      const { mercGrpTypeId } = action;
      return {
        ...state,
        mercGrpTypeId,
      };
    case "ON_DISCOUNT_PROFILE_PAGE_MERC_GRP_TYPE_ITEM_CHANGE":
      const { mercGrpTypeItem } = action;
      return {
        ...state,
        mercGrpTypeItem,
      };
    case "ON_DISCOUNT_PROFILE_PAGE_MERCHANDISE_GROUP_CHANGE":
      const { merchandiseGroupId } = action;
      return {
        ...state,
        merchandiseGroupId,
      };
    case "ON_DISCOUNT_PROFILE_PAGE_MERCHANDISE_GROUP_ITEM_CHANGE":
      const { merchandiseGroupItem } = action;
      return {
        ...state,
        merchandiseGroupItem,
      };
    case "ON_DISCOUNT_PROFILE_PAGE_RECORD_DATE_CHANGE":
      const { recordDate } = action;
      return {
        ...state,
        recordDate: recordDate.toString(),
      };
    case "ON_DISCOUNT_PROFILE_PAGE_RECORD_DATE_TYPE_CHANGE":
      const { recordDateType } = action;
      return {
        ...state,
        recordDateType,
      };
    case "ON_DISCOUNT_PROFILE_PAGE_TAB_ITEM_CHANGE":
      const { tabItem } = action;
      return {
        ...state,
        tabItem,
      };
    default:
      return state;
  }
};
