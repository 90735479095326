// Actions
import {
  onConditionDiscountOfferTypeChange,
  onConditionDiscountOfferTypeItemChange,
  onConditionDiscountStatusTypeChange,
  onConditionDiscountStatusTypeItemChange,
  onKeywordChange,
} from "../../../redux/pages/discountsPage/discountsPageActions";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Dummy Datum
import { discountOfferTypeDummyData } from "../../../dummyDatum/discountOfferTypeDummyData";
import { discountStatusTypeDummyData } from "../../../dummyDatum/discountStatusTypeDummyData";

// Fetches
import { getAllDiscountOfferTypesFetch } from "../../../fetches/discountOfferTypeFetches";
import { getAllDiscountStatusTypesFetch } from "../../../fetches/discountStatusTypeFetches";

// Material UI
// Components
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function DiscountConditionBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const keyword = useSelector((state) => state.discountsPage.keyword);
  const discountOfferTypeId = useSelector(
    (state) => state.discountsPage.conditionDiscountOfferTypeId
  );
  const discountOfferTypeItem = useSelector(
    (state) => state.discountsPage.conditionDiscountOfferTypeItem
  );
  const discountStatusTypeId = useSelector(
    (state) => state.discountsPage.conditionDiscountStatusTypeId
  );
  const discountStatusTypeItem = useSelector(
    (state) => state.discountsPage.conditionDiscountStatusTypeItem
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [discountOfferTypes, setDiscountOfferTypes] = useState(null);
  const [discountStatusTypes, setDiscountStatusTypes] = useState(null);

  // Events
  // Events - Form
  const onInputFieldChange = (field, value) => {
    switch (field) {
      // Update Redux Store
      case "discountOfferType":
        if (value) {
          dispatch(onConditionDiscountOfferTypeChange(value.id));
        }
        break;
      case "discountStatusType":
        if (value) {
          dispatch(onConditionDiscountStatusTypeChange(value.id));
        }
        break;
      case "keyword":
        dispatch(onKeywordChange(value));
        break;
      default:
        break;
    }
  };

  // Functions
  // Functions - Normal
  const getAllConditions = () => {
    getAllDiscountOfferTypes();
    getAllDiscountStatusTypes();
  };

  // Functions - Queries
  const getAllDiscountOfferTypes = async () => {
    const results = await getAllDiscountOfferTypesFetch(token);

    // Set States
    setDiscountOfferTypes(
      results.discountOfferTypes
        ? [discountOfferTypeDummyData, ...results.discountOfferTypes]
        : null
    );

    // Update Redux Store
    if (results.discountOfferTypes && !discountOfferTypeId) {
      dispatch(
        onConditionDiscountOfferTypeChange(discountOfferTypeDummyData.id)
      );
    }
  };

  const getAllDiscountStatusTypes = async () => {
    const results = await getAllDiscountStatusTypesFetch(token);

    // Set States
    setDiscountStatusTypes(
      results.discountStatusTypes
        ? [discountStatusTypeDummyData, ...results.discountStatusTypes]
        : null
    );

    // Update Redux Store
    if (results.discountStatusTypes && !discountStatusTypeId) {
      dispatch(
        onConditionDiscountStatusTypeChange(discountStatusTypeDummyData.id)
      );
    }
  };

  // Life Cycle
  useEffect(() => {
    getAllConditions();
  }, []);

  useEffect(() => {
    // Update Redux Store
    if (discountOfferTypes && discountOfferTypeId) {
      dispatch(
        onConditionDiscountOfferTypeItemChange(
          discountOfferTypes.find((item) => item.id === discountOfferTypeId)
        )
      );
    }
  }, [discountOfferTypes, discountOfferTypeId]);

  useEffect(() => {
    // Update Redux Store
    if (discountStatusTypes && discountStatusTypeId) {
      dispatch(
        onConditionDiscountStatusTypeItemChange(
          discountStatusTypes.find((item) => item.id === discountStatusTypeId)
        )
      );
    }
  }, [discountStatusTypes, discountStatusTypeId]);

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("搜尋優惠")}
          </Typography>
        </div>
      </div>
      {/* Text Field */}
      <div className={classes.autoCompleteContainer}>
        <TextField
          autoFocus
          fullWidth
          label={t("關鍵字")}
          margin="dense"
          name="keyword"
          onChange={(event) =>
            onInputFieldChange("keyword", event.target.value)
          }
          required
          value={keyword}
          variant="standard"
        />
      </div>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("優惠方式")}
          </Typography>
        </div>
      </div>
      {/* Select Box */}
      {discountOfferTypes && (
        <div className={classes.autoCompleteContainer}>
          <Autocomplete
            disablePortal
            fullWidth
            getOptionLabel={(option) =>
              option[t("discount_offer_type_name_ch")]
            }
            onChange={(event, value) =>
              onInputFieldChange("discountOfferType", value)
            }
            options={discountOfferTypes}
            renderInput={(params) => (
              <TextField {...params} label={t("優惠方式")} variant="outlined" />
            )}
            value={discountOfferTypeItem}
          />
        </div>
      )}
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("優惠狀態")}
          </Typography>
        </div>
      </div>
      {/* Select Box */}
      {discountStatusTypes && (
        <div className={classes.autoCompleteContainer}>
          <Autocomplete
            disablePortal
            fullWidth
            getOptionLabel={(option) =>
              option[t("discount_status_type_name_ch")]
            }
            onChange={(event, value) =>
              onInputFieldChange("discountStatusType", value)
            }
            options={discountStatusTypes}
            renderInput={(params) => (
              <TextField {...params} label={t("優惠狀態")} variant="outlined" />
            )}
            value={discountStatusTypeItem}
          />
        </div>
      )}
    </div>
  );
}

export default DiscountConditionBoxesContainer;
