// Configs
import apisConfig from "../configs/apisConfig";

export const autoLoginFetch = async (token) => {
  try {
    const queryRoute = "/staff/autoLogin";

    const reqBody = {
      permissionName: "Sign In To Retail POS Management System",
      isWeb: true,
      deviceToken: null,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllStaffsByShopIdFetch = async (token, shopId) => {
  try {
    const queryRoute = `/staff/getAllStaffsByShopId/${shopId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const loginFetch = async (fullNameOrStaffCode, password) => {
  try {
    const queryRoute = "/staff/login";

    const reqBody = {
      fullNameOrStaffCode,
      password,
      permissionName: "Sign In To Retail POS Management System",
      isWeb: true,
      deviceToken: null,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
