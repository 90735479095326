// Actions
import { closeDrawer } from "../../redux/shared/drawer/drawerActions";

// Components
// List Item
import LeftDrawerList from "./leftDrawerList";

// Material UI
// Components
import Drawer from "@mui/material/Drawer";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { drawerTempStyles } from "../../styles/componentStyles/drawerStyles/leftDrawerStyles";

function LeftDrawerTemp() {
  // Hooks
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const drawerAnchor = useSelector((state) => state.drawer.drawerAnchor);
  const isDrawerOpen = useSelector((state) => state.drawer.isDrawerOpen);

  // Events
  // Events - Drawer
  const onDrawerClosed = () => {
    // Update Redux Store
    dispatch(closeDrawer());
  };

  return (
    <Drawer
      anchor={drawerAnchor}
      onClose={onDrawerClosed}
      open={isDrawerOpen}
      sx={drawerTempStyles}
      variant="temporary"
    >
      <LeftDrawerList />
    </Drawer>
  );
}

export default LeftDrawerTemp;
