// Actions
import { onAgeGroupChange } from "../../../redux/pages/ageGroupsPage/ageGroupsPageActions";

// Components
// Boxes
import AgeGroupBox from "./ageGroupBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Menus
import AgeGroupActionMenu from "../../menus/ageGroupsPage/ageGroupActionMenu";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  deleteOrRestoreAgeGroupFetch,
  getAllAgeGroupsFetch,
} from "../../../fetches/ageGroupFetches";

// Material UI
// Components
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import AddRoundedIcon from "@mui/icons-material/AddRounded";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function AgeGroupBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const ageGroupId = useSelector((state) => state.ageGroupsPage.ageGroupId);
  const formSubmitCount = useSelector(
    (state) => state.ageGroupsPage.formSubmitCount
  );
  const token = useSelector((state) => state.staff.token);
  const typeOfAgeGroups = useSelector(
    (state) => state.ageGroupsPage.typeOfAgeGroups
  );

  // States
  // Data
  const [ageGroups, setAgeGroups] = useState(null);
  // Dialog
  const [ageGroupActionDialogText, setAgeGroupActionDialogText] = useState("");
  const [ageGroupActionDialogType, setAgeGroupActionDialogType] =
    useState(null);
  const [showAgeGroupActionDialog, setShowAgeGroupActionDialog] =
    useState(false);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Menu
  const [showAgeGroupActionMenu, setShowAgeGroupActionMenu] = useState(null);

  // Handle States
  const currentItemId = ageGroupId;
  const itemsArr = ageGroups;

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Update Redux Store
    dispatch(onAgeGroupChange(itemId));
  };

  const onItemRightClicked = (itemId, currentTarget) => {
    onItemClicked(itemId);
    // Set States
    setShowAgeGroupActionMenu(currentTarget);
  };

  const onItemShowMenuBtnClicked = (itemId, currentTarget) => {
    onItemClicked(itemId);
    // Set States
    setShowAgeGroupActionMenu(currentTarget);
  };

  // Events - Dialogs
  const onAgeGroupActionDialogCanceled = () => {
    // Set States
    setShowAgeGroupActionDialog(false);
  };

  const onAgeGroupActionDialogConfirmed = () => {
    switch (ageGroupActionDialogType) {
      case "DeleteAgeGroup":
        deleteOrRestoreAgeGroup();
        break;
      case "RestoreAgeGroup":
        deleteOrRestoreAgeGroup();
        break;
      default:
        break;
    }

    // Set States
    setShowAgeGroupActionDialog(false);
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Title
  const onAddItemBtnClicked = () => {
    // Update Redux Store
    dispatch(onAgeGroupChange(null));
  };

  // Functions
  // Functions - Normal
  const displayAgeGroupActionDialog = (ageGroupActionType) => {
    // Set States
    setAgeGroupActionDialogType(ageGroupActionType);

    switch (ageGroupActionType) {
      case "DeleteAgeGroup":
        setAgeGroupActionDialogText(t("確認要刪除 年齡層 嗎？"));
        break;
      case "RestoreAgeGroup":
        setAgeGroupActionDialogText(t("確認要還原 年齡層 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowAgeGroupActionDialog(true);
  };

  // Functions - Mutations
  const deleteOrRestoreAgeGroup = async () => {
    const results = await deleteOrRestoreAgeGroupFetch(
      token,
      currentItemId,
      typeOfAgeGroups
    );

    if (results.success) {
      getAllAgeGroups(true);
    }
  };

  // Functions - Queries
  const getAllAgeGroups = async (shoulddUpdateCurrentAgeGroupId) => {
    const results = await getAllAgeGroupsFetch(token, typeOfAgeGroups);

    // Set States
    setAgeGroups(results.ageGroups ? results.ageGroups : null);

    // Update Redux Store
    if (
      results.ageGroups &&
      (shoulddUpdateCurrentAgeGroupId ||
        !results.ageGroups.some((item) => item.id === ageGroupId))
    ) {
      dispatch(onAgeGroupChange(results.ageGroups[0].id));
    }
  };

  // Life Cycle
  useEffect(() => {
    getAllAgeGroups();
  }, [formSubmitCount, typeOfAgeGroups]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onAgeGroupActionDialogCanceled}
        onDialogConfirmed={onAgeGroupActionDialogConfirmed}
        // States
        dialogText={ageGroupActionDialogText}
        showDialog={showAgeGroupActionDialog}
      />
      {/* Menu */}
      <AgeGroupActionMenu
        // States
        showAgeGroupActionMenu={showAgeGroupActionMenu}
        // Set States
        setShowAgeGroupActionMenu={setShowAgeGroupActionMenu}
        // Fucntions
        displayAgeGroupActionDialog={displayAgeGroupActionDialog}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("年齡層s")}
          </Typography>
          <Tooltip placement="right" title={t("新增年齡層")}>
            <IconButton color={"primary"} onClick={onAddItemBtnClicked}>
              <AddRoundedIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        itemsArr.map((item) => (
          <AgeGroupBox
            key={item.id}
            // States
            currentItemId={currentItemId}
            hoveredItemId={hoveredItemId}
            item={item}
            // Events
            onItemClicked={onItemClicked}
            onItemRightClicked={onItemRightClicked}
            onItemShowMenuBtnClicked={onItemShowMenuBtnClicked}
            onMouseEnterItem={onMouseEnterItem}
            onMouseLeaveItem={onMouseLeaveItem}
          />
        ))
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有年齡層資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default AgeGroupBoxesContainer;
