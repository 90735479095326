// Actions
import {
  onMerchandiseGroupChange,
  onFormSubmitCountIncrease,
} from "../../../redux/pages/discountProfilePage/discountProfilePageActions";

// Components
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Forms
import EmptyMerchandiseGroupDetailFormContent from "./emptyMerchandiseGroupDetailFormContent";
import MerchandiseGroupDetailFormContent from "./merchandiseGroupDetailFormContent";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { getMerchandiseGroupDetailFormOptionsFetch } from "../../../fetches/formOptionFetches";
import {
  createMerchandiseGroupFetch,
  editMerchandiseGroupFetch,
  getMerchandiseGroupDetailsByIdFetch,
} from "../../../fetches/merchandiseGroupFetches";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

function MerchandiseGroupDetailFormContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const discountId = useSelector(
    (state) => state.discountProfilePage.discountId
  );
  const isMercGrpAvailable = useSelector(
    (state) => state.discountProfilePage.isMercGrpAvailable
  );
  const mercGrpTypeId = useSelector(
    (state) => state.discountProfilePage.mercGrpTypeId
  );
  const merchandiseGroupId = useSelector(
    (state) => state.discountProfilePage.merchandiseGroupId
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Dialog
  const [
    merchandiseGroupDetailActionDialogText,
    setMerchandiseGroupDetailActionDialogText,
  ] = useState("");
  const [
    merchandiseGroupDetailActionDialogType,
    setMerchandiseGroupDetailActionDialogType,
  ] = useState(null);
  const [
    showMerchandiseGroupDetailAction,
    setShowMerchandiseGroupDetailAction,
  ] = useState(false);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Option Fields
  const [ageGroupField, setAgeGroupField] = useState([]);
  const [brandField, setBrandField] = useState([]);
  const [categoryField, setCategoryField] = useState([]);
  const [genderTypeField, setGenderTypeField] = useState([]);
  const [mercGrpAgeTypeField, setMercGrpAgeTypeField] = useState(null);
  const [mercGrpBrdTypeField, setMercGrpBrdTypeField] = useState(null);
  const [mercGrpCatTypeField, setMercGrpCatTypeField] = useState(null);
  const [mercGrpGenTypeField, setMercGrpGenTypeField] = useState(null);
  const [subCategoryField, setSubCategoryField] = useState([]);
  // Options
  const [ageGroupOptions, setAgeGroupOptions] = useState([]);
  const [brandOptions, setBrandOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [genderTypeOptions, setGenderTypeOptions] = useState([]);
  const [mercGrpAgeTypeOptions, setMercGrpAgeTypeOptions] = useState([]);
  const [mercGrpBrdTypeOptions, setMercGrpBrdTypeOptions] = useState([]);
  const [mercGrpCatTypeOptions, setMercGrpCatTypeOptions] = useState([]);
  const [mercGrpGenTypeOptions, setMercGrpGenTypeOptions] = useState([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  // Render
  const [isOptionsRetrieved, setIsOptionsRetrieved] = useState(false);

  // Events
  // Events - Dialogs
  const onMerchandiseGroupDetailActionCanceled = () => {
    // Set States
    setShowMerchandiseGroupDetailAction(false);
  };

  const onMerchandiseGroupDetailActionConfirmed = () => {
    switch (merchandiseGroupDetailActionDialogType) {
      case "CreateMerchandiseGroup":
        createMerchandiseGroup();
        break;
      case "EditMerchandiseGroup":
        editMerchandiseGroup();
        break;
      default:
        break;
    }

    // Set States
    setShowMerchandiseGroupDetailAction(false);
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "ageGroupField":
        setAgeGroupField(value);
        break;
      case "brandField":
        setBrandField(value);
        break;
      case "categoryField":
        setCategoryField(value);
        break;
      case "genderTypeField":
        setGenderTypeField(value);
        break;
      case "mercGrpAgeTypeField":
        setMercGrpAgeTypeField(value);

        if (value.merc_grp_age_type_name_en === "All") {
          setAgeGroupField([]);

          removeErrorField("ageGroupField");
        }

        break;
      case "mercGrpBrdTypeField":
        setMercGrpBrdTypeField(value);

        if (value.merc_grp_brd_type_name_en === "All") {
          setBrandField([]);

          removeErrorField("brandField");
        }

        break;
      case "mercGrpCatTypeField":
        setMercGrpCatTypeField(value);

        switch (value.merc_grp_cat_type_name_en) {
          case "Specific Category":
            setCategoryField([]);

            removeErrorField("categoryField");
            break;
          case "Specific Merchandise":
            setAgeGroupField([]);
            setBrandField([]);
            setCategoryField([]);
            setGenderTypeField([]);
            setMercGrpAgeTypeField(mercGrpAgeTypeOptions[0]);
            setMercGrpBrdTypeField(mercGrpBrdTypeOptions[0]);
            setMercGrpGenTypeField(mercGrpGenTypeOptions[0]);
            setSubCategoryField([]);

            clearErrorFields();
            break;
          case "Specific Sub Category":
            setSubCategoryField([]);

            removeErrorField("subCategoryField");
            break;
          default:
            break;
        }

        break;
      case "mercGrpGenTypeField":
        setMercGrpGenTypeField(value);

        if (value.merc_grp_gen_type_name_en === "All") {
          setGenderTypeField([]);

          removeErrorField("genderTypeField");
        }

        break;
      case "subCategoryField":
        setSubCategoryField(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked();
    }
  };

  // Events - Forms
  const onSubmitBtnClicked = () => {
    let isError = false;

    if (!mercGrpAgeTypeField) {
      addToErrorFields("mercGrpAgeTypeField", t("請先填寫 年齡層類別"));
      isError = true;
    } else {
      if (mercGrpAgeTypeField.merc_grp_age_type_name_en !== "All") {
        if (!ageGroupField[0]) {
          addToErrorFields("ageGroupField", t("請先填寫 年齡層"));
          isError = true;
        }
      }
    }

    if (!mercGrpBrdTypeField) {
      addToErrorFields("mercGrpBrdTypeField", t("請先填寫 品牌類別"));
      isError = true;
    } else {
      if (mercGrpBrdTypeField.merc_grp_brd_type_name_en !== "All") {
        if (!brandField[0]) {
          addToErrorFields("brandField", t("請先填寫 品牌"));
          isError = true;
        }
      }
    }

    if (!mercGrpCatTypeField) {
      addToErrorFields("mercGrpCatTypeField", t("請先填寫 商品分類類別"));
      isError = true;
    } else {
      if (
        mercGrpCatTypeField.merc_grp_cat_type_name_en === "Specific Category"
      ) {
        if (!categoryField[0]) {
          addToErrorFields("categoryField", t("請先填寫 商品類別"));
          isError = true;
        }
      } else if (
        mercGrpCatTypeField.merc_grp_cat_type_name_en ===
        "Specific Sub Category"
      ) {
        if (!subCategoryField[0]) {
          addToErrorFields("subCategoryField", t("請先填寫 商品子類別"));
          isError = true;
        }
      }
    }

    if (!mercGrpGenTypeField) {
      addToErrorFields("mercGrpGenTypeField", t("請先填寫 適用性別類別"));
      isError = true;
    } else {
      if (mercGrpGenTypeField.merc_grp_gen_type_name_en !== "All") {
        if (!genderTypeField[0]) {
          addToErrorFields("genderTypeField", t("請先填寫 適用性別"));
          isError = true;
        }
      }
    }

    if (isError) {
      return;
    }

    displayMerchandiseGroupDetailActionDialog(
      merchandiseGroupId ? "EditMerchandiseGroup" : "CreateMerchandiseGroup"
    );
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const clearErrorFields = () => {
    // Set States
    setErrorFields([]);
  };

  const clearForm = () => {
    // Set States
    setMercGrpBrdTypeField(null);
    setMercGrpCatTypeField(null);
    setMercGrpGenTypeField(null);
    setMercGrpAgeTypeField(null);
    setAgeGroupField([]);
    setBrandField([]);
    setCategoryField([]);
    setGenderTypeField([]);
    setSubCategoryField([]);

    clearErrorFields();
  };

  const displayMerchandiseGroupDetailActionDialog = (
    merchandiseGroupDetailActionType
  ) => {
    // Set States
    setMerchandiseGroupDetailActionDialogType(merchandiseGroupDetailActionType);
    switch (merchandiseGroupDetailActionType) {
      case "CreateMerchandiseGroup":
        setMerchandiseGroupDetailActionDialogText(
          t("確認要新增 商品群組 嗎？")
        );
        break;
      case "EditMerchandiseGroup":
        setMerchandiseGroupDetailActionDialogText(
          t("確認要編輯 商品群組資料 嗎？")
        );
        break;
      default:
        break;
    }

    // Set States
    setShowMerchandiseGroupDetailAction(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const createMerchandiseGroup = async () => {
    const results = await createMerchandiseGroupFetch(
      token,
      discountId,
      mercGrpTypeId,
      mercGrpBrdTypeField ? mercGrpBrdTypeField.id : null,
      mercGrpCatTypeField ? mercGrpCatTypeField.id : null,
      mercGrpGenTypeField ? mercGrpGenTypeField.id : null,
      mercGrpAgeTypeField ? mercGrpAgeTypeField.id : null,
      ageGroupField[0] ? ageGroupField.map((item) => item.id) : null,
      brandField[0] ? brandField.map((item) => item.id) : null,
      categoryField[0] ? categoryField.map((item) => item.id) : null,
      genderTypeField[0] ? genderTypeField.map((item) => item.id) : null,
      subCategoryField[0] ? subCategoryField.map((item) => item.id) : null
    );

    if (results.success) {
      showFormAlert("success", t("成功提交"));

      // Update Redux Store
      dispatch(onMerchandiseGroupChange(results.merchandiseGroupId));
      dispatch(onFormSubmitCountIncrease());
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  const editMerchandiseGroup = async () => {
    if (merchandiseGroupId) {
      const results = await editMerchandiseGroupFetch(
        token,
        merchandiseGroupId,
        mercGrpTypeId,
        mercGrpBrdTypeField ? mercGrpBrdTypeField.id : null,
        mercGrpCatTypeField ? mercGrpCatTypeField.id : null,
        mercGrpGenTypeField ? mercGrpGenTypeField.id : null,
        mercGrpAgeTypeField ? mercGrpAgeTypeField.id : null,
        ageGroupField[0] ? ageGroupField.map((item) => item.id) : null,
        brandField[0] ? brandField.map((item) => item.id) : null,
        categoryField[0] ? categoryField.map((item) => item.id) : null,
        genderTypeField[0] ? genderTypeField.map((item) => item.id) : null,
        subCategoryField[0] ? subCategoryField.map((item) => item.id) : null
      );

      if (results.success) {
        showFormAlert("success", t("成功提交"));

        // Update Redux Store
        dispatch(onFormSubmitCountIncrease());
      } else {
        showFormAlert("error", t("未能提交"));
      }
    }
  };

  // Functions - Queries
  const getMerchandiseGroupDetailFormOptions = async () => {
    const results = await getMerchandiseGroupDetailFormOptionsFetch(token);

    // Set States
    setAgeGroupOptions(results.ageGroups ? results.ageGroups : []);
    setBrandOptions(results.brands ? results.brands : []);
    setCategoryOptions(results.categories ? results.categories : []);
    setGenderTypeOptions(results.genderTypes ? results.genderTypes : []);
    setMercGrpAgeTypeOptions(
      results.mercGrpAgeTypes ? results.mercGrpAgeTypes : []
    );
    setMercGrpBrdTypeOptions(
      results.mercGrpBrdTypes ? results.mercGrpBrdTypes : []
    );
    setMercGrpCatTypeOptions(
      results.mercGrpCatTypes ? results.mercGrpCatTypes : []
    );
    setMercGrpGenTypeOptions(
      results.mercGrpGenTypes ? results.mercGrpGenTypes : []
    );
    setSubCategoryOptions(results.subCategories ? results.subCategories : []);
  };

  const getMerchandiseGroupDetailsById = async () => {
    const results = await getMerchandiseGroupDetailsByIdFetch(
      token,
      merchandiseGroupId
    );

    if (results.merchandiseGroupDetails) {
      const {
        merc_grp_brd_type_id,
        merc_grp_cat_type_id,
        merc_grp_gen_type_id,
        merc_grp_age_type_id,
        dependencies,
      } = results.merchandiseGroupDetails;

      const { ageGroups, brands, categories, genderTypes, subCategories } =
        dependencies;

      // Set States
      setMercGrpBrdTypeField(
        merc_grp_brd_type_id
          ? mercGrpBrdTypeOptions.find(
              (item) => item.id === merc_grp_brd_type_id
            )
          : null
      );
      setMercGrpCatTypeField(
        merc_grp_cat_type_id
          ? mercGrpCatTypeOptions.find(
              (item) => item.id === merc_grp_cat_type_id
            )
          : null
      );
      setMercGrpGenTypeField(
        merc_grp_gen_type_id
          ? mercGrpGenTypeOptions.find(
              (item) => item.id === merc_grp_gen_type_id
            )
          : null
      );
      setMercGrpAgeTypeField(
        merc_grp_age_type_id
          ? mercGrpAgeTypeOptions.find(
              (item) => item.id === merc_grp_age_type_id
            )
          : null
      );

      if (ageGroups) {
        let ageGroupsArr = [];

        for (let ageGroupItem of ageGroups) {
          if (ageGroupOptions.find((item) => item.id === ageGroupItem.id)) {
            ageGroupsArr.push(
              ageGroupOptions.find((item) => item.id === ageGroupItem.id)
            );
          }
        }

        setAgeGroupField(ageGroupsArr);
      } else {
        setAgeGroupField([]);
      }

      if (brands) {
        let brandsArr = [];

        for (let brandItem of brands) {
          if (brandOptions.find((item) => item.id === brandItem.id)) {
            brandsArr.push(
              brandOptions.find((item) => item.id === brandItem.id)
            );
          }
        }

        setBrandField(brandsArr);
      } else {
        setBrandField([]);
      }

      if (categories) {
        let categoriesArr = [];

        for (let categoryItem of categories) {
          if (categoryOptions.find((item) => item.id === categoryItem.id)) {
            categoriesArr.push(
              categoryOptions.find((item) => item.id === categoryItem.id)
            );
          }
        }

        setCategoryField(categoriesArr);
      } else {
        setCategoryField([]);
      }

      if (genderTypes) {
        let genderTypesArr = [];

        for (let genderTypeItem of genderTypes) {
          if (genderTypeOptions.find((item) => item.id === genderTypeItem.id)) {
            genderTypesArr.push(
              genderTypeOptions.find((item) => item.id === genderTypeItem.id)
            );
          }
        }

        setGenderTypeField(genderTypesArr);
      } else {
        setGenderTypeField([]);
      }

      if (subCategories) {
        let subCategoriesArr = [];

        for (let subCategoryItem of subCategories) {
          if (
            subCategoryOptions.find((item) => item.id === subCategoryItem.id)
          ) {
            subCategoriesArr.push(
              subCategoryOptions.find((item) => item.id === subCategoryItem.id)
            );
          }
        }

        setSubCategoryField(subCategoriesArr);
      } else {
        setSubCategoryField([]);
      }
    } else {
      // Set States
      setMercGrpBrdTypeField(null);
      setMercGrpCatTypeField(null);
      setMercGrpGenTypeField(null);
      setMercGrpAgeTypeField(null);
      setAgeGroupField([]);
      setBrandField([]);
      setCategoryField([]);
      setGenderTypeField([]);
      setSubCategoryField([]);
    }

    clearErrorFields();
  };

  // Life Cycle
  useEffect(() => {
    getMerchandiseGroupDetailFormOptions();
  }, []);

  useEffect(() => {
    // Set States
    if (!isOptionsRetrieved && ageGroupOptions[0]) {
      setIsOptionsRetrieved(true);
    }
  }, [ageGroupOptions]);

  useEffect(() => {
    if (isOptionsRetrieved) {
      if (merchandiseGroupId) {
        getMerchandiseGroupDetailsById();
      } else {
        clearForm();
      }
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [isOptionsRetrieved, merchandiseGroupId]);

  return (
    <>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onMerchandiseGroupDetailActionCanceled}
        onDialogConfirmed={onMerchandiseGroupDetailActionConfirmed}
        // States
        dialogText={merchandiseGroupDetailActionDialogText}
        showDialog={showMerchandiseGroupDetailAction}
      />
      {isMercGrpAvailable ? (
        <MerchandiseGroupDetailFormContent
          // States
          ageGroupField={ageGroupField}
          ageGroupOptions={ageGroupOptions}
          brandField={brandField}
          brandOptions={brandOptions}
          categoryField={categoryField}
          categoryOptions={categoryOptions}
          formAlertText={formAlertText}
          formAlertType={formAlertType}
          genderTypeField={genderTypeField}
          genderTypeOptions={genderTypeOptions}
          mercGrpAgeTypeField={mercGrpAgeTypeField}
          mercGrpAgeTypeOptions={mercGrpAgeTypeOptions}
          mercGrpBrdTypeField={mercGrpBrdTypeField}
          mercGrpBrdTypeOptions={mercGrpBrdTypeOptions}
          mercGrpCatTypeField={mercGrpCatTypeField}
          mercGrpCatTypeOptions={mercGrpCatTypeOptions}
          mercGrpGenTypeField={mercGrpGenTypeField}
          mercGrpGenTypeOptions={mercGrpGenTypeOptions}
          merchandiseGroupId={merchandiseGroupId}
          shouldShowFormAlert={shouldShowFormAlert}
          subCategoryField={subCategoryField}
          subCategoryOptions={subCategoryOptions}
          // Events
          onInputFieldChange={onInputFieldChange}
          onInputFieldKeyPressed={onInputFieldKeyPressed}
          onSubmitBtnClicked={onSubmitBtnClicked}
          // Functions
          checkIsFieldError={checkIsFieldError}
          getErrorFieldMessage={getErrorFieldMessage}
        />
      ) : (
        <EmptyMerchandiseGroupDetailFormContent />
      )}
    </>
  );
}

export default MerchandiseGroupDetailFormContainer;
