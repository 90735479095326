// Actions
import { onFormSubmitCountIncrease } from "../../../redux/pages/merchandisesPage/merchandisesPageActions";

// Components
// Boxes
import MerchandisePhotoBox from "./merchandisePhotoBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";

// Configs
import fileConfig from "../../../configs/fileConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  deleteMerchandisePhotoFetch,
  editMerchandisePhotoFetch,
  getMerchandiseDetailsByIdFetch,
} from "../../../fetches/merchandiseFetches";

// Helper Functions
import { checkFileSize } from "../../../helperFunctions/file";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

function MerchandisePhotoBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const merchandiseId = useSelector(
    (state) => state.merchandisesPage.merchandiseId
  );
  const formSubmitCount = useSelector(
    (state) => state.merchandisesPage.formSubmitCount
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [merchandiseDetails, setMerchandiseDetails] = useState(null);
  // Dialog
  const [merchandiseActionDialogText, setMerchandiseActionDialogText] =
    useState("");
  const [merchandiseActionDialogType, setMerchandiseActionDialogType] =
    useState(null);
  const [showMerchandiseActionDialog, setShowMerchandiseActionDialog] =
    useState(false);

  // Events
  // Events - Dialogs
  const onMerchandiseActionDialogCanceled = () => {
    // Set States
    setShowMerchandiseActionDialog(false);
  };

  const onMerchandiseActionDialogConfirmed = () => {
    switch (merchandiseActionDialogType) {
      case "DeleteMerchandisePhoto":
        deleteMerchandisePhoto();
        break;
      default:
        break;
    }

    // Set States
    setShowMerchandiseActionDialog(false);
  };

  // Events - Fields
  const onFileUploaded = (value) => {
    // Check File Size
    const maxSize = fileConfig.applicationMaxFileUploadSize;
    const isFileSizeWithinLimit = checkFileSize([value], maxSize, "Mb");

    if (!isFileSizeWithinLimit) {
      displayMerchandiseActionDialog("ExceedMaxFileUploadSize", maxSize);
      return;
    }

    editMerchandisePhoto(value);
  };

  // Events - Title
  const onDeletePhotoBtnClicked = () => {
    displayMerchandiseActionDialog("DeleteMerchandisePhoto");
  };

  // Functions
  // Functions - Normal
  const clearForm = () => {
    // Set States
    setMerchandiseDetails(null);
  };

  const displayMerchandiseActionDialog = (fileActionType, alertValue) => {
    // Set States
    setMerchandiseActionDialogType(fileActionType);

    switch (fileActionType) {
      case "DeleteMerchandisePhoto":
        setMerchandiseActionDialogText(t("確認要刪除 圖片 嗎？"));
        break;
      case "ExceedMaxFileUploadSize":
        setMerchandiseActionDialogText(`${t("檔案不可超過")} ${alertValue} Mb`);
        break;
      case "UploadFail":
        setMerchandiseActionDialogText(t("未能上載，請再試一次"));
        break;
      default:
        break;
    }

    // Set States
    setShowMerchandiseActionDialog(true);
  };

  // Functions - Mutations
  const deleteMerchandisePhoto = async () => {
    const results = await deleteMerchandisePhotoFetch(token, merchandiseId);

    if (results.success) {
      // Update Redux Store
      dispatch(onFormSubmitCountIncrease());
    } else {
      displayMerchandiseActionDialog("UploadFail");
    }
  };

  const editMerchandisePhoto = async (photo) => {
    const results = await editMerchandisePhotoFetch(
      token,
      merchandiseId,
      photo
    );

    if (results.success) {
      // Update Redux Store
      dispatch(onFormSubmitCountIncrease());
    } else {
      displayMerchandiseActionDialog("UploadFail");
    }
  };

  // Functions - Queries
  const getMerchandiseDetailsById = async () => {
    const results = await getMerchandiseDetailsByIdFetch(token, merchandiseId);

    // Set States
    setMerchandiseDetails(
      results.merchandiseDetails ? results.merchandiseDetails : null
    );
  };

  // Life Cycle
  useEffect(() => {
    if (merchandiseId) {
      getMerchandiseDetailsById();
    } else {
      clearForm();
    }
  }, [merchandiseId, formSubmitCount]);

  return (
    <>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onMerchandiseActionDialogCanceled}
        onDialogConfirmed={onMerchandiseActionDialogConfirmed}
        // States
        dialogText={merchandiseActionDialogText}
        showDialog={showMerchandiseActionDialog}
      />
      {/* Content */}
      {merchandiseDetails && (
        <MerchandisePhotoBox
          // Events
          onDeletePhotoBtnClicked={onDeletePhotoBtnClicked}
          onFileUploaded={onFileUploaded}
          // States
          item={merchandiseDetails}
        />
      )}
    </>
  );
}

export default MerchandisePhotoBoxesContainer;
