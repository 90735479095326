// Components
import StyledMenu from "../styledMenu";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import MenuItem from "@mui/material/MenuItem";
// Icons
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import RestoreRoundedIcon from "@mui/icons-material/RestoreRounded";

// React-Redux
import { useSelector } from "react-redux";

function AttributeActionMenu(props) {
  // Hooks
  // Languages
  const t = useLanguage();

  // Props
  const {
    // States
    showAttributeActionMenu,
    // Set States
    setShowAttributeActionMenu,
    // Functions
    displayAttributeActionDialog,
  } = props;

  // Redux Store
  const typeOfAttributes = useSelector(
    (state) => state.merchandisesPage.typeOfAttributes
  );

  // Events
  const onMenuClosed = () => {
    // Set States
    setShowAttributeActionMenu(null);
  };

  const onMenuItemClicked = (key) => {
    onMenuClosed();

    switch (key) {
      case "Delete":
        displayAttributeActionDialog("DeleteAttribute");
        break;
      case "Restore":
        displayAttributeActionDialog("RestoreAttribute");
        break;
      default:
        break;
    }
  };

  return (
    <StyledMenu
      // States
      anchorEl={showAttributeActionMenu}
      open={Boolean(showAttributeActionMenu)}
      // Events
      onClose={onMenuClosed}
    >
      {typeOfAttributes === "Current" ? (
        <MenuItem disableRipple onClick={() => onMenuItemClicked("Delete")}>
          <DeleteRoundedIcon />
          {t("刪除")}
        </MenuItem>
      ) : (
        <MenuItem disableRipple onClick={() => onMenuItemClicked("Restore")}>
          <RestoreRoundedIcon />
          {t("還原")}
        </MenuItem>
      )}
    </StyledMenu>
  );
}

export default AttributeActionMenu;
