// Consts
import { languageOptions } from "../../../consts/languageConsts";

const initialState = {
  language: languageOptions[0].value,
};

export const languageReducers = (state = initialState, action) => {
  switch (action.type) {
    case "ON_LANGUAGE_CHANGE":
      const { language } = action;
      return {
        ...state,
        language,
      };
    default:
      return state;
  }
};
