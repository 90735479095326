// Configs
import apisConfig from "../configs/apisConfig";

export const getAllMerchandiseSoldsBySalesRecordIdWithPaginationFetch = async (
  token,
  salesRecordId,
  appliedDiscountId,
  pageNum,
  limitNum
) => {
  try {
    const queryRoute = `/merchandiseSold/getAllMerchandiseSoldsBySalesRecordIdWithPagination/${salesRecordId}`;

    const reqBody = { appliedDiscountId, pageNum, limitNum };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
