// Components
// Boxes
import SalesRecordDiscountItemBox from "./salesRecordDiscountItemBox";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { getAllAppliedDiscountItemsBySalesRecordIdFetch } from "../../../fetches/appliedDiscountItemFetches";

// Helper Functions
import { separateComma } from "../../../helperFunctions/separateComma";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function SalesRecordDiscountItemBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const formSubmitCount = useSelector(
    (state) => state.salesRecordProfilePage.formSubmitCount
  );
  const salesRecordId = useSelector(
    (state) => state.salesRecordProfilePage.salesRecordId
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [appliedDiscountItems, setAppliedDiscountItems] = useState(null);

  // Handle States
  const itemsArr = appliedDiscountItems;

  // Functions
  // Functions - Queries
  const getAllAppliedDiscountItemsBySalesRecordId = async () => {
    const results = await getAllAppliedDiscountItemsBySalesRecordIdFetch(
      token,
      salesRecordId
    );

    if (results.appliedDiscountItems) {
      for (let item of results.appliedDiscountItems) {
        item.discount_amount_display = item.discount_amount
          ? `$ ${separateComma(Number(item.discount_amount).toFixed(1))}`
          : null;
      }
    }

    // Set States
    setAppliedDiscountItems(
      results.appliedDiscountItems ? results.appliedDiscountItems : null
    );
  };

  // Life Cycle
  useEffect(() => {
    if (salesRecordId) {
      getAllAppliedDiscountItemsBySalesRecordId();
    }
  }, [salesRecordId, formSubmitCount]);

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("已應用優惠s")}
          </Typography>
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        itemsArr.map((item) => (
          <SalesRecordDiscountItemBox
            key={item.id}
            // States
            item={item}
          />
        ))
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有應用優惠")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default SalesRecordDiscountItemBoxesContainer;
