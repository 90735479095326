// Actions
import { onMerchandiseChange } from "../../../redux/pages/stocksPage/stocksPageActions";

// Components
// Boxes
import RankedMerchandiseBox from "./rankedMerchandiseBox";

// Configs
import paginationConfig from "../../../configs/paginationConfig";
import stylesConfig from "../../../configs/stylesConfig";
import timeoutConfig from "../../../configs/timeoutConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Debounce
import debounce from "lodash.debounce";

// Fetches
import { getRankedMerchandiseStocksWithPaginationFetch } from "../../../fetches/merchandiseStockFetches";

// Material UI
// Components
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

// Consts
const limitNum = paginationConfig.paginationLimitNum;

function RankedMerchandiseBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const brandId = useSelector((state) => state.stocksPage.conditionBrandId);
  const categoryId = useSelector(
    (state) => state.stocksPage.conditionCategoryId
  );
  const formSubmitCount = useSelector(
    (state) => state.stocksPage.formSubmitCount
  );
  const keyword = useSelector((state) => state.stocksPage.keyword);
  const merchandiseId = useSelector((state) => state.stocksPage.merchandiseId);
  const shopId = useSelector((state) => state.stocksPage.conditionShopId);
  const shopItem = useSelector((state) => state.stocksPage.conditionShopItem);
  const subCategoryId = useSelector(
    (state) => state.stocksPage.conditionSubCategoryId
  );
  const token = useSelector((state) => state.staff.token);
  const warehouseId = useSelector(
    (state) => state.stocksPage.conditionWarehouseId
  );
  const warehouseItem = useSelector(
    (state) => state.stocksPage.conditionWarehouseItem
  );
  const warehouseType = useSelector(
    (state) => state.stocksPage.conditionWarehouseType
  );

  // States
  // Data
  const [rankedMerchandises, setRankedMerchandises] = useState(null);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Pagination
  const [pageCount, setPageCount] = useState(null);
  const [pageNum, setPageNum] = useState(1);

  // Handle States
  const currentItemId = merchandiseId;
  const itemsArr = rankedMerchandises;
  const titleDisplay =
    warehouseType === "Shop" && shopItem
      ? shopItem.shop_code
      : warehouseType === "Warehouse" && warehouseItem
      ? warehouseItem.warehouse_name_en_short
      : "";

  // Events
  // Events - Box
  const onItemClicked = (itemId, itemMerchandiseCode) => {
    // Update Redux Store
    dispatch(onMerchandiseChange(itemId, itemMerchandiseCode));
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Pagination
  const onPageChange = (value) => {
    // Set States
    setPageNum(value);
  };

  // Functions
  // Functions - Normal
  const getRankedMerchandiseStocksDebounce = debounce((keywordStr) => {
    getRankedMerchandiseStocksWithPagination(keywordStr);
  }, timeoutConfig.debouceTime);

  // Functions - Queries
  const getRankedMerchandiseStocksWithPagination = async (keywordStr) => {
    const results = await getRankedMerchandiseStocksWithPaginationFetch(
      token,
      categoryId !== "dummy" ? categoryId : null,
      subCategoryId !== "dummy" ? subCategoryId : null,
      brandId !== "dummy" ? brandId : null,
      keywordStr,
      warehouseType === "Shop" && shopId ? shopId : null,
      warehouseType === "Warehouse" && warehouseId ? warehouseId : null,
      pageNum,
      limitNum
    );

    // Set States
    setPageCount(results.pageCount ? results.pageCount : 0);
    setRankedMerchandises(
      results.rankedMerchandises ? results.rankedMerchandises : null
    );

    if (results.pageCount && !results.rankedMerchandises) {
      setPageNum(results.pageCount);
    }

    // Update Redux Store
    if (results.rankedMerchandises) {
      if (
        !(
          merchandiseId &&
          results.rankedMerchandises.some((item) => item.id === merchandiseId)
        )
      ) {
        dispatch(
          onMerchandiseChange(
            results.rankedMerchandises[0].id,
            results.rankedMerchandises[0].merchandise_code
          )
        );
      }
    } else {
      dispatch(onMerchandiseChange(null, null));
    }
  };

  // Life Cycle
  useEffect(() => {
    getRankedMerchandiseStocksDebounce(keyword);
  }, [keyword]);

  useEffect(() => {
    getRankedMerchandiseStocksWithPagination(keyword);
  }, [
    brandId,
    categoryId,
    shopId,
    subCategoryId,
    warehouseId,
    warehouseType,
    pageNum,
    formSubmitCount,
  ]);

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {titleDisplay
              ? `${t("商品庫存s")} - ${titleDisplay}`
              : t("商品庫存s")}
          </Typography>
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        <>
          {itemsArr.map((item) => (
            <RankedMerchandiseBox
              key={item.id}
              // States
              currentItemId={currentItemId}
              hoveredItemId={hoveredItemId}
              item={item}
              // Events
              onItemClicked={onItemClicked}
              onMouseEnterItem={onMouseEnterItem}
              onMouseLeaveItem={onMouseLeaveItem}
            />
          ))}
          <Stack className={classes.paginationStack} spacing={2}>
            <Pagination
              color={"primary"}
              count={pageCount}
              onChange={(event, page) => onPageChange(page)}
              page={pageNum}
            />
          </Stack>
        </>
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有商品庫存")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default RankedMerchandiseBoxesContainer;
