// Actions
import { onSubCategoryChange } from "../../../redux/pages/categoriesAndBrandsPage/categoriesAndBrandsPageActions";

// Components
// Boxes
import SubCategoryBox from "./subCategoryBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Menus
import SubCategoryActionMenu from "../../menus/categoriesAndBrandsPage/subCategoryActionMenu";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  deleteOrRestoreSubCategoryFetch,
  getAllSubCategoriesByCategoryIdFetch,
} from "../../../fetches/subCategoryFetches";

// Material UI
// Components
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import AddRoundedIcon from "@mui/icons-material/AddRounded";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function SubCategoryBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const categoryId = useSelector(
    (state) => state.categoriesAndBrandsPage.categoryId
  );
  const formSubmitCount = useSelector(
    (state) => state.categoriesAndBrandsPage.formSubmitCount
  );
  const subCategoryId = useSelector(
    (state) => state.categoriesAndBrandsPage.subCategoryId
  );
  const token = useSelector((state) => state.staff.token);
  const typeOfSubCategories = useSelector(
    (state) => state.categoriesAndBrandsPage.typeOfSubCategories
  );

  // States
  // Data
  const [subCategories, setSubCategories] = useState(null);
  // Dialog
  const [subCategoryActionDialogText, setSubCategoryActionDialogText] =
    useState("");
  const [subCategoryActionDialogType, setSubCategoryActionDialogType] =
    useState(null);
  const [showSubCategoryActionDialog, setShowSubCategoryActionDialog] =
    useState(false);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Menu
  const [showSubCategoryActionMenu, setShowSubCategoryActionMenu] =
    useState(null);

  // Handle States
  const currentItemId = subCategoryId;
  const itemsArr = subCategories;

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Update Redux Store
    dispatch(onSubCategoryChange(itemId));
  };

  const onItemRightClicked = (itemId, currentTarget) => {
    onItemClicked(itemId);
    // Set States
    setShowSubCategoryActionMenu(currentTarget);
  };

  const onItemShowMenuBtnClicked = (itemId, currentTarget) => {
    onItemClicked(itemId);
    // Set States
    setShowSubCategoryActionMenu(currentTarget);
  };

  // Events - Dialogs
  const onSubCategoryActionDialogCanceled = () => {
    // Set States
    setShowSubCategoryActionDialog(false);
  };

  const onSubCategoryActionDialogConfirmed = () => {
    switch (subCategoryActionDialogType) {
      case "DeleteSubCategory":
        deleteOrRestoreSubCategory();
        break;
      case "RestoreSubCategory":
        deleteOrRestoreSubCategory();
        break;
      default:
        break;
    }

    // Set States
    setShowSubCategoryActionDialog(false);
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Title
  const onAddItemBtnClicked = () => {
    // Update Redux Store
    dispatch(onSubCategoryChange(null));
  };

  // Functions
  // Functions - Normal
  const displaySubCategoryActionDialog = (subCategoryActionType) => {
    // Set States
    setSubCategoryActionDialogType(subCategoryActionType);

    switch (subCategoryActionType) {
      case "DeleteSubCategory":
        setSubCategoryActionDialogText(t("確認要刪除 子類別 嗎？"));
        break;
      case "RestoreSubCategory":
        setSubCategoryActionDialogText(t("確認要還原 子類別 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowSubCategoryActionDialog(true);
  };

  // Functions - Mutations
  const deleteOrRestoreSubCategory = async () => {
    const results = await deleteOrRestoreSubCategoryFetch(
      token,
      currentItemId,
      typeOfSubCategories
    );

    if (results.success) {
      getAllSubCategoriesByCategoryId(true);
    }
  };

  // Functions - Queries
  const getAllSubCategoriesByCategoryId = async (
    shoulddUpdateCurrentSubCategoryId
  ) => {
    const results = await getAllSubCategoriesByCategoryIdFetch(
      token,
      categoryId,
      typeOfSubCategories
    );

    // Set States
    setSubCategories(results.subCategories ? results.subCategories : null);

    // Update Redux Store
    if (
      results.subCategories &&
      (shoulddUpdateCurrentSubCategoryId ||
        !results.subCategories.some((item) => item.id === subCategoryId))
    ) {
      dispatch(
        onSubCategoryChange(
          results.subCategories ? results.subCategories[0].id : null
        )
      );
    }
  };

  // Life Cycle
  useEffect(() => {
    if (categoryId) {
      getAllSubCategoriesByCategoryId(subCategoryId ? false : true);
    }
  }, [categoryId, formSubmitCount, typeOfSubCategories]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onSubCategoryActionDialogCanceled}
        onDialogConfirmed={onSubCategoryActionDialogConfirmed}
        // States
        dialogText={subCategoryActionDialogText}
        showDialog={showSubCategoryActionDialog}
      />
      {/* Menu */}
      <SubCategoryActionMenu
        // States
        showSubCategoryActionMenu={showSubCategoryActionMenu}
        // Set States
        setShowSubCategoryActionMenu={setShowSubCategoryActionMenu}
        // Fucntions
        displaySubCategoryActionDialog={displaySubCategoryActionDialog}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("子類別s")}
          </Typography>
          <Tooltip placement="right" title={t("新增子類別")}>
            <IconButton color={"primary"} onClick={onAddItemBtnClicked}>
              <AddRoundedIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        itemsArr.map((item) => (
          <SubCategoryBox
            key={item.id}
            // States
            currentItemId={currentItemId}
            hoveredItemId={hoveredItemId}
            item={item}
            // Events
            onItemClicked={onItemClicked}
            onItemRightClicked={onItemRightClicked}
            onItemShowMenuBtnClicked={onItemShowMenuBtnClicked}
            onMouseEnterItem={onMouseEnterItem}
            onMouseLeaveItem={onMouseLeaveItem}
          />
        ))
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有子類別資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default SubCategoryBoxesContainer;
