// Actions
import { onAttributeChange } from "../../../redux/pages/merchandisesPage/merchandisesPageActions";

// Components
// Boxes
import AttributeBox from "./attributeBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Menus
import AttributeActionMenu from "../../menus/merchandisesPage/attributeActionMenu";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  deleteOrRestoreAttributeFetch,
  getAllAttributesByPropertyIdFetch,
} from "../../../fetches/attributeFetches";

// Material UI
// Components
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import AddRoundedIcon from "@mui/icons-material/AddRounded";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function AttributeBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const attributeId = useSelector(
    (state) => state.merchandisesPage.attributeId
  );
  const formSubmitCount = useSelector(
    (state) => state.merchandisesPage.formSubmitCount
  );
  const propertyId = useSelector((state) => state.merchandisesPage.propertyId);
  const token = useSelector((state) => state.staff.token);
  const typeOfAttributes = useSelector(
    (state) => state.merchandisesPage.typeOfAttributes
  );

  // States
  // Data
  const [attributes, setAttributes] = useState(null);
  // Dialog
  const [attributeActionDialogText, setAttributeActionDialogText] =
    useState("");
  const [attributeActionDialogType, setAttributeActionDialogType] =
    useState(null);
  const [showAttributeActionDialog, setShowAttributeActionDialog] =
    useState(false);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Menu
  const [showAttributeActionMenu, setShowAttributeActionMenu] = useState(null);

  // Handle States
  const currentItemId = attributeId;
  const itemsArr = attributes;

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Update Redux Store
    dispatch(onAttributeChange(itemId));
  };

  const onItemRightClicked = (itemId, currentTarget) => {
    onItemClicked(itemId);
    // Set States
    setShowAttributeActionMenu(currentTarget);
  };

  const onItemShowMenuBtnClicked = (itemId, currentTarget) => {
    onItemClicked(itemId);
    // Set States
    setShowAttributeActionMenu(currentTarget);
  };

  // Events - Dialogs
  const onAttributeActionDialogCanceled = () => {
    // Set States
    setShowAttributeActionDialog(false);
  };

  const onAttributeActionDialogConfirmed = () => {
    switch (attributeActionDialogType) {
      case "DeleteAttribute":
        deleteOrRestoreAttribute();
        break;
      case "RestoreAttribute":
        deleteOrRestoreAttribute();
        break;
      default:
        break;
    }

    // Set States
    setShowAttributeActionDialog(false);
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Title
  const onAddItemBtnClicked = () => {
    // Update Redux Store
    dispatch(onAttributeChange(null));
  };

  // Functions
  // Functions - Normal
  const displayAttributeActionDialog = (attributeActionType) => {
    // Set States
    setAttributeActionDialogType(attributeActionType);

    switch (attributeActionType) {
      case "DeleteAttribute":
        setAttributeActionDialogText(t("確認要刪除 選項 嗎？"));
        break;
      case "RestoreAttribute":
        setAttributeActionDialogText(t("確認要還原 選項 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowAttributeActionDialog(true);
  };

  // Functions - Mutations
  const deleteOrRestoreAttribute = async () => {
    const results = await deleteOrRestoreAttributeFetch(
      token,
      currentItemId,
      typeOfAttributes
    );

    if (results.success) {
      getAllAttributesByPropertyId(true);
    }
  };

  // Functions - Queries
  const getAllAttributesByPropertyId = async (
    shoulddUpdateCurrentAttributeId
  ) => {
    const results = await getAllAttributesByPropertyIdFetch(
      token,
      propertyId,
      typeOfAttributes
    );

    // Set States
    setAttributes(results.attributes ? results.attributes : null);

    // Update Redux Store
    if (
      results.attributes &&
      (shoulddUpdateCurrentAttributeId ||
        !results.attributes.some((item) => item.id === attributeId))
    ) {
      dispatch(
        onAttributeChange(results.attributes ? results.attributes[0].id : null)
      );
    }
  };

  // Life Cycle
  useEffect(() => {
    if (propertyId) {
      getAllAttributesByPropertyId(attributeId ? false : true);
    }
  }, [propertyId, formSubmitCount, typeOfAttributes]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onAttributeActionDialogCanceled}
        onDialogConfirmed={onAttributeActionDialogConfirmed}
        // States
        dialogText={attributeActionDialogText}
        showDialog={showAttributeActionDialog}
      />
      {/* Menu */}
      <AttributeActionMenu
        // States
        showAttributeActionMenu={showAttributeActionMenu}
        // Set States
        setShowAttributeActionMenu={setShowAttributeActionMenu}
        // Fucntions
        displayAttributeActionDialog={displayAttributeActionDialog}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("選項s")}
          </Typography>
          <Tooltip placement="right" title={t("新增選項")}>
            <IconButton color={"primary"} onClick={onAddItemBtnClicked}>
              <AddRoundedIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        itemsArr.map((item) => (
          <AttributeBox
            key={item.id}
            // States
            currentItemId={currentItemId}
            hoveredItemId={hoveredItemId}
            item={item}
            // Events
            onItemClicked={onItemClicked}
            onItemRightClicked={onItemRightClicked}
            onItemShowMenuBtnClicked={onItemShowMenuBtnClicked}
            onMouseEnterItem={onMouseEnterItem}
            onMouseLeaveItem={onMouseLeaveItem}
          />
        ))
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有選項資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default AttributeBoxesContainer;
