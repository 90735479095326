// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// Styles
import { useStandardItemBoxStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxStyles";

function SalesRecordBox(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxStyles();

  // Props
  const {
    // States
    hoveredItemId,
    item,
    // Events
    onItemClicked,
    onMouseEnterItem,
    onMouseLeaveItem,
  } = props;

  // Handle States
  const isHovered = hoveredItemId && hoveredItemId === item.id;
  const salespersonItem = item.salespersonBasicInfo
    ? item.salespersonBasicInfo
    : null;

  return (
    <div
      className={
        isHovered ? classes.hoveredItemContainer : classes.itemContainer
      }
      onClick={() => onItemClicked(item.id)}
      onMouseEnter={() => onMouseEnterItem(item.id)}
      onMouseLeave={onMouseLeaveItem}
    >
      <div className={classes.itemContentSubContainerLarge}>
        <Typography
          align={"left"}
          color={isHovered ? stylesConfig.mainTextColor : null}
          variant="body1"
        >
          {item.sales_record_code}
        </Typography>
        <Typography
          align={"left"}
          color={stylesConfig.greyTextColor}
          variant="body2"
        >
          {item.time_created}
        </Typography>
      </div>
      <div className={classes.itemContentSubContainerLarge}>
        <Typography
          align={"left"}
          color={isHovered ? stylesConfig.mainTextColor : null}
          variant="body1"
        >
          {`${salespersonItem.staff_code} - ${salespersonItem.full_name_en}`}
        </Typography>
        <Typography
          align={"left"}
          color={stylesConfig.greyTextColor}
          variant="body2"
        >
          {`${salespersonItem.dept_name_display} - `}
          {salespersonItem[t("title_name_ch")]}
        </Typography>
      </div>
      <div className={classes.itemContentSubContainer}>
        <Typography
          align={"left"}
          color={isHovered ? stylesConfig.mainTextColor : null}
          variant="body1"
        >
          {`${item.numOfMerchandiseSolds} ${t("件商品")}`}
        </Typography>
      </div>
      <div className={classes.itemContentSubContainerRight}>
        <Typography
          align={"left"}
          color={isHovered ? stylesConfig.mainTextColor : null}
          variant="body1"
        >
          {item.total_price}
        </Typography>
        <Typography
          align={"left"}
          color={stylesConfig.greyTextColor}
          variant="body2"
        >
          {item[t("payment_method_name_ch")]}
        </Typography>
      </div>
    </div>
  );
}

export default SalesRecordBox;
