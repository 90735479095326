export function onAttributeChange(attributeId) {
  return {
    type: "ON_MERCHANDISES_PAGE_ATTRIBUTE_CHANGE",
    attributeId,
  };
}

export function onConditionAgeGroupChange(conditionAgeGroupId) {
  return {
    type: "ON_MERCHANDISES_PAGE_CONDITION_AGE_GROUP_CHANGE",
    conditionAgeGroupId,
  };
}

export function onConditionAgeGroupItemChange(conditionAgeGroupItem) {
  return {
    type: "ON_MERCHANDISES_PAGE_CONDITION_AGE_GROUP_ITEM_CHANGE",
    conditionAgeGroupItem,
  };
}

export function onConditionBrandChange(conditionBrandId) {
  return {
    type: "ON_MERCHANDISES_PAGE_CONDITION_BRAND_CHANGE",
    conditionBrandId,
  };
}

export function onConditionBrandItemChange(conditionBrandItem) {
  return {
    type: "ON_MERCHANDISES_PAGE_CONDITION_BRAND_ITEM_CHANGE",
    conditionBrandItem,
  };
}

export function onConditionCategoryChange(conditionCategoryId) {
  return {
    type: "ON_MERCHANDISES_PAGE_CONDITION_CATEGORY_CHANGE",
    conditionCategoryId,
  };
}

export function onConditionCategoryItemChange(conditionCategoryItem) {
  return {
    type: "ON_MERCHANDISES_PAGE_CONDITION_CATEGORY_ITEM_CHANGE",
    conditionCategoryItem,
  };
}

export function onConditionGenderTypeChange(conditionGenderTypeId) {
  return {
    type: "ON_MERCHANDISES_PAGE_CONDITION_GENDER_TYPE_CHANGE",
    conditionGenderTypeId,
  };
}

export function onConditionGenderTypeItemChange(conditionGenderTypeItem) {
  return {
    type: "ON_MERCHANDISES_PAGE_CONDITION_GENDER_TYPE_ITEM_CHANGE",
    conditionGenderTypeItem,
  };
}

export function onConditionSubCategoryChange(conditionSubCategoryId) {
  return {
    type: "ON_MERCHANDISES_PAGE_CONDITION_SUB_CATEGORY_CHANGE",
    conditionSubCategoryId,
  };
}

export function onConditionSubCategoryItemChange(conditionSubCategoryItem) {
  return {
    type: "ON_MERCHANDISES_PAGE_CONDITION_SUB_CATEGORY_ITEM_CHANGE",
    conditionSubCategoryItem,
  };
}

export function onFormSubmitCountIncrease() {
  return {
    type: "ON_MERCHANDISES_PAGE_FORM_SUBMIT_COUNT_INCREASE",
  };
}

export function onKeywordChange(keyword) {
  return {
    type: "ON_MERCHANDISES_PAGE_KEYWORD_CHANGE",
    keyword,
  };
}

export function onMerchandiseChange(merchandiseId) {
  return {
    type: "ON_MERCHANDISES_PAGE_MERCHANDISE_CHANGE",
    merchandiseId,
  };
}

export function onPropertyChange(propertyId) {
  return {
    type: "ON_MERCHANDISES_PAGE_PROPERTY_CHANGE",
    propertyId,
  };
}

export function onTabItemChange(tabItem) {
  return {
    type: "ON_MERCHANDISES_PAGE_TAB_ITEM_CHANGE",
    tabItem,
  };
}

export function onTypeOfAttributesChange(typeOfAttributes) {
  return {
    type: "ON_MERCHANDISES_PAGE_TYPE_OF_ATTRIBUTES_CHANGE",
    typeOfAttributes,
  };
}

export function onTypeOfContentChange(typeOfContent) {
  return {
    type: "ON_MERCHANDISES_PAGE_TYPE_OF_CONTENT_CHANGE",
    typeOfContent,
  };
}

export function onTypeOfMerchandisesChange(typeOfMerchandises) {
  return {
    type: "ON_MERCHANDISES_PAGE_TYPE_OF_MERCHANDISES_CHANGE",
    typeOfMerchandises,
  };
}

export function onTypeOfPropertiesChange(typeOfProperties) {
  return {
    type: "ON_MERCHANDISES_PAGE_TYPE_OF_PROPERTIES_CHANGE",
    typeOfProperties,
  };
}
