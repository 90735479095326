const initialState = {
  attributeId: null,
  conditionAgeGroupId: null,
  conditionAgeGroupItem: null,
  conditionBrandId: null,
  conditionBrandItem: null,
  conditionCategoryId: null,
  conditionCategoryItem: null,
  conditionGenderTypeId: null,
  conditionGenderTypeItem: null,
  conditionSubCategoryId: null,
  conditionSubCategoryItem: null,
  formSubmitCount: 0,
  keyword: "",
  merchandiseId: null,
  propertyId: null,
  tabItem: "MerchandiseList",
  typeOfAttributes: "Current",
  typeOfContent: "AttributeList",
  typeOfMerchandises: "Current",
  typeOfProperties: "Current",
};

export const merchandisesPageReducers = (state = initialState, action) => {
  switch (action.type) {
    case "ON_MERCHANDISES_PAGE_ATTRIBUTE_CHANGE":
      const { attributeId } = action;
      return {
        ...state,
        attributeId,
      };
    case "ON_MERCHANDISES_PAGE_CONDITION_AGE_GROUP_CHANGE":
      const { conditionAgeGroupId } = action;
      return {
        ...state,
        conditionAgeGroupId,
      };
    case "ON_MERCHANDISES_PAGE_CONDITION_AGE_GROUP_ITEM_CHANGE":
      const { conditionAgeGroupItem } = action;
      return {
        ...state,
        conditionAgeGroupItem,
      };
    case "ON_MERCHANDISES_PAGE_CONDITION_BRAND_CHANGE":
      const { conditionBrandId } = action;
      return {
        ...state,
        conditionBrandId,
      };
    case "ON_MERCHANDISES_PAGE_CONDITION_BRAND_ITEM_CHANGE":
      const { conditionBrandItem } = action;
      return {
        ...state,
        conditionBrandItem,
      };
    case "ON_MERCHANDISES_PAGE_CONDITION_CATEGORY_CHANGE":
      const { conditionCategoryId } = action;
      return {
        ...state,
        conditionCategoryId,
      };
    case "ON_MERCHANDISES_PAGE_CONDITION_CATEGORY_ITEM_CHANGE":
      const { conditionCategoryItem } = action;
      return {
        ...state,
        conditionCategoryItem,
      };
    case "ON_MERCHANDISES_PAGE_CONDITION_GENDER_TYPE_CHANGE":
      const { conditionGenderTypeId } = action;
      return {
        ...state,
        conditionGenderTypeId,
      };
    case "ON_MERCHANDISES_PAGE_CONDITION_GENDER_TYPE_ITEM_CHANGE":
      const { conditionGenderTypeItem } = action;
      return {
        ...state,
        conditionGenderTypeItem,
      };
    case "ON_MERCHANDISES_PAGE_CONDITION_SUB_CATEGORY_CHANGE":
      const { conditionSubCategoryId } = action;
      return {
        ...state,
        conditionSubCategoryId,
      };
    case "ON_MERCHANDISES_PAGE_CONDITION_SUB_CATEGORY_ITEM_CHANGE":
      const { conditionSubCategoryItem } = action;
      return {
        ...state,
        conditionSubCategoryItem,
      };
    case "ON_MERCHANDISES_PAGE_FORM_SUBMIT_COUNT_INCREASE":
      return {
        ...state,
        formSubmitCount: state.formSubmitCount + 1,
      };
    case "ON_MERCHANDISES_PAGE_KEYWORD_CHANGE":
      const { keyword } = action;
      return {
        ...state,
        keyword,
      };
    case "ON_MERCHANDISES_PAGE_MERCHANDISE_CHANGE":
      const { merchandiseId } = action;
      return {
        ...state,
        merchandiseId,
      };
    case "ON_MERCHANDISES_PAGE_PROPERTY_CHANGE":
      const { propertyId } = action;
      return {
        ...state,
        propertyId,
      };
    case "ON_MERCHANDISES_PAGE_TAB_ITEM_CHANGE":
      const { tabItem } = action;
      return {
        ...state,
        tabItem,
      };
    case "ON_MERCHANDISES_PAGE_TYPE_OF_ATTRIBUTES_CHANGE":
      const { typeOfAttributes } = action;
      return {
        ...state,
        typeOfAttributes,
      };
    case "ON_MERCHANDISES_PAGE_TYPE_OF_CONTENT_CHANGE":
      const { typeOfContent } = action;
      return {
        ...state,
        typeOfContent,
      };
    case "ON_MERCHANDISES_PAGE_TYPE_OF_MERCHANDISES_CHANGE":
      const { typeOfMerchandises } = action;
      return {
        ...state,
        typeOfMerchandises,
      };
    case "ON_MERCHANDISES_PAGE_TYPE_OF_PROPERTIES_CHANGE":
      const { typeOfProperties } = action;
      return {
        ...state,
        typeOfProperties,
      };
    default:
      return state;
  }
};
