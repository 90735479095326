// Actions
import { closeDrawer } from "../../redux/shared/drawer/drawerActions";

// Components
// List Item
import LeftDrawerListItem from "./leftDrawerListItem";

// Configs
import stylesConfig from "../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../customHooks/getLanguage";

// Helper Functions
import { getPagePath } from "../../helperFunctions/getPagePath";

// Material UI
// Components
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
// Icons
import AssignmentIcon from "@mui/icons-material/Assignment";
import BarChartIcon from "@mui/icons-material/BarChart";
import DiscountRoundedIcon from "@mui/icons-material/DiscountRounded";
import Diversity3RoundedIcon from "@mui/icons-material/Diversity3Rounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import Inventory2RoundedIcon from "@mui/icons-material/Inventory2Rounded";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import ShoppingBagRoundedIcon from "@mui/icons-material/ShoppingBagRounded";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

// React-Redux
import { useDispatch } from "react-redux";

// React-Router
import { useLocation, useNavigate } from "react-router-dom";

// Styles
import { dividerStyles } from "../../styles/componentStyles/drawerStyles/leftDrawerStyles";

function LeftDrawerList() {
  // Hooks
  // Languages
  const t = useLanguage();
  // React-Router
  const location = useLocation();
  const navigate = useNavigate();
  // Redux
  const dispatch = useDispatch();

  // Events
  // Events - Drawer Items
  const onDrawerItemClicked = (key) => {
    const path = getPagePath(key);

    // Navigate
    navigate(path);

    // Update Redux Store
    dispatch(closeDrawer());
  };

  return (
    <List>
      <ListItem sx={{ height: stylesConfig.leftDrawerHeight }}>
        <ListItemText primary={t("零售銷售管理系統")} />
      </ListItem>
      <Divider sx={dividerStyles} />
      {/* 主頁 */}
      <LeftDrawerListItem
        // Render
        icon={<HomeRoundedIcon />}
        itemKey={"Dashboard"}
        location={location}
        pathnamesArr={["/dashboard"]}
        listItemText={t("主頁")}
        // Events
        onDrawerItemClicked={onDrawerItemClicked}
      />
      {/* 銷售報告 */}
      <LeftDrawerListItem
        // Render
        icon={<PointOfSaleIcon />}
        itemKey={"SalesReports"}
        location={location}
        pathnamesArr={["/salesReports"]}
        listItemText={t("銷售報告s")}
        // Events
        onDrawerItemClicked={onDrawerItemClicked}
      />
      {/* 商品銷量 */}
      <LeftDrawerListItem
        // Render
        icon={<BarChartIcon />}
        itemKey={"MerchandiseSales"}
        location={location}
        pathnamesArr={["/merchandiseSales"]}
        listItemText={t("商品銷量s")}
        // Events
        onDrawerItemClicked={onDrawerItemClicked}
      />
      {/* 優惠 */}
      <LeftDrawerListItem
        // Render
        icon={<DiscountRoundedIcon />}
        itemKey={"Discounts"}
        location={location}
        pathnamesArr={["/discounts", "/discountProfile"]}
        listItemText={t("優惠s")}
        // Events
        onDrawerItemClicked={onDrawerItemClicked}
      />
      {/* 銷售紀錄 */}
      <LeftDrawerListItem
        // Render
        icon={<AssignmentIcon />}
        itemKey={"SalesRecords"}
        location={location}
        pathnamesArr={["/salesRecords", "/salesRecordProfile"]}
        listItemText={t("銷售紀錄s")}
        // Events
        onDrawerItemClicked={onDrawerItemClicked}
      />
      {/* 庫存 */}
      <LeftDrawerListItem
        // Render
        icon={<Inventory2RoundedIcon />}
        itemKey={"Stocks"}
        location={location}
        pathnamesArr={["/stocks"]}
        listItemText={t("庫存s")}
        // Events
        onDrawerItemClicked={onDrawerItemClicked}
      />
      {/* 商品 */}
      <LeftDrawerListItem
        // Render
        icon={<ShoppingCartIcon />}
        itemKey={"Merchandises"}
        location={location}
        pathnamesArr={["/merchandises"]}
        listItemText={t("商品s")}
        // Events
        onDrawerItemClicked={onDrawerItemClicked}
      />
      {/* 類別 / 品牌 */}
      <LeftDrawerListItem
        // Render
        icon={<ShoppingBagRoundedIcon />}
        itemKey={"CategoriesAndBrands"}
        location={location}
        pathnamesArr={["/categoriesAndBrands"]}
        listItemText={t("類別 / 品牌s")}
        // Events
        onDrawerItemClicked={onDrawerItemClicked}
      />
      {/* 年齡層 */}
      <LeftDrawerListItem
        // Render
        icon={<Diversity3RoundedIcon />}
        itemKey={"AgeGroups"}
        location={location}
        pathnamesArr={["/ageGroups"]}
        listItemText={t("年齡層s")}
        // Events
        onDrawerItemClicked={onDrawerItemClicked}
      />
    </List>
  );
}

export default LeftDrawerList;
