// Configs
import stylesConfig from "../../../configs/stylesConfig";

// sx Styles
export const dividerStyles = { marginBottom: "10px", marginTop: "10px" };

export const drawerPermStyles = {
  display: { xs: "none", xl: "block" },
  flexShrink: 0,
  width: stylesConfig.leftDrawerWidth,
  "& .MuiDrawer-paper": {
    backgroundColor: stylesConfig.backgroundColor,
    border: "none",
    boxSizing: "border-box",
    paddingLeft: stylesConfig.leftDrawerItemPadding,
    paddingRight: stylesConfig.leftDrawerItemPadding,
    width: stylesConfig.leftDrawerWidth,
  },
};

export const drawerTempStyles = {
  display: { xs: "block", xl: "none" },
  flexShrink: 0,
  width: stylesConfig.leftDrawerWidth,
  "& .MuiDrawer-paper": {
    backgroundColor: stylesConfig.backgroundColor,
    border: "none",
    boxSizing: "border-box",
    paddingLeft: stylesConfig.leftDrawerItemPadding,
    paddingRight: stylesConfig.leftDrawerItemPadding,
    width: stylesConfig.leftDrawerWidth,
  },
};

export const listItemStyles = {
  borderRadius: stylesConfig.borderRadiusTiny,
};

export const selectedListItemStyles = {
  borderRadius: stylesConfig.borderRadiusTiny,
};
