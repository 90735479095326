// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Helper Functions
import { getFileURL } from "../../../helperFunctions/file";

// File Uplaoder
import { FileUploader } from "react-drag-drop-files";

// Material UI
// Components
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
// Icons
import NoPhotographyRoundedIcon from "@mui/icons-material/NoPhotographyRounded";

// Styles
import {
  avatorButtonStyles,
  avatorWithSrcStyles,
  avatorWithTextStyles,
  useStandardItemBoxStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxStyles";

function MerchandisePhotoBox(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxStyles();

  // Props
  const {
    // Events
    onDeletePhotoBtnClicked,
    onFileUploaded,
    // States
    item,
  } = props;

  // Handle States
  const isPhotoExist = item.file_path ? true : false;
  const photoURL = isPhotoExist ? getFileURL(item.file_path) : null;

  return (
    <div className={classes.itemContainer}>
      {isPhotoExist && (
        <Stack direction="row" spacing={1}>
          <IconButton disabled>
            <NoPhotographyRoundedIcon className={classes.dummyBtn} />
          </IconButton>
        </Stack>
      )}
      <div className={classes.profilePhotoContainer}>
        <FileUploader
          children={
            <Button component="label" variant="text" sx={avatorButtonStyles}>
              {item.file_path ? (
                <Avatar
                  alt={t("圖片")}
                  src={photoURL}
                  sx={avatorWithSrcStyles}
                />
              ) : (
                <Avatar sx={avatorWithTextStyles}>{t("上載圖片")}</Avatar>
              )}
            </Button>
          }
          handleChange={onFileUploaded}
          name="file"
          types={["gif", "jpeg", "jpg", "png", "svg", "tiff", "webp"]}
        />
      </div>
      {isPhotoExist && (
        <Stack direction="row" spacing={1}>
          <Tooltip title={t("刪除圖片")}>
            <IconButton onClick={onDeletePhotoBtnClicked}>
              <NoPhotographyRoundedIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      )}
    </div>
  );
}

export default MerchandisePhotoBox;
