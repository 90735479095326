const initialState = {
  ageGroupId: null,
  formSubmitCount: 0,
  tabItem: "AgeGroups",
  typeOfAgeGroups: "Current",
};

export const ageGroupsPageReducers = (state = initialState, action) => {
  switch (action.type) {
    case "ON_AGE_GROUPS_PAGE_AGE_GROUP_CHANGE":
      const { ageGroupId } = action;
      return {
        ...state,
        ageGroupId,
      };
    case "ON_AGE_GROUPS_PAGE_FORM_SUBMIT_COUNT_INCREASE":
      return {
        ...state,
        formSubmitCount: state.formSubmitCount + 1,
      };
    case "ON_AGE_GROUPS_PAGE_TAB_ITEM_CHANGE":
      const { tabItem } = action;
      return {
        ...state,
        tabItem,
      };
    case "ON_AGE_GROUPS_PAGE_TYPE_OF_AGE_GROUPS_CHANGE":
      const { typeOfAgeGroups } = action;
      return {
        ...state,
        typeOfAgeGroups,
      };
    default:
      return state;
  }
};
