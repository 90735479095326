// Actions
import { staffLogin } from "../../../redux/shared/staff/staffActions";

// Configs
import keyConfig from "../../../configs/keyConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { loginFetch } from "../../../fetches/staffFetches";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React
import { useState } from "react";

// React-Redux
import { useDispatch } from "react-redux";

// React-Router
import { useNavigate } from "react-router-dom";

// Styles
import { useLoginPageStyles } from "../../../styles/pageStyles/loginPageStyles";

function LoginForm() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Router
  const navigate = useNavigate();
  // Styles
  const classes = useLoginPageStyles();

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Text Fields
  const [fullNameOrStaffCode, setFullNameOrStaffCode] = useState("");
  const [password, setPassword] = useState("");

  // Events
  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "fullNameOrStaffCode":
        setFullNameOrStaffCode(value);
        break;
      case "password":
        setPassword(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked();
    }
  };

  // Events - Forms
  const onSubmitBtnClicked = async () => {
    let isError = false;

    if (!fullNameOrStaffCode) {
      addToErrorFields(
        "fullNameOrStaffCode",
        t("請先填寫 英文全名 或 員工編號")
      );
      isError = true;
    }

    if (!password) {
      addToErrorFields("password", t("請先填寫 密碼"));
      isError = true;
    }

    if (isError) {
      return;
    }

    login();
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  const updateStaffInfo = (staff, token) => {
    // Update Redux Store
    dispatch(
      staffLogin(
        token,
        staff.alias,
        staff.dept_name_display,
        staff.full_name_en,
        staff.id
      )
    );

    // Local Storage
    localStorage.setItem(keyConfig.tokenKey, token);
  };

  // Functions - Queries
  const login = async () => {
    const results = await loginFetch(fullNameOrStaffCode, password);

    if (results.token && results.staff) {
      const { staff, token } = results;

      updateStaffInfo(staff, token);

      // Navigate
      navigate("/dashboard");
    } else if (results.message && results.message === "Permission denied") {
      showFormAlert("warning", t("未有權限"));
    } else {
      showFormAlert("error", t("未能登入"));
    }
  };

  return (
    <>
      <Typography align={"center"} variant="h6">
        {t("零售銷售管理系統")}
      </Typography>
      <div className={classes.loginInputsContainer}>
        <TextField
          error={checkIsFieldError("fullNameOrStaffCode")}
          fullWidth
          helperText={getErrorFieldMessage("fullNameOrStaffCode")}
          label={t("英文全名 或 員工編號")}
          margin="dense"
          name="fullNameOrStaffCode"
          onChange={(event) =>
            onInputFieldChange("fullNameOrStaffCode", event.target.value)
          }
          onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
          required
          variant="standard"
        />
        <TextField
          autoComplete="current-password"
          error={checkIsFieldError("password")}
          fullWidth
          helperText={getErrorFieldMessage("password")}
          label={t("密碼")}
          margin="dense"
          name="password"
          onChange={(event) =>
            onInputFieldChange("password", event.target.value)
          }
          onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
          required
          type="password"
          variant="standard"
        />
      </div>
      <div className={classes.loginSubmitContainer}>
        <Button fullWidth onClick={onSubmitBtnClicked} variant="contained">
          {t("登入")}
        </Button>
      </div>
      {shouldShowFormAlert && (
        <div className={classes.alertContainer}>
          <Alert severity={formAlertType}>{formAlertText}</Alert>
        </div>
      )}
    </>
  );
}

export default LoginForm;
