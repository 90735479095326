// Actions
import {
  onDiscountChange,
  onTabItemChange,
} from "../../../redux/pages/discountProfilePage/discountProfilePageActions";

// Components
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Forms
import DiscountActionModalContent from "./discountActionModalContent";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { getDiscountDetailFormOptionsFetch } from "../../../fetches/formOptionFetches";
import {
  createDiscountFetch,
  editDiscountFetch,
  getDiscountDetailsByIdFetch,
} from "../../../fetches/discountFetches";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// React-Router
import { useNavigate } from "react-router-dom";

function DiscountActionModalContainer(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Router
  const navigate = useNavigate();

  // Props
  const {
    // Events
    onModalClosed,
    // States
    defaultAttributeId,
    defaultAttributeType,
    discountId,
  } = props;

  // Redux Store
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Date Fields
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  // Dialog
  const [discountActionDialogText, setDiscountActionDialogText] = useState("");
  const [discountActionDialogType, setDiscountActionDialogType] =
    useState(null);
  const [showDiscountActionDialog, setShowDiscountActionDialog] =
    useState(false);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Option Fields
  const [discountCalculationTypeField, setDiscountCalculationTypeField] =
    useState(null);
  const [discountConditionTypeField, setDiscountConditionTypeField] =
    useState(null);
  const [discountOfferTypeField, setDiscountOfferTypeField] = useState(null);
  const [discountPaymentTypeField, setDiscountPaymentTypeField] =
    useState(null);
  const [discountRuleTypeField, setDiscountRuleTypeField] = useState(null);
  const [paymentMethodField, setPaymentMethodField] = useState([]);
  // Options
  const [discountCalculationTypeOptions, setDiscountCalculationTypeOptions] =
    useState([]);
  const [discountConditionTypeOptions, setDiscountConditionTypeOptions] =
    useState([]);
  const [discountOfferTypeOptions, setDiscountOfferTypeOptions] = useState([]);
  const [discountPaymentTypeOptions, setDiscountPaymentTypeOptions] = useState(
    []
  );
  const [discountRuleTypeOptions, setDiscountRuleTypeOptions] = useState([]);
  const [paymentMethodOptions, setPaymentMethodOptions] = useState([]);
  // Render
  const [isOptionsRetrieved, setIsOptionsRetrieved] = useState(false);
  // Switches
  const [isAddOnsIncluded, setIsAddOnsIncluded] = useState(true);
  const [isCumulative, setIsCumulative] = useState(true);
  const [isItemActive, setIsItemActive] = useState(true);
  const [isTimeLimit, setIsTimeLimit] = useState(true);
  // Text Fields
  const [code, setCode] = useState("");
  const [nameCh, setNameCh] = useState("");
  const [nameEn, setNameEn] = useState("");

  // Handle States
  const endDateVar = endDate ? new Date(endDate) : null;
  const startDateVar = startDate ? new Date(startDate) : null;

  // Events
  // Events - Dialogs
  const onDiscountActionDialogCanceled = () => {
    // Set States
    setShowDiscountActionDialog(false);
  };

  const onDiscountActionDialogConfirmed = () => {
    switch (discountActionDialogType) {
      case "CreateDiscount":
        createDiscount();
        break;
      case "EditDiscount":
        editDiscount();
        break;
      default:
        break;
    }

    // Set States
    setShowDiscountActionDialog(false);
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "code":
        setCode(value);
        break;
      case "discountCalculationTypeField":
        setDiscountCalculationTypeField(value);
        break;
      case "discountConditionTypeField":
        setDiscountConditionTypeField(value);

        if (value.discount_condition_type_name_en !== "Amount") {
          setDiscountCalculationTypeField(
            discountCalculationTypeOptions.find(
              (item) =>
                item.discount_calculation_type_name_en === "By Original Price"
            )
          );

          removeErrorField("discountCalculationTypeField");
        }

        break;
      case "discountOfferTypeField":
        setDiscountOfferTypeField(value);

        if (value.discount_offer_type_name_en !== "Merchandises") {
          if (
            discountConditionTypeField &&
            ["Quantity (Every Piece)", "Quantity (Each Piece)"].includes(
              discountConditionTypeField.discount_condition_type_name_en
            )
          ) {
            setDiscountConditionTypeField(
              discountConditionTypeOptions.find(
                (item) => item.discount_condition_type_name_en === "Amount"
              )
            );

            removeErrorField("discountConditionTypeField");
          }
        }

        if (value.discount_offer_type_name_en === "Merchandises") {
          setDiscountCalculationTypeField(
            discountCalculationTypeOptions.find(
              (item) =>
                item.discount_calculation_type_name_en === "By Original Price"
            )
          );

          removeErrorField("discountCalculationTypeField");
        }

        if (value.discount_offer_type_name_en !== "Whole Order") {
          setIsAddOnsIncluded(false);
        }

        break;
      case "discountPaymentTypeField":
        setDiscountPaymentTypeField(value);

        if (value.discount_payment_type_name_en === "All") {
          setPaymentMethodField([]);

          removeErrorField("paymentMethod");
        }

        break;
      case "discountRuleTypeField":
        setDiscountRuleTypeField(value);
        break;
      case "endDate":
        setEndDate(value);
        break;
      case "isAddOnsIncluded":
        setIsAddOnsIncluded(value);
        break;
      case "isCumulative":
        setIsCumulative(value);
        break;
      case "isItemActive":
        setIsItemActive(value);
        break;
      case "isTimeLimit":
        setIsTimeLimit(value);

        if (!value) {
          setEndDate(null);
        }

        break;
      case "nameCh":
        setNameCh(value);
        break;
      case "nameEN":
        setNameEn(value);
        break;
      case "paymentMethodField":
        setPaymentMethodField(value);
        break;
      case "startDate":
        setStartDate(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked();
    }
  };

  // Events - Forms
  const onSubmitBtnClicked = () => {
    let isError = false;

    if (!discountOfferTypeField) {
      addToErrorFields("discountOfferTypeField", t("請先填寫 優惠方式"));
      isError = true;
    }

    if (!discountCalculationTypeField) {
      addToErrorFields("discountCalculationTypeField", t("請先填寫 計算方式"));
      isError = true;
    }

    if (!discountConditionTypeField) {
      addToErrorFields("discountConditionTypeField", t("請先填寫 優惠條件"));
      isError = true;
    }

    if (!discountPaymentTypeField) {
      addToErrorFields("discountPaymentTypeField", t("請先填寫 付款方式類別"));
      isError = true;
    } else {
      if (discountPaymentTypeField.discount_payment_type_name_en !== "All") {
        if (!paymentMethodField[0]) {
          addToErrorFields("paymentMethodField", t("請先填寫 付款方式"));
          isError = true;
        }
      }
    }

    if (!discountRuleTypeField) {
      addToErrorFields("discountRuleTypeField", t("請先填寫 折扣方式"));
      isError = true;
    }

    if (!code) {
      addToErrorFields("code", t("請先填寫 代號"));
      isError = true;
    }

    if (isTimeLimit) {
      if (!endDate) {
        addToErrorFields("endDate", t("請先填寫 結束日期"));
        isError = true;
      }
    }

    if (!nameCh) {
      addToErrorFields("nameCh", t("請先填寫 中文名稱"));
      isError = true;
    }

    if (!nameEn) {
      addToErrorFields("nameEn", t("請先填寫 英文名稱"));
      isError = true;
    }

    if (!startDate) {
      addToErrorFields("startDate", t("請先填寫 開始日期"));
      isError = true;
    }

    if (isError) {
      return;
    }

    displayDiscountActionDialog(discountId ? "EditDiscount" : "CreateDiscount");
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const clearErrorFields = () => {
    // Set States
    setErrorFields([]);
  };

  const displayDiscountActionDialog = (discountActionType) => {
    // Set States
    setDiscountActionDialogType(discountActionType);

    switch (discountActionType) {
      case "CreateDiscount":
        setDiscountActionDialogText(t("確認要新增 優惠 嗎？"));
        break;
      case "EditDiscount":
        setDiscountActionDialogText(t("確認要編輯 優惠 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowDiscountActionDialog(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const createDiscount = async () => {
    const results = await createDiscountFetch(
      token,
      discountOfferTypeField ? discountOfferTypeField.id : null,
      discountConditionTypeField ? discountConditionTypeField.id : null,
      discountCalculationTypeField ? discountCalculationTypeField.id : null,
      discountRuleTypeField ? discountRuleTypeField.id : null,
      discountPaymentTypeField ? discountPaymentTypeField.id : null,
      code,
      nameCh,
      nameEn,
      isTimeLimit,
      startDate,
      endDate,
      isCumulative,
      isAddOnsIncluded,
      isItemActive,
      paymentMethodField[0] ? paymentMethodField.map((item) => item.id) : null,
      defaultAttributeType ? defaultAttributeType : null,
      defaultAttributeId ? defaultAttributeId : null
    );

    if (results.success) {
      onModalClosed();

      if (results.discountId) {
        // Update Redux Store
        dispatch(onDiscountChange(results.discountId));
        dispatch(onTabItemChange("BasicInfo"));

        // Navigate
        navigate("/discountProfile");
      }
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  const editDiscount = async () => {
    const results = await editDiscountFetch(
      token,
      discountId,
      discountOfferTypeField ? discountOfferTypeField.id : null,
      discountConditionTypeField ? discountConditionTypeField.id : null,
      discountCalculationTypeField ? discountCalculationTypeField.id : null,
      discountRuleTypeField ? discountRuleTypeField.id : null,
      discountPaymentTypeField ? discountPaymentTypeField.id : null,
      code,
      nameCh,
      nameEn,
      isTimeLimit,
      startDate,
      endDate,
      isCumulative,
      isAddOnsIncluded,
      isItemActive,
      paymentMethodField[0] ? paymentMethodField.map((item) => item.id) : null
    );

    if (results.success) {
      onModalClosed();
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  // Functions = Queries
  const getDiscountDetailsById = async () => {
    const results = await getDiscountDetailsByIdFetch(token, discountId);

    if (results.discountDetails) {
      const {
        discount_code,
        discount_name_ch,
        discount_name_en,
        is_time_limit,
        startDate,
        endDate,
        is_cumulative,
        is_add_ons_included,
        is_active,
        discount_offer_type_id,
        discount_condition_type_id,
        discount_calculation_type_id,
        discount_rule_type_id,
        discount_payment_type_id,
        dependencies,
      } = results.discountDetails;

      const { paymentMethods } = dependencies;

      // Set States
      setCode(discount_code ? discount_code : "");
      setNameCh(discount_name_ch ? discount_name_ch : "");
      setNameEn(discount_name_en ? discount_name_en : "");
      setIsTimeLimit(is_time_limit);
      setStartDate(startDate ? startDate : new Date());
      setEndDate(endDate ? endDate : new Date());
      setIsAddOnsIncluded(is_add_ons_included);
      setIsCumulative(is_cumulative);
      setIsItemActive(is_active);
      setDiscountOfferTypeField(
        discount_offer_type_id
          ? discountOfferTypeOptions.find(
              (item) => item.id === discount_offer_type_id
            )
          : null
      );
      setDiscountConditionTypeField(
        discount_condition_type_id
          ? discountConditionTypeOptions.find(
              (item) => item.id === discount_condition_type_id
            )
          : null
      );
      setDiscountCalculationTypeField(
        discount_calculation_type_id
          ? discountCalculationTypeOptions.find(
              (item) => item.id === discount_calculation_type_id
            )
          : null
      );
      setDiscountRuleTypeField(
        discount_rule_type_id
          ? discountRuleTypeOptions.find(
              (item) => item.id === discount_rule_type_id
            )
          : null
      );
      setDiscountPaymentTypeField(
        discount_payment_type_id
          ? discountPaymentTypeOptions.find(
              (item) => item.id === discount_payment_type_id
            )
          : null
      );

      if (paymentMethods) {
        let paymentMethodsArr = [];

        for (let paymentMethodItem of paymentMethods) {
          if (
            paymentMethodOptions.find(
              (item) => item.id === paymentMethodItem.id
            )
          ) {
            paymentMethodsArr.push(
              paymentMethodOptions.find(
                (item) => item.id === paymentMethodItem.id
              )
            );
          }
        }

        setPaymentMethodField(paymentMethodsArr);
      } else {
        setPaymentMethodField([]);
      }
    }

    clearErrorFields();
  };

  const getDiscountDetailFormOptions = async () => {
    const results = await getDiscountDetailFormOptionsFetch(token);

    // Set States
    setDiscountCalculationTypeOptions(
      results.discountCalculationTypes ? results.discountCalculationTypes : []
    );
    setDiscountConditionTypeOptions(
      results.discountConditionTypes ? results.discountConditionTypes : []
    );
    setDiscountOfferTypeOptions(
      results.discountOfferTypes ? results.discountOfferTypes : []
    );
    setDiscountRuleTypeOptions(
      results.discountRuleTypes ? results.discountRuleTypes : []
    );
    setDiscountPaymentTypeOptions(
      results.discountPaymentTypes ? results.discountPaymentTypes : []
    );
    setPaymentMethodOptions(
      results.paymentMethods ? results.paymentMethods : []
    );
  };

  // Life Cycle
  useEffect(() => {
    getDiscountDetailFormOptions();
  }, []);

  useEffect(() => {
    // Set States
    if (!isOptionsRetrieved && discountCalculationTypeOptions[0]) {
      setIsOptionsRetrieved(true);
    }
  }, [discountCalculationTypeOptions]);

  useEffect(() => {
    if (isOptionsRetrieved) {
      if (discountId) {
        getDiscountDetailsById();
      }
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [isOptionsRetrieved, discountId]);

  return (
    <>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onDiscountActionDialogCanceled}
        onDialogConfirmed={onDiscountActionDialogConfirmed}
        // States
        dialogText={discountActionDialogText}
        showDialog={showDiscountActionDialog}
      />
      <DiscountActionModalContent
        // Events
        onInputFieldChange={onInputFieldChange}
        onInputFieldKeyPressed={onInputFieldKeyPressed}
        onSubmitBtnClicked={onSubmitBtnClicked}
        // Functions
        checkIsFieldError={checkIsFieldError}
        getErrorFieldMessage={getErrorFieldMessage}
        // States
        code={code}
        discountCalculationTypeField={discountCalculationTypeField}
        discountCalculationTypeOptions={discountCalculationTypeOptions}
        discountConditionTypeField={discountConditionTypeField}
        discountConditionTypeOptions={discountConditionTypeOptions}
        discountId={discountId}
        discountOfferTypeField={discountOfferTypeField}
        discountOfferTypeOptions={discountOfferTypeOptions}
        discountPaymentTypeField={discountPaymentTypeField}
        discountPaymentTypeOptions={discountPaymentTypeOptions}
        discountRuleTypeField={discountRuleTypeField}
        discountRuleTypeOptions={discountRuleTypeOptions}
        endDateVar={endDateVar}
        formAlertText={formAlertText}
        formAlertType={formAlertType}
        isAddOnsIncluded={isAddOnsIncluded}
        isCumulative={isCumulative}
        isItemActive={isItemActive}
        isTimeLimit={isTimeLimit}
        nameCh={nameCh}
        nameEn={nameEn}
        paymentMethodField={paymentMethodField}
        paymentMethodOptions={paymentMethodOptions}
        shouldShowFormAlert={shouldShowFormAlert}
        startDateVar={startDateVar}
      />
    </>
  );
}

export default DiscountActionModalContainer;
