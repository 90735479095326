export function onAppliedDiscountChange(
  appliedDiscountId,
  discountId,
  typeOfAppliedDiscount
) {
  return {
    type: "ON_SALES_RECORD_PROFILE_PAGE_APPLIED_DISCOUNT_CHANGE",
    appliedDiscountId,
    discountId,
    typeOfAppliedDiscount,
  };
}

export function onFormSubmitCountIncrease() {
  return {
    type: "ON_SALES_RECORD_PROFILE_PAGE_FORM_SUBMIT_COUNT_INCREASE",
  };
}

export function onMerchandiseSoldChange(merchandiseSoldId) {
  return {
    type: "ON_SALES_RECORD_PROFILE_PAGE_MERCHANDISE_SOLD_CHANGE",
    merchandiseSoldId,
  };
}

export function onMerchandiseSoldItemChange(merchandiseSoldItemId) {
  return {
    type: "ON_SALES_RECORD_PROFILE_PAGE_MERCHANDISE_SOLD_ITEM_CHANGE",
    merchandiseSoldItemId,
  };
}

export function onSalesRecordChange(salesRecordId) {
  return {
    type: "ON_SALES_RECORD_PROFILE_PAGE_SALES_RECORD_CHANGE",
    salesRecordId,
  };
}

export function onTabItemChange(tabItem) {
  return {
    type: "ON_SALES_RECORD_PROFILE_PAGE_TAB_ITEM_CHANGE",
    tabItem,
  };
}
