// Components
// Boxes
import UsageInfoBox from "./usageInfoBox";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { getDiscountUsageBasicInfoFetch } from "../../../fetches/discountUsageNumberFetches";

// Helper Functions
import { getDateVarsForSalesTrends } from "../../../helperFunctions/getDateVarForCharts";
import { getMomentLocale } from "../../../helperFunctions/getMomentLocale";
import { separateComma } from "../../../helperFunctions/separateComma";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// Moment
import moment from "moment";
import "moment/min/locales";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function UsageInfoBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const discountId = useSelector(
    (state) => state.discountProfilePage.discountId
  );
  const language = useSelector((state) => state.language.language);
  const recordDate = useSelector(
    (state) => state.discountProfilePage.recordDate
  );
  const recordDateType = useSelector(
    (state) => state.discountProfilePage.recordDateType
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [usageBasicInfo, setUsageBasicInfo] = useState(null);

  // Handle States
  const item = usageBasicInfo;
  const recordDateVar = new Date(recordDate);

  const recordDateDisplay =
    recordDateType === "Date"
      ? moment(recordDateVar).format(t("Y 年 M 月"))
      : moment(recordDateVar).format(t("Y 年"));

  // Functions
  // Functions - Queries
  const getDiscountUsageBasicInfo = async () => {
    const { startDateVar, endDateVar } = getDateVarsForSalesTrends(
      recordDateType,
      false,
      recordDateVar,
      null
    );

    const results = await getDiscountUsageBasicInfoFetch(
      token,
      discountId,
      startDateVar,
      endDateVar
    );

    if (results.revenue) {
      results.revenue = results.revenue
        ? `$ ${separateComma(Number(results.revenue).toFixed(1))}`
        : null;

      results.revenueWithinPeriod = results.revenueWithinPeriod
        ? `$ ${separateComma(Number(results.revenueWithinPeriod).toFixed(1))}`
        : null;
    }

    // Set States
    setUsageBasicInfo(results.revenue ? results : null);
  };

  // Life Cycle
  useEffect(() => {
    getDiscountUsageBasicInfo();
  }, [discountId, recordDate, recordDateType, language]);

  useEffect(() => {
    moment.locale(getMomentLocale(language));
  }, [language]);

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("使用狀況 (總計)")}
          </Typography>
        </div>
      </div>
      {/* List */}
      {item ? (
        <UsageInfoBox
          // States
          item={item}
          recordDateDisplay={recordDateDisplay}
        />
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有使用狀況資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default UsageInfoBoxesContainer;
