// Pages
import AgeGroupsPage from "./pages/ageGroupsPage";
import CategoriesAndBrandsPage from "./pages/categoriesAndBrandsPage";
import DashboardPage from "./pages/dashboardPage";
import DiscountsPage from "./pages/discountsPage";
import DiscountProfilePage from "./pages/discountProfilePage";
import LoginPage from "./pages/loginPage";
import MerchandisesPage from "./pages/merchandisesPage";
import MerchandiseSalesPage from "./pages/merchandiseSalesPage";
import SalesRecordsPage from "./pages/salesRecordsPage";
import SalesRecordProfilePage from "./pages/salesRecordProfilePage";
import SalesReportsPage from "./pages/salesReportsPage";
import StocksPage from "./pages/stocksPage";

// React-Redux
import { useSelector } from "react-redux";

// React-Router
import { Navigate, Route, Routes } from "react-router-dom";

function Router() {
  // Redux Store
  const token = useSelector((state) => state.staff.token);

  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route
        path="/ageGroups"
        element={token ? <AgeGroupsPage /> : <Navigate to="/" />}
      />
      <Route
        path="/categoriesAndBrands"
        element={token ? <CategoriesAndBrandsPage /> : <Navigate to="/" />}
      />
      <Route
        path="/dashboard"
        element={token ? <DashboardPage /> : <Navigate to="/" />}
      />
      <Route
        path="/discounts"
        element={token ? <DiscountsPage /> : <Navigate to="/" />}
      />
      <Route
        path="/discountProfile"
        element={token ? <DiscountProfilePage /> : <Navigate to="/" />}
      />
      <Route
        path="/merchandises"
        element={token ? <MerchandisesPage /> : <Navigate to="/" />}
      />
      <Route
        path="/merchandiseSales"
        element={token ? <MerchandiseSalesPage /> : <Navigate to="/" />}
      />
      <Route
        path="/salesRecords"
        element={token ? <SalesRecordsPage /> : <Navigate to="/" />}
      />
      <Route
        path="/salesRecordProfile"
        element={token ? <SalesRecordProfilePage /> : <Navigate to="/" />}
      />
      <Route
        path="/salesReports"
        element={token ? <SalesReportsPage /> : <Navigate to="/" />}
      />
      <Route
        path="/stocks"
        element={token ? <StocksPage /> : <Navigate to="/" />}
      />
    </Routes>
  );
}

export default Router;
