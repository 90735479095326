// Actions
import {
  onAttributeChange,
  onFormSubmitCountIncrease,
} from "../../../redux/pages/merchandisesPage/merchandisesPageActions";

// Components
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  createAttributeFetch,
  editAttributeFetch,
  getAttributeDetailsByIdFetch,
} from "../../../fetches/attributeFetches";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function AttributeDetailForm() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const propertyId = useSelector((state) => state.merchandisesPage.propertyId);
  const attributeId = useSelector(
    (state) => state.merchandisesPage.attributeId
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Dialog
  const [attributeDetailActionDialogText, setAttributeDetailActionDialogText] =
    useState("");
  const [attributeDetailActionDialogType, setAttributeDetailActionDialogType] =
    useState(null);
  const [showAttributeDetailAction, setShowAttributeDetailAction] =
    useState(false);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Switches
  const [isItemActive, setIsItemActive] = useState(true);
  // Text Fields
  const [code, setCode] = useState("");
  const [nameCh, setNameCh] = useState("");
  const [nameEn, setNameEn] = useState("");

  // Events
  // Events - Dialogs
  const onAttributeDetailActionCanceled = () => {
    // Set States
    setShowAttributeDetailAction(false);
  };

  const onAttributeDetailActionConfirmed = () => {
    switch (attributeDetailActionDialogType) {
      case "CreateAttribute":
        createAttribute();
        break;
      case "EditAttribute":
        editAttribute();
        break;
      default:
        break;
    }

    // Set States
    setShowAttributeDetailAction(false);
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "code":
        setCode(value);
        break;
      case "isItemActive":
        setIsItemActive(value);
        break;
      case "nameCh":
        setNameCh(value);
        break;
      case "nameEn":
        setNameEn(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked();
    }
  };

  // Events - Forms
  const onSubmitBtnClicked = () => {
    let isError = false;

    if (!code) {
      addToErrorFields("code", t("請先填寫 代號"));
      isError = true;
    }

    if (!nameCh) {
      addToErrorFields("nameCh", t("請先填寫 中文名稱"));
      isError = true;
    }

    if (!nameEn) {
      addToErrorFields("nameEn", t("請先填寫 英文名稱"));
      isError = true;
    }

    if (isError) {
      return;
    }

    displayAttributeDetailActionDialog(
      attributeId ? "EditAttribute" : "CreateAttribute"
    );
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const clearErrorFields = () => {
    // Set States
    setErrorFields([]);
  };

  const clearForm = () => {
    // Set States
    setCode("");
    setNameCh("");
    setNameEn("");
    setIsItemActive(true);

    clearErrorFields();
  };

  const displayAttributeDetailActionDialog = (attributeDetailActionType) => {
    // Set States
    setAttributeDetailActionDialogType(attributeDetailActionType);
    switch (attributeDetailActionType) {
      case "CreateAttribute":
        setAttributeDetailActionDialogText(t("確認要新增 選項 嗎？"));
        break;
      case "EditAttribute":
        setAttributeDetailActionDialogText(t("確認要編輯 選項資料 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowAttributeDetailAction(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const createAttribute = async () => {
    const results = await createAttributeFetch(
      token,
      propertyId,
      code,
      nameCh,
      nameEn,
      isItemActive
    );

    if (results.success) {
      showFormAlert("success", t("成功提交"));

      // Update Redux Store
      dispatch(onAttributeChange(results.attributeId));
      dispatch(onFormSubmitCountIncrease());
    } else if (results.isDuplicate) {
      showFormAlert("warning", t("此 選項 已存在"));
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  const editAttribute = async () => {
    if (attributeId) {
      const results = await editAttributeFetch(
        token,
        attributeId,
        propertyId,
        code,
        nameCh,
        nameEn,
        isItemActive
      );

      if (results.success) {
        showFormAlert("success", t("成功提交"));

        // Update Redux Store
        dispatch(onFormSubmitCountIncrease());
      } else if (results.isDuplicate) {
        showFormAlert("warning", t("此 選項 已存在"));
      } else {
        showFormAlert("error", t("未能提交"));
      }
    }
  };

  // Functions - Queries
  const getAttributeDetailsById = async () => {
    const results = await getAttributeDetailsByIdFetch(token, attributeId);

    if (results.attributeDetails) {
      const {
        attribute_code,
        attribute_name_ch,
        attribute_name_en,
        is_active,
      } = results.attributeDetails;

      // Set States
      setCode(attribute_code ? attribute_code : "");
      setNameCh(attribute_name_ch ? attribute_name_ch : null);
      setNameEn(attribute_name_en ? attribute_name_en : null);
      setIsItemActive(is_active);
    } else {
      // Set States
      setCode("");
      setNameCh("");
      setNameEn("");
      setIsItemActive(true);
    }

    clearErrorFields();
  };

  // Life Cycle
  useEffect(() => {
    if (attributeId) {
      getAttributeDetailsById();
    } else {
      clearForm();
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [attributeId]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onAttributeDetailActionCanceled}
        onDialogConfirmed={onAttributeDetailActionConfirmed}
        // States
        dialogText={attributeDetailActionDialogText}
        showDialog={showAttributeDetailAction}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("選項資料")}
          </Typography>
        </div>
      </div>
      {/* Form */}
      <Grid
        className={classes.formContainer}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        container
        rowSpacing={1}
      >
        <Grid item xs={6}>
          <TextField
            error={checkIsFieldError("code")}
            fullWidth
            helperText={getErrorFieldMessage("code")}
            label={t("代號")}
            margin="dense"
            name="code"
            onChange={(event) => onInputFieldChange("code", event.target.value)}
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={code}
            variant="standard"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            error={checkIsFieldError("nameCh")}
            fullWidth
            helperText={getErrorFieldMessage("nameCh")}
            label={t("中文名稱")}
            margin="dense"
            name="nameCh"
            onChange={(event) =>
              onInputFieldChange("nameCh", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            required
            value={nameCh}
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={checkIsFieldError("nameEn")}
            fullWidth
            helperText={getErrorFieldMessage("nameEn")}
            label={t("英文名稱")}
            margin="dense"
            name="nameEn"
            onChange={(event) =>
              onInputFieldChange("nameEn", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={nameEn}
            variant="standard"
          />
        </Grid>
        <Grid item xs={6} className={classes.formSwitchContainer}>
          <Typography align={"left"} variant="body1">
            {t("有效")}
          </Typography>
          <Switch
            checked={isItemActive}
            onChange={() => onInputFieldChange("isItemActive", !isItemActive)}
          />
        </Grid>
        <Grid item xs={12} className={classes.formSubmitBtnContainer}>
          <Button
            onClick={onSubmitBtnClicked}
            variant="contained"
            sx={formSubmitBtnStyles}
          >
            {attributeId ? t("修改") : t("新增")}
          </Button>
        </Grid>
        {/* Alert */}
        {shouldShowFormAlert && (
          <Grid item xs={12}>
            <Alert severity={formAlertType}>{formAlertText}</Alert>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default AttributeDetailForm;
