// Actions
import { onTypeOfRankedWarehouseContentChange } from "../../../redux/pages/stocksPage/stocksPageActions";

// Components
// Boxes
import RankedShopAndWarehouseBox from "./rankedShopAndWarehouseBox";

// Configs
import paginationConfig from "../../../configs/paginationConfig";
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  getRankedShopStocksByMerchandiseIdWithPaginationFetch,
  getRankedWarehouseStocksByMerchandiseIdWithPaginationFetch,
} from "../../../fetches/merchandiseStockFetches";

// Material UI
// Components
import Button from "@mui/material/Button";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
// Icons
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

// Consts
const limitNum = paginationConfig.paginationLimitNum;

function RankedShopAndWarehouseBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const formSubmitCount = useSelector(
    (state) => state.stocksPage.formSubmitCount
  );
  const merchandiseId = useSelector((state) => state.stocksPage.merchandiseId);
  const merchandiseCodeText = useSelector(
    (state) => state.stocksPage.merchandiseCodeText
  );
  const token = useSelector((state) => state.staff.token);
  const typeOfRankedWarehouseContent = useSelector(
    (state) => state.stocksPage.typeOfRankedWarehouseContent
  );
  const variationId = useSelector((state) => state.stocksPage.variationId);
  const variationAttributeText = useSelector(
    (state) => state.stocksPage.variationAttributeText
  );

  // States
  // Data
  const [rankedShops, setRankedShops] = useState(null);
  const [rankedWarehouses, setRankedWarehouses] = useState(null);
  // Pagination
  const [pageCount, setPageCount] = useState(null);
  const [pageNum, setPageNum] = useState(1);

  // Handle States
  const itemsArr =
    typeOfRankedWarehouseContent === "Shops" ? rankedShops : rankedWarehouses;

  // Events
  // Events - Pagination
  const onPageChange = (value) => {
    // Set States
    setPageNum(value);
  };

  // Events - Title
  const onChangeRecordDateTypeBtnClicked = () => {
    // Update Redux Store
    dispatch(
      onTypeOfRankedWarehouseContentChange(
        typeOfRankedWarehouseContent === "Shops" ? "Warehouses" : "Shops"
      )
    );
  };

  // Functions
  // Functions - Queries
  const getRankedShopStocksByMerchandiseIdWithPagination = async () => {
    const results = await getRankedShopStocksByMerchandiseIdWithPaginationFetch(
      token,
      merchandiseId,
      variationId,
      pageNum,
      limitNum
    );

    // Set States
    setPageCount(results.pageCount ? results.pageCount : 0);
    setRankedShops(results.rankedShops ? results.rankedShops : null);

    if (results.pageCount && !results.rankedShops) {
      setPageNum(results.pageCount);
    }
  };

  const getRankedWarehouseStocksByMerchandiseIdWithPagination = async () => {
    const results =
      await getRankedWarehouseStocksByMerchandiseIdWithPaginationFetch(
        token,
        merchandiseId,
        variationId,
        pageNum,
        limitNum
      );

    // Set States
    setPageCount(results.pageCount ? results.pageCount : 0);
    setRankedWarehouses(
      results.rankedWarehouses ? results.rankedWarehouses : null
    );

    if (results.pageCount && !results.rankedWarehouses) {
      setPageNum(results.pageCount);
    }
  };

  // Life Cycle
  useEffect(() => {
    if (merchandiseId) {
      if (typeOfRankedWarehouseContent === "Shops") {
        getRankedShopStocksByMerchandiseIdWithPagination();
      } else {
        getRankedWarehouseStocksByMerchandiseIdWithPagination();
      }
    }
  }, [
    merchandiseId,
    variationId,
    typeOfRankedWarehouseContent,
    pageNum,
    formSubmitCount,
  ]);

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {variationAttributeText
              ? `${
                  typeOfRankedWarehouseContent === "Shops"
                    ? t("店鋪")
                    : t("倉庫")
                }${t(
                  " 庫存s"
                )} (${merchandiseCodeText} - ${variationAttributeText})`
              : `${
                  typeOfRankedWarehouseContent === "Shops"
                    ? t("店鋪")
                    : t("倉庫")
                }${t(" 庫存s")} (${merchandiseCodeText})`}
          </Typography>
        </div>
        <Button
          endIcon={<KeyboardArrowDownRoundedIcon />}
          onClick={onChangeRecordDateTypeBtnClicked}
          variant="text"
        >{`${t("顯示 ")}${
          typeOfRankedWarehouseContent === "Shops" ? t("倉庫") : t("店鋪")
        }${t(" 庫存s")}`}</Button>
      </div>
      {/* List */}
      {itemsArr ? (
        <>
          {itemsArr.map((item) => (
            <RankedShopAndWarehouseBox
              key={item.id}
              // States
              item={item}
              typeOfRankedWarehouseContent={typeOfRankedWarehouseContent}
            />
          ))}
          <Stack className={classes.paginationStack} spacing={2}>
            <Pagination
              color={"primary"}
              count={pageCount}
              onChange={(event, page) => onPageChange(page)}
              page={pageNum}
            />
          </Stack>
        </>
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有商品選項庫存")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default RankedShopAndWarehouseBoxesContainer;
