// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Helper Functions
import { getFileURL } from "../../../helperFunctions/file";

// Material UI
// Components
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

// Styles
import {
  avatorWithSrcSmallStyles,
  avatorWithTextSmallStyles,
  useStandardItemBoxStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxStyles";

function PopularMerchandiseBox(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxStyles();

  // Props
  const {
    // States
    item,
  } = props;

  // Handle States
  const photoURL = item.file_path ? getFileURL(item.file_path) : null;

  return (
    <div className={classes.itemContainer}>
      <div className={classes.itemContentContainer}>
        <div className={classes.itemContentSubContainerAvator}>
          {photoURL ? (
            <Avatar
              alt={t("圖片")}
              src={photoURL}
              sx={avatorWithSrcSmallStyles}
            />
          ) : (
            <Avatar sx={avatorWithTextSmallStyles}>
              {item.merchandise_code}
            </Avatar>
          )}
        </div>
        <div className={classes.itemContentSubContainerLarge}>
          <Typography align={"left"} variant="body1">
            {`${item.merchandise_code} (${item[t("sub_category_name_ch")]})`}
          </Typography>
          <Typography
            align={"left"}
            color={stylesConfig.greyTextColor}
            variant="body2"
          >
            {`${item.brand_name} - 
          ${item[t("merchandise_name_ch")]}`}
          </Typography>
        </div>
        <div className={classes.itemContentSubContainerRight}>
          <Typography align={"left"} variant="body1">
            {`${item.num_of_merchandise_solds}${t(" 件")}`}
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default PopularMerchandiseBox;
