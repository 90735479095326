// Actions
import { enterPage } from "../redux/shared/location/locationActions";

// Components
// Boxes
import AttributeBoxesContainer from "../components/boxes/merchandisesPage/attributeBoxesContainer";
import MerchandiseBoxesContainer from "../components/boxes/merchandisesPage/merchandiseBoxesContainer";
import MerchandiseAttributeBoxesContainer from "../components/boxes/merchandisesPage/merchandiseAttributeBoxesContainer";
import MerchandiseConditionBoxesContainer from "../components/boxes/merchandisesPage/merchandiseConditionBoxesContainer";
import PropertyBoxesContainer from "../components/boxes/merchandisesPage/propertyBoxesContainer";
// Forms
import AttributeDetailForm from "../components/forms/merchandisesPage/attributeDetailForm";
import MerchandiseDetailFormContainer from "../components/forms/merchandisesPage/merchandiseDetailFormContainer";
import PropertyDetailForm from "../components/forms/merchandisesPage/propertyDetailForm";
// Headers
import MerchandisesPageHeader from "../components/headers/merchandisesPage/merchandisesPageHeader";
// Spacing Boxes
import SpacingBox from "../components/boxes/spacing/spacingBox";

// Configs
import stylesConfig from "../configs/stylesConfig";

// Material UI
// Components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// React
import { useEffect } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

function MerchandisesPage() {
  // Hooks
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const tabItem = useSelector((state) => state.merchandisesPage.tabItem);
  const typeOfContent = useSelector(
    (state) => state.merchandisesPage.typeOfContent
  );

  // Life Cycle
  useEffect(() => {
    // Update Redux Store
    dispatch(enterPage("Merchandises"));
  }, []);

  return (
    <Container maxWidth={false}>
      {/* Header */}
      <SpacingBox
        // Render
        height={stylesConfig.appBarHeight}
      />
      <MerchandisesPageHeader />
      {/* Content */}
      <Grid container spacing={4}>
        <Grid item xs={12} md={4} lg={3}>
          {tabItem === "MerchandiseList" && (
            <MerchandiseConditionBoxesContainer />
          )}
          {tabItem === "MerchandiseProperties" && <PropertyBoxesContainer />}
          <SpacingBox
            // Render
            height={stylesConfig.spacingBoxMarginBottom}
          />
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          {tabItem === "MerchandiseList" && (
            <>
              <Grid container spacing={4}>
                <Grid item xs={12} lg={6}>
                  <MerchandiseBoxesContainer />
                  <SpacingBox
                    // Render
                    height={stylesConfig.spacingBoxMarginBottom}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <MerchandiseDetailFormContainer />
                  <SpacingBox
                    // Render
                    height={stylesConfig.spacingBoxMarginBottom}
                  />
                  <MerchandiseAttributeBoxesContainer />
                  <SpacingBox
                    // Render
                    height={stylesConfig.spacingBoxMarginBottom}
                  />
                </Grid>
              </Grid>
            </>
          )}
          {tabItem === "MerchandiseProperties" &&
            typeOfContent === "PropertyDetail" && <PropertyDetailForm />}
          {tabItem === "MerchandiseProperties" &&
            typeOfContent === "AttributeList" && (
              <Grid container spacing={4}>
                <Grid item xs={12} lg={6}>
                  <AttributeBoxesContainer />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <AttributeDetailForm />
                </Grid>
              </Grid>
            )}
        </Grid>
      </Grid>
    </Container>
  );
}

export default MerchandisesPage;
