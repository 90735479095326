// Actions
import {
  onAgeGroupChange,
  onFormSubmitCountIncrease,
} from "../../../redux/pages/ageGroupsPage/ageGroupsPageActions";

// Components
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";

// Consts
import { ageOptions } from "../../../consts/ageGroupDetailFormConsts";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  createAgeGroupFetch,
  editAgeGroupFetch,
  getAgeGroupDetailsByIdFetch,
} from "../../../fetches/ageGroupFetches";

// Material UI
// Components
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function AgeGroupDetailForm() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const ageGroupId = useSelector((state) => state.ageGroupsPage.ageGroupId);
  const token = useSelector((state) => state.staff.token);

  // States
  // Alerts
  const [shouldShowFormAlert, setShouldShowFormAlert] = useState(false);
  const [formAlertType, setFormAlertType] = useState("");
  const [formAlertText, setFormAlertText] = useState("");
  // Dialog
  const [ageGroupDetailActionDialogText, setAgeGroupDetailActionDialogText] =
    useState("");
  const [ageGroupDetailActionDialogType, setAgeGroupDetailActionDialogType] =
    useState(null);
  const [showAgeGroupDetailAction, setShowAgeGroupDetailAction] =
    useState(false);
  // Error Fields
  const [errorFields, setErrorFields] = useState([]);
  // Option Fields
  const [endAgeField, setEndAgeField] = useState(null);
  const [startAgeField, setStartAgeField] = useState(null);
  // Switches
  const [isItemActive, setIsItemActive] = useState(true);
  // Text Fields
  const [code, setCode] = useState("");
  const [nameCh, setNameCh] = useState("");
  const [nameEn, setNameEn] = useState("");

  // Events
  // Events - Dialogs
  const onAgeGroupDetailActionCanceled = () => {
    // Set States
    setShowAgeGroupDetailAction(false);
  };

  const onAgeGroupDetailActionConfirmed = () => {
    switch (ageGroupDetailActionDialogType) {
      case "CreateAgeGroup":
        createAgeGroup();
        break;
      case "EditAgeGroup":
        editAgeGroup();
        break;
      default:
        break;
    }

    // Set States
    setShowAgeGroupDetailAction(false);
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    setShouldShowFormAlert(false);

    // Set States
    switch (field) {
      case "code":
        setCode(value);
        break;
      case "endAgeField":
        setEndAgeField(value);
        break;
      case "isItemActive":
        setIsItemActive(value);
        break;
      case "nameCh":
        setNameCh(value);
        break;
      case "nameEn":
        setNameEn(value);
        break;
      case "startAgeField":
        setStartAgeField(value);
        break;
      default:
        break;
    }

    removeErrorField(field);
  };

  const onInputFieldKeyPressed = (key) => {
    if (key === "Enter") {
      onSubmitBtnClicked();
    }
  };

  // Events - Forms
  const onSubmitBtnClicked = () => {
    let isError = false;

    if (!code) {
      addToErrorFields("code", t("請先填寫 代號"));
      isError = true;
    }

    if (!endAgeField && !startAgeField) {
      addToErrorFields("endAgeField", t("請先填寫 開始年齡 或 結束年齡"));
      addToErrorFields("startAgeField", t("請先填寫 開始年齡 或 結束年齡"));
      isError = true;
    }

    if (!nameCh) {
      addToErrorFields("nameCh", t("請先填寫 中文名稱"));
      isError = true;
    }

    if (!nameEn) {
      addToErrorFields("nameEn", t("請先填寫 英文名稱"));
      isError = true;
    }

    if (isError) {
      return;
    }

    displayAgeGroupDetailActionDialog(
      ageGroupId ? "EditAgeGroup" : "CreateAgeGroup"
    );
  };

  // Functions
  // Functions - Normal
  const addToErrorFields = (field, message) => {
    if (errorFields.some((item) => item.field === field)) {
      return;
    }

    // Set States
    setErrorFields((currentState) => [...currentState, { field, message }]);
  };

  const checkIsFieldError = (field) => {
    return errorFields.some((item) => item.field === field);
  };

  const clearErrorFields = () => {
    // Set States
    setErrorFields([]);
  };

  const clearForm = () => {
    // Set States
    setCode("");
    setNameCh("");
    setNameEn("");
    setStartAgeField(null);
    setEndAgeField(null);
    setIsItemActive(true);

    clearErrorFields();
  };

  const displayAgeGroupDetailActionDialog = (ageGroupDetailActionType) => {
    // Set States
    setAgeGroupDetailActionDialogType(ageGroupDetailActionType);
    switch (ageGroupDetailActionType) {
      case "CreateAgeGroup":
        setAgeGroupDetailActionDialogText(t("確認要新增 年齡層 嗎？"));
        break;
      case "EditAgeGroup":
        setAgeGroupDetailActionDialogText(t("確認要編輯 年齡層資料 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowAgeGroupDetailAction(true);
  };

  const getErrorFieldMessage = (field) => {
    const targetField = errorFields.find((item) => item.field === field);

    if (!targetField) {
      return null;
    }

    return targetField.message;
  };

  const removeErrorField = (field) => {
    // Set States
    setErrorFields((currentState) =>
      currentState.filter((item) => item.field !== field)
    );
  };

  const showFormAlert = (alertTypeStr, alertTextStr) => {
    // Set States
    setFormAlertText(alertTextStr);
    setFormAlertType(alertTypeStr);
    setShouldShowFormAlert(true);
  };

  // Functions - Mutations
  const createAgeGroup = async () => {
    const results = await createAgeGroupFetch(
      token,
      code,
      nameCh,
      nameEn,
      startAgeField ? startAgeField.value : null,
      endAgeField ? endAgeField.value : null,
      isItemActive
    );

    if (results.success) {
      showFormAlert("success", t("成功提交"));

      // Update Redux Store
      dispatch(onAgeGroupChange(results.ageGroupId));
      dispatch(onFormSubmitCountIncrease());
    } else if (results.isDuplicate) {
      showFormAlert("warning", t("此 年齡層 已存在"));
    } else {
      showFormAlert("error", t("未能提交"));
    }
  };

  const editAgeGroup = async () => {
    if (ageGroupId) {
      const results = await editAgeGroupFetch(
        token,
        ageGroupId,
        code,
        nameCh,
        nameEn,
        startAgeField ? startAgeField.value : null,
        endAgeField ? endAgeField.value : null,
        isItemActive
      );

      if (results.success) {
        showFormAlert("success", t("成功提交"));

        // Update Redux Store
        dispatch(onFormSubmitCountIncrease());
      } else if (results.isDuplicate) {
        showFormAlert("warning", t("此 年齡層 已存在"));
      } else {
        showFormAlert("error", t("未能提交"));
      }
    }
  };

  // Functions - Queries
  const getAgeGroupDetailsById = async () => {
    const results = await getAgeGroupDetailsByIdFetch(token, ageGroupId);

    if (results.ageGroupDetails) {
      const {
        age_group_code,
        age_group_name_ch,
        age_group_name_en,
        start_age,
        end_age,
        is_active,
      } = results.ageGroupDetails;

      // Set States
      setCode(age_group_code ? age_group_code : "");
      setNameCh(age_group_name_ch ? age_group_name_ch : null);
      setNameEn(age_group_name_en ? age_group_name_en : null);
      setStartAgeField(
        start_age ? ageOptions.find((item) => item.value === start_age) : null
      );
      setEndAgeField(
        end_age ? ageOptions.find((item) => item.value === end_age) : null
      );
      setIsItemActive(is_active);
    } else {
      // Set States
      setCode("");
      setNameCh("");
      setNameEn("");
      setStartAgeField(null);
      setEndAgeField(null);
      setIsItemActive(true);
    }

    clearErrorFields();
  };

  // Life Cycle
  useEffect(() => {
    if (ageGroupId) {
      getAgeGroupDetailsById();
    } else {
      clearForm();
    }

    // Set States
    setShouldShowFormAlert(false);
  }, [ageGroupId]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onAgeGroupDetailActionCanceled}
        onDialogConfirmed={onAgeGroupDetailActionConfirmed}
        // States
        dialogText={ageGroupDetailActionDialogText}
        showDialog={showAgeGroupDetailAction}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("年齡層資料")}
          </Typography>
        </div>
      </div>
      {/* Form */}
      <Grid
        className={classes.formContainer}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        container
        rowSpacing={1}
      >
        <Grid item xs={6}>
          <TextField
            error={checkIsFieldError("code")}
            fullWidth
            helperText={getErrorFieldMessage("code")}
            label={t("代號")}
            margin="dense"
            name="code"
            onChange={(event) => onInputFieldChange("code", event.target.value)}
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            value={code}
            variant="standard"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            error={checkIsFieldError("nameCh")}
            fullWidth
            helperText={getErrorFieldMessage("nameCh")}
            label={t("中文名稱")}
            margin="dense"
            name="nameCh"
            onChange={(event) =>
              onInputFieldChange("nameCh", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            required
            value={nameCh}
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={checkIsFieldError("nameEn")}
            fullWidth
            helperText={getErrorFieldMessage("nameEn")}
            label={t("英文名稱")}
            margin="dense"
            name="nameEn"
            onChange={(event) =>
              onInputFieldChange("nameEn", event.target.value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            required
            value={nameEn}
            variant="standard"
          />
        </Grid>
        <Grid className={classes.formAutoCompleteContainer} item xs={5}>
          <Autocomplete
            disablePortal
            getOptionLabel={(option) => option[t("textCh")]}
            onChange={(event, value) =>
              onInputFieldChange("startAgeField", value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            options={ageOptions}
            renderInput={(params) => (
              <TextField
                {...params}
                error={checkIsFieldError("startAgeField")}
                helperText={getErrorFieldMessage("startAgeField")}
                label={t("開始年齡")}
                variant="standard"
              />
            )}
            value={startAgeField}
          />
        </Grid>
        <Grid className={classes.formTwinInputContainerText} item xs={2}>
          <Typography align={"left"} variant="body1">
            {t("至")}
          </Typography>
        </Grid>
        <Grid className={classes.formAutoCompleteContainer} item xs={5}>
          <Autocomplete
            disablePortal
            getOptionLabel={(option) => option[t("textCh")]}
            onChange={(event, value) =>
              onInputFieldChange("endAgeField", value)
            }
            onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
            options={ageOptions}
            renderInput={(params) => (
              <TextField
                {...params}
                error={checkIsFieldError("endAgeField")}
                helperText={getErrorFieldMessage("endAgeField")}
                label={t("結束年齡")}
                variant="standard"
              />
            )}
            value={endAgeField}
          />
        </Grid>
        <Grid item xs={6} className={classes.formSwitchContainer}>
          <Typography align={"left"} variant="body1">
            {t("有效")}
          </Typography>
          <Switch
            checked={isItemActive}
            onChange={() => onInputFieldChange("isItemActive", !isItemActive)}
          />
        </Grid>
        <Grid item xs={12} className={classes.formSubmitBtnContainer}>
          <Button
            onClick={onSubmitBtnClicked}
            variant="contained"
            sx={formSubmitBtnStyles}
          >
            {ageGroupId ? t("修改") : t("新增")}
          </Button>
        </Grid>
        {/* Alert */}
        {shouldShowFormAlert && (
          <Grid item xs={12}>
            <Alert severity={formAlertType}>{formAlertText}</Alert>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default AgeGroupDetailForm;
