// Components
// Backdrop
import LoadingBackdrop from "../../backdrop/loadingBackdrop";
// Charts
import SalesTrendChart from "./salesTrendChart";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Consts
import { salesNumberTypes } from "../../../consts/salesNumberTypeConsts";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  getSalesTrendBySalespersonIdFetch,
  getSalesTrendByShopBrandIdFetch,
  getSalesTrendByShopIdFetch,
  getSalesTrendForWholeCompanyFetch,
} from "../../../fetches/salesTrendFetches";

// Helper Functions
import { getDateVarsForSalesTrends } from "../../../helperFunctions/getDateVarForCharts";
import { getMomentLocale } from "../../../helperFunctions/getMomentLocale";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// Moment
import moment from "moment";
import "moment/min/locales";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function SalesTrendChartContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const comparisonRecordDate = useSelector(
    (state) => state.salesReportsPage.comparisonRecordDate
  );
  const isComparisonShown = useSelector(
    (state) => state.salesReportsPage.isComparisonShown
  );
  const language = useSelector((state) => state.language.language);
  const recordDate = useSelector((state) => state.salesReportsPage.recordDate);
  const recordDateType = useSelector(
    (state) => state.salesReportsPage.recordDateType
  );
  const salesNumberType = useSelector(
    (state) => state.salesReportsPage.salesNumberType
  );
  const shopBrandId = useSelector(
    (state) => state.salesReportsPage.conditionShopBrandId
  );
  const shopId = useSelector((state) => state.salesReportsPage.conditionShopId);
  const staffId = useSelector(
    (state) => state.salesReportsPage.conditionStaffId
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [salesNumbers, setSalesNumbers] = useState(null);
  const [xAxisDataGridKey, setXAxisDataGridKey] = useState("");
  // Redner
  const [isLoading, setIsLoading] = useState(false);

  // Handle States
  const comparisonRecordDateVar = new Date(comparisonRecordDate);
  const itemsArr = salesNumbers;
  const recordDateVar = new Date(recordDate);
  const salesNumberTypeItem = salesNumberTypes.find(
    (item) => item.type === salesNumberType
  );

  const comparisonRecordDateDisplay =
    recordDateType === "Date"
      ? moment(comparisonRecordDateVar).format(t("Y 年 M 月"))
      : moment(comparisonRecordDateVar).format(t("Y 年"));
  const isMoney = salesNumberTypeItem ? salesNumberTypeItem.isMoney : false;
  const recordDateDisplay =
    recordDateType === "Date"
      ? moment(recordDateVar).format(t("Y 年 M 月"))
      : moment(recordDateVar).format(t("Y 年"));
  const salesNumberTypeItemName = salesNumberTypeItem
    ? salesNumberTypeItem[t("nameCh")]
    : "";

  // Functions
  // Functions - Queries
  const getSalesTrendBySalespersonId = async () => {
    const {
      startDateVar,
      endDateVar,
      comparisonStartDateVar,
      comparisonEndDateVar,
    } = getDateVarsForSalesTrends(
      recordDateType,
      isComparisonShown,
      recordDateVar,
      comparisonRecordDateVar
    );

    const results = await getSalesTrendBySalespersonIdFetch(
      token,
      staffId,
      salesNumberType,
      recordDateType,
      startDateVar,
      endDateVar,
      comparisonStartDateVar,
      comparisonEndDateVar
    );

    if (results.months) {
      for (let item of results.months) {
        item.salesNumber = Number(item.salesNumber);
        item.comparisonSalesNumber = item.comparisonSalesNumber
          ? Number(item.comparisonSalesNumber)
          : 0;
      }
    }

    // Set States
    setSalesNumbers(results.months ? results.months : null);

    setIsLoading(false);
  };

  const getSalesTrendByShopBrandId = async () => {
    const {
      startDateVar,
      endDateVar,
      comparisonStartDateVar,
      comparisonEndDateVar,
    } = getDateVarsForSalesTrends(
      recordDateType,
      isComparisonShown,
      recordDateVar,
      comparisonRecordDateVar
    );

    const results = await getSalesTrendByShopBrandIdFetch(
      token,
      shopBrandId,
      salesNumberType,
      recordDateType,
      startDateVar,
      endDateVar,
      comparisonStartDateVar,
      comparisonEndDateVar
    );

    if (results.months) {
      for (let item of results.months) {
        item.salesNumber = Number(item.salesNumber);
        item.comparisonSalesNumber = item.comparisonSalesNumber
          ? Number(item.comparisonSalesNumber)
          : 0;
      }
    }

    // Set States
    setSalesNumbers(results.months ? results.months : null);

    setIsLoading(false);
  };

  const getSalesTrendByShopId = async () => {
    const {
      startDateVar,
      endDateVar,
      comparisonStartDateVar,
      comparisonEndDateVar,
    } = getDateVarsForSalesTrends(
      recordDateType,
      isComparisonShown,
      recordDateVar,
      comparisonRecordDateVar
    );

    const results = await getSalesTrendByShopIdFetch(
      token,
      shopId,
      salesNumberType,
      recordDateType,
      startDateVar,
      endDateVar,
      comparisonStartDateVar,
      comparisonEndDateVar
    );

    if (results.months) {
      for (let item of results.months) {
        item.salesNumber = Number(item.salesNumber);
        item.comparisonSalesNumber = item.comparisonSalesNumber
          ? Number(item.comparisonSalesNumber)
          : 0;
      }
    }

    // Set States
    setSalesNumbers(results.months ? results.months : null);

    setIsLoading(false);
  };

  const getSalesTrendForWholeCompany = async () => {
    const {
      startDateVar,
      endDateVar,
      comparisonStartDateVar,
      comparisonEndDateVar,
    } = getDateVarsForSalesTrends(
      recordDateType,
      isComparisonShown,
      recordDateVar,
      comparisonRecordDateVar
    );

    const results = await getSalesTrendForWholeCompanyFetch(
      token,
      salesNumberType,
      recordDateType,
      startDateVar,
      endDateVar,
      comparisonStartDateVar,
      comparisonEndDateVar
    );

    if (results.months) {
      for (let item of results.months) {
        item.salesNumber = Number(item.salesNumber);
        item.comparisonSalesNumber = item.comparisonSalesNumber
          ? Number(item.comparisonSalesNumber)
          : 0;
        item.dateNumberText = moment(item.date).format(t("D日"));
        item.monthNumberText = moment(item.date).format(t("M月"));
      }
    }

    // Set States
    setSalesNumbers(results.months ? results.months : null);

    setIsLoading(false);
  };

  // Life Cycle
  useEffect(() => {
    // Set States
    setXAxisDataGridKey(
      recordDateType === "Date" ? "dateNumberText" : "monthNumberText"
    );
  }, [recordDateType]);

  useEffect(() => {
    if (shopBrandId && shopBrandId === "dummy") {
      // Set States
      setIsLoading(true);

      getSalesTrendForWholeCompany();
    }
  }, [
    salesNumberType,
    shopBrandId,
    recordDate,
    comparisonRecordDate,
    recordDateType,
    isComparisonShown,
  ]);

  useEffect(() => {
    if (
      shopBrandId &&
      shopBrandId !== "dummy" &&
      shopId &&
      shopId === "dummy"
    ) {
      // Set States
      setIsLoading(true);

      getSalesTrendByShopBrandId();
    }
  }, [
    salesNumberType,
    shopBrandId,
    shopId,
    recordDate,
    comparisonRecordDate,
    recordDateType,
    isComparisonShown,
  ]);

  useEffect(() => {
    if (
      shopBrandId &&
      shopBrandId !== "dummy" &&
      shopId &&
      shopId !== "dummy" &&
      staffId &&
      staffId === "dummy"
    ) {
      // Set States
      setIsLoading(true);

      getSalesTrendByShopId();
    }
  }, [
    salesNumberType,
    shopBrandId,
    shopId,
    staffId,
    recordDate,
    comparisonRecordDate,
    recordDateType,
    isComparisonShown,
  ]);

  useEffect(() => {
    if (
      shopBrandId &&
      shopBrandId !== "dummy" &&
      shopId &&
      shopId !== "dummy" &&
      staffId &&
      staffId !== "dummy"
    ) {
      // Set States
      setIsLoading(true);

      getSalesTrendBySalespersonId();
    }
  }, [
    salesNumberType,
    shopBrandId,
    shopId,
    staffId,
    recordDate,
    comparisonRecordDate,
    recordDateType,
    isComparisonShown,
  ]);

  useEffect(() => {
    moment.locale(getMomentLocale(language));
  }, [language]);

  return (
    <div className={classes.contentBox}>
      {/* Backdrop */}
      <LoadingBackdrop
        // States
        showBackdrop={isLoading}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {`${t("趨勢分析s")} - ${salesNumberTypeItemName}`}
          </Typography>
        </div>
      </div>
      {/* Content */}
      {itemsArr ? (
        <SalesTrendChart
          // States
          comparisonRecordDateDisplay={comparisonRecordDateDisplay}
          isComparisonShown={isComparisonShown}
          isMoney={isMoney}
          itemsArr={itemsArr}
          recordDateDisplay={recordDateDisplay}
          xAxisDataGridKey={xAxisDataGridKey}
        />
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有趨勢分析")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default SalesTrendChartContainer;
