// Helper Functions
import {
  getMonthEndDate,
  getMonthStartDate,
  getYearEndDate,
  getYearStartDate,
} from "./getDate";

export const getDateVarsForSalesNumbers = (
  recordDateType,
  isComparisonShown,
  recordDateVar,
  comparisonRecordDateVar
) => {
  // Get Dates
  const startDate = getMonthStartDate(recordDateVar);
  const endDate = getMonthEndDate(recordDateVar);
  const comparisonStartDate = comparisonRecordDateVar
    ? getMonthStartDate(comparisonRecordDateVar)
    : null;
  const comparisonEndDate = comparisonRecordDateVar
    ? getMonthEndDate(comparisonRecordDateVar)
    : null;

  // Get Date Vars
  const startDateVar = recordDateType === "Date" ? recordDateVar : startDate;
  const endDateVar = recordDateType === "Date" ? null : endDate;
  const comparisonStartDateVar = isComparisonShown
    ? recordDateType === "Date"
      ? comparisonRecordDateVar
      : comparisonStartDate
    : null;
  const comparisonEndDateVar = isComparisonShown
    ? recordDateType === "Date"
      ? null
      : comparisonEndDate
    : null;

  return {
    startDateVar,
    endDateVar,
    comparisonStartDateVar,
    comparisonEndDateVar,
  };
};

export const getDateVarsForSalesTrends = (
  recordDateType,
  isComparisonShown,
  recordDateVar,
  comparisonRecordDateVar
) => {
  // Get Dates
  const startDate =
    recordDateType === "Date"
      ? getMonthStartDate(recordDateVar)
      : getYearStartDate(recordDateVar);
  const endDate =
    recordDateType === "Date"
      ? getMonthEndDate(recordDateVar)
      : getYearEndDate(recordDateVar);
  const comparisonStartDate = comparisonRecordDateVar
    ? recordDateType === "Date"
      ? getMonthStartDate(comparisonRecordDateVar)
      : getYearStartDate(comparisonRecordDateVar)
    : null;
  const comparisonEndDate = comparisonRecordDateVar
    ? recordDateType === "Date"
      ? getMonthEndDate(comparisonRecordDateVar)
      : getYearEndDate(comparisonRecordDateVar)
    : null;

  // Get Date Vars
  const startDateVar = startDate;
  const endDateVar = endDate;
  const comparisonStartDateVar = isComparisonShown ? comparisonStartDate : null;
  const comparisonEndDateVar = isComparisonShown ? comparisonEndDate : null;

  return {
    startDateVar,
    endDateVar,
    comparisonStartDateVar,
    comparisonEndDateVar,
  };
};
