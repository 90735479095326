// Configs
import apisConfig from "../configs/apisConfig";

export const getCurrentRevenueBasicInfoFetch = async (token) => {
  try {
    const queryRoute = "/salesNumber/getCurrentRevenueBasicInfo";

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getPopularSalespersonsFetch = async (
  token,
  startDate,
  endDate
) => {
  try {
    const queryRoute = "/salesNumber/getPopularSalespersons";

    const reqBody = {
      startDate,
      endDate,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getPopularShopsFetch = async (token, startDate, endDate) => {
  try {
    const queryRoute = "/salesNumber/getPopularShops";

    const reqBody = {
      startDate,
      endDate,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getSalesNumbersForSalespersonsByShopIdFetch = async (
  token,
  shopId,
  salesNumberType,
  startDate,
  endDate,
  comparisonStartDate,
  comparisonEndDate
) => {
  try {
    const queryRoute = `/salesNumber/getSalesNumbersForSalespersonsByShopId/${shopId}`;

    const reqBody = {
      salesNumberType,
      startDate,
      endDate,
      comparisonStartDate,
      comparisonEndDate,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getSalesNumbersForShopBrandsFetch = async (
  token,
  salesNumberType,
  startDate,
  endDate,
  comparisonStartDate,
  comparisonEndDate
) => {
  try {
    const queryRoute = "/salesNumber/getSalesNumbersForShopBrands";

    const reqBody = {
      salesNumberType,
      startDate,
      endDate,
      comparisonStartDate,
      comparisonEndDate,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getSalesNumbersForShopsByShopBrandIdFetch = async (
  token,
  shopBrandId,
  salesNumberType,
  startDate,
  endDate,
  comparisonStartDate,
  comparisonEndDate
) => {
  try {
    const queryRoute = `/salesNumber/getSalesNumbersForShopsByShopBrandId/${shopBrandId}`;

    const reqBody = {
      salesNumberType,
      startDate,
      endDate,
      comparisonStartDate,
      comparisonEndDate,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
