// Configs
import stylesConfig from "../../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../../customHooks/getLanguage";

// Material UI
// Components
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function MerchandiseAttributeBox(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // Events
    onDeleteDynamicFieldBtnClicked,
    onDynamicInputFieldChange,
    onInputFieldKeyPressed,
    // Functions
    checkIsDynamicFieldError,
    getDynamicErrorFieldMessage,
    // States
    attributeOptions,
    index,
    item,
    propertyOptions,
  } = props;

  // Handle States
  const itemId = item.id;

  return (
    <>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {`${t("特徵")} ${index + 1}`}
          </Typography>
        </div>
        <div className={classes.titleTextContainer}>
          {checkIsDynamicFieldError("property", itemId, "propertyField") ? (
            <Button
              endIcon={<DeleteRoundedIcon />}
              onClick={() => onDeleteDynamicFieldBtnClicked("property", itemId)}
              sx={{ color: stylesConfig.redTextColor }}
              variant="text"
            >
              {t("如不需填寫特徵，請按此刪除")}
            </Button>
          ) : (
            <Tooltip placement="right" title={t("刪除特徵")}>
              <IconButton
                onClick={() =>
                  onDeleteDynamicFieldBtnClicked("property", itemId)
                }
              >
                <DeleteRoundedIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
      {/* Content */}
      <div>
        <Grid
          className={classes.formContainer}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          container
          rowSpacing={1}
        >
          <Grid className={classes.formAutoCompleteContainer} item xs={12}>
            {propertyOptions && (
              <Autocomplete
                disablePortal
                getOptionLabel={(option) =>
                  option[t("remarks_ch")]
                    ? `${option[t("property_name_ch")]} (${
                        option[t("remarks_ch")]
                      })`
                    : option[t("property_name_ch")]
                }
                onChange={(event, value) =>
                  onDynamicInputFieldChange(
                    "property",
                    itemId,
                    "propertyField",
                    value
                  )
                }
                onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                options={propertyOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={checkIsDynamicFieldError(
                      "property",
                      itemId,
                      "propertyField"
                    )}
                    helperText={getDynamicErrorFieldMessage(
                      "property",
                      itemId,
                      "propertyField"
                    )}
                    label={t("特徵")}
                    variant="standard"
                  />
                )}
                value={item.propertyField}
              />
            )}
          </Grid>
          {item.propertyField && (
            <Grid className={classes.formAutoCompleteContainer} item xs={12}>
              {attributeOptions && (
                <Autocomplete
                  disableCloseOnSelect
                  disablePortal
                  getOptionLabel={(option) => option.attribute_code}
                  multiple
                  onChange={(event, value) =>
                    onDynamicInputFieldChange(
                      "property",
                      itemId,
                      "attributeField",
                      value
                    )
                  }
                  onKeyPress={(event) => onInputFieldKeyPressed(event.key)}
                  options={attributeOptions.filter(
                    (option) => option.property_id === item.propertyField.id
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={checkIsDynamicFieldError(
                        "property",
                        itemId,
                        "attributeField"
                      )}
                      helperText={getDynamicErrorFieldMessage(
                        "property",
                        itemId,
                        "attributeField"
                      )}
                      label={t("選項")}
                      variant="standard"
                    />
                  )}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.attribute_code}
                    </li>
                  )}
                  value={item.attributeField}
                />
              )}
            </Grid>
          )}
        </Grid>
      </div>
    </>
  );
}

export default MerchandiseAttributeBox;
