// Configs
import apisConfig from "../configs/apisConfig";

export const getDiscountUsageBasicInfoFetch = async (
  token,
  discountId,
  startDate,
  endDate
) => {
  try {
    const queryRoute = `/discountUsageNumber/getDiscountUsageBasicInfo/${discountId}`;

    const reqBody = {
      startDate,
      endDate,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
