// Configs
import apisConfig from "../configs/apisConfig";

export const checkIsMerchandiseGroupActionsAvailableFetch = async (
  token,
  discountId,
  mercGrpTypeId
) => {
  try {
    const queryRoute = `/merchandiseGroup/checkIsMerchandiseGroupActionsAvailable/${discountId}`;

    const reqBody = {
      mercGrpTypeId,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const createMerchandiseGroupFetch = async (
  token,
  discountId,
  mercGrpTypeId,
  mercGrpBrdTypeId,
  mercGrpCatTypeId,
  mercGrpGenTypeId,
  mercGrpAgeTypeId,
  ageGroupIdsArr,
  brandIdsArr,
  categoryIdsArr,
  genTypeIdsArr,
  subCategoryIdsArr
) => {
  try {
    const queryRoute = `/merchandiseGroup/createMerchandiseGroup/${discountId}`;

    const reqBody = {
      mercGrpTypeId,
      mercGrpBrdTypeId,
      mercGrpCatTypeId,
      mercGrpGenTypeId,
      mercGrpAgeTypeId,
      ageGroupIdsArr,
      brandIdsArr,
      categoryIdsArr,
      genTypeIdsArr,
      subCategoryIdsArr,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const deleteMerchandiseGroupFetch = async (
  token,
  merchandiseGroupId
) => {
  try {
    const queryRoute = `/merchandiseGroup/deleteMerchandiseGroup/${merchandiseGroupId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editMerchandiseGroupFetch = async (
  token,
  merchandiseGroupId,
  mercGrpTypeId,
  mercGrpBrdTypeId,
  mercGrpCatTypeId,
  mercGrpGenTypeId,
  mercGrpAgeTypeId,
  ageGroupIdsArr,
  brandIdsArr,
  categoryIdsArr,
  genTypeIdsArr,
  subCategoryIdsArr
) => {
  try {
    const queryRoute = `/merchandiseGroup/editMerchandiseGroup/${merchandiseGroupId}`;

    const reqBody = {
      mercGrpTypeId,
      mercGrpBrdTypeId,
      mercGrpCatTypeId,
      mercGrpGenTypeId,
      mercGrpAgeTypeId,
      ageGroupIdsArr,
      brandIdsArr,
      categoryIdsArr,
      genTypeIdsArr,
      subCategoryIdsArr,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllMerchandiseGroupsByDiscountIdFetch = async (
  token,
  discountId,
  mercGrpTypeId
) => {
  try {
    const queryRoute = `/merchandiseGroup/getAllMerchandiseGroupsByDiscountId/${discountId}`;

    const reqBody = {
      mercGrpTypeId,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getMerchandiseGroupDetailsByIdFetch = async (
  token,
  merchandiseGroupId
) => {
  try {
    const queryRoute = `/merchandiseGroup/getMerchandiseGroupDetailsById/${merchandiseGroupId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
