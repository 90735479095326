// Configs
import stylesConfig from "../../configs/stylesConfig";

// Material-UI
import { createStyles, makeStyles } from "@mui/styles";

export const selectBoxContainerStyles = {
  m: 1,
  minWidth: 120,
  marginLeft: "20px",
};

export const useStandardListPageStyles = makeStyles((theme) =>
  createStyles({
    divisionsContainer: {
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: 30,
    },
    emptyDataContainer: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      height: `calc(100% - ${stylesConfig.contentPaddingTop}px)`,
      justifyContent: "center",
    },
    searchBox: {
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      marginLeft: 15,
    },
    selectBoxContainer: {
      marginRight: 20,
    },
    stepperContainer: {
      width: "100%",
    },
    tabsContainer: {
      display: "flex",
      flexDirection: "row",
    },
    topBtnsContainer: {
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    topLeftBtnsContainer: {
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
    },
    // Form
    formTwinIconBtnContainerLeft: {
      display: "flex",
      flexDirection: "row",
      marginRight: 10,
    },
    formTwinIconBtnContainerRight: {
      display: "flex",
      flexDirection: "row",
      marginLeft: 10,
    },
  })
);
