// Components
// Boxes
import MerchandiseSuggestionBox from "../../boxes/searchModal/merchandiseSuggestionBox";

// Configs
import stylesConfig from "../../../configs/stylesConfig";
import timeoutConfig from "../../../configs/timeoutConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Debounce
import debounce from "lodash.debounce";

// Fetches
import { getMerchandiseSuggestionForMerchandiseGroupByKeywordFetch } from "../../../fetches/searchFetches";

// Helper Functions
import { separateComma } from "../../../helperFunctions/separateComma";

// Material UI
// Components
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
// Icons
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import {
  formSubmitBtnStyles,
  useStandardItemBoxesContainerStyles,
} from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function SelectMerchandiseModal(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Props
  const {
    // Events
    onModalSubmitBtnClicked,
    // States
    paramsArr,
  } = props;

  // Redux Store
  const merchandiseGroupId = useSelector(
    (state) => state.discountProfilePage.merchandiseGroupId
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [checkedItemIdsArr, setCheckedItemIdsArr] = useState([]);
  const [checkedItemsArr, setCheckedItemsArr] = useState([]);
  const [suggestions, setSuggestions] = useState(null);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);
  const [hoveredItemType, setHoveredItemType] = useState(null);
  // Text Fields
  const [keyword, setKeyword] = useState("");
  // Render
  const [shouldShowCheckedItemsOnly, setShouldShowCheckedItemsOnly] =
    useState(false);

  // Handle States
  const itemsArr = !shouldShowCheckedItemsOnly ? suggestions : checkedItemsArr;

  // Events
  // Events - Boxes
  const onItemClicked = (item) => {
    const itemId = item.id;

    // Set States
    if (!checkedItemIdsArr.includes(itemId)) {
      setCheckedItemIdsArr((currentState) => [...currentState, itemId]);
      setCheckedItemsArr((currentState) => [...currentState, item]);
    } else {
      setCheckedItemIdsArr((currentState) =>
        currentState.filter((id) => id !== itemId)
      );
      setCheckedItemsArr((currentState) =>
        currentState.filter((item) => item.id !== itemId)
      );
    }
  };

  // Events - Buttons
  const onSubmitBtnClicked = () => {
    onModalSubmitBtnClicked(checkedItemIdsArr);
  };

  // Events - Fields
  const onInputFieldChange = (field, value) => {
    // Set States
    switch (field) {
      case "keyword":
        setKeyword(value);
        break;
      default:
        break;
    }
  };

  // Events - Hover
  const onMouseEnterItem = (itemId, itemType) => {
    // Set States
    setHoveredItemId(itemId);
    setHoveredItemType(itemType);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
    setHoveredItemType(null);
  };

  // Events - Titles
  const onClearCheckedItemsBtnClicked = () => {
    // Set States
    setCheckedItemIdsArr([]);
    setCheckedItemsArr([]);
  };

  const onShowCheckedItemsOnlyBtnClicked = () => {
    // Set States
    setShouldShowCheckedItemsOnly(!shouldShowCheckedItemsOnly);
  };

  // Functions
  // Functions - Normal
  const getSuggestions = async (keywordStr) => {
    switch (paramsArr[0]) {
      case "MerchandiseGroup":
        getMerchandiseSuggestionForMerchandiseGroupByKeyword(keywordStr);
        break;
      default:
        break;
    }
  };

  const getSuggestionsDebounce = debounce((keywordStr) => {
    getSuggestions(keywordStr);
  }, timeoutConfig.debouceTime);

  const showAllItems = () => {
    // Set States
    setShouldShowCheckedItemsOnly(false);
  };

  // Functions - Queries
  const getMerchandiseSuggestionForMerchandiseGroupByKeyword = async (
    keywordStr
  ) => {
    const results =
      await getMerchandiseSuggestionForMerchandiseGroupByKeywordFetch(
        token,
        merchandiseGroupId,
        keywordStr
      );

    if (results.suggestions) {
      for (let item of results.suggestions) {
        item.base_price = item.base_price
          ? `$ ${separateComma(Number(item.base_price).toFixed(1))}`
          : null;
      }
    }

    // Set States
    setSuggestions(results.suggestions ? results.suggestions : null);
  };

  // Life Cycle
  useEffect(() => {
    if (keyword) {
      getSuggestionsDebounce(keyword);
    }
  }, [keyword]);

  useEffect(() => {
    if (!checkedItemIdsArr[0]) {
      showAllItems();
    }
  }, [checkedItemIdsArr]);

  return (
    <div className={classes.modalContainerLarge}>
      {/* Content */}
      <div>
        <Grid
          className={classes.formContainer}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          container
          rowSpacing={1}
        >
          <Grid
            className={classes.formAutoCompleteStickyContainer}
            item
            xs={12}
          >
            <TextField
              autoFocus
              fullWidth
              label={t("關鍵字")}
              margin="dense"
              name="keyword"
              onChange={(event) =>
                onInputFieldChange("keyword", event.target.value)
              }
              required
              value={keyword}
              variant="standard"
            />
            {checkedItemIdsArr[0] && (
              <div className={classes.titleContainerWithoutMargin}>
                <Button
                  endIcon={<ClearRoundedIcon />}
                  onClick={onClearCheckedItemsBtnClicked}
                  variant="text"
                >
                  {t("清除已選擇商品")}
                </Button>
                <Button
                  endIcon={<KeyboardArrowDownRoundedIcon />}
                  onClick={onShowCheckedItemsOnlyBtnClicked}
                  variant="text"
                >
                  {`${t("顯示 ")}${
                    shouldShowCheckedItemsOnly ? t("所有") : t("已選擇")
                  }${t(" 商品s")}`}
                </Button>
              </div>
            )}
          </Grid>
          <Grid item xs={12}>
            {/* List */}
            {itemsArr ? (
              itemsArr.map((item) => (
                <MerchandiseSuggestionBox
                  key={`${item.type_en}-${item.id}`}
                  // States
                  checkedItemIdsArr={checkedItemIdsArr}
                  hoveredItemId={hoveredItemId}
                  hoveredItemType={hoveredItemType}
                  item={item}
                  // Events
                  onItemClicked={onItemClicked}
                  onMouseEnterItem={onMouseEnterItem}
                  onMouseLeaveItem={onMouseLeaveItem}
                />
              ))
            ) : (
              <div className={classes.emptyDataContainer}>
                <Typography align={"center"} color={stylesConfig.greyTextColor}>
                  {t("未有相關資料")}
                </Typography>
              </div>
            )}
          </Grid>
          {checkedItemIdsArr[0] && (
            <Grid item xs={12} className={classes.formSubmitBtnStickyContainer}>
              <Button
                onClick={onSubmitBtnClicked}
                variant="contained"
                sx={formSubmitBtnStyles}
              >
                {t("新增a")}
              </Button>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
}

export default SelectMerchandiseModal;
