// Actions
import {
  onIsMercGrpAvailableChange,
  onMercGrpTypeChange,
  onMercGrpTypeItemChange,
  onMerchandiseGroupChange,
  onMerchandiseGroupItemChange,
} from "../../../redux/pages/discountProfilePage/discountProfilePageActions";

// Components
// Boxes
import MerchandiseGroupBox from "./merchandiseGroupBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Menus
import MerchandiseGroupActionMenu from "../../menus/discountProfilePage/merchandiseGroupActionMenu";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  checkIsMerchandiseGroupActionsAvailableFetch,
  deleteMerchandiseGroupFetch,
  getAllMerchandiseGroupsByDiscountIdFetch,
} from "../../../fetches/merchandiseGroupFetches";
import { getAllMercGrpTypesFetch } from "../../../fetches/mercGrpTypeFetches";

// Material UI
// Components
import Autocomplete from "@mui/material/Autocomplete";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import AddRoundedIcon from "@mui/icons-material/AddRounded";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function MerchandiseGroupBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const discountId = useSelector(
    (state) => state.discountProfilePage.discountId
  );
  const formSubmitCount = useSelector(
    (state) => state.discountProfilePage.formSubmitCount
  );
  const isMercGrpAvailable = useSelector(
    (state) => state.discountProfilePage.isMercGrpAvailable
  );
  const mercGrpTypeId = useSelector(
    (state) => state.discountProfilePage.mercGrpTypeId
  );
  const mercGrpTypeItem = useSelector(
    (state) => state.discountProfilePage.mercGrpTypeItem
  );
  const merchandiseGroupId = useSelector(
    (state) => state.discountProfilePage.merchandiseGroupId
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [mercGrpTypes, setMercGrpTypes] = useState(null);
  const [merchandiseGroups, setMerchandiseGroups] = useState(null);
  // Dialog
  const [
    merchandiseGroupActionDialogText,
    setMerchandiseGroupActionDialogText,
  ] = useState("");
  const [
    merchandiseGroupActionDialogType,
    setMerchandiseGroupActionDialogType,
  ] = useState(null);
  const [
    showMerchandiseGroupActionDialog,
    setShowMerchandiseGroupActionDialog,
  ] = useState(false);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Menu
  const [showMerchandiseGroupActionMenu, setShowMerchandiseGroupActionMenu] =
    useState(null);
  // Render
  const [isCreatable, setIsCreatable] = useState(false);
  const [isOnlyMerchandiseGroup, setIsOnlyMerchandiseGroup] = useState(false);

  // Handle States
  const currentItemId = merchandiseGroupId;
  const itemsArr = merchandiseGroups;

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Update Redux Store
    dispatch(onMerchandiseGroupChange(itemId));
  };

  const onItemRightClicked = (itemId, currentTarget) => {
    if (isOnlyMerchandiseGroup) {
      return;
    }

    onItemClicked(itemId);
    // Set States
    setShowMerchandiseGroupActionMenu(currentTarget);
  };

  const onItemShowMenuBtnClicked = (itemId, currentTarget) => {
    if (isOnlyMerchandiseGroup) {
      return;
    }

    onItemClicked(itemId);
    // Set States
    setShowMerchandiseGroupActionMenu(currentTarget);
  };

  // Events - Dialogs
  const onMerchandiseGroupActionDialogCanceled = () => {
    // Set States
    setShowMerchandiseGroupActionDialog(false);
  };

  const onMerchandiseGroupActionDialogConfirmed = () => {
    switch (merchandiseGroupActionDialogType) {
      case "DeleteMerchandiseGroup":
        deleteMerchandiseGroup();
        break;
      default:
        break;
    }

    // Set States
    setShowMerchandiseGroupActionDialog(false);
  };

  // Events - Form
  const onInputFieldChange = (field, value) => {
    switch (field) {
      // Update Redux Store
      case "mercGrpType":
        dispatch(onMercGrpTypeChange(value.id));
        break;
      default:
        break;
    }
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Title
  const onAddItemBtnClicked = () => {
    // Update Redux Store
    dispatch(onMerchandiseGroupChange(null));
  };

  // Functions
  // Functions - Normal
  const displayMerchandiseGroupActionDialog = (merchandiseGroupActionType) => {
    // Set States
    setMerchandiseGroupActionDialogType(merchandiseGroupActionType);

    switch (merchandiseGroupActionType) {
      case "DeleteMerchandiseGroup":
        setMerchandiseGroupActionDialogText(t("確認要刪除 商品群組 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowMerchandiseGroupActionDialog(true);
  };

  // Functions - Mutations
  const checkIsMerchandiseGroupActionsAvailable = async () => {
    const results = await checkIsMerchandiseGroupActionsAvailableFetch(
      token,
      discountId,
      mercGrpTypeId
    );

    // Set States
    setIsCreatable(results.isCreatable ? results.isCreatable : false);
    setIsOnlyMerchandiseGroup(
      results.isOnlyMerchandiseGroup ? results.isOnlyMerchandiseGroup : false
    );

    // Update Redux Store
    dispatch(
      onIsMercGrpAvailableChange(
        results.isAvalable ? results.isAvalable : false
      )
    );
  };

  const deleteMerchandiseGroup = async () => {
    const results = await deleteMerchandiseGroupFetch(token, currentItemId);

    if (results.success) {
      getAllMerchandiseGroupsByDiscountId(true);
    }
  };

  // Functions = Queries
  const getAllMerchandiseGroupsByDiscountId = async (
    shoulddUpdateCurrentMerchandiseGroupId
  ) => {
    const results = await getAllMerchandiseGroupsByDiscountIdFetch(
      token,
      discountId,
      mercGrpTypeId
    );

    // Set States
    setMerchandiseGroups(
      results.merchandiseGroups ? results.merchandiseGroups : null
    );

    // Update Redux Store
    if (
      results.merchandiseGroups &&
      (shoulddUpdateCurrentMerchandiseGroupId ||
        !results.merchandiseGroups.some(
          (item) => item.id === merchandiseGroupId
        ))
    ) {
      dispatch(onMerchandiseGroupChange(results.merchandiseGroups[0].id));
    }
  };

  const getAllMercGrpTypes = async () => {
    const results = await getAllMercGrpTypesFetch(token);

    // Set States
    setMercGrpTypes(results.mercGrpTypes ? results.mercGrpTypes : null);

    // Update Redux Store
    if (results.mercGrpTypes && !mercGrpTypeId) {
      dispatch(onMercGrpTypeChange(results.mercGrpTypes[0].id));
    }
  };

  // Life Cycle
  useEffect(() => {
    // Update Redux Store
    if (mercGrpTypes && mercGrpTypeId) {
      dispatch(
        onMercGrpTypeItemChange(
          mercGrpTypes.find((item) => item.id === mercGrpTypeId)
        )
      );
    }
  }, [mercGrpTypes, mercGrpTypeId]);

  useEffect(() => {
    // Update Redux Store
    if (merchandiseGroups && merchandiseGroupId) {
      dispatch(
        onMerchandiseGroupItemChange(
          merchandiseGroups.find((item) => item.id === merchandiseGroupId)
        )
      );
    }
  }, [merchandiseGroups, merchandiseGroupId]);

  useEffect(() => {
    getAllMercGrpTypes();
  }, [formSubmitCount]);

  useEffect(() => {
    if (discountId && mercGrpTypeId) {
      checkIsMerchandiseGroupActionsAvailable();
      getAllMerchandiseGroupsByDiscountId(merchandiseGroupId ? false : true);
    }
  }, [discountId, mercGrpTypeId, formSubmitCount]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onMerchandiseGroupActionDialogCanceled}
        onDialogConfirmed={onMerchandiseGroupActionDialogConfirmed}
        // States
        dialogText={merchandiseGroupActionDialogText}
        showDialog={showMerchandiseGroupActionDialog}
      />
      {/* Menu */}
      <MerchandiseGroupActionMenu
        // States
        showMerchandiseGroupActionMenu={showMerchandiseGroupActionMenu}
        // Set States
        setShowMerchandiseGroupActionMenu={setShowMerchandiseGroupActionMenu}
        // Fucntions
        displayMerchandiseGroupActionDialog={
          displayMerchandiseGroupActionDialog
        }
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("商品群組類別")}
          </Typography>
        </div>
      </div>
      {/* Select Box */}
      {mercGrpTypes && (
        <div className={classes.autoCompleteContainer}>
          <Autocomplete
            disablePortal
            fullWidth
            getOptionLabel={(option) => option[t("merc_grp_type_name_ch")]}
            onChange={(event, value) =>
              onInputFieldChange("mercGrpType", value)
            }
            options={mercGrpTypes}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("商品群組類別")}
                variant="outlined"
              />
            )}
            value={mercGrpTypeItem}
          />
        </div>
      )}
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("商品群組s")}
          </Typography>
          {isMercGrpAvailable && isCreatable && (
            <Tooltip placement="right" title={t("新增商品群組")}>
              <IconButton color={"primary"} onClick={onAddItemBtnClicked}>
                <AddRoundedIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        itemsArr.map((item, index) => (
          <MerchandiseGroupBox
            key={item.id}
            // States
            currentItemId={currentItemId}
            hoveredItemId={hoveredItemId}
            index={index}
            isOnlyMerchandiseGroup={isOnlyMerchandiseGroup}
            item={item}
            // Events
            onItemClicked={onItemClicked}
            onItemRightClicked={onItemRightClicked}
            onItemShowMenuBtnClicked={onItemShowMenuBtnClicked}
            onMouseEnterItem={onMouseEnterItem}
            onMouseLeaveItem={onMouseLeaveItem}
          />
        ))
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {isMercGrpAvailable ? t("未有商品群組資料") : t("不適用")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default MerchandiseGroupBoxesContainer;
