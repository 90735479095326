// Actions
import { enterPage } from "../redux/shared/location/locationActions";

// Components
// Boxes
import AppliedDiscountBoxesContainer from "../components/boxes/salesRecordProfilePage/appliedDiscountBoxesContainer";
import DiscountedMerchandiseSoldItemBoxesContainer from "../components/boxes/salesRecordProfilePage/discountedMerchandiseSoldItemBoxesContainer";
import MerchandiseDiscountItemBoxesContainer from "../components/boxes/salesRecordProfilePage/merchandiseDiscountItemBoxesContainer";
import MerchandiseSoldBoxesContainer from "../components/boxes/salesRecordProfilePage/merchandiseSoldBoxesContainer";
import SalesRecordDiscountItemBoxesContainer from "../components/boxes/salesRecordProfilePage/salesRecordDiscountItemBoxesContainer";
import SalesRecordIntroBoxesContainer from "../components/boxes/salesRecordProfilePage/salesRecordIntroBoxesContainer";
// Headers
import SalesRecordProfilePageHeader from "../components/headers/salesRecordProfilePage/salesRecordProfilePageHeader";
// Spacing Boxes
import SpacingBox from "../components/boxes/spacing/spacingBox";

// Configs
import stylesConfig from "../configs/stylesConfig";

// Material UI
// Components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// React
import { useEffect } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

function SalesRecordProfilePage() {
  // Hooks
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const tabItem = useSelector((state) => state.salesRecordProfilePage.tabItem);
  const typeOfAppliedDiscount = useSelector(
    (state) => state.salesRecordProfilePage.typeOfAppliedDiscount
  );

  // Life Cycle
  useEffect(() => {
    // Update Redux Store
    dispatch(enterPage("SalesRecordProfile"));
  }, []);

  return (
    <Container maxWidth={false}>
      {/* Header */}
      <SpacingBox
        // Render
        height={stylesConfig.appBarHeight}
      />
      <SalesRecordProfilePageHeader />
      {/* Content */}
      <Grid container spacing={4}>
        <Grid item xs={12} md={4} lg={3}>
          <SalesRecordIntroBoxesContainer />
          <SpacingBox
            // Render
            height={stylesConfig.spacingBoxMarginBottom}
          />
          {tabItem === "BasicInfo" && (
            <>
              <AppliedDiscountBoxesContainer />
              <SpacingBox
                // Render
                height={stylesConfig.spacingBoxMarginBottom}
              />
            </>
          )}
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          {tabItem === "BasicInfo" &&
            (typeOfAppliedDiscount &&
            typeOfAppliedDiscount === "AppliedToSalesRecord" ? (
              <>
                <SalesRecordDiscountItemBoxesContainer />
                <SpacingBox
                  // Render
                  height={stylesConfig.spacingBoxMarginBottom}
                />
              </>
            ) : (
              <Grid container spacing={4}>
                <Grid item xs={12} lg={6}>
                  <MerchandiseSoldBoxesContainer />
                  <SpacingBox
                    // Render
                    height={stylesConfig.spacingBoxMarginBottom}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <DiscountedMerchandiseSoldItemBoxesContainer />
                  <SpacingBox
                    // Render
                    height={stylesConfig.spacingBoxMarginBottom}
                  />
                  <MerchandiseDiscountItemBoxesContainer />
                  <SpacingBox
                    // Render
                    height={stylesConfig.spacingBoxMarginBottom}
                  />
                </Grid>
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Container>
  );
}

export default SalesRecordProfilePage;
