// Actions
import {
  onDiscountChange,
  onTabItemChange,
} from "../../../redux/pages/discountProfilePage/discountProfilePageActions";

// Components
// Boxes
import DiscountBox from "./discountBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Menus
import DiscountActionMenu from "../../menus/discountsPage/discountActionMenu";
// Modals
import DiscountActionModalContainer from "../../modals/discountProfilePage/discountActionModalContainer";
import ModalContainer from "../../modals/modalContainer";

// Configs
import paginationConfig from "../../../configs/paginationConfig";
import stylesConfig from "../../../configs/stylesConfig";
import timeoutConfig from "../../../configs/timeoutConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Debounce
import debounce from "lodash.debounce";

// Fetches
import {
  deleteOrRestoreDiscountFetch,
  getAllDiscountsWithPaginationFetch,
} from "../../../fetches/discountFetches";

// Helper Functions
import { getMomentLocale } from "../../../helperFunctions/getMomentLocale";

// Material UI
// Components
import IconButton from "@mui/material/IconButton";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import AddRoundedIcon from "@mui/icons-material/AddRounded";

// Moment
import moment from "moment";
import "moment/min/locales";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// React-Router
import { useNavigate } from "react-router-dom";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

// Consts
const limitNum = paginationConfig.paginationLimitNum;

function DiscountBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Router
  const navigate = useNavigate();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const discountOfferTypeId = useSelector(
    (state) => state.discountsPage.conditionDiscountOfferTypeId
  );
  const discountStatusTypeId = useSelector(
    (state) => state.discountsPage.conditionDiscountStatusTypeId
  );
  const formSubmitCount = useSelector(
    (state) => state.discountsPage.formSubmitCount
  );
  const keyword = useSelector((state) => state.discountsPage.keyword);
  const language = useSelector((state) => state.language.language);
  const token = useSelector((state) => state.staff.token);
  const typeOfDiscounts = useSelector(
    (state) => state.discountsPage.typeOfDiscounts
  );

  // States
  // Data
  const [discounts, setDiscounts] = useState(null);
  // Dialog
  const [discountActionDialogText, setDiscountActionDialogText] = useState("");
  const [discountActionDialogType, setDiscountActionDialogType] =
    useState(null);
  const [showDiscountActionDialog, setShowDiscountActionDialog] =
    useState(false);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Menu
  const [showDiscountActionMenu, setShowDiscountActionMenu] = useState(null);
  // Modals
  const [showDiscountActionModal, setShowDiscountActionModal] = useState(false);
  // Pagination
  const [pageCount, setPageCount] = useState(null);
  const [pageNum, setPageNum] = useState(1);
  // Render
  const [selectedDiscountId, setSelectedDiscountId] = useState(null);

  // Handle States
  const currentItemId = selectedDiscountId;
  const itemsArr = discounts;

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Update Redux Store
    dispatch(onDiscountChange(itemId));
    dispatch(onTabItemChange("BasicInfo"));

    // Navigate
    navigate("/discountProfile");
  };

  const onItemRightClicked = (itemId, currentTarget) => {
    // Set States
    setSelectedDiscountId(itemId);
    setShowDiscountActionMenu(currentTarget);
  };

  const onItemShowMenuBtnClicked = (itemId, currentTarget) => {
    // Set States
    setSelectedDiscountId(itemId);
    setShowDiscountActionMenu(currentTarget);
  };

  // Events - Dialogs
  const onDiscountActionDialogCanceled = () => {
    // Set States
    setShowDiscountActionDialog(false);
  };

  const onDiscountActionDialogConfirmed = () => {
    switch (discountActionDialogType) {
      case "DeleteDiscount":
        deleteOrRestoreDiscount();
        break;
      case "RestoreDiscount":
        deleteOrRestoreDiscount();
        break;
      default:
        break;
    }

    // Set States
    setShowDiscountActionDialog(false);
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Modal
  const onDiscountActionModalClosed = () => {
    // Set States
    setShowDiscountActionModal(false);
  };

  // Events - Pagination
  const onPageChange = (value) => {
    // Set States
    setPageNum(value);
  };

  // Events - Title
  const onAddItemBtnClicked = () => {
    displayDiscountActionModal();
  };

  // Functions
  // Functions - Normal
  const displayDiscountActionDialog = (discountActionType) => {
    // Set States
    setDiscountActionDialogType(discountActionType);

    switch (discountActionType) {
      case "DeleteDiscount":
        setDiscountActionDialogText(t("確認要刪除 優惠 嗎？"));
        break;
      case "RestoreDiscount":
        setDiscountActionDialogText(t("確認要還原 優惠 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowDiscountActionDialog(true);
  };

  const displayDiscountActionModal = () => {
    setShowDiscountActionModal(true);
  };

  const getAllDiscountsDebounce = debounce((keywordStr) => {
    getAllDiscountsWithPagination(keywordStr);
  }, timeoutConfig.debouceTime);

  // Functions - Mutations
  const deleteOrRestoreDiscount = async () => {
    const results = await deleteOrRestoreDiscountFetch(
      token,
      currentItemId,
      typeOfDiscounts
    );

    if (results.success) {
      getAllDiscountsWithPagination(keyword);
    }
  };

  // Functions - Queries
  const getAllDiscountsWithPagination = async (keywordStr) => {
    const results = await getAllDiscountsWithPaginationFetch(
      token,
      discountStatusTypeId !== "dummy" ? discountStatusTypeId : null,
      discountOfferTypeId !== "dummy" ? discountOfferTypeId : null,
      typeOfDiscounts,
      keywordStr,
      pageNum,
      limitNum
    );

    if (results.discounts) {
      for (let item of results.discounts) {
        item.startDateDisplay = item.startDate
          ? moment(item.startDate).format(t("Y 年 M 月 D 日"))
          : null;

        item.endDateDisplay = item.endDate
          ? moment(item.endDate).format(t("Y 年 M 月 D 日"))
          : null;
      }
    }

    // Set States
    setPageCount(results.pageCount ? results.pageCount : 0);
    setDiscounts(results.discounts ? results.discounts : null);

    if (results.pageCount && !results.discounts) {
      setPageNum(results.pageCount);
    }
  };

  // Life Cycle
  useEffect(() => {
    getAllDiscountsDebounce(keyword);
  }, [keyword]);

  useEffect(() => {
    getAllDiscountsWithPagination(keyword);
  }, [
    discountOfferTypeId,
    discountStatusTypeId,
    pageNum,
    typeOfDiscounts,
    formSubmitCount,
    language,
  ]);

  useEffect(() => {
    moment.locale(getMomentLocale(language));
  }, [language]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onDiscountActionDialogCanceled}
        onDialogConfirmed={onDiscountActionDialogConfirmed}
        // States
        dialogText={discountActionDialogText}
        showDialog={showDiscountActionDialog}
      />
      {/* Menu */}
      <DiscountActionMenu
        // States
        showDiscountActionMenu={showDiscountActionMenu}
        // Set States
        setShowDiscountActionMenu={setShowDiscountActionMenu}
        // Fucntions
        displayDiscountActionDialog={displayDiscountActionDialog}
      />
      {/* Modals */}
      <ModalContainer
        // Events
        onModalClosed={onDiscountActionModalClosed}
        // States
        showModal={showDiscountActionModal}
      >
        <DiscountActionModalContainer
          // Events
          onModalClosed={onDiscountActionModalClosed}
          // States
          defaultAttributeId={null}
          defaultAttributeType={null}
          discountId={null}
        />
      </ModalContainer>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("優惠列表")}
          </Typography>
          <Tooltip placement="right" title={t("新增優惠")}>
            <IconButton color={"primary"} onClick={onAddItemBtnClicked}>
              <AddRoundedIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        <>
          {itemsArr.map((item) => (
            <DiscountBox
              key={item.id}
              // States
              currentItemId={currentItemId}
              hoveredItemId={hoveredItemId}
              item={item}
              // Events
              onItemClicked={onItemClicked}
              onItemRightClicked={onItemRightClicked}
              onItemShowMenuBtnClicked={onItemShowMenuBtnClicked}
              onMouseEnterItem={onMouseEnterItem}
              onMouseLeaveItem={onMouseLeaveItem}
            />
          ))}
          <Stack className={classes.paginationStack} spacing={2}>
            <Pagination
              color={"primary"}
              count={pageCount}
              onChange={(event, page) => onPageChange(page)}
              page={pageNum}
            />
          </Stack>
        </>
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有優惠資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default DiscountBoxesContainer;
