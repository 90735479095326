export function onConditionBrandChange(conditionBrandId) {
  return {
    type: "ON_STOCKS_PAGE_CONDITION_BRAND_CHANGE",
    conditionBrandId,
  };
}

export function onConditionBrandItemChange(conditionBrandItem) {
  return {
    type: "ON_STOCKS_PAGE_CONDITION_BRAND_ITEM_CHANGE",
    conditionBrandItem,
  };
}

export function onConditionCategoryChange(conditionCategoryId) {
  return {
    type: "ON_STOCKS_PAGE_CONDITION_CATEGORY_CHANGE",
    conditionCategoryId,
  };
}

export function onConditionCategoryItemChange(conditionCategoryItem) {
  return {
    type: "ON_STOCKS_PAGE_CONDITION_CATEGORY_ITEM_CHANGE",
    conditionCategoryItem,
  };
}

export function onConditionShopChange(conditionShopId) {
  return {
    type: "ON_STOCKS_PAGE_CONDITION_SHOP_CHANGE",
    conditionShopId,
  };
}

export function onConditionShopItemChange(conditionShopItem) {
  return {
    type: "ON_STOCKS_PAGE_CONDITION_SHOP_ITEM_CHANGE",
    conditionShopItem,
  };
}

export function onConditionSubCategoryChange(conditionSubCategoryId) {
  return {
    type: "ON_STOCKS_PAGE_CONDITION_SUB_CATEGORY_CHANGE",
    conditionSubCategoryId,
  };
}

export function onConditionSubCategoryItemChange(conditionSubCategoryItem) {
  return {
    type: "ON_STOCKS_PAGE_CONDITION_SUB_CATEGORY_ITEM_CHANGE",
    conditionSubCategoryItem,
  };
}

export function onConditionWarehouseChange(conditionWarehouseId) {
  return {
    type: "ON_STOCKS_PAGE_CONDITION_WAREHOUSE_CHANGE",
    conditionWarehouseId,
  };
}

export function onConditionWarehouseItemChange(conditionWarehouseItem) {
  return {
    type: "ON_STOCKS_PAGE_CONDITION_WAREHOUSE_ITEM_CHANGE",
    conditionWarehouseItem,
  };
}

export function onConditionWarehouseTypeChange(conditionWarehouseType) {
  return {
    type: "ON_STOCKS_PAGE_CONDITION_WAREHOUSE_TYPE_CHANGE",
    conditionWarehouseType,
  };
}

export function onFormSubmitCountIncrease() {
  return {
    type: "ON_STOCKS_PAGE_FORM_SUBMIT_COUNT_INCREASE",
  };
}

export function onKeywordChange(keyword) {
  return {
    type: "ON_MERCHANDISES_SALES_PAGE_KEYWORD_CHANGE",
    keyword,
  };
}

export function onMerchandiseChange(merchandiseId, merchandiseCodeText) {
  return {
    type: "ON_STOCKS_PAGE_MERCHANDISE_CHANGE",
    merchandiseId,
    merchandiseCodeText,
  };
}

export function onTabItemChange(tabItem) {
  return {
    type: "ON_STOCKS_PAGE_TAB_ITEM_CHANGE",
    tabItem,
  };
}

export function onTypeOfRankedWarehouseContentChange(
  typeOfRankedWarehouseContent
) {
  return {
    type: "ON_STOCKS_PAGE_TYPE_OF_RANKD_WAREHOUSE_CONTENT_CHANGE",
    typeOfRankedWarehouseContent,
  };
}

export function onVariationChange(variationId, variationAttributeText) {
  return {
    type: "ON_STOCKS_PAGE_VARIATION_CHANGE",
    variationId,
    variationAttributeText,
  };
}
