// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Material-UI
import { createStyles, makeStyles } from "@mui/styles";

export const appBarContainerStyles = {
  alignItems: "flex-end",
  boxShadow: "none",
  color: "black",
  display: "flex",
  flexDirection: "row",
  height: stylesConfig.appBarHeight,
  paddingLeft: { xs: "0px", xl: `${stylesConfig.leftDrawerWidth}px` },
};

export const showMenuBtnStyles = {
  display: { xs: "flex", xl: "none" },
};

export const useAppBarStyles = makeStyles((theme) =>
  createStyles({
    contentContainer: {
      alignItems: "center",
      backgroundColor:
        theme.palette.mode === "light"
          ? stylesConfig.appBarBackgroundColorLight
          : stylesConfig.appBarBackgroundColorDark,
      borderRadius: stylesConfig.borderRadiusExtraSmall,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginLeft: "24px",
      marginRight: "24px",
      marginTop: "20px",
      width: "100%",
    },
    rightContainer: {
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
    },
    searchBtnContainer: {
      marginRight: "20px",
    },
    selfInfoContainer: {
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    selfInfoContentContainer: {
      alignItems: "flex-end",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      "& :hover": {
        cursor: "pointer",
      },
    },
    spaceHolder: { display: { xs: "none", xl: "flex" } },
  })
);
