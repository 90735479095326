export function onAgeGroupChange(ageGroupId) {
  return {
    type: "ON_AGE_GROUPS_PAGE_AGE_GROUP_CHANGE",
    ageGroupId,
  };
}

export function onFormSubmitCountIncrease() {
  return {
    type: "ON_AGE_GROUPS_PAGE_FORM_SUBMIT_COUNT_INCREASE",
  };
}

export function onTabItemChange(tabItem) {
  return {
    type: "ON_AGE_GROUPS_PAGE_TAB_ITEM_CHANGE",
    tabItem,
  };
}

export function onTypeOfAgeGroupsChange(typeOfAgeGroups) {
  return {
    type: "ON_AGE_GROUPS_PAGE_TYPE_OF_AGE_GROUPS_CHANGE",
    typeOfAgeGroups,
  };
}
