// Components
import StyledMenu from "../styledMenu";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import MenuItem from "@mui/material/MenuItem";
// Icons
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import RestoreRoundedIcon from "@mui/icons-material/RestoreRounded";

// React-Redux
import { useSelector } from "react-redux";

function PropertyActionMenu(props) {
  // Hooks
  // Languages
  const t = useLanguage();

  // Props
  const {
    // States
    showPropertyActionMenu,
    // Set States
    setShowPropertyActionMenu,
    // Functions
    displayPropertyActionDialog,
  } = props;

  // Redux Store
  const typeOfProperties = useSelector(
    (state) => state.merchandisesPage.typeOfProperties
  );

  // Events
  const onMenuClosed = () => {
    // Set States
    setShowPropertyActionMenu(null);
  };

  const onMenuItemClicked = (key) => {
    onMenuClosed();

    switch (key) {
      case "Delete":
        displayPropertyActionDialog("DeleteProperty");
        break;
      case "Restore":
        displayPropertyActionDialog("RestoreProperty");
        break;
      default:
        break;
    }
  };

  return (
    <StyledMenu
      // States
      anchorEl={showPropertyActionMenu}
      open={Boolean(showPropertyActionMenu)}
      // Events
      onClose={onMenuClosed}
    >
      {typeOfProperties === "Current" ? (
        <MenuItem disableRipple onClick={() => onMenuItemClicked("Delete")}>
          <DeleteRoundedIcon />
          {t("刪除")}
        </MenuItem>
      ) : (
        <MenuItem disableRipple onClick={() => onMenuItemClicked("Restore")}>
          <RestoreRoundedIcon />
          {t("還原")}
        </MenuItem>
      )}
    </StyledMenu>
  );
}

export default PropertyActionMenu;
