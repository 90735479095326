// Configs
import apisConfig from "../configs/apisConfig";

export const createPropertyFetch = async (
  token,
  propertyCode,
  propertyNameCh,
  propertyNameEn,
  remarksCh,
  remarksEn,
  isActive
) => {
  try {
    const queryRoute = "/property/createProperty";

    const reqBody = {
      propertyCode,
      propertyNameCh,
      propertyNameEn,
      remarksCh,
      remarksEn,
      isActive,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const deleteOrRestorePropertyFetch = async (
  token,
  propertyId,
  typeOfProperties
) => {
  try {
    const queryRoute = `/property/deleteOrRestoreProperty/${propertyId}`;

    const reqBody = { typeOfProperties };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editPropertyFetch = async (
  token,
  propertyId,
  propertyCode,
  propertyNameCh,
  propertyNameEn,
  remarksCh,
  remarksEn,
  isActive
) => {
  try {
    const queryRoute = `/property/editProperty/${propertyId}`;

    const reqBody = {
      propertyCode,
      propertyNameCh,
      propertyNameEn,
      remarksCh,
      remarksEn,
      isActive,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllPropertiesFetch = async (token, typeOfProperties) => {
  try {
    const queryRoute = "/property/getAllProperties";

    const reqBody = { typeOfProperties };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllPropertiesByMerchandiseIdFetch = async (
  token,
  merchandiseId
) => {
  try {
    const queryRoute = `/property/getAllPropertiesByMerchandiseId/${merchandiseId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllPropertiesAndAttributesByMerchandiseIdFetch = async (
  token,
  merchandiseId
) => {
  try {
    const queryRoute = `/property/getAllPropertiesAndAttributesByMerchandiseId/${merchandiseId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getPropertyDetailsByIdFetch = async (token, propertyId) => {
  try {
    const queryRoute = `/property/getPropertyDetailsById/${propertyId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
