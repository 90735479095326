// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// Styles
import { useStandardItemBoxStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxStyles";

function DiscountedMerchandiseSoldItemBox(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxStyles();

  // Props
  const {
    // States
    currentItemId,
    hoveredItemId,
    index,
    item,
    // Events
    onItemClicked,
    onMouseEnterItem,
    onMouseLeaveItem,
  } = props;

  // Handle States
  const isHovered = hoveredItemId && hoveredItemId === item.id;
  const isSelected = currentItemId && currentItemId === item.id;

  return (
    <div
      className={
        isSelected
          ? classes.selectedItemContainer
          : isHovered
          ? classes.hoveredItemContainer
          : classes.itemContainer
      }
      onMouseEnter={() => onMouseEnterItem(item.id)}
      onMouseLeave={onMouseLeaveItem}
    >
      <div
        className={classes.itemContentContainer}
        onClick={() => onItemClicked(item.id)}
      >
        <div className={classes.itemContentSubContainerLarge}>
          <Typography
            align={"left"}
            color={
              isSelected
                ? stylesConfig.whiteTextColor
                : isHovered
                ? stylesConfig.mainTextColor
                : null
            }
            variant="body1"
          >
            {`${t("第 ")}${index + 1}${t(" 件")}`}
          </Typography>
          <Typography
            align={"left"}
            color={
              isSelected
                ? stylesConfig.whiteTextColor
                : stylesConfig.greyTextColor
            }
            variant="body2"
          >
            {item.is_add_on ? t("加購商品") : t("一般商品")}
          </Typography>
        </div>
        <div className={classes.itemContentSubContainerRight}>
          <Typography
            align={"left"}
            color={
              isSelected
                ? stylesConfig.whiteTextColor
                : isHovered
                ? stylesConfig.mainTextColor
                : null
            }
            sx={{ textDecoration: "line-through" }}
            variant="body1"
          >
            {`${t("原價")} - ${item.product_price_display}`}
          </Typography>
          <Typography
            align={"left"}
            color={
              isSelected
                ? stylesConfig.whiteTextColor
                : stylesConfig.redTextColor
            }
            variant="body2"
          >
            {`${t("折實價")} - ${item.actual_price_display}`}
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default DiscountedMerchandiseSoldItemBox;
