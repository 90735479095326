// Components
// Boxes
import SalesRecordIntroBasicInfoBox from "./salesRecordIntroBasicInfoBox";
import SalesRecordIntroPaymentInfoBox from "./salesRecordIntroPaymentInfoBox";
import SalesRecordIntroSalespersonInfoBox from "./salesRecordIntroSalespersonInfoBox";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { getSalesRecordDetailsByIdFetch } from "../../../fetches/salesRecordFetches";

// Helper Functions
import { getMomentLocale } from "../../../helperFunctions/getMomentLocale";
import { separateComma } from "../../../helperFunctions/separateComma";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// Moment
import moment from "moment";
import "moment/min/locales";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function SalesRecordIntroBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const formSubmitCount = useSelector(
    (state) => state.salesRecordProfilePage.formSubmitCount
  );
  const language = useSelector((state) => state.language.language);
  const salesRecordId = useSelector(
    (state) => state.salesRecordProfilePage.salesRecordId
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [salesRecordDetails, setSalesRecordDetails] = useState(null);

  // Handle States
  const item = salesRecordDetails;

  // Functions
  // Functions - Queries
  const getSalesRecordDetailsById = async () => {
    const results = await getSalesRecordDetailsByIdFetch(token, salesRecordId);

    if (results.salesRecordDetails) {
      results.salesRecordDetails.sub_total_display = results.salesRecordDetails
        .sub_total
        ? `$ ${separateComma(
            Number(results.salesRecordDetails.sub_total).toFixed(1)
          )}`
        : null;

      results.salesRecordDetails.total_price_display = results
        .salesRecordDetails.total_price
        ? `$ ${separateComma(
            Number(results.salesRecordDetails.total_price).toFixed(1)
          )}`
        : null;

      results.salesRecordDetails.discount_amount_display =
        results.salesRecordDetails.sub_total &&
        results.salesRecordDetails.total_price
          ? `$ ${separateComma(
              (
                Number(results.salesRecordDetails.sub_total) -
                Number(results.salesRecordDetails.total_price)
              ).toFixed(1)
            )}`
          : null;

      results.salesRecordDetails.time_created = results.salesRecordDetails
        .time_created
        ? moment(results.salesRecordDetails.time_created).format(t("a h:mm"))
        : null;
    }

    // Set States
    setSalesRecordDetails(
      results.salesRecordDetails ? results.salesRecordDetails : null
    );
  };

  // Life Cycle
  useEffect(() => {
    if (salesRecordId) {
      getSalesRecordDetailsById();
    }
  }, [salesRecordId, formSubmitCount, language]);

  useEffect(() => {
    moment.locale(getMomentLocale(language));
  }, [language]);

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("銷售紀錄")}
          </Typography>
        </div>
      </div>
      {/* Content */}
      {item ? (
        <>
          <SalesRecordIntroBasicInfoBox
            // States
            item={item}
          />
        </>
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有銷售紀錄資料")}
          </Typography>
        </div>
      )}
      {item && (
        <>
          {/* Content */}
          {item.salespersonBasicInfo ? (
            <SalesRecordIntroSalespersonInfoBox
              // States
              item={item}
            />
          ) : (
            <div className={classes.emptyDataContainer}>
              <Typography align={"center"} color={stylesConfig.greyTextColor}>
                {t("未有銷售員資料")}
              </Typography>
            </div>
          )}
        </>
      )}
      {item && (
        <>
          {/* Title */}
          <div className={classes.titleContainer}>
            <div className={classes.titleTextContainer}>
              <Typography variant="h6" align={"left"}>
                {t("結算")}
              </Typography>
            </div>
          </div>
          {/* Content */}
          <SalesRecordIntroPaymentInfoBox
            // States
            item={item}
          />
        </>
      )}
    </div>
  );
}

export default SalesRecordIntroBoxesContainer;
