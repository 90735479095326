// Configs
import apisConfig from "../configs/apisConfig";

export const createOrEditMerchandiseToPropertiesAndAttributesFetch = async (
  token,
  merchandiseId,
  propertyIdsArr,
  attributeIdsArr
) => {
  try {
    const queryRoute = `/merchandiseToProperty/createOrEditMerchandiseToPropertiesAndAttributes/${merchandiseId}`;

    const reqBody = {
      propertyIdsArr,
      attributeIdsArr,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const removeMerchandiseToPropertiesAndAttributesFetch = async (
  token,
  merchandiseId
) => {
  try {
    const queryRoute = `/merchandiseToProperty/removeMerchandiseToPropertiesAndAttributes/${merchandiseId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
