// Components
// Boxes
import PopularSalespersonBox from "./popularSalespersonBox";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { getPopularSalespersonsFetch } from "../../../fetches/salesNumberFetches";

// Helper Functions
import { separateComma } from "../../../helperFunctions/separateComma";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

// Consts
const today = new Date();

function PopularSalespersonBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [popularSalespersons, setPopularSalespersons] = useState(null);

  // Handle States
  const itemsArr = popularSalespersons;

  // Functions
  // Functions - Queries
  const getPopularSalespersons = async () => {
    const results = await getPopularSalespersonsFetch(token, today, null);

    if (results.popularSalespersons) {
      for (let item of results.popularSalespersons) {
        item.revenue = item.revenue
          ? `$ ${separateComma(Number(item.revenue).toFixed(1))}`
          : null;
      }
    }

    // Set States
    setPopularSalespersons(
      results.popularSalespersons ? results.popularSalespersons : null
    );
  };

  // Life Cycle
  useEffect(() => {
    getPopularSalespersons();
  }, []);

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("本日最佳員工 (營業額)")}
          </Typography>
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        itemsArr.map((item) => (
          <PopularSalespersonBox
            key={item.id}
            // States
            item={item}
          />
        ))
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有最佳員工資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default PopularSalespersonBoxesContainer;
