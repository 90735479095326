// Configs
import apisConfig from "../configs/apisConfig";

export const createDiscountFetch = async (
  token,
  discountOfferTypeId,
  discountConditionTypeId,
  discountCalculationTypeId,
  discountRuleTypeId,
  discountPaymentTypeId,
  discountCode,
  discountNameCh,
  discountNameEn,
  isTimeLimit,
  startDate,
  endDate,
  isCumulative,
  isAddOnsIncluded,
  isActive,
  paymentMethodIdsArr,
  defaultAttributeType,
  defaultAttributeId
) => {
  try {
    const queryRoute = "/discount/createDiscount";

    const reqBody = {
      discountOfferTypeId,
      discountConditionTypeId,
      discountCalculationTypeId,
      discountRuleTypeId,
      discountPaymentTypeId,
      discountCode,
      discountNameCh,
      discountNameEn,
      isTimeLimit,
      startDate,
      endDate,
      isCumulative,
      isAddOnsIncluded,
      isActive,
      paymentMethodIdsArr,
      defaultAttributeType,
      defaultAttributeId,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const deleteOrRestoreDiscountFetch = async (
  token,
  discountId,
  typeOfDiscounts
) => {
  try {
    const queryRoute = `/discount/deleteOrRestoreDiscount/${discountId}`;

    const reqBody = { typeOfDiscounts };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editDiscountFetch = async (
  token,
  discountId,
  discountOfferTypeId,
  discountConditionTypeId,
  discountCalculationTypeId,
  discountRuleTypeId,
  discountPaymentTypeId,
  discountCode,
  discountNameCh,
  discountNameEn,
  isTimeLimit,
  startDate,
  endDate,
  isCumulative,
  isAddOnsIncluded,
  isActive,
  paymentMethodIdsArr
) => {
  try {
    const queryRoute = `/discount/editDiscount/${discountId}`;

    const reqBody = {
      discountOfferTypeId,
      discountConditionTypeId,
      discountCalculationTypeId,
      discountRuleTypeId,
      discountPaymentTypeId,
      discountCode,
      discountNameCh,
      discountNameEn,
      isTimeLimit,
      startDate,
      endDate,
      isCumulative,
      isAddOnsIncluded,
      isActive,
      paymentMethodIdsArr,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllDiscountsWithPaginationFetch = async (
  token,
  discountStatusTypeId,
  discountOfferTypeId,
  typeOfDiscounts,
  keyword,
  pageNum,
  limitNum
) => {
  try {
    const queryRoute = "/discount/getAllDiscountsWithPagination";

    const reqBody = {
      discountStatusTypeId,
      discountOfferTypeId,
      typeOfDiscounts,
      keyword,
      pageNum,
      limitNum,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getDiscountDetailsByIdFetch = async (token, discountId) => {
  try {
    const queryRoute = `/discount/getDiscountDetailsById/${discountId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
