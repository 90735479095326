// Material UI
// Components
import Modal from "@mui/material/Modal";

// Styles
import { useModalContainerStyles } from "../../styles/componentStyles/modalStyles/modalContainerStyles";

function ModalContainer(props) {
  // Hooks
  const classes = useModalContainerStyles();

  // Props
  const {
    // Children
    children,
    // Events
    onModalClosed,
    // States
    showModal,
  } = props;

  return (
    <Modal
      open={showModal}
      onClose={onModalClosed}
      className={classes.modalContainer}
    >
      <div className={classes.modalBoxContainer}>
        <div className={classes.modalBoxContent}>{children}</div>
      </div>
    </Modal>
  );
}

export default ModalContainer;
