// Actions
import {
  onPropertyChange,
  onTypeOfContentChange,
} from "../../../redux/pages/merchandisesPage/merchandisesPageActions";

// Components
// Boxes
import PropertyBox from "./propertyBox";
// Dialogs
import AlertDialog from "../../dialogs/alertDialog";
// Menus
import PropertyActionMenu from "../../menus/merchandisesPage/propertyActionMenu";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  deleteOrRestorePropertyFetch,
  getAllPropertiesFetch,
} from "../../../fetches/propertyFetches";

// Material UI
// Components
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function PropertyBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Redux
  const dispatch = useDispatch();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const propertyId = useSelector((state) => state.merchandisesPage.propertyId);
  const formSubmitCount = useSelector(
    (state) => state.merchandisesPage.formSubmitCount
  );
  const token = useSelector((state) => state.staff.token);
  const typeOfProperties = useSelector(
    (state) => state.merchandisesPage.typeOfProperties
  );
  const typeOfContent = useSelector(
    (state) => state.merchandisesPage.typeOfContent
  );

  // States
  // Data
  const [properties, setProperties] = useState(null);
  // Dialog
  const [propertyActionDialogText, setPropertyActionDialogText] = useState("");
  const [propertyActionDialogType, setPropertyActionDialogType] =
    useState(null);
  const [showPropertyActionDialog, setShowPropertyActionDialog] =
    useState(false);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Menu
  const [showPropertyActionMenu, setShowPropertyActionMenu] = useState(null);

  // Handle States
  const currentItemId = propertyId;
  const itemsArr = properties;

  // Events
  // Events - Box
  const onItemClicked = (itemId) => {
    // Update Redux Store
    dispatch(onPropertyChange(itemId));
  };

  const onItemRightClicked = (itemId, currentTarget) => {
    onItemClicked(itemId);
    // Set States
    setShowPropertyActionMenu(currentTarget);
  };

  const onItemShowMenuBtnClicked = (itemId, currentTarget) => {
    onItemClicked(itemId);
    // Set States
    setShowPropertyActionMenu(currentTarget);
  };

  // Events - Dialogs
  const onPropertyActionDialogCanceled = () => {
    // Set States
    setShowPropertyActionDialog(false);
  };

  const onPropertyActionDialogConfirmed = () => {
    switch (propertyActionDialogType) {
      case "DeleteProperty":
        deleteOrRestoreProperty();
        break;
      case "RestoreProperty":
        deleteOrRestoreProperty();
        break;
      default:
        break;
    }

    // Set States
    setShowPropertyActionDialog(false);
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Title
  const onAddItemBtnClicked = () => {
    // Update Redux Store
    dispatch(onPropertyChange(null));

    // Update Redux Store
    dispatch(onTypeOfContentChange("PropertyDetail"));
  };

  const onChangeTypeOfContentBtnClicked = () => {
    // Update Redux Store
    dispatch(
      onTypeOfContentChange(
        typeOfContent === "AttributeList" ? "PropertyDetail" : "AttributeList"
      )
    );
  };

  // Functions
  // Functions - Normal
  const displayPropertyActionDialog = (propertyActionType) => {
    // Set States
    setPropertyActionDialogType(propertyActionType);

    switch (propertyActionType) {
      case "DeleteProperty":
        setPropertyActionDialogText(t("確認要刪除 特徵 嗎？"));
        break;
      case "RestoreProperty":
        setPropertyActionDialogText(t("確認要還原 特徵 嗎？"));
        break;
      default:
        break;
    }

    // Set States
    setShowPropertyActionDialog(true);
  };

  // Functions - Mutations
  const deleteOrRestoreProperty = async () => {
    const results = await deleteOrRestorePropertyFetch(
      token,
      currentItemId,
      typeOfProperties
    );

    if (results.success) {
      getAllProperties(true);
    }
  };

  // Functions - Queries
  const getAllProperties = async (shoulddUpdateCurrentPropertyId) => {
    const results = await getAllPropertiesFetch(token, typeOfProperties);

    // Set States
    setProperties(results.properties ? results.properties : null);

    // Update Redux Store
    if (shoulddUpdateCurrentPropertyId) {
      dispatch(
        onPropertyChange(results.properties ? results.properties[0].id : null)
      );
    }
  };

  // Life Cycle
  useEffect(() => {
    getAllProperties(propertyId ? false : true);
  }, [formSubmitCount, typeOfProperties]);

  return (
    <div className={classes.contentBox}>
      {/* Dialog */}
      <AlertDialog
        // Events
        onDialogClosed={onPropertyActionDialogCanceled}
        onDialogConfirmed={onPropertyActionDialogConfirmed}
        // States
        dialogText={propertyActionDialogText}
        showDialog={showPropertyActionDialog}
      />
      {/* Menu */}
      <PropertyActionMenu
        // States
        showPropertyActionMenu={showPropertyActionMenu}
        // Set States
        setShowPropertyActionMenu={setShowPropertyActionMenu}
        // Fucntions
        displayPropertyActionDialog={displayPropertyActionDialog}
      />
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("特徵s")}
          </Typography>
          <Tooltip placement="right" title={t("新增特徵")}>
            <IconButton color={"primary"} onClick={onAddItemBtnClicked}>
              <AddRoundedIcon />
            </IconButton>
          </Tooltip>
        </div>
        <Button
          endIcon={<ChevronRightRoundedIcon />}
          onClick={onChangeTypeOfContentBtnClicked}
          variant="text"
        >{`${t("顯示 ")}${
          typeOfContent === "AttributeList" ? t("特徵資料") : t("選項列表")
        }`}</Button>
      </div>
      {/* List */}
      {itemsArr ? (
        itemsArr.map((item) => (
          <PropertyBox
            key={item.id}
            // States
            currentItemId={currentItemId}
            hoveredItemId={hoveredItemId}
            item={item}
            // Events
            onItemClicked={onItemClicked}
            onItemRightClicked={onItemRightClicked}
            onItemShowMenuBtnClicked={onItemShowMenuBtnClicked}
            onMouseEnterItem={onMouseEnterItem}
            onMouseLeaveItem={onMouseLeaveItem}
          />
        ))
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有特徵資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default PropertyBoxesContainer;
