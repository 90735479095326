// Configs
import apisConfig from "../configs/apisConfig";

export const createDiscountRuleFetch = async (
  token,
  discountId,
  ruleTypeNum,
  discountNum
) => {
  try {
    const queryRoute = `/discountRule/createDiscountRule/${discountId}`;

    const reqBody = {
      ruleTypeNum,
      discountNum,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const deleteDiscountRuleFetch = async (token, discountRuleId) => {
  try {
    const queryRoute = `/discountRule/deleteDiscountRule/${discountRuleId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editDiscountRuleFetch = async (
  token,
  discountRuleId,
  ruleTypeNum,
  discountNum
) => {
  try {
    const queryRoute = `/discountRule/editDiscountRule/${discountRuleId}`;

    const reqBody = {
      ruleTypeNum,
      discountNum,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllDiscountRulesByDiscountIdFetch = async (
  token,
  discountId
) => {
  try {
    const queryRoute = `/discountRule/getAllDiscountRulesByDiscountId/${discountId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getDiscountRuleDetailsByIdFetch = async (
  token,
  discountRuleId
) => {
  try {
    const queryRoute = `/discountRule/getDiscountRuleDetailsById/${discountRuleId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getDiscountRulesDetailByDiscountIdFetch = async (
  token,
  discountId
) => {
  try {
    const queryRoute = `/discountRule/getDiscountRulesDetailByDiscountId/${discountId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
