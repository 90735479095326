// Configs
import apisConfig from "../configs/apisConfig";

export const createSubCategoryFetch = async (
  token,
  categoryId,
  subCategoryCode,
  subCategoryNameCh,
  subCategoryNameEn,
  isActive
) => {
  try {
    const queryRoute = "/subCategory/createSubCategory";

    const reqBody = {
      categoryId,
      subCategoryCode,
      subCategoryNameCh,
      subCategoryNameEn,
      isActive,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const deleteOrRestoreSubCategoryFetch = async (
  token,
  subCategoryId,
  typeOfSubCategories
) => {
  try {
    const queryRoute = `/subCategory/deleteOrRestoreSubCategory/${subCategoryId}`;

    const reqBody = { typeOfSubCategories };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editSubCategoryFetch = async (
  token,
  subCategoryId,
  categoryId,
  subCategoryCode,
  subCategoryNameCh,
  subCategoryNameEn,
  isActive
) => {
  try {
    const queryRoute = `/subCategory/editSubCategory/${subCategoryId}`;

    const reqBody = {
      categoryId,
      subCategoryCode,
      subCategoryNameCh,
      subCategoryNameEn,
      isActive,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllSubCategoriesByCategoryIdFetch = async (
  token,
  categoryId,
  typeOfSubCategories
) => {
  try {
    const queryRoute = `/subCategory/getAllSubCategoriesByCategoryId/${categoryId}`;

    const reqBody = { typeOfSubCategories };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getSubCategoryDetailsByIdFetch = async (token, subCategoryId) => {
  try {
    const queryRoute = `/subCategory/getSubCategoryDetailsById/${subCategoryId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
