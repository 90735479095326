// Components
// Boxes
import MerchandiseAttributeBox from "./merchandiseAttributeBox";
// Menus
import MerchandiseAttributeActionMenu from "../../menus/merchandisesPage/merchandiseAttributeActionMenu";
// Modals
import ModalContainer from "../../modals/modalContainer";
import MerchandiseAttributeActionModal from "../../modals/merchandisesPage/merchandiseAttributeActionModal";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { getAllPropertiesByMerchandiseIdFetch } from "../../../fetches/propertyFetches";

// Material UI
// Components
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// Icons
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function MerchandiseAttributeBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const formSubmitCount = useSelector(
    (state) => state.merchandisesPage.formSubmitCount
  );
  const merchandiseId = useSelector(
    (state) => state.merchandisesPage.merchandiseId
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [merchandiseAttributes, setMerchandiseAttributes] = useState(null);
  // Hover
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // Menu
  const [
    showMerchandiseAttributeActionMenu,
    setShowMerchandiseAttributeActionMenu,
  ] = useState(null);
  // Modals
  const [
    showMerchandiseAttributeActionModal,
    setShowMerchandiseAttributeActionModal,
  ] = useState(false);

  // Handle States
  const itemsArr = merchandiseAttributes;

  // Events
  // Events - Box
  const onItemClicked = () => {
    displayMerchandiseAttributeActionModal();
  };

  const onItemRightClicked = (currentTarget) => {
    // Set States
    setShowMerchandiseAttributeActionMenu(currentTarget);
  };

  // Events - Hover
  const onMouseEnterItem = (itemId) => {
    // Set States
    setHoveredItemId(itemId);
  };

  const onMouseLeaveItem = () => {
    // Set States
    setHoveredItemId(null);
  };

  // Events - Modal
  const onMerchandiseAttributeActionModalClosed = () => {
    getAllPropertiesByMerchandiseId();
    // Set States
    setShowMerchandiseAttributeActionModal(false);
  };

  // Events - Title
  const onAddItemBtnClicked = () => {
    displayMerchandiseAttributeActionModal();
  };

  const onEditItemBtnClicked = () => {
    displayMerchandiseAttributeActionModal();
  };

  // Functions
  // Functions - Normal
  const clearData = () => {
    // Set States
    setMerchandiseAttributes(null);
  };

  const displayMerchandiseAttributeActionModal = () => {
    setShowMerchandiseAttributeActionModal(true);
  };

  // Functions - Queries
  const getAllPropertiesByMerchandiseId = async () => {
    const results = await getAllPropertiesByMerchandiseIdFetch(
      token,
      merchandiseId
    );

    // Set States
    setMerchandiseAttributes(results.properties ? results.properties : null);
  };

  // Life Cycle
  useEffect(() => {
    if (merchandiseId) {
      getAllPropertiesByMerchandiseId();
    } else {
      clearData();
    }
  }, [merchandiseId, formSubmitCount]);

  return (
    <div className={classes.contentBox}>
      {/* Menu */}
      <MerchandiseAttributeActionMenu
        // States
        showMerchandiseAttributeActionMenu={showMerchandiseAttributeActionMenu}
        // Set States
        setShowMerchandiseAttributeActionMenu={
          setShowMerchandiseAttributeActionMenu
        }
        // Functions
        displayMerchandiseAttributeActionModal={
          displayMerchandiseAttributeActionModal
        }
      />
      {/* Modal */}
      <ModalContainer
        // Events
        onModalClosed={onMerchandiseAttributeActionModalClosed}
        // States
        showModal={showMerchandiseAttributeActionModal}
      >
        <MerchandiseAttributeActionModal
          // Events
          onModalClosed={onMerchandiseAttributeActionModalClosed}
        />
      </ModalContainer>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("選項s")}
          </Typography>
          {itemsArr ? (
            <Tooltip placement="right" title={t("編輯選項")}>
              <IconButton color={"primary"} onClick={onEditItemBtnClicked}>
                <EditRoundedIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip placement="right" title={t("新增選項")}>
              <IconButton color={"primary"} onClick={onAddItemBtnClicked}>
                <AddRoundedIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        itemsArr.map((item) => (
          <MerchandiseAttributeBox
            key={item.id}
            // States
            hoveredItemId={hoveredItemId}
            item={item}
            // Events
            onItemClicked={onItemClicked}
            onItemRightClicked={onItemRightClicked}
            onMouseEnterItem={onMouseEnterItem}
            onMouseLeaveItem={onMouseLeaveItem}
          />
        ))
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有選項資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default MerchandiseAttributeBoxesContainer;
