// Configs
import apisConfig from "../configs/apisConfig";

export const getRankedMerchandiseStocksWithPaginationFetch = async (
  token,
  categoryId,
  subCategoryId,
  brandId,
  keyword,
  shopId,
  warehouseId,
  pageNum,
  limitNum
) => {
  try {
    const queryRoute =
      "/merchandiseStock/getRankedMerchandiseStocksWithPagination";

    const reqBody = {
      categoryId,
      subCategoryId,
      brandId,
      keyword,
      shopId,
      warehouseId,
      pageNum,
      limitNum,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getRankedShopStocksByMerchandiseIdWithPaginationFetch = async (
  token,
  merchandiseId,
  variationId,
  pageNum,
  limitNum
) => {
  try {
    const queryRoute = `/merchandiseStock/getRankedShopStocksByMerchandiseIdWithPagination/${merchandiseId}`;

    const reqBody = { variationId, pageNum, limitNum };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getRankedWarehouseStocksByMerchandiseIdWithPaginationFetch =
  async (token, merchandiseId, variationId, pageNum, limitNum) => {
    try {
      const queryRoute = `/merchandiseStock/getRankedWarehouseStocksByMerchandiseIdWithPagination/${merchandiseId}`;

      const reqBody = { variationId, pageNum, limitNum };

      const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...reqBody,
        }),
      });

      const results = await fetchRes.json();

      return results;
    } catch (err) {
      console.log(err.message);
    }
  };

export const getRankedVariationStocksByMerchandiseIdWithPaginationFetch =
  async (token, merchandiseId, shopId, warehouseId, pageNum, limitNum) => {
    try {
      const queryRoute = `/merchandiseStock/getRankedVariationStocksByMerchandiseIdWithPagination/${merchandiseId}`;

      const reqBody = {
        shopId,
        warehouseId,
        pageNum,
        limitNum,
      };

      const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...reqBody,
        }),
      });

      const results = await fetchRes.json();

      return results;
    } catch (err) {
      console.log(err.message);
    }
  };
