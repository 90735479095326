// Components
// Boxes
import PopularMerchandiseBox from "./popularMerchandiseBox";

// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import {
  getPopularMerchandisesByShopBrandIdFetch,
  getPopularMerchandisesByShopIdFetch,
  getPopularMerchandisesFetch,
} from "../../../fetches/merchandiseSalesNumberFetches";

// Helper Functions
import {
  getMonthEndDate,
  getMonthStartDate,
} from "../../../helperFunctions/getDate";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function PopularMerchandiseBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const recordDate = useSelector((state) => state.salesReportsPage.recordDate);
  const recordDateType = useSelector(
    (state) => state.salesReportsPage.recordDateType
  );
  const shopBrandId = useSelector(
    (state) => state.salesReportsPage.conditionShopBrandId
  );
  const shopBrandItem = useSelector(
    (state) => state.salesReportsPage.conditionShopBrandItem
  );
  const shopId = useSelector((state) => state.salesReportsPage.conditionShopId);
  const shopItem = useSelector(
    (state) => state.salesReportsPage.conditionShopItem
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [popularMerchandises, setPopularMerchandises] = useState(null);

  // Handle States
  const itemsArr = popularMerchandises;
  const merchandiseTypeDisplay =
    shopBrandId === "dummy"
      ? t("全公司")
      : shopId === "dummy"
      ? shopBrandItem
        ? shopBrandItem.brand_name_en_full
        : ""
      : shopItem
      ? shopItem.shop_code
      : "";
  const recordDateVar = new Date(recordDate);

  // Functions
  // Functions - Queries
  const getPopularMerchandises = async () => {
    const startDate = getMonthStartDate(recordDateVar);
    const endDate = getMonthEndDate(recordDateVar);

    const results = await getPopularMerchandisesFetch(
      token,
      recordDateType === "Date" ? recordDateVar : startDate,
      recordDateType === "Date" ? null : endDate
    );

    // Set States
    setPopularMerchandises(
      results.popularMerchandises ? results.popularMerchandises : null
    );
  };

  const getPopularMerchandisesByShopBrandId = async () => {
    const startDate = getMonthStartDate(recordDateVar);
    const endDate = getMonthEndDate(recordDateVar);

    const results = await getPopularMerchandisesByShopBrandIdFetch(
      token,
      shopBrandId,
      recordDateType === "Date" ? recordDateVar : startDate,
      recordDateType === "Date" ? null : endDate
    );

    // Set States
    setPopularMerchandises(
      results.popularMerchandises ? results.popularMerchandises : null
    );
  };

  const getPopularMerchandisesByShopId = async () => {
    const startDate = getMonthStartDate(recordDateVar);
    const endDate = getMonthEndDate(recordDateVar);

    const results = await getPopularMerchandisesByShopIdFetch(
      token,
      shopId,
      recordDateType === "Date" ? recordDateVar : startDate,
      recordDateType === "Date" ? null : endDate
    );

    // Set States
    setPopularMerchandises(
      results.popularMerchandises ? results.popularMerchandises : null
    );
  };

  // Life Cycle
  useEffect(() => {
    if (shopBrandId && shopBrandId === "dummy") {
      getPopularMerchandises();
    }
  }, [shopBrandId, recordDate, recordDateType]);

  useEffect(() => {
    if (
      shopBrandId &&
      shopBrandId !== "dummy" &&
      shopId &&
      shopId === "dummy"
    ) {
      getPopularMerchandisesByShopBrandId();
    }
  }, [shopBrandId, shopId, recordDate, recordDateType]);

  useEffect(() => {
    if (
      shopBrandId &&
      shopBrandId !== "dummy" &&
      shopId &&
      shopId !== "dummy"
    ) {
      getPopularMerchandisesByShopId();
    }
  }, [shopBrandId, shopId, recordDate, recordDateType]);

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {`${t("暢銷商品s")} - ${merchandiseTypeDisplay}`}
          </Typography>
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        itemsArr.map((item) => (
          <PopularMerchandiseBox
            key={item.id}
            // States
            item={item}
          />
        ))
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有暢銷商品資料")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default PopularMerchandiseBoxesContainer;
