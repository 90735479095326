// Actions
import {
  onDiscountNumberTypeChange,
  onRecordDateTypeChange,
} from "../../../redux/pages/discountProfilePage/discountProfilePageActions";

// Consts
import { discountNumberTypes } from "../../../consts/discountNumberTypeConsts";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
// Icons
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function DiscountReportConditionBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const discountNumberType = useSelector(
    (state) => state.discountProfilePage.discountNumberType
  );
  const recordDateType = useSelector(
    (state) => state.discountProfilePage.recordDateType
  );

  // Handle States
  const discountNumberTypeItem = discountNumberTypes.find(
    (item) => item.type === discountNumberType
  );

  // Events
  // Events - Form
  const onInputFieldChange = (field, value) => {
    switch (field) {
      // Update Redux Store
      case "discountNumberType":
        dispatch(onDiscountNumberTypeChange(value.type));
        break;
      default:
        break;
    }
  };

  // Events - Title
  const onChangeRecordDateTypeBtnClicked = () => {
    // Update Redux Store
    dispatch(
      onRecordDateTypeChange(recordDateType === "Date" ? "Month" : "Date")
    );
  };

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("優惠數據類別")}
          </Typography>
        </div>
        <Button
          endIcon={<ChevronRightRoundedIcon />}
          onClick={onChangeRecordDateTypeBtnClicked}
          variant="text"
        >
          {recordDateType === "Date"
            ? t("以月為顯示單位")
            : t("以日為顯示單位")}
        </Button>
      </div>
      {/* Select Box */}
      <div className={classes.autoCompleteContainer}>
        <Autocomplete
          disablePortal
          fullWidth
          getOptionLabel={(option) => option[t("nameCh")]}
          onChange={(event, value) =>
            onInputFieldChange("discountNumberType", value)
          }
          options={discountNumberTypes}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("優惠數據類別")}
              variant="outlined"
            />
          )}
          value={discountNumberTypeItem}
        />
      </div>
    </div>
  );
}

export default DiscountReportConditionBoxesContainer;
