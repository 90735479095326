// Consts
import { languageOptions } from "../consts/languageConsts";

export const getChangeLanguageItem = (currentLanguage) => {
  const changeLanguageItem = languageOptions.find(
    (item) => item.value !== currentLanguage
  );

  return changeLanguageItem ? changeLanguageItem : null;
};

export const getCurrentLanguageItem = (currentLanguage) => {
  const currentLanguageItem = languageOptions.find(
    (item) => item.value === currentLanguage
  );

  return currentLanguageItem ? currentLanguageItem : null;
};
