export const ageOptions = [
  { value: 1, textCh: "1歲", textEn: "1 Year Old" },
  { value: 2, textCh: "2歲", textEn: "2 Years Old" },
  { value: 3, textCh: "3歲", textEn: "3 Years Old" },
  { value: 4, textCh: "4歲", textEn: "4 Years Old" },
  { value: 5, textCh: "5歲", textEn: "5 Years Old" },
  { value: 6, textCh: "6歲", textEn: "6 Years Old" },
  { value: 7, textCh: "7歲", textEn: "7 Years Old" },
  { value: 8, textCh: "8歲", textEn: "8 Years Old" },
  { value: 9, textCh: "9歲", textEn: "9 Years Old" },
  { value: 10, textCh: "10歲", textEn: "10 Years Old" },
  { value: 11, textCh: "11歲", textEn: "11 Years Old" },
  { value: 12, textCh: "12歲", textEn: "12 Years Old" },
  { value: 13, textCh: "13歲", textEn: "13 Years Old" },
  { value: 14, textCh: "14歲", textEn: "14 Years Old" },
  { value: 15, textCh: "15歲", textEn: "15 Years Old" },
  { value: 16, textCh: "16歲", textEn: "16 Years Old" },
  { value: 17, textCh: "17歲", textEn: "17 Years Old" },
  { value: 18, textCh: "18歲", textEn: "18 Years Old" },
];
