export function onComparisonRecordDateChange(comparisonRecordDate) {
  return {
    type: "ON_MERCHANDISE_SALES_PAGE_COMPARISON_RECORD_DATE_CHANGE",
    comparisonRecordDate,
  };
}

export function onConditionBrandChange(conditionBrandId) {
  return {
    type: "ON_MERCHANDISE_SALES_PAGE_CONDITION_BRAND_CHANGE",
    conditionBrandId,
  };
}

export function onConditionBrandItemChange(conditionBrandItem) {
  return {
    type: "ON_MERCHANDISE_SALES_PAGE_CONDITION_BRAND_ITEM_CHANGE",
    conditionBrandItem,
  };
}

export function onConditionCategoryChange(conditionCategoryId) {
  return {
    type: "ON_MERCHANDISE_SALES_PAGE_CONDITION_CATEGORY_CHANGE",
    conditionCategoryId,
  };
}

export function onConditionCategoryItemChange(conditionCategoryItem) {
  return {
    type: "ON_MERCHANDISE_SALES_PAGE_CONDITION_CATEGORY_ITEM_CHANGE",
    conditionCategoryItem,
  };
}

export function onConditionSubCategoryChange(conditionSubCategoryId) {
  return {
    type: "ON_MERCHANDISE_SALES_PAGE_CONDITION_SUB_CATEGORY_CHANGE",
    conditionSubCategoryId,
  };
}

export function onConditionSubCategoryItemChange(conditionSubCategoryItem) {
  return {
    type: "ON_MERCHANDISE_SALES_PAGE_CONDITION_SUB_CATEGORY_ITEM_CHANGE",
    conditionSubCategoryItem,
  };
}

export function onFormSubmitCountIncrease() {
  return {
    type: "ON_MERCHANDISE_SALES_PAGE_FORM_SUBMIT_COUNT_INCREASE",
  };
}

export function onIsComparisonShownChange(isComparisonShown) {
  return {
    type: "ON_MERCHANDISE_SALES_PAGE_IS_COMPARISON_CHANGE",
    isComparisonShown,
  };
}

export function onKeywordChange(keyword) {
  return {
    type: "ON_MERCHANDISES_SALES_PAGE_KEYWORD_CHANGE",
    keyword,
  };
}

export function onMerchandiseChange(merchandiseId) {
  return {
    type: "ON_MERCHANDISE_SALES_PAGE_MERCHANDISE_CHANGE",
    merchandiseId,
  };
}

export function onRecordDateChange(recordDate) {
  return {
    type: "ON_MERCHANDISE_SALES_PAGE_RECORD_DATE_CHANGE",
    recordDate,
  };
}

export function onRecordDateTypeChange(recordDateType) {
  return {
    type: "ON_MERCHANDISE_SALES_PAGE_RECORD_DATE_TYPE_CHANGE",
    recordDateType,
  };
}

export function onSalesNumberTypeChange(salesNumberType) {
  return {
    type: "ON_MERCHANDISE_SALES_PAGE_SALES_NUMBER_TYPE_CHANGE",
    salesNumberType,
  };
}

export function onTabItemChange(tabItem) {
  return {
    type: "ON_MERCHANDISE_SALES_PAGE_TAB_ITEM_CHANGE",
    tabItem,
  };
}
