// Configs
import stylesConfig from "../../configs/stylesConfig";

// Material UI
// Components
import Badge from "@mui/material/Badge";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import {
  listItemStyles,
  selectedListItemStyles,
} from "../../styles/componentStyles/drawerStyles/leftDrawerStyles";

function LeftDrawerListItem(props) {
  // Redux Store
  const themeMode = useSelector((state) => state.theme.themeMode);

  // Props
  const {
    // Render
    badgeContent,
    icon,
    itemKey,
    location,
    pathnamesArr,
    listItemText,
    // Events
    onDrawerItemClicked,
  } = props;

  // Hadnle States
  const isSelected = pathnamesArr.includes(location.pathname);

  return (
    <ListItem
      key={itemKey}
      button={!isSelected}
      onClick={() => onDrawerItemClicked(itemKey)}
      sx={
        isSelected
          ? {
              ...selectedListItemStyles,
              backgroundColor: stylesConfig.mainTextColor,
              boxShadow:
                themeMode === "light"
                  ? stylesConfig.shadowTinyLight
                  : stylesConfig.shadowTinyDark,
              color: stylesConfig.whiteTextColor,
            }
          : listItemStyles
      }
    >
      <Badge
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        badgeContent={badgeContent && badgeContent > 0 ? badgeContent : 0}
        color="primary"
      >
        <ListItemIcon
          sx={isSelected ? { color: stylesConfig.whiteTextColor } : null}
        >
          {icon}
        </ListItemIcon>
      </Badge>
      <ListItemText primary={listItemText} />
    </ListItem>
  );
}

export default LeftDrawerListItem;
