// Configs
import apisConfig from "../configs/apisConfig";

export const createBrandFetch = async (
  token,
  brandCode,
  brandName,
  isActive
) => {
  try {
    const queryRoute = "/brand/createBrand";

    const reqBody = {
      brandCode,
      brandName,
      isActive,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const deleteOrRestoreBrandFetch = async (
  token,
  brandId,
  typeOfBrands
) => {
  try {
    const queryRoute = `/brand/deleteOrRestoreBrand/${brandId}`;

    const reqBody = { typeOfBrands };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const editBrandFetch = async (
  token,
  brandId,
  brandCode,
  brandName,
  isActive
) => {
  try {
    const queryRoute = `/brand/editBrand/${brandId}`;

    const reqBody = {
      brandCode,
      brandName,
      isActive,
    };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllBrandsFetch = async (token, typeOfBrands) => {
  try {
    const queryRoute = "/brand/getAllBrands";

    const reqBody = { typeOfBrands };

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...reqBody,
      }),
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getBrandDetailsByIdFetch = async (token, brandId) => {
  try {
    const queryRoute = `/brand/getBrandDetailsById/${brandId}`;

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
