// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// Styles
import { useStandardItemBoxStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxStyles";

function UsageInfoBox(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxStyles();

  // Props
  const {
    // States
    item,
    recordDateDisplay,
  } = props;

  return (
    <>
      {/* 營業額 */}
      <div className={classes.itemContainer}>
        <div className={classes.itemContentContainer}>
          <div className={classes.itemContentSubContainerLarge}>
            <Typography align={"left"} variant="body1">
              {t("營業額")}
            </Typography>
            <Typography
              align={"left"}
              color={stylesConfig.greyTextColor}
              variant="body2"
            >
              {`(${recordDateDisplay})`}
            </Typography>
          </div>
          <div className={classes.itemContentSubContainerRight}>
            <Typography align={"left"} variant="body1">
              {item.revenue}
            </Typography>
            <Typography
              align={"left"}
              color={stylesConfig.greyTextColor}
              variant="body2"
            >
              {`(${item.revenueWithinPeriod})`}
            </Typography>
          </div>
        </div>
      </div>
      {/* 使用次數 */}
      <div className={classes.itemContainer}>
        <div className={classes.itemContentContainer}>
          <div className={classes.itemContentSubContainerLarge}>
            <Typography align={"left"} variant="body1">
              {t("使用次數")}
            </Typography>
            <Typography
              align={"left"}
              color={stylesConfig.greyTextColor}
              variant="body2"
            >
              {`(${recordDateDisplay})`}
            </Typography>
          </div>
          <div className={classes.itemContentSubContainerRight}>
            <Typography align={"left"} variant="body1">
              {`${item.usageCount} ${t("次s")}`}
            </Typography>
            <Typography
              align={"left"}
              color={stylesConfig.greyTextColor}
              variant="body2"
            >
              {`(${item.usageCountWithinPeriod} ${t("次s")})`}
            </Typography>
          </div>
        </div>
      </div>
    </>
  );
}

export default UsageInfoBox;
