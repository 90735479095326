// Configs
import stylesConfig from "../../../configs/stylesConfig";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Material UI
// Components
import Typography from "@mui/material/Typography";

// Styles
import { useStandardItemBoxStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxStyles";

function SalesRecordIntroSalespersonInfoBox(props) {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxStyles();

  // Props
  const {
    // States
    item,
  } = props;

  // Handle States
  const salespersonItem = item.salespersonBasicInfo
    ? item.salespersonBasicInfo
    : null;

  return (
    <div className={classes.itemContainer}>
      <div className={classes.itemContentContainer}>
        <div className={classes.itemContentSubContainer}>
          <Typography align={"left"} variant="body1">
            {`${salespersonItem.staff_code} - ${salespersonItem.full_name_en}`}
          </Typography>
          <Typography
            align={"left"}
            color={stylesConfig.greyTextColor}
            variant="body2"
          >
            {`${salespersonItem.dept_name_display} - `}
            {salespersonItem[t("title_name_ch")]}
          </Typography>
        </div>
        <div className={classes.itemContentSubContainerRight}>
          <Typography
            align={"left"}
            color={stylesConfig.greyTextColor}
            variant="body2"
          >
            {item[t("payment_method_name_ch")]}
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default SalesRecordIntroSalespersonInfoBox;
