// Configs
import colorsConfig from "../configs/colorsConfig";

export const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === "light"
      ? {
          backgroundColor: "rgb(246, 248, 250)",
          primary: {
            main: colorsConfig.mainColor,
            light: "#42a5f5",
            dark: "#1565c0",
            contrastText: "#ffffff",
          },
        }
      : {
          backgroundColor: "rgb(30, 38, 52)",
          primary: {
            main: colorsConfig.mainColor,
            light: "#42a5f5",
            dark: "#1565c0",
            contrastText: "#ffffff",
          },
        }),
  },
});
