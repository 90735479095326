// Components
// Boxes
import RankedVariationBox from "./rankedVariationBox";

// Configs
import paginationConfig from "../../../configs/paginationConfig";
import stylesConfig from "../../../configs/stylesConfig";

// Consts
import { merchandiseSalesNumberTypes } from "../../../consts/salesNumberTypeConsts";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Fetches
import { getRankedVariationsByMerchandiseIdWithPaginationFetch } from "../../../fetches/merchandiseSalesNumberFetches";

// Helper Functions
import {
  getMonthEndDate,
  getMonthStartDate,
} from "../../../helperFunctions/getDate";
import { separateComma } from "../../../helperFunctions/separateComma";

// Material UI
// Components
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

// Consts
const limitNum = paginationConfig.paginationLimitNumSmall;

function RankedVariationBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();

  // Redux Store
  const formSubmitCount = useSelector(
    (state) => state.merchandiseSalesPage.formSubmitCount
  );
  const merchandiseId = useSelector(
    (state) => state.merchandiseSalesPage.merchandiseId
  );
  const recordDate = useSelector(
    (state) => state.merchandiseSalesPage.recordDate
  );
  const recordDateType = useSelector(
    (state) => state.merchandiseSalesPage.recordDateType
  );
  const salesNumberType = useSelector(
    (state) => state.merchandiseSalesPage.salesNumberType
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [rankedVariations, setRankedVariations] = useState(null);
  // Pagination
  const [pageCount, setPageCount] = useState(null);
  const [pageNum, setPageNum] = useState(1);

  // Handle States
  const itemsArr = rankedVariations;
  const recordDateVar = new Date(recordDate);
  const salesNumberTypeItem = merchandiseSalesNumberTypes.find(
    (item) => item.type === salesNumberType
  );

  // Events
  // Events - Pagination
  const onPageChange = (value) => {
    // Set States
    setPageNum(value);
  };

  // Functions
  // Functions - Queries
  const getRankedVariationsByMerchandiseIdWithPagination = async () => {
    const startDate = getMonthStartDate(recordDateVar);
    const endDate = getMonthEndDate(recordDateVar);

    const results = await getRankedVariationsByMerchandiseIdWithPaginationFetch(
      token,
      merchandiseId,
      salesNumberType,
      recordDateType === "Date" ? recordDateVar : startDate,
      recordDateType === "Date" ? null : endDate,
      pageNum,
      limitNum
    );

    if (results.rankedVariations) {
      for (let item of results.rankedVariations) {
        item.revenue = item.revenue
          ? `$ ${separateComma(Number(item.revenue).toFixed(1))}`
          : null;
      }
    }

    // Set States
    setPageCount(results.pageCount ? results.pageCount : 0);
    setRankedVariations(
      results.rankedVariations ? results.rankedVariations : null
    );

    if (results.pageCount && !results.rankedVariations) {
      setPageNum(results.pageCount);
    }
  };

  // Life Cycle
  useEffect(() => {
    if (merchandiseId) {
      getRankedVariationsByMerchandiseIdWithPagination();
    }
  }, [
    merchandiseId,
    salesNumberType,
    recordDate,
    recordDateType,
    pageNum,
    formSubmitCount,
  ]);

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {`${t("商品選項排行")} - ${salesNumberTypeItem[t("nameCh")]}`}
          </Typography>
        </div>
      </div>
      {/* List */}
      {itemsArr ? (
        <>
          {itemsArr.map((item) => (
            <RankedVariationBox
              key={item.id}
              // States
              item={item}
              salesNumberType={salesNumberType}
            />
          ))}
          <Stack className={classes.paginationStack} spacing={2}>
            <Pagination
              color={"primary"}
              count={pageCount}
              onChange={(event, page) => onPageChange(page)}
              page={pageNum}
            />
          </Stack>
        </>
      ) : (
        <div className={classes.emptyDataContainer}>
          <Typography align={"center"} color={stylesConfig.greyTextColor}>
            {t("未有商品選項排行")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default RankedVariationBoxesContainer;
