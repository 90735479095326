// Actions
import { enterPage } from "../redux/shared/location/locationActions";

// Components
// Boxes
import PopularMerchandiseBoxesContainer from "../components/boxes/dashboardPage/popularMerchandiseBoxesContainer";
import PopularSalespersonBoxesContainer from "../components/boxes/dashboardPage/popularSalespersonBoxesContainer";
import PopularShopBoxesContainer from "../components/boxes/dashboardPage/popularShopBoxesContainer";
import RevenueInfoBoxesContainer from "../components/boxes/dashboardPage/revenueInfoBoxesContainer";
// Charts
import PaymentMethodChartContainer from "../components/charts/dashboardPage/paymentMethodChartContainer";
// Headers
import DashboardPageHeader from "../components/headers/dashboardPage/dashboardPageHeader";
// Spacing Boxes
import SpacingBox from "../components/boxes/spacing/spacingBox";

// Configs
import stylesConfig from "../configs/stylesConfig";

// Material UI
// Components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// React
import { useEffect } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

function DashboardPage() {
  // Hooks
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const tabItem = useSelector((state) => state.dashboardPage.tabItem);

  // Life Cycle
  useEffect(() => {
    // Update Redux Store
    dispatch(enterPage("Dashboard"));
  }, []);

  return (
    <Container maxWidth={false}>
      {/* Header */}
      <SpacingBox
        // Render
        height={stylesConfig.appBarHeight}
      />
      <DashboardPageHeader />
      {/* Content */}
      <Grid container spacing={4}>
        {tabItem === "Summary" && (
          <>
            <Grid item xs={12} md={6} lg={4}>
              <RevenueInfoBoxesContainer />
            </Grid>
            <Grid item xs={12} md={12} lg={8}>
              <PaymentMethodChartContainer />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <PopularShopBoxesContainer />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <PopularSalespersonBoxesContainer />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <PopularMerchandiseBoxesContainer />
            </Grid>
          </>
        )}
      </Grid>
      <SpacingBox
        // Render
        height={stylesConfig.appBarHeight}
      />
    </Container>
  );
}

export default DashboardPage;
