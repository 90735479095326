export const getMonthEndDate = (month) => {
  const monthVar = new Date(month);

  return new Date(monthVar.getFullYear(), monthVar.getMonth() + 1, 0);
};

export const getMonthStartDate = (month) => {
  const monthVar = new Date(month);

  return new Date(monthVar.getFullYear(), monthVar.getMonth(), 1);
};

export const getYearEndDate = (year) => {
  const yearVar = new Date(year);

  return new Date(yearVar.getFullYear() + 1, 0, 0);
};

export const getYearStartDate = (year) => {
  const yearVar = new Date(year);

  return new Date(yearVar.getFullYear(), 0, 1);
};
