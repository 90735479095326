// Configs
import apisConfig from "../configs/apisConfig";

export const getDiscountDetailFormOptionsFetch = async (token) => {
  try {
    const queryRoute = "/formOption/getDiscountDetailFormOptions";

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getMerchandiseAttributeFormOptionsFetch = async (token) => {
  try {
    const queryRoute = "/formOption/getMerchandiseAttributeFormOptions";

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getMerchandiseDetailFormOptionsFetch = async (token) => {
  try {
    const queryRoute = "/formOption/getMerchandiseDetailFormOptions";

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getMerchandiseGroupDetailFormOptionsFetch = async (token) => {
  try {
    const queryRoute = "/formOption/getMerchandiseGroupDetailFormOptions";

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};

export const getSubCategoryDetailFormOptionsFetch = async (token) => {
  try {
    const queryRoute = "/formOption/getSubCategoryDetailFormOptions";

    const fetchRes = await fetch(`${apisConfig.backendURL}${queryRoute}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const results = await fetchRes.json();

    return results;
  } catch (err) {
    console.log(err.message);
  }
};
