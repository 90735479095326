// Languages
import { languageEnUs } from "../languages/en-us";
import { languageZhHk } from "../languages/zh-hk";

// React-Redux
import { useSelector } from "react-redux";

export function useLanguage() {
  // Redux Store
  const language = useSelector((state) => state.language.language);

  function getLanguage(key) {
    let text = "";

    switch (language) {
      case "en-us":
        text = languageEnUs[key];
        break;
      case "zh-hk":
        text = languageZhHk[key];
        break;
      default:
        break;
    }

    return text;
  }

  return getLanguage;
}
