// Actions
import {
  onConditionShopBrandChange,
  onConditionShopBrandItemChange,
  onConditionShopChange,
  onConditionShopItemChange,
  onConditionStaffChange,
  onConditionStaffItemChange,
} from "../../../redux/pages/salesRecordsPage/salesRecordsPageActions";

// Custom Hooks
import { useLanguage } from "../../../customHooks/getLanguage";

// Dummy Datum
import { staffDummyData } from "../../../dummyDatum/staffDummyData";

// Fetches
import { getAllBrandsFetch } from "../../../fetches/shopBrandFetches";
import { getAllShopsByBrandIdFetch } from "../../../fetches/shopFetches";
import { getAllStaffsByShopIdFetch } from "../../../fetches/staffFetches";

// Material UI
// Components
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// React
import { useEffect, useState } from "react";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// Styles
import { useStandardItemBoxesContainerStyles } from "../../../styles/componentStyles/boxStyles/standardItemBoxesContainerStyles";

function SalesRecordConditionBoxesContainer() {
  // Hooks
  // Languages
  const t = useLanguage();
  // Styles
  const classes = useStandardItemBoxesContainerStyles();
  // Redux
  const dispatch = useDispatch();

  // Redux Store
  const shopBrandId = useSelector(
    (state) => state.salesRecordsPage.conditionShopBrandId
  );
  const shopBrandItem = useSelector(
    (state) => state.salesRecordsPage.conditionShopBrandItem
  );
  const shopId = useSelector((state) => state.salesRecordsPage.conditionShopId);
  const shopItem = useSelector(
    (state) => state.salesRecordsPage.conditionShopItem
  );
  const staffId = useSelector(
    (state) => state.salesRecordsPage.conditionStaffId
  );
  const staffItem = useSelector(
    (state) => state.salesRecordsPage.conditionStaffItem
  );
  const token = useSelector((state) => state.staff.token);

  // States
  // Data
  const [shopBrands, setShopBrands] = useState(null);
  const [shops, setShops] = useState(null);
  const [staffs, setStaffs] = useState(null);

  // Events
  // Events - Form
  const onInputFieldChange = (field, value) => {
    switch (field) {
      // Update Redux Store
      case "shopBrand":
        if (value) {
          dispatch(onConditionShopBrandChange(value.id));
        }
        break;
      case "shop":
        if (value) {
          dispatch(onConditionShopChange(value.id));
        }
        break;
      case "staff":
        if (value) {
          dispatch(onConditionStaffChange(value.id));
        }
        break;
      default:
        break;
    }
  };

  // Functions
  // Functions - Queries
  const getAllBrands = async () => {
    const results = await getAllBrandsFetch(token, "Current");

    // Set States
    setShopBrands(results.brands ? results.brands : null);

    // Update Redux Store
    if (results.brands && !shopBrandId) {
      dispatch(onConditionShopBrandChange(results.brands[0].id));
    }
  };

  const getAllShopsByBrandId = async () => {
    const results = await getAllShopsByBrandIdFetch(
      token,
      shopBrandId,
      "Current"
    );

    // Set States
    setShops(results.shops ? results.shops : null);

    // Update Redux Store
    if (
      results.shops &&
      (!shopId || !results.shops.some((item) => item.id === shopId))
    ) {
      dispatch(onConditionShopChange(results.shops[0].id));
    }
  };

  const getAllStaffsByShopId = async () => {
    const results = await getAllStaffsByShopIdFetch(token, shopId);

    // Set States
    setStaffs(results.staffs ? [staffDummyData, ...results.staffs] : null);

    // Update Redux Store
    if (
      results.staffs &&
      (!staffId || !results.staffs.some((item) => item.id === staffId))
    ) {
      dispatch(onConditionStaffChange(staffDummyData.id));
    }
  };

  // Life Cycle
  useEffect(() => {
    getAllBrands();
  }, []);

  useEffect(() => {
    if (shopBrandId) {
      getAllShopsByBrandId();
    }
  }, [shopBrandId]);

  useEffect(() => {
    if (shopId) {
      getAllStaffsByShopId();
    }
  }, [shopId]);

  useEffect(() => {
    // Update Redux Store
    if (shopBrands && shopBrandId) {
      dispatch(
        onConditionShopBrandItemChange(
          shopBrands.find((item) => item.id === shopBrandId)
        )
      );
    }
  }, [shopBrands, shopBrandId]);

  useEffect(() => {
    // Update Redux Store
    if (shops && shopId) {
      dispatch(
        onConditionShopItemChange(shops.find((item) => item.id === shopId))
      );
    }
  }, [shops, shopId]);

  useEffect(() => {
    // Update Redux Store
    if (staffs && staffId) {
      dispatch(
        onConditionStaffItemChange(staffs.find((item) => item.id === staffId))
      );
    }
  }, [staffs, staffId]);

  return (
    <div className={classes.contentBox}>
      {/* Title */}
      <div className={classes.titleContainer}>
        <div className={classes.titleTextContainer}>
          <Typography variant="h6" align={"left"}>
            {t("店舖")}
          </Typography>
        </div>
      </div>
      {/* Select Box */}
      {shopBrands && (
        <div className={classes.autoCompleteContainer}>
          <Autocomplete
            disablePortal
            fullWidth
            getOptionLabel={(option) => option.brand_name_en_full}
            onChange={(event, value) => onInputFieldChange("shopBrand", value)}
            options={shopBrands}
            renderInput={(params) => (
              <TextField {...params} label={t("店舖品牌")} variant="outlined" />
            )}
            value={shopBrandItem}
          />
        </div>
      )}
      {shopBrandId && (
        <>
          {/* Select Box */}
          {shops && (
            <div className={classes.autoCompleteContainer}>
              <Autocomplete
                disablePortal
                fullWidth
                getOptionLabel={(option) => option.shop_code}
                onChange={(event, value) => onInputFieldChange("shop", value)}
                options={shops}
                renderInput={(params) => (
                  <TextField {...params} label={t("店舖")} variant="outlined" />
                )}
                value={shopItem}
              />
            </div>
          )}
        </>
      )}
      {shopId && (
        <>
          {/* Title */}
          <div className={classes.titleContainer}>
            <div className={classes.titleTextContainer}>
              <Typography variant="h6" align={"left"}>
                {t("員工")}
              </Typography>
            </div>
          </div>
          {/* Select Box */}
          {staffs && (
            <div className={classes.autoCompleteContainer}>
              <Autocomplete
                disablePortal
                fullWidth
                getOptionLabel={(option) =>
                  option.id !== "dummy"
                    ? option.full_name_en
                      ? `${option.staff_code} - ${option.full_name_en}`
                      : option.staff_code
                    : option[t("full_name_ch")]
                }
                onChange={(event, value) => onInputFieldChange("staff", value)}
                options={staffs}
                renderInput={(params) => (
                  <TextField {...params} label={t("員工")} variant="outlined" />
                )}
                value={staffItem}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default SalesRecordConditionBoxesContainer;
